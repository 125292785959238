/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindAppleArtists
// ====================================================

export interface FindAppleArtists_apple_artists {
  __typename: "NetworkApiArtist";
  /**
   * Name of the artist
   */
  name: string;
  /**
   * Unique Spotify ID
   */
  eid: string;
  /**
   * Image path for artist
   */
  image_url: string | null;
}

export interface FindAppleArtists {
  /**
   * Find artists on specified network
   */
  apple_artists: FindAppleArtists_apple_artists[];
}

export interface FindAppleArtistsVariables {
  artistName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckFeature
// ====================================================

export interface CheckFeature_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has any feature at all
   */
  has_any_feature: boolean;
}

export interface CheckFeature {
  /**
   * Get information about the currently logged in user
   */
  me: CheckFeature_me;
}

export interface CheckFeatureVariables {
  features: Features[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExistingCollaboratingArtistMetadatasAndRoles
// ====================================================

export interface ExistingCollaboratingArtistMetadatasAndRoles_me_collaborating_artist_metadatas {
  __typename: "CollaboratingArtistMetadata";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface ExistingCollaboratingArtistMetadatasAndRoles_me {
  __typename: "User";
  id: string;
  /**
   * List of collaborating artist metadatas
   */
  collaborating_artist_metadatas: ExistingCollaboratingArtistMetadatasAndRoles_me_collaborating_artist_metadatas[];
}

export interface ExistingCollaboratingArtistMetadatasAndRoles_additional_artist_roles {
  __typename: "CollaboratingArtistRoleObject";
  id: string;
  /**
   * Unique role name
   */
  name: string;
  /**
   * Unique name that will be displayed in the UI
   */
  display_name: string;
}

export interface ExistingCollaboratingArtistMetadatasAndRoles_credit_roles {
  __typename: "CollaboratingArtistRoleObject";
  id: string;
  /**
   * Unique role name
   */
  name: string;
  /**
   * Unique name that will be displayed in the UI
   */
  display_name: string;
}

export interface ExistingCollaboratingArtistMetadatasAndRoles {
  /**
   * Get information about the currently logged in user
   */
  me: ExistingCollaboratingArtistMetadatasAndRoles_me;
  /**
   * Get all collaborating artist roles of type "additional_artist" for specified content_type
   */
  additional_artist_roles: ExistingCollaboratingArtistMetadatasAndRoles_additional_artist_roles[];
  /**
   * Get all collaborating artist roles of type "credit" for specified content_type
   */
  credit_roles: ExistingCollaboratingArtistMetadatasAndRoles_credit_roles[];
}

export interface ExistingCollaboratingArtistMetadatasAndRolesVariables {
  input: CollaboratingArtistContentTypeFilter;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NavHeaderD2
// ====================================================

export interface NavHeaderD2_umbrella_available_users {
  __typename: "User";
  id: string;
}

export interface NavHeaderD2_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface NavHeaderD2_me {
  __typename: "User";
  id: string;
  display_name_or_email: string;
  /**
   * Flag for the email is confirmed
   */
  email_confirmed: boolean;
  is_admin: boolean;
  is_whitelabel: boolean;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: NavHeaderD2_me_organization | null;
  /**
   * If the user has signed the latest terms and conditions
   */
  has_signed_terms_and_conditions: boolean;
  unread_notifications_count: number;
  /**
   * Flag for a user has a feature
   */
  has_feature_d2_creators: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_paid_services: boolean;
  /**
   * User should not be shown anything related to Vydia plan
   */
  has_hidden_payment_plan: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_vip: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_distribute_service_access: boolean;
  /**
   * Flag for a user has any feature at all
   */
  has_sales_trends: boolean;
  /**
   * Flag for a user has a feature
   */
  has_overview_v2: boolean;
}

export interface NavHeaderD2_branding {
  __typename: "Branding";
  id: string;
  faq_url: string;
}

export interface NavHeaderD2 {
  observer_signed_in: boolean;
  /**
   * Get linked users for a user that this user is an umbrella user with access over the linked users. Includes the main umbrella user and current user
   */
  umbrella_available_users: NavHeaderD2_umbrella_available_users[];
  /**
   * Get information about the currently logged in user
   */
  me: NavHeaderD2_me;
  branding: NavHeaderD2_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediasIndexAudioMultiButton
// ====================================================

export interface MediasIndexAudioMultiButton_i18n_UserArchive {
  __typename: "UserArchive";
  confirm: string;
  error: string;
}

export interface MediasIndexAudioMultiButton_i18n {
  __typename: "I18n";
  UserArchive: MediasIndexAudioMultiButton_i18n_UserArchive;
}

export interface MediasIndexAudioMultiButton {
  /**
   * Get translation strings for screens and components.
   */
  i18n: MediasIndexAudioMultiButton_i18n;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediasIndexVideoMultiButton
// ====================================================

export interface MediasIndexVideoMultiButton_i18n_UserArchive {
  __typename: "UserArchive";
  confirm: string;
  error: string;
}

export interface MediasIndexVideoMultiButton_i18n {
  __typename: "I18n";
  UserArchive: MediasIndexVideoMultiButton_i18n_UserArchive;
}

export interface MediasIndexVideoMultiButton {
  /**
   * Get translation strings for screens and components.
   */
  i18n: MediasIndexVideoMultiButton_i18n;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MutationComponentExample
// ====================================================

export interface MutationComponentExample_updateMetadata_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface MutationComponentExample_updateMetadata {
  __typename: "UpdateMetadataPayload";
  errors: MutationComponentExample_updateMetadata_errors[] | null;
}

export interface MutationComponentExample {
  /**
   * Update Metadata. This mutation returns a Metadata. Used to change the information about a piece of content that is not an album.
   */
  updateMetadata: MutationComponentExample_updateMetadata;
}

export interface MutationComponentExampleVariables {
  input: UpdateMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HasSignedAgreements
// ====================================================

export interface HasSignedAgreements_me {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user need to sign a new universal cla
   */
  must_sign_ucla: boolean;
  /**
   * When a user is not able to upload content due to a pending agreement from their label's owner
   */
  requires_label_owner_agreement: boolean;
  /**
   * Whether or not the user needs to acknowledge an address change
   */
  must_acknowledge_address_change: boolean;
  /**
   * Has previously signed a CLA or submitted a publishing release
   */
  show_optional_ucla: boolean;
}

export interface HasSignedAgreements {
  /**
   * Get information about the currently logged in user
   */
  me: HasSignedAgreements_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserOtpQrCode
// ====================================================

export interface UserOtpQrCode_mfa {
  __typename: "MFA";
  id: string;
  /**
   * The qr code for the user
   */
  otp_authenticator_qr_code: string | null;
}

export interface UserOtpQrCode {
  /**
   * Information used to populate the MFA/OTP screen when a user is prompted to enter MFA information
   */
  mfa: UserOtpQrCode_mfa;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: performanceRightsOrganizations
// ====================================================

export interface performanceRightsOrganizations_performance_rights_organizations {
  __typename: "PerformanceRightsOrganization";
  id: string;
  /**
   * The numeric code representing this Performance Rights Organization
   */
  code: string;
  /**
   * The society name for this Performance Rights Organization
   */
  society: string;
}

export interface performanceRightsOrganizations {
  /**
   * Gets all the available performance_rights_organizations from our system
   */
  performance_rights_organizations: performanceRightsOrganizations_performance_rights_organizations[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FindSpotifyArtists
// ====================================================

export interface FindSpotifyArtists_spotify_artists {
  __typename: "NetworkApiArtist";
  /**
   * Name of the artist
   */
  name: string;
  /**
   * Unique Spotify ID
   */
  eid: string;
  /**
   * Image path for artist
   */
  image_url: string | null;
}

export interface FindSpotifyArtists {
  /**
   * Find artists on specified network
   */
  spotify_artists: FindSpotifyArtists_spotify_artists[];
}

export interface FindSpotifyArtistsVariables {
  artistName: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpotifyForArtistsFlashMessageBanner
// ====================================================

export interface SpotifyForArtistsFlashMessageBanner_me_artists {
  __typename: "Artist";
  id: string;
}

export interface SpotifyForArtistsFlashMessageBanner_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has seen a feature
   */
  has_seen_d2_spotify_for_artists: boolean;
  /**
   * List out all Artists for the current user
   */
  artists: SpotifyForArtistsFlashMessageBanner_me_artists[];
}

export interface SpotifyForArtistsFlashMessageBanner {
  /**
   * Get information about the currently logged in user
   */
  me: SpotifyForArtistsFlashMessageBanner_me;
}

export interface SpotifyForArtistsFlashMessageBannerVariables {
  timestamp?: Scalar$DateTime | null;
  artistInput?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StorageConsumptionByFile
// ====================================================

export interface StorageConsumptionByFile_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface StorageConsumptionByFile_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface StorageConsumptionByFile_me_medias_v2_file_upload {
  __typename: "FileUpload";
  id: string;
  size_in_bytes: number | null;
  type: string | null;
}

export interface StorageConsumptionByFile_me_medias_v2 {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
  type: MediaType;
  artist: StorageConsumptionByFile_me_medias_v2_artist | null;
  metadata: StorageConsumptionByFile_me_medias_v2_metadata | null;
  /**
   * Actual file associated to this media
   */
  file_upload: StorageConsumptionByFile_me_medias_v2_file_upload | null;
}

export interface StorageConsumptionByFile_me {
  __typename: "User";
  id: string;
  /**
   * Count of medias
   */
  medias_v2_count: number;
  /**
   * Medias of the user
   */
  medias_v2: StorageConsumptionByFile_me_medias_v2[];
}

export interface StorageConsumptionByFile_i18n_UserArchive {
  __typename: "UserArchive";
  confirm: string;
  error: string;
}

export interface StorageConsumptionByFile_i18n {
  __typename: "I18n";
  UserArchive: StorageConsumptionByFile_i18n_UserArchive;
}

export interface StorageConsumptionByFile {
  /**
   * Get information about the currently logged in user
   */
  me: StorageConsumptionByFile_me;
  /**
   * Get translation strings for screens and components.
   */
  i18n: StorageConsumptionByFile_i18n;
}

export interface StorageConsumptionByFileVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: storageConsumptionProgressBar
// ====================================================

export interface storageConsumptionProgressBar_me_active_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
}

export interface storageConsumptionProgressBar_me {
  __typename: "User";
  id: string;
  current_storage_usage: number;
  /**
   * current tier permission a user is subscribed to
   */
  active_tier_permission: storageConsumptionProgressBar_me_active_tier_permission;
}

export interface storageConsumptionProgressBar {
  /**
   * Get information about the currently logged in user
   */
  me: storageConsumptionProgressBar_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: downgradeInfo
// ====================================================

export interface downgradeInfo_me_active_storage_subscription_current_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
}

export interface downgradeInfo_me_active_storage_subscription_current {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: downgradeInfo_me_active_storage_subscription_current_tier_permission;
}

export interface downgradeInfo_me_active_storage_subscription_future_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
}

export interface downgradeInfo_me_active_storage_subscription_future {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: downgradeInfo_me_active_storage_subscription_future_tier_permission;
}

export interface downgradeInfo_me_active_storage_subscription {
  __typename: "Subscription";
  id: string;
  current: downgradeInfo_me_active_storage_subscription_current | null;
  /**
   * Time when this subscription renews at
   */
  until: Scalar$DateTime;
  future: downgradeInfo_me_active_storage_subscription_future | null;
  requestedAt: Scalar$DateTime | null;
}

export interface downgradeInfo_me {
  __typename: "User";
  id: string;
  /**
   * current storage subscription a user is subscribed to
   */
  active_storage_subscription: downgradeInfo_me_active_storage_subscription | null;
}

export interface downgradeInfo {
  /**
   * Get information about the currently logged in user
   */
  me: downgradeInfo_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: storageTiers
// ====================================================

export interface storageTiers_me_active_storage_tier_tier_permission_storage_tier_charge {
  __typename: "StorageTierCharge";
  /**
   * Returns a custom ID like StorageTierID_UserID_CurrentDiscountID or StorageTierID_UserID
   */
  id: string;
  original_onetime_price: number;
  onetime_price: number;
  has_discount: boolean;
  /**
   * Prorated is a user already has a subscription plan and tries to upgrade while in active-subscription they get the remaining charge.
   */
  is_prorated: boolean;
}

export interface storageTiers_me_active_storage_tier_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Name for every TierPermission
   */
  name: string;
  /**
   * Price for a TierPermission
   */
  yearly_price: number;
  description: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
  /**
   * Minimum Storage for a tier permission, used for rendering available storage tiers
   */
  minimum_storage_bytes: number;
  /**
   * Amount charged for a TierPermission for a valid user
   */
  storage_tier_charge: storageTiers_me_active_storage_tier_tier_permission_storage_tier_charge;
}

export interface storageTiers_me_active_storage_tier {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: storageTiers_me_active_storage_tier_tier_permission;
}

export interface storageTiers_me {
  __typename: "User";
  id: string;
  current_storage_usage: number;
  /**
   * Active Plan a user is subscribed to, returns only a StorageTier
   */
  active_storage_tier: storageTiers_me_active_storage_tier;
}

export interface storageTiers_storage_tiers_tier_permission_storage_tier_charge {
  __typename: "StorageTierCharge";
  /**
   * Returns a custom ID like StorageTierID_UserID_CurrentDiscountID or StorageTierID_UserID
   */
  id: string;
  original_onetime_price: number;
  onetime_price: number;
  has_discount: boolean;
  /**
   * Prorated is a user already has a subscription plan and tries to upgrade while in active-subscription they get the remaining charge.
   */
  is_prorated: boolean;
}

export interface storageTiers_storage_tiers_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Maximum number of artists allowed
   */
  max_artist_count: number | null;
  /**
   * Name for every TierPermission
   */
  name: string;
  /**
   * Price for a TierPermission
   */
  yearly_price: number;
  description: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
  /**
   * Minimum Storage for a tier permission, used for rendering available storage tiers
   */
  minimum_storage_bytes: number;
  /**
   * Amount charged for a TierPermission for a valid user
   */
  storage_tier_charge: storageTiers_storage_tiers_tier_permission_storage_tier_charge;
}

export interface storageTiers_storage_tiers {
  __typename: "StorageTier";
  id: string;
  /**
   * Flag for a storage tier is free or paid, default is free and any other tiers are paid
   */
  is_default: boolean;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: storageTiers_storage_tiers_tier_permission;
}

export interface storageTiers {
  /**
   * Get information about the currently logged in user
   */
  me: storageTiers_me;
  storage_tiers: storageTiers_storage_tiers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StorageSpaceBanner
// ====================================================

export interface StorageSpaceBanner_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user exceeded their storage limit.
   */
  exceeded_storage_limit: boolean;
  /**
   * User should not be shown anything related to Vydia plan
   */
  has_hidden_payment_plan: boolean;
}

export interface StorageSpaceBanner {
  /**
   * Get information about the currently logged in user
   */
  me: StorageSpaceBanner_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: deleteMedias
// ====================================================

export interface deleteMedias_deleteMedias_medias {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
}

export interface deleteMedias_deleteMedias_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface deleteMedias_deleteMedias {
  __typename: "DeleteMediasPayload";
  /**
   * List of medias
   */
  medias: deleteMedias_deleteMedias_medias[] | null;
  errors: deleteMedias_deleteMedias_errors[] | null;
}

export interface deleteMedias {
  /**
   * User archive multiple Medias. This mutation returns an array of Media objects. Used to soft-delete a series of media records.
   */
  deleteMedias: deleteMedias_deleteMedias;
}

export interface deleteMediasVariables {
  input: DeleteMediasInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsPaidStorageUser
// ====================================================

export interface IsPaidStorageUser_me {
  __typename: "User";
  id: string;
  /**
   * User should not be shown anything related to Vydia plan
   */
  has_hidden_payment_plan: boolean;
  /**
   * User has a paid storage tier or storage override
   */
  is_paid_storage_user: boolean;
}

export interface IsPaidStorageUser {
  /**
   * Get information about the currently logged in user
   */
  me: IsPaidStorageUser_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptPayeeAssetSplit
// ====================================================

export interface AcceptPayeeAssetSplit_acceptPayeeAssetSplit_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AcceptPayeeAssetSplit_acceptPayeeAssetSplit_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AcceptPayeeAssetSplit_acceptPayeeAssetSplit_media {
  __typename: "Media";
  id: string;
  metadata: AcceptPayeeAssetSplit_acceptPayeeAssetSplit_media_metadata | null;
}

export interface AcceptPayeeAssetSplit_acceptPayeeAssetSplit {
  __typename: "AcceptPayeeAssetSplitPayload";
  errors: AcceptPayeeAssetSplit_acceptPayeeAssetSplit_errors[] | null;
  media: AcceptPayeeAssetSplit_acceptPayeeAssetSplit_media | null;
}

export interface AcceptPayeeAssetSplit {
  /**
   * Accept a PayeeAssetSplit for a specific media by ID. This mutation returns a Media object. Used to accept asset splits for payees through the royalty center.
   */
  acceptPayeeAssetSplit: AcceptPayeeAssetSplit_acceptPayeeAssetSplit;
}

export interface AcceptPayeeAssetSplitVariables {
  input: AcceptPayeeAssetSplitInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptRca
// ====================================================

export interface AcceptRca_acceptRca_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AcceptRca_acceptRca {
  __typename: "AcceptRcaPayload";
  errors: AcceptRca_acceptRca_errors[] | null;
}

export interface AcceptRca {
  /**
   * Accept an RCA UserAgreement for a User. This mutation returns a User object. Used to specifically accept an RCA agreement once reviewed by the user.
   */
  acceptRca: AcceptRca_acceptRca;
}

export interface AcceptRcaVariables {
  input: AcceptRcaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptTermsAndConditions
// ====================================================

export interface AcceptTermsAndConditions_acceptTermsAndConditions_user {
  __typename: "User";
  id: string;
  /**
   * If the user has signed the latest terms and conditions
   */
  has_signed_terms_and_conditions: boolean;
}

export interface AcceptTermsAndConditions_acceptTermsAndConditions_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AcceptTermsAndConditions_acceptTermsAndConditions {
  __typename: "AcceptTermsAndConditionsPayload";
  user: AcceptTermsAndConditions_acceptTermsAndConditions_user;
  errors: AcceptTermsAndConditions_acceptTermsAndConditions_errors[] | null;
}

export interface AcceptTermsAndConditions {
  /**
   * Accept the terms and conditions for a User. This mutation returns a User object. Used to accept the terms and conditions once a user has read them.
   */
  acceptTermsAndConditions: AcceptTermsAndConditions_acceptTermsAndConditions;
}

export interface AcceptTermsAndConditionsVariables {
  input: AcceptTermsAndConditionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountUnderReview
// ====================================================

export interface AccountUnderReview_me {
  __typename: "User";
  id: string;
  /**
   * Is the user's account in good standing with Vydia
   */
  good_standing: boolean;
}

export interface AccountUnderReview {
  /**
   * Get information about the currently logged in user
   */
  me: AccountUnderReview_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Branding
// ====================================================

export interface Branding_branding {
  __typename: "Branding";
  id: string;
  is_whitelabel: boolean;
  has_open_signup: boolean;
  dashboard_url: string | null;
  custom_terms_of_use_url: string | null;
  name: string;
  logo_url_default: string;
  logo_url_no_text: string;
  logo_url_white_text: string;
  logo_url_square: string;
  logo_url_sign_in: string;
  merch_email: string;
  nav_logo_url: string;
  sign_in_background_image_url: string | null;
  sign_in_logo_max_width_px: number | null;
  support_url: string;
  /**
   * Primary color for branding theme
   */
  primary_color: string;
  /**
   * Secondary color for branding theme
   */
  secondary_color: string;
  /**
   * Tertiary color for branding theme
   */
  tertiary_color: string;
  /**
   * Success color for branding theme
   */
  success_color: string;
  /**
   * Error color for branding theme
   */
  error_color: string;
  /**
   * Warning color for branding theme
   */
  warning_color: string;
  /**
   * Info color for branding theme
   */
  info_color: string;
  /**
   * Primary gradient start for branding theme
   */
  primary_gradient_start: string;
  /**
   * Primary gradient end for branding theme
   */
  primary_gradient_end: string;
  /**
   * Secondary gradient start for branding theme
   */
  secondary_gradient_start: string;
  /**
   * Secondary gradient end for branding theme
   */
  secondary_gradient_end: string;
  sign_in_additional_image_one_url: string | null;
  sign_in_additional_image_two_url: string | null;
  sign_in_additional_image_one_max_width_px: number | null;
  sign_in_additional_image_two_max_width_px: number | null;
  sign_in_custom_tagline: string | null;
  sign_in_template: BrandSignInTemplate;
  sign_in_custom_background_color: string | null;
  sign_in_gradient_start: string | null;
  sign_in_gradient_end: string | null;
  sign_in_carousel_text_color: string | null;
  sign_in_tagline_text_color: string | null;
  /**
   * Header background color for branding theme
   */
  header_background_color: string;
  /**
   * Header tab text color for branding theme
   */
  header_tab_text_color: string;
  /**
   * Header tab text selected color for branding theme
   */
  header_tab_text_selected_color: string;
  /**
   * Primary font name for branding theme
   */
  primary_font_name: string;
  /**
   * Primary font fallback for branding theme
   */
  primary_font_fallback: string;
  primary_font_url: string | null;
  primary_font_family: string;
  /**
   * Secondary font name for branding theme
   */
  secondary_font_name: string;
  /**
   * Secondary font fallback for branding theme
   */
  secondary_font_fallback: string;
  secondary_font_url: string | null;
  secondary_font_family: string;
  application_url: string | null;
  request_invite_text: string | null;
  /**
   * boolean value for if environment is production
   */
  is_production: boolean | null;
}

export interface Branding {
  branding: Branding_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsOverArtistLimit
// ====================================================

export interface IsOverArtistLimit_me {
  __typename: "User";
  id: string;
  /**
   * Is over their storage plan artist limit
   */
  is_over_artist_limit: boolean;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface IsOverArtistLimit {
  /**
   * Get information about the currently logged in user
   */
  me: IsOverArtistLimit_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEarningsStatementIngestion
// ====================================================

export interface CreateEarningsStatementIngestion_createEarningsStatementIngestion_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface CreateEarningsStatementIngestion_createEarningsStatementIngestion {
  __typename: "CreateEarningsStatementIngestionPayload";
  errors: CreateEarningsStatementIngestion_createEarningsStatementIngestion_errors[] | null;
}

export interface CreateEarningsStatementIngestion {
  /**
   * Create an EarningsStatementTransformation and ingest a period.
   */
  createEarningsStatementIngestion: CreateEarningsStatementIngestion_createEarningsStatementIngestion;
}

export interface CreateEarningsStatementIngestionVariables {
  input: CreateEarningsStatementIngestionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAssets
// ====================================================

export interface CreateAssets_createAssets_medias_assets {
  __typename: "Asset";
  id: string;
  type: AssetType;
}

export interface CreateAssets_createAssets_medias {
  __typename: "Media";
  id: string;
  /**
   * The assets associated to this media
   */
  assets: CreateAssets_createAssets_medias_assets[];
}

export interface CreateAssets_createAssets_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface CreateAssets_createAssets {
  __typename: "CreateAssetsPayload";
  /**
   * Return field for a list of medias
   */
  medias: CreateAssets_createAssets_medias[] | null;
  errors: CreateAssets_createAssets_errors[] | null;
}

export interface CreateAssets {
  /**
   * Create multiple Assets for multiple Medias. Used to make an association between medias and their assets for financials.
   */
  createAssets: CreateAssets_createAssets;
}

export interface CreateAssetsVariables {
  input: CreateAssetsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMedias
// ====================================================

export interface CreateMedias_createMedias_medias {
  __typename: "Media";
  id: string;
}

export interface CreateMedias_createMedias_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_type: string | null;
  resource_id: string | null;
}

export interface CreateMedias_createMedias {
  __typename: "CreateMediasPayload";
  medias: CreateMedias_createMedias_medias[] | null;
  errors: CreateMedias_createMedias_errors[] | null;
}

export interface CreateMedias {
  /**
   * Create multiple Media records. This mutation returns an array of Media objects.
   */
  createMedias: CreateMedias_createMedias;
}

export interface CreateMediasVariables {
  input: CreateMediasInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteAlbums
// ====================================================

export interface DeleteAlbums_deleteAlbums_albums {
  __typename: "Album";
  id: string;
  archived: boolean;
}

export interface DeleteAlbums_deleteAlbums_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface DeleteAlbums_deleteAlbums {
  __typename: "DeleteAlbumsPayload";
  /**
   * List of albums that have been deleted
   */
  albums: DeleteAlbums_deleteAlbums_albums[] | null;
  errors: DeleteAlbums_deleteAlbums_errors[] | null;
}

export interface DeleteAlbums {
  /**
   * Archive one or many Albums. This mutation returns an array of Album objects. Used to soft-delete a list of Album records.
   */
  deleteAlbums: DeleteAlbums_deleteAlbums;
}

export interface DeleteAlbumsVariables {
  input: DeleteAlbumsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteArtist
// ====================================================

export interface DeleteArtist_deleteArtist_artist {
  __typename: "Artist";
  id: string;
}

export interface DeleteArtist_deleteArtist_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface DeleteArtist_deleteArtist {
  __typename: "DeleteArtistPayload";
  artist: DeleteArtist_deleteArtist_artist | null;
  errors: DeleteArtist_deleteArtist_errors[] | null;
}

export interface DeleteArtist {
  /**
   * User archive an artist. This mutation returns an Artist object. Used to soft-delete an artist.
   */
  deleteArtist: DeleteArtist_deleteArtist;
}

export interface DeleteArtistVariables {
  input: DeleteArtistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteMedias
// ====================================================

export interface DeleteMedias_deleteMedias_medias {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
}

export interface DeleteMedias_deleteMedias_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface DeleteMedias_deleteMedias {
  __typename: "DeleteMediasPayload";
  /**
   * List of medias
   */
  medias: DeleteMedias_deleteMedias_medias[] | null;
  errors: DeleteMedias_deleteMedias_errors[] | null;
}

export interface DeleteMedias {
  /**
   * User archive multiple Medias. This mutation returns an array of Media objects. Used to soft-delete a series of media records.
   */
  deleteMedias: DeleteMedias_deleteMedias;
}

export interface DeleteMediasVariables {
  input: DeleteMediasInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseExperiment
// ====================================================

export interface UseExperiment_experiment_AdditionalServices {
  __typename: "AdditionalServices";
  id: string;
  AdditionalServicesVariant: AdditionalServicesVariant;
}

export interface UseExperiment_experiment_TestExample {
  __typename: "TestExample";
  id: string;
  TestExampleVariant: TestExampleVariant;
}

export interface UseExperiment_experiment_WhitelabelBannerAd {
  __typename: "WhitelabelBannerAd";
  id: string;
  WhitelabelBannerAdVariant: WhitelabelBannerAdVariant;
}

export interface UseExperiment_experiment_YoutubeOacPricing {
  __typename: "YoutubeOacPricing";
  id: string;
  YoutubeOacPricingVariant: YoutubeOacPricingVariant;
}

export type UseExperiment_experiment = UseExperiment_experiment_AdditionalServices | UseExperiment_experiment_TestExample | UseExperiment_experiment_WhitelabelBannerAd | UseExperiment_experiment_YoutubeOacPricing;

export interface UseExperiment {
  /**
   * Decide which Experiment (a/b test) variant to use for the current user, given the name of an experiment.
   */
  experiment: UseExperiment_experiment;
}

export interface UseExperimentVariables {
  name: ExperimentName;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: FinishGoal
// ====================================================

export interface FinishGoal_finishGoal_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface FinishGoal_finishGoal {
  __typename: "FinishGoalPayload";
  errors: FinishGoal_finishGoal_errors[] | null;
}

export interface FinishGoal {
  /**
   * Finish a split test experiment by providing the accomplished goal. This is used for A/B testing.
   */
  finishGoal: FinishGoal_finishGoal;
}

export interface FinishGoalVariables {
  input: FinishGoalInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateIsrc
// ====================================================

export interface GenerateIsrc_generateIsrc_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface GenerateIsrc_generateIsrc {
  __typename: "GenerateIsrcPayload";
  /**
   * Generated ISRC
   */
  isrc: string;
  errors: GenerateIsrc_generateIsrc_errors[] | null;
}

export interface GenerateIsrc {
  /**
   * Generate an ISRC code. This is used to generate ISRC codes for medias when filling out metadata.
   */
  generateIsrc: GenerateIsrc_generateIsrc;
}

export interface GenerateIsrcVariables {
  input: GenerateIsrcInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HasPermission
// ====================================================

export interface HasPermission_me {
  __typename: "User";
  id: string;
  /**
   * Checks if user or current observer has a permission
   */
  has_permission: boolean;
}

export interface HasPermission {
  /**
   * Get information about the currently logged in user
   */
  me: HasPermission_me;
}

export interface HasPermissionVariables {
  action: AccessPolicyAction;
  model: AccessPolicyModel;
  id?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: IngestLabelUsersAndArtists
// ====================================================

export interface IngestLabelUsersAndArtists_ingestLabelUsersAndArtists_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface IngestLabelUsersAndArtists_ingestLabelUsersAndArtists {
  __typename: "IngestLabelUsersAndArtistsPayload";
  errors: IngestLabelUsersAndArtists_ingestLabelUsersAndArtists_errors[] | null;
}

export interface IngestLabelUsersAndArtists {
  /**
   * Bulk create users and artists for a label.
   */
  ingestLabelUsersAndArtists: IngestLabelUsersAndArtists_ingestLabelUsersAndArtists;
}

export interface IngestLabelUsersAndArtistsVariables {
  input: IngestLabelUsersAndArtistsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsAdmin
// ====================================================

export interface IsAdmin_me {
  __typename: "User";
  id: string;
  is_admin: boolean;
}

export interface IsAdmin {
  /**
   * Get information about the currently logged in user
   */
  me: IsAdmin_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: IsLabel
// ====================================================

export interface IsLabel_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface IsLabel {
  /**
   * Get information about the currently logged in user
   */
  me: IsLabel_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ExampleMutation
// ====================================================

export interface ExampleMutation_acceptTermsAndConditions_user {
  __typename: "User";
  id: string;
}

export interface ExampleMutation_acceptTermsAndConditions_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ExampleMutation_acceptTermsAndConditions {
  __typename: "AcceptTermsAndConditionsPayload";
  user: ExampleMutation_acceptTermsAndConditions_user;
  errors: ExampleMutation_acceptTermsAndConditions_errors[] | null;
}

export interface ExampleMutation {
  /**
   * Accept the terms and conditions for a User. This mutation returns a User object. Used to accept the terms and conditions once a user has read them.
   */
  acceptTermsAndConditions: ExampleMutation_acceptTermsAndConditions;
}

export interface ExampleMutationVariables {
  input: AcceptTermsAndConditionsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: NotifyUploadComplete
// ====================================================

export interface NotifyUploadComplete_notifyUploadComplete_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface NotifyUploadComplete_notifyUploadComplete {
  __typename: "NotifyUploadCompletePayload";
  errors: NotifyUploadComplete_notifyUploadComplete_errors[] | null;
  /**
   * ID of the file upload
   */
  file_upload_id: string | null;
}

export interface NotifyUploadComplete {
  /**
   * Run after a file has been uploaded directly to S3 for serverless processing. This will dispatch the Lambda.
   */
  notifyUploadComplete: NotifyUploadComplete_notifyUploadComplete;
}

export interface NotifyUploadCompleteVariables {
  input: NotifyUploadCompleteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ExampleQuery
// ====================================================

export interface ExampleQuery_i18n_VydiaTestTranslations {
  __typename: "VydiaTestTranslations";
  basic: string;
  withArgs: string;
  basicList: string[];
  listWithArgs: string[];
}

export interface ExampleQuery_i18n {
  __typename: "I18n";
  VydiaTestTranslations: ExampleQuery_i18n_VydiaTestTranslations;
}

export interface ExampleQuery {
  /**
   * Get translation strings for screens and components.
   */
  i18n: ExampleQuery_i18n;
}

export interface ExampleQueryVariables {
  locale?: I18nLocale | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LoadingSwitchNoVariables
// ====================================================

export interface LoadingSwitchNoVariables {
  /**
   * List of years for period
   */
  period_years: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RejectPayeeAssetSplit
// ====================================================

export interface RejectPayeeAssetSplit_rejectPayeeAssetSplit_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface RejectPayeeAssetSplit_rejectPayeeAssetSplit_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface RejectPayeeAssetSplit_rejectPayeeAssetSplit_media {
  __typename: "Media";
  id: string;
  metadata: RejectPayeeAssetSplit_rejectPayeeAssetSplit_media_metadata | null;
}

export interface RejectPayeeAssetSplit_rejectPayeeAssetSplit {
  __typename: "RejectPayeeAssetSplitPayload";
  errors: RejectPayeeAssetSplit_rejectPayeeAssetSplit_errors[] | null;
  media: RejectPayeeAssetSplit_rejectPayeeAssetSplit_media | null;
}

export interface RejectPayeeAssetSplit {
  /**
   * Reject a PayeeAssetSplit. Used when a payee (user) chooses to not accept a proposed financial split on an asset in the royalty center.
   */
  rejectPayeeAssetSplit: RejectPayeeAssetSplit_rejectPayeeAssetSplit;
}

export interface RejectPayeeAssetSplitVariables {
  input: RejectPayeeAssetSplitInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendConfirmationEmail
// ====================================================

export interface ResendConfirmationEmail_resendConfirmationEmail_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ResendConfirmationEmail_resendConfirmationEmail {
  __typename: "ResendConfirmationEmailPayload";
  errors: ResendConfirmationEmail_resendConfirmationEmail_errors[] | null;
}

export interface ResendConfirmationEmail {
  /**
   * Resend a confirmation email. Used to send an email to the user's address to confirm their account.
   */
  resendConfirmationEmail: ResendConfirmationEmail_resendConfirmationEmail;
}

export interface ResendConfirmationEmailVariables {
  input: ResendConfirmationEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendBalanceHistoryDetailsReport
// ====================================================

export interface SendBalanceHistoryDetailsReport_sendBalanceHistoryDetailsReport_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SendBalanceHistoryDetailsReport_sendBalanceHistoryDetailsReport {
  __typename: "SendBalanceHistoryDetailsReportPayload";
  errors: SendBalanceHistoryDetailsReport_sendBalanceHistoryDetailsReport_errors[] | null;
}

export interface SendBalanceHistoryDetailsReport {
  /**
   * Send a balance history details report. Used to send a user's or an organization's balance history details as a email through the royalty center.
   */
  sendBalanceHistoryDetailsReport: SendBalanceHistoryDetailsReport_sendBalanceHistoryDetailsReport;
}

export interface SendBalanceHistoryDetailsReportVariables {
  input: SendBalanceHistoryDetailsReportInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendMediaAnalyticsReport
// ====================================================

export interface SendMediaAnalyticsReport_sendMediaAnalyticsReport_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SendMediaAnalyticsReport_sendMediaAnalyticsReport {
  __typename: "SendMediaAnalyticsReportPayload";
  errors: SendMediaAnalyticsReport_sendMediaAnalyticsReport_errors[] | null;
}

export interface SendMediaAnalyticsReport {
  /**
   * Send a media's analytics report. Used to email the video / audio file's analytics to a specific address.
   */
  sendMediaAnalyticsReport: SendMediaAnalyticsReport_sendMediaAnalyticsReport;
}

export interface SendMediaAnalyticsReportVariables {
  input: SendMediaAnalyticsReportInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendYoutubeConflictsReport
// ====================================================

export interface SendYoutubeConflictsReport_sendYoutubeConflictsReport_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SendYoutubeConflictsReport_sendYoutubeConflictsReport {
  __typename: "SendYoutubeConflictsReportPayload";
  errors: SendYoutubeConflictsReport_sendYoutubeConflictsReport_errors[] | null;
}

export interface SendYoutubeConflictsReport {
  /**
   * Send a youtube conflicts report. Used to email the conflicts to a specific address.
   */
  sendYoutubeConflictsReport: SendYoutubeConflictsReport_sendYoutubeConflictsReport;
}

export interface SendYoutubeConflictsReportVariables {
  input: SendYoutubeConflictsReportInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UseSessionQuery
// ====================================================

export interface UseSessionQuery_me_or_null {
  __typename: "User";
  id: string;
}

export interface UseSessionQuery {
  /**
   * Gets the currently logged in user, returning null if no user is signed in
   */
  me_or_null: UseSessionQuery_me_or_null | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMediaMetadataForAudio
// ====================================================

export interface UpdateMediaMetadataForAudio_updateMetadata_media {
  __typename: "Media";
  id: string;
  invalid_reasons: InvalidMediaReason[];
}

export interface UpdateMediaMetadataForAudio_updateMetadata_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface UpdateMediaMetadataForAudio_updateMetadata_metadata_additionalArtists {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface UpdateMediaMetadataForAudio_updateMetadata_metadata_additionalCredits {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface UpdateMediaMetadataForAudio_updateMetadata_metadata_writerCredits {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface UpdateMediaMetadataForAudio_updateMetadata_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * Unique Musical Work Code
   */
  iswc: string | null;
  /**
   * TikTok start time
   */
  clip_start_time: Scalar$TrackPosition | null;
  copyright_name: string;
  copyright_year: number | null;
  /**
   * Country of recording country code enum for the metadata
   */
  country_of_recording_enum: CountryCode | null;
  /**
   * Genre enum for the media associated to this metadata
   */
  genre_enum: Genre | null;
  /**
   * Secondary Genre enum for the media associated to this metadata
   */
  secondary_genre_enum: Genre | null;
  /**
   * Flag for the media associated with this metadata is a cover
   */
  is_cover: boolean | null;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
  /**
   * Two letter language code to identify the language that this media is in
   */
  language_code: string | null;
  /**
   * Language code enum for the media associated to this metadata
   */
  language_code_enum: MediaLanguageCode | null;
  label: string;
  lyrics: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  collaborating_artists: UpdateMediaMetadataForAudio_updateMetadata_metadata_collaborating_artists[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  additionalArtists: UpdateMediaMetadataForAudio_updateMetadata_metadata_additionalArtists[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  additionalCredits: UpdateMediaMetadataForAudio_updateMetadata_metadata_additionalCredits[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  writerCredits: UpdateMediaMetadataForAudio_updateMetadata_metadata_writerCredits[];
}

export interface UpdateMediaMetadataForAudio_updateMetadata_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface UpdateMediaMetadataForAudio_updateMetadata {
  __typename: "UpdateMetadataPayload";
  /**
   * media
   */
  media: UpdateMediaMetadataForAudio_updateMetadata_media | null;
  metadata: UpdateMediaMetadataForAudio_updateMetadata_metadata | null;
  errors: UpdateMediaMetadataForAudio_updateMetadata_errors[] | null;
}

export interface UpdateMediaMetadataForAudio {
  /**
   * Update Metadata. This mutation returns a Metadata. Used to change the information about a piece of content that is not an album.
   */
  updateMetadata: UpdateMediaMetadataForAudio_updateMetadata;
}

export interface UpdateMediaMetadataForAudioVariables {
  input: UpdateMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMediaCategory
// ====================================================

export interface UpdateMediaCategory_updateCategory_media {
  __typename: "Media";
  id: string;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  /**
   * Subcategory of the media
   */
  subcategory: string | null;
  invalid_reasons: InvalidMediaReason[];
}

export interface UpdateMediaCategory_updateCategory_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateMediaCategory_updateCategory {
  __typename: "UpdateCategoryPayload";
  media: UpdateMediaCategory_updateCategory_media | null;
  errors: UpdateMediaCategory_updateCategory_errors[] | null;
}

export interface UpdateMediaCategory {
  /**
   * Update a Media's category. This mutation returns a Media. Used when the classification of a media changes.
   */
  updateCategory: UpdateMediaCategory_updateCategory;
}

export interface UpdateMediaCategoryVariables {
  input: UpdateCategoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMediaMetadataForComp
// ====================================================

export interface UpdateMediaMetadataForComp_updateMetadata_media {
  __typename: "Media";
  id: string;
  invalid_reasons: InvalidMediaReason[];
}

export interface UpdateMediaMetadataForComp_updateMetadata_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface UpdateMediaMetadataForComp_updateMetadata_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * Unique Musical Work Code
   */
  iswc: string | null;
  copyright_name: string;
  copyright_year: number | null;
  /**
   * Country of recording country code enum for the metadata
   */
  country_of_recording_enum: CountryCode | null;
  /**
   * Genre enum for the media associated to this metadata
   */
  genre_enum: Genre | null;
  /**
   * Secondary Genre enum for the media associated to this metadata
   */
  secondary_genre_enum: Genre | null;
  /**
   * Flag for the media associated with this metadata is a cover
   */
  is_cover: boolean | null;
  /**
   * Two letter language code to identify the language that this media is in
   */
  language_code: string | null;
  /**
   * Language code enum for the media associated to this metadata
   */
  language_code_enum: MediaLanguageCode | null;
  label: string;
  lyrics: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  collaborating_artists: UpdateMediaMetadataForComp_updateMetadata_metadata_collaborating_artists[];
}

export interface UpdateMediaMetadataForComp_updateMetadata_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface UpdateMediaMetadataForComp_updateMetadata {
  __typename: "UpdateMetadataPayload";
  /**
   * media
   */
  media: UpdateMediaMetadataForComp_updateMetadata_media | null;
  metadata: UpdateMediaMetadataForComp_updateMetadata_metadata | null;
  errors: UpdateMediaMetadataForComp_updateMetadata_errors[] | null;
}

export interface UpdateMediaMetadataForComp {
  /**
   * Update Metadata. This mutation returns a Metadata. Used to change the information about a piece of content that is not an album.
   */
  updateMetadata: UpdateMediaMetadataForComp_updateMetadata;
}

export interface UpdateMediaMetadataForCompVariables {
  input: UpdateMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOwnershipAndPolicies
// ====================================================

export interface UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata_rights_composer {
  __typename: "Composer";
  id: string;
}

export interface UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata_rights {
  __typename: "Right";
  id: string;
  type: RightType;
  /**
   * percentage of ownership owned by an artist that asserted this right
   */
  ownership_percentage: number;
  owner_distributes_revenue: boolean | null;
  /**
   * List of country codes where the restrictions apply
   */
  geo_restrictions: string[];
  /**
   * Flag for the restriction includes a region
   */
  geo_include: boolean;
  /**
   * Composer object for the right
   */
  composer: UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata_rights_composer | null;
}

export interface UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata_policies_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata_policies {
  __typename: "Policy";
  id: string;
  /**
   * Values are permitted only to Permit | Monetize | Block
   */
  type_2: MediaPolicyType;
  /**
   * Deployment status of the policy for this metadata policy
   */
  status: MediaPolicyStatus;
  network: UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata_policies_network;
}

export interface UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Returns an array if rights that are asserted by the user of the media associated with this metadata
   */
  rights: UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata_rights[];
  /**
   * Returns an array of policies 
   */
  policies: UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata_policies[];
}

export interface UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media {
  __typename: "Media";
  id: string;
  metadata: UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media_metadata | null;
}

export interface UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface UpdateOwnershipAndPolicies_updateOwnershipAndPolicies {
  __typename: "UpdateOwnershipAndPoliciesPayload";
  /**
   * Media
   */
  media: UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_media | null;
  errors: UpdateOwnershipAndPolicies_updateOwnershipAndPolicies_errors[] | null;
}

export interface UpdateOwnershipAndPolicies {
  /**
   * Update Ownership and Policies. This mutation returns a release and a metadata. Used when a user sets the ownership (Rights) of content and the policies for 3rd party platforms like Facebook or YouTube.
   */
  updateOwnershipAndPolicies: UpdateOwnershipAndPolicies_updateOwnershipAndPolicies;
}

export interface UpdateOwnershipAndPoliciesVariables {
  input: UpdateOwnershipAndPoliciesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMediaMetadataForVideo
// ====================================================

export interface UpdateMediaMetadataForVideo_updateMetadata_media {
  __typename: "Media";
  id: string;
  invalid_reasons: InvalidMediaReason[];
  video_type: SupportedVideo | null;
}

export interface UpdateMediaMetadataForVideo_updateMetadata_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface UpdateMediaMetadataForVideo_updateMetadata_metadata_additionalArtists {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface UpdateMediaMetadataForVideo_updateMetadata_metadata_additionalCredits {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface UpdateMediaMetadataForVideo_updateMetadata_metadata_writerCredits {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface UpdateMediaMetadataForVideo_updateMetadata_metadata {
  __typename: "Metadata";
  id: string;
  copyright_year: number | null;
  lyrics: string;
  /**
   * Unique code for video
   */
  video_isrc: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * Unique Musical Work Code
   */
  iswc: string | null;
  /**
   * Genre for the media associated to this metadata
   */
  genre: string;
  /**
   * Genre enum for the media associated to this metadata
   */
  genre_enum: Genre | null;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * Album Name that this media metadata is associated with
   */
  album: string;
  director: string;
  video_producer: string;
  label: string;
  production_company: string;
  copyright_name: string;
  /**
   * Flag for the media associated with this metadata is a cover
   */
  is_cover: boolean | null;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  collaborating_artists: UpdateMediaMetadataForVideo_updateMetadata_metadata_collaborating_artists[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  additionalArtists: UpdateMediaMetadataForVideo_updateMetadata_metadata_additionalArtists[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  additionalCredits: UpdateMediaMetadataForVideo_updateMetadata_metadata_additionalCredits[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  writerCredits: UpdateMediaMetadataForVideo_updateMetadata_metadata_writerCredits[];
}

export interface UpdateMediaMetadataForVideo_updateMetadata_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateMediaMetadataForVideo_updateMetadata {
  __typename: "UpdateMetadataPayload";
  /**
   * media
   */
  media: UpdateMediaMetadataForVideo_updateMetadata_media | null;
  metadata: UpdateMediaMetadataForVideo_updateMetadata_metadata | null;
  errors: UpdateMediaMetadataForVideo_updateMetadata_errors[] | null;
}

export interface UpdateMediaMetadataForVideo {
  /**
   * Update Metadata. This mutation returns a Metadata. Used to change the information about a piece of content that is not an album.
   */
  updateMetadata: UpdateMediaMetadataForVideo_updateMetadata;
}

export interface UpdateMediaMetadataForVideoVariables {
  input: UpdateMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateS3MultipartUpload
// ====================================================

export interface GenerateS3MultipartUpload_generateS3MultipartUpload {
  __typename: "GenerateS3MultipartUploadPayload";
  /**
   * S3 Bucket name
   */
  bucket: string | null;
  /**
   * key of the multipart upload
   */
  key: string | null;
  /**
   * S3 access key ID
   */
  aws_key: string | null;
}

export interface GenerateS3MultipartUpload {
  /**
   * Generate an Amazon S3 multipart upload. Used to provide information needed to start a direct-to-s3 multipart upload.
   */
  generateS3MultipartUpload: GenerateS3MultipartUpload_generateS3MultipartUpload;
}

export interface GenerateS3MultipartUploadVariables {
  input: GenerateS3MultipartUploadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateAWSV4Signature
// ====================================================

export interface GenerateAWSV4Signature_generateAWSV4Signature {
  __typename: "GenerateAWSV4SignaturePayload";
  /**
   * Signed part
   */
  signed: string;
}

export interface GenerateAWSV4Signature {
  /**
   * Returns a AWS v4 signature for a string. Used to sign individual upload parts for direct-to-s3 multipart uploads.
   */
  generateAWSV4Signature: GenerateAWSV4Signature_generateAWSV4Signature;
}

export interface GenerateAWSV4SignatureVariables {
  input: GenerateAWSV4SignatureInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DashboardLayout
// ====================================================

export interface DashboardLayout_me_tipalti_user_data {
  __typename: "TipaltiUser";
  id: string;
  must_submit_tax_form: boolean;
}

export interface DashboardLayout_me {
  __typename: "User";
  id: string;
  display_name_or_email: string;
  /**
   * Flag for the email is confirmed
   */
  email_confirmed: boolean;
  is_admin: boolean;
  /**
   * Tipalti data for user's tax documents
   */
  tipalti_user_data: DashboardLayout_me_tipalti_user_data | null;
}

export interface DashboardLayout_current_observer {
  __typename: "User";
  display_name_or_email: string;
  id: string;
}

export interface DashboardLayout {
  observer_signed_in: boolean;
  payment_announcements: string | null;
  /**
   * Get information about the currently logged in user
   */
  me: DashboardLayout_me;
  current_observer: DashboardLayout_current_observer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AcceptPayeeAssetSplitButtons
// ====================================================

export interface AcceptPayeeAssetSplitButtons_me {
  __typename: "User";
  id: string;
  /**
   * User has been onboarded to tipalti
   */
  is_payable: boolean;
}

export interface AcceptPayeeAssetSplitButtons {
  /**
   * Get information about the currently logged in user
   */
  me: AcceptPayeeAssetSplitButtons_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AddressChangeAcknowledgement
// ====================================================

export interface AddressChangeAcknowledgement_me {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user needs to acknowledge an address change
   */
  must_acknowledge_address_change: boolean;
  /**
   * Returns a rendered html string of Address Change Acknowledgement
   */
  render_address_change_acknowledgement: string;
}

export interface AddressChangeAcknowledgement {
  /**
   * Get information about the currently logged in user
   */
  me: AddressChangeAcknowledgement_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcknowledgeAddressChange
// ====================================================

export interface AcknowledgeAddressChange_acknowledgeAddressChange_user {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user needs to acknowledge an address change
   */
  must_acknowledge_address_change: boolean;
}

export interface AcknowledgeAddressChange_acknowledgeAddressChange_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AcknowledgeAddressChange_acknowledgeAddressChange {
  __typename: "AcknowledgeAddressChangePayload";
  user: AcknowledgeAddressChange_acknowledgeAddressChange_user;
  errors: AcknowledgeAddressChange_acknowledgeAddressChange_errors[] | null;
}

export interface AcknowledgeAddressChange {
  /**
   * Acknowledge the address change for a User. This mutation returns a User object. Used to specifically create an Address Change Acknowledgement once reviewed by the user.
   */
  acknowledgeAddressChange: AcknowledgeAddressChange_acknowledgeAddressChange;
}

export interface AcknowledgeAddressChangeVariables {
  input: AcknowledgeAddressChangeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksAvailableAudioNetworks
// ====================================================

export interface SmartlinksAvailableAudioNetworks_album_broadcasts_cursor_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartlinksAvailableAudioNetworks_album_broadcasts_cursor {
  __typename: "Broadcast";
  id: string;
  /**
   * Network associated with a broadcast
   */
  network: SmartlinksAvailableAudioNetworks_album_broadcasts_cursor_network;
}

export interface SmartlinksAvailableAudioNetworks_album {
  __typename: "Album";
  id: string;
  /**
   * Broadcasts for an album
   */
  broadcasts_cursor: SmartlinksAvailableAudioNetworks_album_broadcasts_cursor[];
}

export interface SmartlinksAvailableAudioNetworks {
  /**
   * Find the current album by ID
   */
  album: SmartlinksAvailableAudioNetworks_album;
}

export interface SmartlinksAvailableAudioNetworksVariables {
  albumId: string;
  input: BroadcastsCursorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinkEligibleAlbums
// ====================================================

export interface SmartlinkEligibleAlbums_me_smartlink_eligible_albums_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface SmartlinkEligibleAlbums_me_smartlink_eligible_albums_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface SmartlinkEligibleAlbums_me_smartlink_eligible_albums_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface SmartlinkEligibleAlbums_me_smartlink_eligible_albums {
  __typename: "Album";
  id: string;
  metadata: SmartlinkEligibleAlbums_me_smartlink_eligible_albums_metadata;
  artist: SmartlinkEligibleAlbums_me_smartlink_eligible_albums_artist;
  file_upload: SmartlinkEligibleAlbums_me_smartlink_eligible_albums_file_upload | null;
}

export interface SmartlinkEligibleAlbums_me {
  __typename: "User";
  id: string;
  /**
   * Albums that are smartlink eligible
   */
  smartlink_eligible_albums: SmartlinkEligibleAlbums_me_smartlink_eligible_albums[] | null;
}

export interface SmartlinkEligibleAlbums {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinkEligibleAlbums_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumTracks
// ====================================================

export interface AlbumTracks_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AlbumTracks_album_album_medias_cursor_album_medias_media {
  __typename: "Media";
  id: string;
  file_song_name: string;
}

export interface AlbumTracks_album_album_medias_cursor_album_medias {
  __typename: "AlbumMedia";
  id: string;
  /**
   * track number associated with this media in an album
   */
  track_number: number;
  media: AlbumTracks_album_album_medias_cursor_album_medias_media;
}

export interface AlbumTracks_album_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
  /**
   * List of medias on an album
   */
  album_medias: AlbumTracks_album_album_medias_cursor_album_medias[];
}

export interface AlbumTracks_album {
  __typename: "Album";
  id: string;
  metadata: AlbumTracks_album_metadata;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: AlbumTracks_album_album_medias_cursor;
}

export interface AlbumTracks {
  /**
   * Find the current album by ID
   */
  album: AlbumTracks_album;
}

export interface AlbumTracksVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsArtistsPicker
// ====================================================

export interface AnalyticsArtistsPicker_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  thumbnail_url: string;
  user_id: string;
}

export interface AnalyticsArtistsPicker_me {
  __typename: "User";
  id: string;
  /**
   * Count of artists for the user
   */
  artists_count: number;
  /**
   * Any more artists in this user
   */
  artists_any_more_results: boolean;
  /**
   * List out all Artists for the current user
   */
  artists: AnalyticsArtistsPicker_me_artists[];
}

export interface AnalyticsArtistsPicker {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsArtistsPicker_me;
}

export interface AnalyticsArtistsPickerVariables {
  input?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsMediasPicker
// ====================================================

export interface AnalyticsMediasPicker_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AnalyticsMediasPicker_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AnalyticsMediasPicker_me_medias_v2 {
  __typename: "Media";
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  artist: AnalyticsMediasPicker_me_medias_v2_artist | null;
  metadata: AnalyticsMediasPicker_me_medias_v2_metadata | null;
  user_id: string;
}

export interface AnalyticsMediasPicker_me {
  __typename: "User";
  id: string;
  /**
   * Any more results of medias
   */
  medias_v2_any_more_results: boolean;
  /**
   * Medias of the user
   */
  medias_v2: AnalyticsMediasPicker_me_medias_v2[];
}

export interface AnalyticsMediasPicker {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsMediasPicker_me;
}

export interface AnalyticsMediasPickerVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsUsersPicker
// ====================================================

export interface AnalyticsUsersPicker_me_organization_users_cursor {
  __typename: "User";
  id: string;
  full_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface AnalyticsUsersPicker_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of users in organization
   */
  users_count: number;
  /**
   * Any more users in organization
   */
  users_any_more_results: boolean;
  /**
   * Users in organization
   */
  users_cursor: AnalyticsUsersPicker_me_organization_users_cursor[];
}

export interface AnalyticsUsersPicker_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AnalyticsUsersPicker_me_organization | null;
}

export interface AnalyticsUsersPicker {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsUsersPicker_me;
}

export interface AnalyticsUsersPickerVariables {
  input?: UsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsByCountry
// ====================================================

export interface AudioAnalyticsByCountry_media_audio_analytics_by_country_country {
  __typename: "AnalyticsCountry";
  id: string;
  name: string;
  code: AnalyticsCountryCode;
}

export interface AudioAnalyticsByCountry_media_audio_analytics_by_country {
  __typename: "AudioAnalyticsByCountry";
  id: string;
  /**
   * total streams for a given network
   */
  total_streams: number;
  /**
   * total listeners for a given network
   */
  total_listeners: number | null;
  /**
   * country
   */
  country: AudioAnalyticsByCountry_media_audio_analytics_by_country_country;
}

export interface AudioAnalyticsByCountry_media {
  __typename: "Media";
  id: string;
  /**
   * media analytics by country
   */
  audio_analytics_by_country: AudioAnalyticsByCountry_media_audio_analytics_by_country[];
}

export interface AudioAnalyticsByCountry {
  /**
   * Find the current media by ID
   */
  media: AudioAnalyticsByCountry_media;
}

export interface AudioAnalyticsByCountryVariables {
  input?: AudioAnalyticsByCountryCursorInput | null;
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaAnalyticsPlaylistsTable
// ====================================================

export interface MediaAnalyticsPlaylistsTable_media_playlists_analytics_cursor_playlists_AppleMusicPlaylist {
  __typename: "AppleMusicPlaylist";
  /**
   * Chartmetric playlist ID
   */
  id: string;
  /**
   * Total number of days listed in playlist
   */
  days_in_list: number | null;
  /**
   * Playlist cover image URL
   */
  image_url: string;
  /**
   * Playlist ID from the partner, usually will be present in the playlist's URI/URL
   */
  is_private_playlist: boolean;
  /**
   * Playlist Title
   */
  name: string;
  /**
   * Curator name
   */
  owner_name: string;
  /**
   * Playlist ID from the partner, usually will be present in the playlist's URI/URL
   */
  playlist_eid: string;
  /**
   * Removal date
   */
  removed_at: Scalar$DateTime | null;
  /**
   * Track streams
   */
  track_streams: number;
  /**
   * URL link to the playlist on Apple Music website
   */
  url: string | null;
  /**
   * The current position of the track in the playlist
   */
  position: number;
  /**
   * The peak position of the track ever in the playlist
   */
  peak_position: number;
}

export interface MediaAnalyticsPlaylistsTable_media_playlists_analytics_cursor_playlists_SpotifyPlaylist {
  __typename: "SpotifyPlaylist";
  /**
   * Chartmetric playlist ID
   */
  id: string;
  /**
   * Total number of days listed in playlist
   */
  days_in_list: number | null;
  /**
   * Playlist cover image URL
   */
  image_url: string;
  /**
   * Playlist ID from the partner, usually will be present in the playlist's URI/URL
   */
  is_private_playlist: boolean;
  /**
   * Playlist Title
   */
  name: string;
  /**
   * Curator name
   */
  owner_name: string;
  /**
   * Playlist ID from the partner, usually will be present in the playlist's URI/URL
   */
  playlist_eid: string;
  /**
   * Removal date
   */
  removed_at: Scalar$DateTime | null;
  /**
   * Track streams
   */
  track_streams: number;
  /**
   * URL link to the playlist on Spotify website
   */
  url: string | null;
  /**
   * Most recent follower count
   */
  followers: number;
}

export type MediaAnalyticsPlaylistsTable_media_playlists_analytics_cursor_playlists = MediaAnalyticsPlaylistsTable_media_playlists_analytics_cursor_playlists_AppleMusicPlaylist | MediaAnalyticsPlaylistsTable_media_playlists_analytics_cursor_playlists_SpotifyPlaylist;

export interface MediaAnalyticsPlaylistsTable_media_playlists_analytics_cursor {
  __typename: "AnalyticsPlaylists";
  any_more_results: boolean;
  count: number;
  /**
   * List of playlists with an offset and limit
   */
  playlists: MediaAnalyticsPlaylistsTable_media_playlists_analytics_cursor_playlists[];
}

export interface MediaAnalyticsPlaylistsTable_media {
  __typename: "Media";
  id: string;
  playlists_analytics_cursor: MediaAnalyticsPlaylistsTable_media_playlists_analytics_cursor;
}

export interface MediaAnalyticsPlaylistsTable {
  /**
   * Find the current media by ID
   */
  media: MediaAnalyticsPlaylistsTable_media;
}

export interface MediaAnalyticsPlaylistsTableVariables {
  mediaID: string;
  limit: number;
  offset: number;
  source: NetworkPlaylistSource;
  status: NetworkPlaylistStatus;
  sortBy?: PlaylistAnalyticsSortBy | null;
  sortOrder?: SortOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistAnalyticsPlaylistsTable
// ====================================================

export interface ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists_AppleMusicPlaylist_media {
  __typename: "Media";
  id: string;
}

export interface ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists_AppleMusicPlaylist {
  __typename: "AppleMusicPlaylist";
  /**
   * Chartmetric playlist ID
   */
  id: string;
  /**
   * Total number of days listed in playlist
   */
  days_in_list: number | null;
  /**
   * Playlist cover image URL
   */
  image_url: string;
  /**
   * Playlist ID from the partner, usually will be present in the playlist's URI/URL
   */
  is_private_playlist: boolean;
  /**
   * Playlist Title
   */
  name: string;
  /**
   * Curator name
   */
  owner_name: string;
  /**
   * Playlist ID from the partner, usually will be present in the playlist's URI/URL
   */
  playlist_eid: string;
  /**
   * Removal date
   */
  removed_at: Scalar$DateTime | null;
  /**
   * Track name
   */
  track_name: string;
  /**
   * Track streams
   */
  track_streams: number;
  /**
   * URL link to the playlist on Apple Music website
   */
  url: string | null;
  media: ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists_AppleMusicPlaylist_media | null;
  /**
   * The current position of the track in the playlist
   */
  position: number;
  /**
   * The peak position of the track ever in the playlist
   */
  peak_position: number;
}

export interface ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists_SpotifyPlaylist_media {
  __typename: "Media";
  id: string;
}

export interface ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists_SpotifyPlaylist {
  __typename: "SpotifyPlaylist";
  /**
   * Chartmetric playlist ID
   */
  id: string;
  /**
   * Total number of days listed in playlist
   */
  days_in_list: number | null;
  /**
   * Playlist cover image URL
   */
  image_url: string;
  /**
   * Playlist ID from the partner, usually will be present in the playlist's URI/URL
   */
  is_private_playlist: boolean;
  /**
   * Playlist Title
   */
  name: string;
  /**
   * Curator name
   */
  owner_name: string;
  /**
   * Playlist ID from the partner, usually will be present in the playlist's URI/URL
   */
  playlist_eid: string;
  /**
   * Removal date
   */
  removed_at: Scalar$DateTime | null;
  /**
   * Track name
   */
  track_name: string;
  /**
   * Track streams
   */
  track_streams: number;
  /**
   * URL link to the playlist on Spotify website
   */
  url: string | null;
  media: ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists_SpotifyPlaylist_media | null;
  /**
   * Most recent follower count
   */
  followers: number;
}

export type ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists = ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists_AppleMusicPlaylist | ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists_SpotifyPlaylist;

export interface ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor {
  __typename: "AnalyticsPlaylists";
  any_more_results: boolean;
  count: number;
  /**
   * List of playlists with an offset and limit
   */
  playlists: ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor_playlists[];
}

export interface ArtistAnalyticsPlaylistsTable_artist {
  __typename: "Artist";
  id: string;
  playlists_analytics_cursor: ArtistAnalyticsPlaylistsTable_artist_playlists_analytics_cursor;
}

export interface ArtistAnalyticsPlaylistsTable {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: ArtistAnalyticsPlaylistsTable_artist;
}

export interface ArtistAnalyticsPlaylistsTableVariables {
  artistID: string;
  limit: number;
  offset: number;
  source: NetworkPlaylistSource;
  status: NetworkPlaylistStatus;
  sortBy?: PlaylistAnalyticsSortBy | null;
  sortOrder?: SortOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchivePayeeAssetSplits
// ====================================================

export interface ArchivePayeeAssetSplits_archivePayeeAssetSplits_payee_asset_splits {
  __typename: "PayeeAssetSplit";
  id: string;
}

export interface ArchivePayeeAssetSplits_archivePayeeAssetSplits_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ArchivePayeeAssetSplits_archivePayeeAssetSplits {
  __typename: "ArchivePayeeAssetSplitsPayload";
  payee_asset_splits: ArchivePayeeAssetSplits_archivePayeeAssetSplits_payee_asset_splits[] | null;
  errors: ArchivePayeeAssetSplits_archivePayeeAssetSplits_errors[] | null;
}

export interface ArchivePayeeAssetSplits {
  /**
   * Default description for "ArchivePayeeAssetSplits" mutation.
   */
  archivePayeeAssetSplits: ArchivePayeeAssetSplits_archivePayeeAssetSplits;
}

export interface ArchivePayeeAssetSplitsVariables {
  input: ArchivePayeeAssetSplitsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ArchivePayeeInvite
// ====================================================

export interface ArchivePayeeInvite_archivePayeeInvite_payee_invite_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface ArchivePayeeInvite_archivePayeeInvite_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: ArchivePayeeInvite_archivePayeeInvite_payee_invite_payee | null;
}

export interface ArchivePayeeInvite_archivePayeeInvite_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ArchivePayeeInvite_archivePayeeInvite {
  __typename: "ArchivePayeeInvitePayload";
  payee_invite: ArchivePayeeInvite_archivePayeeInvite_payee_invite | null;
  errors: ArchivePayeeInvite_archivePayeeInvite_errors[] | null;
}

export interface ArchivePayeeInvite {
  /**
   * Default description for "ArchivePayeeInvite" mutation.
   */
  archivePayeeInvite: ArchivePayeeInvite_archivePayeeInvite;
}

export interface ArchivePayeeInviteVariables {
  input: ArchivePayeeInviteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistPicker
// ====================================================

export interface ArtistPicker_me_artists_user {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
  full_name: string | null;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface ArtistPicker_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  user: ArtistPicker_me_artists_user;
}

export interface ArtistPicker_me {
  __typename: "User";
  id: string;
  /**
   * Any more artists in this user
   */
  artists_any_more_results: boolean;
  /**
   * List out all Artists for the current user
   */
  artists: ArtistPicker_me_artists[];
}

export interface ArtistPicker {
  /**
   * Get information about the currently logged in user
   */
  me: ArtistPicker_me;
}

export interface ArtistPickerVariables {
  input?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioFileList
// ====================================================

export interface AudioFileList_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AudioFileList_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
}

export interface AudioFileList_me_medias_v2 {
  __typename: "Media";
  artist: AudioFileList_me_medias_v2_artist | null;
  created_at: Scalar$DateTime;
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
  metadata: AudioFileList_me_medias_v2_metadata | null;
}

export interface AudioFileList_me {
  __typename: "User";
  id: string;
  /**
   * Any more results of medias
   */
  medias_v2_any_more_results: boolean;
  /**
   * Medias of the user
   */
  medias_v2: AudioFileList_me_medias_v2[];
  /**
   * Count of medias
   */
  medias_v2_count: number;
}

export interface AudioFileList_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface AudioFileList {
  /**
   * Get information about the currently logged in user
   */
  me: AudioFileList_me;
  /**
   * Gets all the available Genres from our system
   */
  genres: AudioFileList_genres[];
}

export interface AudioFileListVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableMusicVideoTypeNetworks
// ====================================================

export interface AvailableMusicVideoTypeNetworks_me_available_networks {
  __typename: "Network";
  id: string;
  /**
   * Image URL for this network
   */
  image_url: string;
  name: string;
}

export interface AvailableMusicVideoTypeNetworks_me {
  __typename: "User";
  id: string;
  /**
   * List of networks available for a user
   */
  available_networks: AvailableMusicVideoTypeNetworks_me_available_networks[];
}

export interface AvailableMusicVideoTypeNetworks {
  /**
   * Get information about the currently logged in user
   */
  me: AvailableMusicVideoTypeNetworks_me;
}

export interface AvailableMusicVideoTypeNetworksVariables {
  musicVideoType?: SupportedVideo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicFilterBarMediaTab
// ====================================================

export interface BasicFilterBarMediaTab_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface BasicFilterBarMediaTab_media_user_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface BasicFilterBarMediaTab_media_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  /**
   * An Organization a user is a member of
   */
  organization: BasicFilterBarMediaTab_media_user_organization | null;
}

export interface BasicFilterBarMediaTab_media {
  __typename: "Media";
  id: string;
  type: MediaType;
  thumbnail_url: string | null;
  metadata: BasicFilterBarMediaTab_media_metadata | null;
  user: BasicFilterBarMediaTab_media_user;
}

export interface BasicFilterBarMediaTab {
  /**
   * Find the current media by ID
   */
  media: BasicFilterBarMediaTab_media;
}

export interface BasicFilterBarMediaTabVariables {
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicFilterBarAlbumTab
// ====================================================

export interface BasicFilterBarAlbumTab_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface BasicFilterBarAlbumTab_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface BasicFilterBarAlbumTab_album {
  __typename: "Album";
  id: string;
  metadata: BasicFilterBarAlbumTab_album_metadata;
  file_upload: BasicFilterBarAlbumTab_album_file_upload | null;
}

export interface BasicFilterBarAlbumTab {
  /**
   * Find the current album by ID
   */
  album: BasicFilterBarAlbumTab_album;
}

export interface BasicFilterBarAlbumTabVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicFilterBarArtistTab
// ====================================================

export interface BasicFilterBarArtistTab_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface BasicFilterBarArtistTab {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: BasicFilterBarArtistTab_artist;
}

export interface BasicFilterBarArtistTabVariables {
  artistId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicFilterBarCurrentUserOrganizationTab
// ====================================================

export interface BasicFilterBarCurrentUserOrganizationTab_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface BasicFilterBarCurrentUserOrganizationTab_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: BasicFilterBarCurrentUserOrganizationTab_me_organization | null;
}

export interface BasicFilterBarCurrentUserOrganizationTab {
  /**
   * Get information about the currently logged in user
   */
  me: BasicFilterBarCurrentUserOrganizationTab_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicFilterBarOrganizationByIdTab
// ====================================================

export interface BasicFilterBarOrganizationByIdTab_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface BasicFilterBarOrganizationByIdTab {
  /**
   * Find an Organization by ID
   */
  organization: BasicFilterBarOrganizationByIdTab_organization;
}

export interface BasicFilterBarOrganizationByIdTabVariables {
  orgId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicFilterBarUserByIdTab
// ====================================================

export interface BasicFilterBarUserByIdTab_user_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface BasicFilterBarUserByIdTab_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  /**
   * An Organization a user is a member of
   */
  organization: BasicFilterBarUserByIdTab_user_organization | null;
}

export interface BasicFilterBarUserByIdTab {
  /**
   * Find a User by ID
   */
  user: BasicFilterBarUserByIdTab_user;
}

export interface BasicFilterBarUserByIdTabVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicFilterBarCurrentUserTab
// ====================================================

export interface BasicFilterBarCurrentUserTab_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface BasicFilterBarCurrentUserTab_me {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  /**
   * An Organization a user is a member of
   */
  organization: BasicFilterBarCurrentUserTab_me_organization | null;
}

export interface BasicFilterBarCurrentUserTab {
  /**
   * Get information about the currently logged in user
   */
  me: BasicFilterBarCurrentUserTab_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicAlbumsPicker
// ====================================================

export interface BasicAlbumsPicker_me_albums_v2_cursor_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface BasicAlbumsPicker_me_albums_v2_cursor_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface BasicAlbumsPicker_me_albums_v2_cursor_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface BasicAlbumsPicker_me_albums_v2_cursor_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
}

export interface BasicAlbumsPicker_me_albums_v2_cursor {
  __typename: "Album";
  artist: BasicAlbumsPicker_me_albums_v2_cursor_artist;
  file_upload: BasicAlbumsPicker_me_albums_v2_cursor_file_upload | null;
  id: string;
  current_user_pretty_album_revenue: string;
  created_at: Scalar$DateTime;
  user_id: string;
  archived: boolean;
  metadata: BasicAlbumsPicker_me_albums_v2_cursor_metadata;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: BasicAlbumsPicker_me_albums_v2_cursor_album_medias_cursor;
}

export interface BasicAlbumsPicker_me {
  __typename: "User";
  id: string;
  /**
   * Count of albums for the user
   */
  albums_v2_count: number;
  /**
   * Any more albums in this user
   */
  albums_v2_any_more_results: boolean;
  /**
   * List out all the albums for the current user
   */
  albums_v2_cursor: BasicAlbumsPicker_me_albums_v2_cursor[];
}

export interface BasicAlbumsPicker {
  /**
   * Get information about the currently logged in user
   */
  me: BasicAlbumsPicker_me;
}

export interface BasicAlbumsPickerVariables {
  input?: AlbumsCursorInput | null;
  size?: ThumbnailSize | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicArtistsPicker
// ====================================================

export interface BasicArtistsPicker_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  thumbnail_url: string;
  user_id: string;
}

export interface BasicArtistsPicker_me {
  __typename: "User";
  id: string;
  /**
   * Count of artists for the user
   */
  artists_count: number;
  /**
   * Any more artists in this user
   */
  artists_any_more_results: boolean;
  /**
   * List out all Artists for the current user
   */
  artists: BasicArtistsPicker_me_artists[];
}

export interface BasicArtistsPicker {
  /**
   * Get information about the currently logged in user
   */
  me: BasicArtistsPicker_me;
}

export interface BasicArtistsPickerVariables {
  input?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicMediasPicker
// ====================================================

export interface BasicMediasPicker_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface BasicMediasPicker_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface BasicMediasPicker_me_medias_v2 {
  __typename: "Media";
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  artist: BasicMediasPicker_me_medias_v2_artist | null;
  metadata: BasicMediasPicker_me_medias_v2_metadata | null;
  user_id: string;
}

export interface BasicMediasPicker_me {
  __typename: "User";
  id: string;
  /**
   * Any more results of medias
   */
  medias_v2_any_more_results: boolean;
  /**
   * Medias of the user
   */
  medias_v2: BasicMediasPicker_me_medias_v2[];
}

export interface BasicMediasPicker {
  /**
   * Get information about the currently logged in user
   */
  me: BasicMediasPicker_me;
}

export interface BasicMediasPickerVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VydiaAdminMediasPicker
// ====================================================

export interface VydiaAdminMediasPicker_medias_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface VydiaAdminMediasPicker_medias_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface VydiaAdminMediasPicker_medias {
  __typename: "Media";
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  artist: VydiaAdminMediasPicker_medias_artist | null;
  metadata: VydiaAdminMediasPicker_medias_metadata | null;
  user_id: string;
}

export interface VydiaAdminMediasPicker {
  /**
   * Any more Medias via cursor (Admin Field)
   */
  medias_any_more_results: boolean;
  /**
   * List Medias via cursor (Admin Field)
   */
  medias: VydiaAdminMediasPicker_medias[];
}

export interface VydiaAdminMediasPickerVariables {
  input?: RootMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: BasicOrganizationsPicker
// ====================================================

export interface BasicOrganizationsPicker_organizations {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface BasicOrganizationsPicker {
  /**
   * Any more Organizations via cursor (Admin Field)
   */
  organizations_any_more_results: boolean;
  /**
   * List Organizations via cursor (Admin Field)
   */
  organizations: BasicOrganizationsPicker_organizations[];
}

export interface BasicOrganizationsPickerVariables {
  input?: OrganizationsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgUsersPicker
// ====================================================

export interface OrgUsersPicker_me_organization_users_cursor {
  __typename: "User";
  id: string;
  full_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface OrgUsersPicker_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Any more users in organization
   */
  users_any_more_results: boolean;
  /**
   * Users in organization
   */
  users_cursor: OrgUsersPicker_me_organization_users_cursor[];
}

export interface OrgUsersPicker_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrgUsersPicker_me_organization | null;
}

export interface OrgUsersPicker {
  /**
   * Get information about the currently logged in user
   */
  me: OrgUsersPicker_me;
}

export interface OrgUsersPickerVariables {
  input?: UsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VydiaAdminUsersPicker
// ====================================================

export interface VydiaAdminUsersPicker_users {
  __typename: "User";
  id: string;
  full_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface VydiaAdminUsersPicker {
  /**
   * Any more Users via cursor (Admin Field)
   */
  users_any_more_results: boolean;
  /**
   * List Users via cursor (Admin Field)
   */
  users: VydiaAdminUsersPicker_users[];
}

export interface VydiaAdminUsersPickerVariables {
  input?: RootUsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClaimsOrganization
// ====================================================

export interface ClaimsOrganization_me_organization_youtube_ugc_totals {
  __typename: "YoutubeUgcTotals";
  id: string;
  total_estimated_views: number;
  total_estimated_claims: number;
}

export interface ClaimsOrganization_me_organization_youtube_ugc_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ClaimsOrganization_me_organization_youtube_ugc_media {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
}

export interface ClaimsOrganization_me_organization_youtube_ugc_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface ClaimsOrganization_me_organization_youtube_ugc {
  __typename: "YoutubeUgc";
  id: string;
  artist_name: string | null;
  user_name: string | null;
  media_id: string;
  claim_id: string;
  ugc_title: string;
  ugc_video_id: string;
  channel_name: string;
  channel_id: string;
  claim_type: string;
  claim_date: Scalar$DateTime;
  estimated_claimed_views: number;
  song_name: string;
  artist: ClaimsOrganization_me_organization_youtube_ugc_artist | null;
  media: ClaimsOrganization_me_organization_youtube_ugc_media | null;
  user: ClaimsOrganization_me_organization_youtube_ugc_user | null;
}

export interface ClaimsOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * When the most recent YouTube Ugc report was ingested into Vydia's Database
   */
  youtube_ugc_latest_reports_ingested_at: Scalar$DateTime;
  youtube_ugc_totals: ClaimsOrganization_me_organization_youtube_ugc_totals[];
  youtube_ugc_count: number;
  youtube_ugc_any_more_results: boolean;
  youtube_ugc: ClaimsOrganization_me_organization_youtube_ugc[];
}

export interface ClaimsOrganization_me {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * An Organization a user is a member of
   */
  organization: ClaimsOrganization_me_organization | null;
}

export interface ClaimsOrganization_current_observer {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface ClaimsOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: ClaimsOrganization_me;
  observer_signed_in: boolean;
  current_observer: ClaimsOrganization_current_observer | null;
}

export interface ClaimsOrganizationVariables {
  input?: YoutubeUgcCursorInput | null;
  totals?: YoutubeUgcCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClaimsUser
// ====================================================

export interface ClaimsUser_me_youtube_ugc_totals {
  __typename: "YoutubeUgcTotals";
  id: string;
  total_estimated_claims: number;
  total_estimated_views: number;
}

export interface ClaimsUser_me_youtube_ugc_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ClaimsUser_me_youtube_ugc_media {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
}

export interface ClaimsUser_me_youtube_ugc_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface ClaimsUser_me_youtube_ugc {
  __typename: "YoutubeUgc";
  id: string;
  artist_name: string | null;
  user_name: string | null;
  media_id: string;
  ugc_title: string;
  ugc_video_id: string;
  channel_name: string;
  channel_id: string;
  claim_type: string;
  claim_date: Scalar$DateTime;
  estimated_claimed_views: number;
  song_name: string;
  artist: ClaimsUser_me_youtube_ugc_artist | null;
  media: ClaimsUser_me_youtube_ugc_media | null;
  user: ClaimsUser_me_youtube_ugc_user | null;
}

export interface ClaimsUser_me {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * When the most recent YouTube Ugc report was ingested into Vydia's Database
   */
  youtube_ugc_latest_reports_ingested_at: Scalar$DateTime;
  youtube_ugc_totals: ClaimsUser_me_youtube_ugc_totals[];
  youtube_ugc_count: number;
  youtube_ugc_any_more_results: boolean;
  youtube_ugc: ClaimsUser_me_youtube_ugc[];
}

export interface ClaimsUser_current_observer {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface ClaimsUser {
  /**
   * Get information about the currently logged in user
   */
  me: ClaimsUser_me;
  observer_signed_in: boolean;
  current_observer: ClaimsUser_current_observer | null;
}

export interface ClaimsUserVariables {
  input?: YoutubeUgcCursorInput | null;
  totals?: YoutubeUgcCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ComposerSelect
// ====================================================

export interface ComposerSelect_me_composers {
  __typename: "Composer";
  id: string;
  full_name: string;
  has_rights: boolean;
  ipi: string | null;
  perf_rights_org_name: string;
}

export interface ComposerSelect_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * List out all Composers for the current user
   */
  composers: ComposerSelect_me_composers[];
}

export interface ComposerSelect {
  /**
   * Get information about the currently logged in user
   */
  me: ComposerSelect_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ComposerEdit
// ====================================================

export interface ComposerEdit_composer {
  __typename: "Composer";
  id: string;
  email: string | null;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  full_name: string;
  has_rights: boolean;
  ipi: string | null;
  perf_rights_org_name: string;
  perf_rights_org: string;
  publisher: string;
  publisher_ipi: string | null;
}

export interface ComposerEdit {
  /**
   * Find one of the current user's Composers by ID
   */
  composer: ComposerEdit_composer;
}

export interface ComposerEditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ComposerPublishers
// ====================================================

export interface ComposerPublishers_me_publishers {
  __typename: "Publisher";
  /**
   * Custom ID for Publisher
   */
  id: string;
  ipi: string | null;
  name: string;
}

export interface ComposerPublishers_me {
  __typename: "User";
  id: string;
  /**
   * List out all Publishers for the current user
   */
  publishers: ComposerPublishers_me_publishers[];
}

export interface ComposerPublishers {
  /**
   * Get information about the currently logged in user
   */
  me: ComposerPublishers_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateComposer
// ====================================================

export interface CreateComposer_createComposer_composer {
  __typename: "Composer";
  id: string;
  full_name: string;
}

export interface CreateComposer_createComposer_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface CreateComposer_createComposer {
  __typename: "CreateComposerPayload";
  composer: CreateComposer_createComposer_composer | null;
  errors: CreateComposer_createComposer_errors[] | null;
}

export interface CreateComposer {
  /**
   * Create a Composer. This mutation returns an Composer object.
   */
  createComposer: CreateComposer_createComposer;
}

export interface CreateComposerVariables {
  input: CreateComposerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateComposer
// ====================================================

export interface UpdateComposer_updateComposer_composer {
  __typename: "Composer";
  id: string;
  full_name: string;
  email: string | null;
  first_name: string;
  last_name: string;
  middle_name: string | null;
  has_rights: boolean;
  ipi: string | null;
  perf_rights_org_name: string;
  perf_rights_org: string;
  publisher: string;
  publisher_ipi: string | null;
}

export interface UpdateComposer_updateComposer_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateComposer_updateComposer {
  __typename: "UpdateComposerPayload";
  composer: UpdateComposer_updateComposer_composer | null;
  errors: UpdateComposer_updateComposer_errors[] | null;
}

export interface UpdateComposer {
  /**
   * Update a Composer. This mutation returns an Composer object.
   */
  updateComposer: UpdateComposer_updateComposer;
}

export interface UpdateComposerVariables {
  input: UpdateComposerInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PasswordConfirm
// ====================================================

export interface PasswordConfirm_confirmPassword_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface PasswordConfirm_confirmPassword {
  __typename: "ConfirmPasswordPayload";
  errors: PasswordConfirm_confirmPassword_errors[] | null;
}

export interface PasswordConfirm {
  /**
   * Confirm a password for a User. This mutation returns a User object. Used in the password confirmation screen.
   */
  confirmPassword: PasswordConfirm_confirmPassword;
}

export interface PasswordConfirmVariables {
  input: ConfirmPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConflictsOrganization
// ====================================================

export interface ConflictsOrganization_me_organization_youtube_conflict_totals {
  __typename: "YoutubeConflictTotals";
  id: string;
  latest_youtube_reports_ingested_at: Scalar$DateTime;
  total_conflicts: number;
  total_artists_in_conflict: number;
  total_users_in_conflict: number;
  total_active_conflicts: number;
  total_resolved_conflicts: number;
}

export interface ConflictsOrganization_me_organization_youtube_conflicts_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface ConflictsOrganization_me_organization_youtube_conflicts_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ConflictsOrganization_me_organization_youtube_conflicts_media {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
}

export interface ConflictsOrganization_me_organization_youtube_conflicts {
  __typename: "YoutubeConflict";
  id: string;
  daily_views: number;
  song_name: string;
  artist_name: string;
  asset_type: string;
  conflicting_owner: string;
  conflicting_territories: string;
  created_at: Scalar$DateTime;
  resolved: boolean;
  user_name: string;
  user: ConflictsOrganization_me_organization_youtube_conflicts_user;
  artist: ConflictsOrganization_me_organization_youtube_conflicts_artist | null;
  media: ConflictsOrganization_me_organization_youtube_conflicts_media;
}

export interface ConflictsOrganization_me_organization {
  __typename: "Organization";
  id: string;
  youtube_conflict_totals: ConflictsOrganization_me_organization_youtube_conflict_totals[];
  youtube_conflicts_count: number;
  youtube_conflicts: ConflictsOrganization_me_organization_youtube_conflicts[];
}

export interface ConflictsOrganization_me {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * An Organization a user is a member of
   */
  organization: ConflictsOrganization_me_organization | null;
}

export interface ConflictsOrganization_current_observer {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface ConflictsOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: ConflictsOrganization_me;
  observer_signed_in: boolean;
  current_observer: ConflictsOrganization_current_observer | null;
}

export interface ConflictsOrganizationVariables {
  input?: YoutubeConflictsCursorInput | null;
  totals?: YoutubeConflictsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConflictsUser
// ====================================================

export interface ConflictsUser_me_youtube_conflict_totals {
  __typename: "YoutubeConflictTotals";
  id: string;
  latest_youtube_reports_ingested_at: Scalar$DateTime;
  total_conflicts: number;
  total_artists_in_conflict: number;
  total_users_in_conflict: number;
  total_active_conflicts: number;
  total_resolved_conflicts: number;
}

export interface ConflictsUser_me_youtube_conflicts_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface ConflictsUser_me_youtube_conflicts_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ConflictsUser_me_youtube_conflicts_media {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
}

export interface ConflictsUser_me_youtube_conflicts {
  __typename: "YoutubeConflict";
  id: string;
  artist_name: string;
  daily_views: number;
  song_name: string;
  asset_type: string;
  conflicting_owner: string;
  conflicting_territories: string;
  created_at: Scalar$DateTime;
  resolved: boolean;
  user_name: string;
  user: ConflictsUser_me_youtube_conflicts_user;
  artist: ConflictsUser_me_youtube_conflicts_artist | null;
  media: ConflictsUser_me_youtube_conflicts_media;
}

export interface ConflictsUser_me {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
  youtube_conflict_totals: ConflictsUser_me_youtube_conflict_totals[];
  youtube_conflicts_count: number;
  youtube_conflicts: ConflictsUser_me_youtube_conflicts[];
}

export interface ConflictsUser_current_observer {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface ConflictsUser {
  /**
   * Get information about the currently logged in user
   */
  me: ConflictsUser_me;
  observer_signed_in: boolean;
  current_observer: ConflictsUser_current_observer | null;
}

export interface ConflictsUserVariables {
  input?: YoutubeConflictsCursorInput | null;
  totals?: YoutubeConflictsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ConnectSocialsArtists
// ====================================================

export interface ConnectSocialsArtists_artist_socials {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  /**
   * Associated deployer name
   */
  deployer_name: string | null;
}

export interface ConnectSocialsArtists_artist {
  __typename: "Artist";
  id: string;
  /**
   * URL to connect Twitter account to this artist
   */
  twitter_connect_url: string;
  facebook_connect_url: string;
  youtube_connect_url: string;
  /**
   * List of socials deployable or not for an artist
   */
  socials: ConnectSocialsArtists_artist_socials[];
}

export interface ConnectSocialsArtists {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: ConnectSocialsArtists_artist;
}

export interface ConnectSocialsArtistsVariables {
  id: string;
  successPath: string;
  errorPath: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateArtist
// ====================================================

export interface CreateArtist_createArtist_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface CreateArtist_createArtist_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface CreateArtist_createArtist {
  __typename: "CreateArtistPayload";
  artist: CreateArtist_createArtist_artist | null;
  errors: CreateArtist_createArtist_errors[] | null;
}

export interface CreateArtist {
  /**
   * Create an Artist. This mutation returns an Artist object. Used in artist creation flows.
   */
  createArtist: CreateArtist_createArtist;
}

export interface CreateArtistVariables {
  input: CreateArtistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationUserSelect
// ====================================================

export interface OrganizationUserSelect_me_organization_users {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
  full_name: string | null;
}

export interface OrganizationUserSelect_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * List of users in this organization
   */
  users: OrganizationUserSelect_me_organization_users[];
}

export interface OrganizationUserSelect_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationUserSelect_me_organization | null;
}

export interface OrganizationUserSelect {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationUserSelect_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePayeeInvitation
// ====================================================

export interface CreatePayeeInvitation_createPayeeInvitation_payee_invite_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface CreatePayeeInvitation_createPayeeInvitation_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: CreatePayeeInvitation_createPayeeInvitation_payee_invite_payee | null;
}

export interface CreatePayeeInvitation_createPayeeInvitation_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface CreatePayeeInvitation_createPayeeInvitation {
  __typename: "CreatePayeeInvitationPayload";
  payee_invite: CreatePayeeInvitation_createPayeeInvitation_payee_invite | null;
  errors: CreatePayeeInvitation_createPayeeInvitation_errors[] | null;
}

export interface CreatePayeeInvitation {
  /**
   * Create a PayeeInvite. This mutation returns a PayeeInvite object. Used when an invitation to the royalty center is sent.
   */
  createPayeeInvitation: CreatePayeeInvitation_createPayeeInvitation;
}

export interface CreatePayeeInvitationVariables {
  input: CreatePayeeInvitationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DisplayRevenueBannerQuery
// ====================================================

export interface DisplayRevenueBannerQuery_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has seen a feature
   */
  has_seen_d2_revenue_banner: boolean;
}

export interface DisplayRevenueBannerQuery {
  /**
   * Get information about the currently logged in user
   */
  me: DisplayRevenueBannerQuery_me;
}

export interface DisplayRevenueBannerQueryVariables {
  timestamp?: Scalar$DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateArtist
// ====================================================

export interface UpdateArtist_updateArtist_artist {
  __typename: "Artist";
  id: string;
  beatport: string | null;
  facebook: string | null;
  instagram: string | null;
  itunes: string | null;
  site: string | null;
  soundcloud: string | null;
  spotify: string | null;
  tumblr: string | null;
  twitter: string | null;
  youtube_channel_url: string | null;
}

export interface UpdateArtist_updateArtist_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateArtist_updateArtist {
  __typename: "UpdateArtistPayload";
  artist: UpdateArtist_updateArtist_artist | null;
  errors: UpdateArtist_updateArtist_errors[] | null;
}

export interface UpdateArtist {
  /**
   * Update an Artist. This mutation returns an Artist. Used when a user updates the information on an artist.
   */
  updateArtist: UpdateArtist_updateArtist;
}

export interface UpdateArtistVariables {
  input: UpdateArtistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EditArtistCustomLinksQuery
// ====================================================

export interface EditArtistCustomLinksQuery_artist {
  __typename: "Artist";
  id: string;
  beatport: string | null;
  facebook: string | null;
  instagram: string | null;
  itunes: string | null;
  site: string | null;
  soundcloud: string | null;
  spotify: string | null;
  tumblr: string | null;
  twitter: string | null;
  youtube_channel_url: string | null;
}

export interface EditArtistCustomLinksQuery {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: EditArtistCustomLinksQuery_artist;
}

export interface EditArtistCustomLinksQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EditPoliciesField
// ====================================================

export interface EditPoliciesField_me {
  __typename: "User";
  id: string;
  is_whitelabel: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_sound_exchange: boolean;
}

export interface EditPoliciesField_branding {
  __typename: "Branding";
  id: string;
  rights_management_learn_more_url: string;
}

export interface EditPoliciesField {
  /**
   * Get information about the currently logged in user
   */
  me: EditPoliciesField_me;
  branding: EditPoliciesField_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RenderFanInsightsPrivacyAgreement
// ====================================================

export interface RenderFanInsightsPrivacyAgreement_me {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user can access fan insights
   */
  can_access_fan_insights: boolean;
  render_agreement_by_type: string;
}

export interface RenderFanInsightsPrivacyAgreement {
  /**
   * Get information about the currently logged in user
   */
  me: RenderFanInsightsPrivacyAgreement_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcknowledgeFanInsightsPrivacyAgreement
// ====================================================

export interface AcknowledgeFanInsightsPrivacyAgreement_acceptAgreementByType_user {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user can access fan insights
   */
  can_access_fan_insights: boolean;
}

export interface AcknowledgeFanInsightsPrivacyAgreement_acceptAgreementByType_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AcknowledgeFanInsightsPrivacyAgreement_acceptAgreementByType {
  __typename: "AcceptAgreementByTypePayload";
  user: AcknowledgeFanInsightsPrivacyAgreement_acceptAgreementByType_user | null;
  errors: AcknowledgeFanInsightsPrivacyAgreement_acceptAgreementByType_errors[] | null;
}

export interface AcknowledgeFanInsightsPrivacyAgreement {
  /**
   * Accept an agreement by type, for when the agreement type is not one of the types that has a dedicated mutation.
   */
  acceptAgreementByType: AcknowledgeFanInsightsPrivacyAgreement_acceptAgreementByType;
}

export interface AcknowledgeFanInsightsPrivacyAgreementVariables {
  input: AcceptAgreementByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsFilterBarMediaTab
// ====================================================

export interface AnalyticsFilterBarMediaTab_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AnalyticsFilterBarMediaTab_media {
  __typename: "Media";
  id: string;
  type: MediaType;
  thumbnail_url: string | null;
  metadata: AnalyticsFilterBarMediaTab_media_metadata | null;
}

export interface AnalyticsFilterBarMediaTab {
  /**
   * Find the current media by ID
   */
  media: AnalyticsFilterBarMediaTab_media;
}

export interface AnalyticsFilterBarMediaTabVariables {
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsFilterBarAlbumTab
// ====================================================

export interface AnalyticsFilterBarAlbumTab_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AnalyticsFilterBarAlbumTab_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AnalyticsFilterBarAlbumTab_album {
  __typename: "Album";
  id: string;
  metadata: AnalyticsFilterBarAlbumTab_album_metadata;
  file_upload: AnalyticsFilterBarAlbumTab_album_file_upload | null;
}

export interface AnalyticsFilterBarAlbumTab {
  /**
   * Find the current album by ID
   */
  album: AnalyticsFilterBarAlbumTab_album;
}

export interface AnalyticsFilterBarAlbumTabVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsFilterBarArtistTab
// ====================================================

export interface AnalyticsFilterBarArtistTab_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AnalyticsFilterBarArtistTab {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: AnalyticsFilterBarArtistTab_artist;
}

export interface AnalyticsFilterBarArtistTabVariables {
  artistId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsFilterBarOrganizationTab
// ====================================================

export interface AnalyticsFilterBarOrganizationTab_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface AnalyticsFilterBarOrganizationTab_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: AnalyticsFilterBarOrganizationTab_me_organization | null;
}

export interface AnalyticsFilterBarOrganizationTab {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsFilterBarOrganizationTab_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsFilterBarOrganizationUserTab
// ====================================================

export interface AnalyticsFilterBarOrganizationUserTab_me_organization_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface AnalyticsFilterBarOrganizationUserTab_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * User in this organization
   */
  user: AnalyticsFilterBarOrganizationUserTab_me_organization_user | null;
}

export interface AnalyticsFilterBarOrganizationUserTab_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AnalyticsFilterBarOrganizationUserTab_me_organization | null;
}

export interface AnalyticsFilterBarOrganizationUserTab {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsFilterBarOrganizationUserTab_me;
}

export interface AnalyticsFilterBarOrganizationUserTabVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsFilterBarUserTab
// ====================================================

export interface AnalyticsFilterBarUserTab_me {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface AnalyticsFilterBarUserTab {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsFilterBarUserTab_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: instagramBusinessButton
// ====================================================

export interface instagramBusinessButton_me {
  __typename: "User";
  id: string;
}

export interface instagramBusinessButton {
  /**
   * Get information about the currently logged in user
   */
  me: instagramBusinessButton_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: instagramInfoModal
// ====================================================

export interface instagramInfoModal_me {
  __typename: "User";
  id: string;
}

export interface instagramInfoModal_artist {
  __typename: "Artist";
  id: string;
  facebook_connect_url: string;
}

export interface instagramInfoModal {
  /**
   * Get information about the currently logged in user
   */
  me: instagramInfoModal_me;
  /**
   * Find one of the current user's Artists by ID
   */
  artist: instagramInfoModal_artist;
}

export interface instagramInfoModalVariables {
  artistId: string;
  successPath?: string | null;
  errorPath?: string | null;
  urlType?: string | null;
  instagram?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VideoInformation
// ====================================================

export interface VideoInformation_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface VideoInformation_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface VideoInformation_media {
  __typename: "Media";
  id: string;
  artist: VideoInformation_media_artist | null;
  metadata: VideoInformation_media_metadata | null;
  thumbnail_url: string | null;
}

export interface VideoInformation {
  /**
   * Find the current media by ID
   */
  media: VideoInformation_media;
}

export interface VideoInformationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumInformation
// ====================================================

export interface AlbumInformation_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AlbumInformation_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AlbumInformation_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AlbumInformation_album_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
}

export interface AlbumInformation_album {
  __typename: "Album";
  artist: AlbumInformation_album_artist;
  file_upload: AlbumInformation_album_file_upload | null;
  id: string;
  metadata: AlbumInformation_album_metadata;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: AlbumInformation_album_album_medias_cursor;
}

export interface AlbumInformation {
  /**
   * Find the current album by ID
   */
  album: AlbumInformation_album;
}

export interface AlbumInformationVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AppleListenersArtistAnalytics
// ====================================================

export interface AppleListenersArtistAnalytics_artist_apple_music_artist_analytics {
  __typename: "AppleMusicArtistAnalytics";
  id: string;
  /**
   * discrete listeners
   */
  listeners_discrete: number | null;
  /**
   * cumulative listeners
   */
  listeners_cumulative: number | null;
  date: Scalar$DateTime;
}

export interface AppleListenersArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * artist analytics for apple music
   */
  apple_music_artist_analytics: AppleListenersArtistAnalytics_artist_apple_music_artist_analytics[];
}

export interface AppleListenersArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: AppleListenersArtistAnalytics_artist;
}

export interface AppleListenersArtistAnalyticsVariables {
  id: string;
  input?: AppleMusicArtistAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FacebookFansArtistAnalytics
// ====================================================

export interface FacebookFansArtistAnalytics_artist_facebook_artist_analytics {
  __typename: "FacebookArtistAnalytics";
  id: string;
  /**
   * cumulative fans
   */
  fans_cumulative: number;
  /**
   * discrete fans
   */
  fans_discrete: number;
  date: Scalar$DateTime | null;
}

export interface FacebookFansArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * Flag to check if any socials needs reconnection
   */
  any_socials_need_reconnect: boolean;
  facebook_reconnect_url: string;
  /**
   * artist analytics for facebook
   */
  facebook_artist_analytics: FacebookFansArtistAnalytics_artist_facebook_artist_analytics[];
}

export interface FacebookFansArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: FacebookFansArtistAnalytics_artist;
}

export interface FacebookFansArtistAnalyticsVariables {
  id: string;
  input?: FacebookArtistAnalyticsCursorInput | null;
  successPath: string;
  errorPath: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FacebookFansByCountryArtistAnalytics
// ====================================================

export interface FacebookFansByCountryArtistAnalytics_artist_facebook_artist_analytics {
  __typename: "FacebookArtistAnalytics";
  id: string;
  country_code: AnalyticsCountryCode | null;
  /**
   * cumulative fans
   */
  fans_cumulative: number;
}

export interface FacebookFansByCountryArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * Flag to check if any socials needs reconnection
   */
  any_socials_need_reconnect: boolean;
  facebook_reconnect_url: string;
  /**
   * artist analytics for facebook
   */
  facebook_artist_analytics: FacebookFansByCountryArtistAnalytics_artist_facebook_artist_analytics[];
}

export interface FacebookFansByCountryArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: FacebookFansByCountryArtistAnalytics_artist;
}

export interface FacebookFansByCountryArtistAnalyticsVariables {
  id: string;
  input?: FacebookArtistAnalyticsCursorInput | null;
  successPath: string;
  errorPath: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InstagramFollowersArtistAnalytics
// ====================================================

export interface InstagramFollowersArtistAnalytics_artist_instagram_artist_analytics {
  __typename: "InstagramArtistAnalytics";
  id: string;
  /**
   * discrete followers
   */
  followers_discrete: number | null;
  /**
   * cumulative followers
   */
  followers_cumulative: number | null;
  date: Scalar$DateTime;
}

export interface InstagramFollowersArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * Flag to check if any socials needs reconnection
   */
  any_socials_need_reconnect: boolean;
  instagram_reconnect_url: string;
  /**
   * Total followers count on connected instagram business accounts
   */
  total_followers_count: number;
  /**
   * artist analytics for instagram
   */
  instagram_artist_analytics: InstagramFollowersArtistAnalytics_artist_instagram_artist_analytics[];
}

export interface InstagramFollowersArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: InstagramFollowersArtistAnalytics_artist;
}

export interface InstagramFollowersArtistAnalyticsVariables {
  id: string;
  input?: InstagramArtistAnalyticsCursorInput | null;
  successPath: string;
  errorPath: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InstagramAudienceArtistAnalytics
// ====================================================

export interface InstagramAudienceArtistAnalytics_artist_instagram_artist_audience_analytics {
  __typename: "InstagramArtistAudienceAnalytics";
  id: string;
  country_code: AnalyticsCountryCode | null;
  /**
   * cumulative audience
   */
  audience_cumulative: number;
}

export interface InstagramAudienceArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * Flag to check if any socials needs reconnection
   */
  any_socials_need_reconnect: boolean;
  instagram_reconnect_url: string;
  /**
   * artist analytics for instagram audience
   */
  instagram_artist_audience_analytics: InstagramAudienceArtistAnalytics_artist_instagram_artist_audience_analytics[];
}

export interface InstagramAudienceArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: InstagramAudienceArtistAnalytics_artist;
}

export interface InstagramAudienceArtistAnalyticsVariables {
  id: string;
  input?: InstagramArtistAudienceAnalyticsCursorInput | null;
  successPath: string;
  errorPath: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: NativeArtistAnalytics
// ====================================================

export interface NativeArtistAnalytics_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface NativeArtistAnalytics_me {
  __typename: "User";
  id: string;
  /**
   * List out all Artists for the current user
   */
  artists: NativeArtistAnalytics_me_artists[];
}

export interface NativeArtistAnalytics {
  /**
   * Get information about the currently logged in user
   */
  me: NativeArtistAnalytics_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpotifyFollowersArtistAnalytics
// ====================================================

export interface SpotifyFollowersArtistAnalytics_artist_spotify_artist_analytics {
  __typename: "SpotifyArtistAnalytics";
  id: string;
  /**
   * cumulative followers
   */
  followers_cumulative: number | null;
  /**
   * discrete followers
   */
  followers_discrete: number | null;
  date: Scalar$DateTime;
}

export interface SpotifyFollowersArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * artist analytics for spotify
   */
  spotify_artist_analytics: SpotifyFollowersArtistAnalytics_artist_spotify_artist_analytics[];
}

export interface SpotifyFollowersArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: SpotifyFollowersArtistAnalytics_artist;
}

export interface SpotifyFollowersArtistAnalyticsVariables {
  id: string;
  input?: SpotifyArtistAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpotifyListenersArtistAnalytics
// ====================================================

export interface SpotifyListenersArtistAnalytics_artist_spotify_artist_analytics {
  __typename: "SpotifyArtistAnalytics";
  id: string;
  /**
   * discrete listeners
   */
  listeners_discrete: number | null;
  date: Scalar$DateTime;
}

export interface SpotifyListenersArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * artist analytics for spotify
   */
  spotify_artist_analytics: SpotifyListenersArtistAnalytics_artist_spotify_artist_analytics[];
}

export interface SpotifyListenersArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: SpotifyListenersArtistAnalytics_artist;
}

export interface SpotifyListenersArtistAnalyticsVariables {
  id: string;
  input?: SpotifyArtistAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpotifyPopularityArtistAnalytics
// ====================================================

export interface SpotifyPopularityArtistAnalytics_artist_spotify_artist_analytics {
  __typename: "SpotifyArtistAnalytics";
  id: string;
  /**
   * popularity
   */
  popularity: number | null;
  date: Scalar$DateTime;
}

export interface SpotifyPopularityArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * artist analytics for spotify
   */
  spotify_artist_analytics: SpotifyPopularityArtistAnalytics_artist_spotify_artist_analytics[];
}

export interface SpotifyPopularityArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: SpotifyPopularityArtistAnalytics_artist;
}

export interface SpotifyPopularityArtistAnalyticsVariables {
  id: string;
  input?: SpotifyArtistAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: YoutubeSubscribersArtistAnalytics
// ====================================================

export interface YoutubeSubscribersArtistAnalytics_artist_youtube_artist_analytics {
  __typename: "YoutubeArtistAnalytics";
  id: string;
  /**
   * discrete subscribers
   */
  subscribers_discrete: number | null;
  /**
   * cumulative subscribers
   */
  subscribers_cumulative: number | null;
  date: Scalar$DateTime;
}

export interface YoutubeSubscribersArtistAnalytics_artist {
  __typename: "Artist";
  id: string;
  /**
   * Flag to check if any socials needs reconnection
   */
  any_socials_need_reconnect: boolean;
  youtube_reconnect_url: string;
  /**
   * artist analytics for youtube
   */
  youtube_artist_analytics: YoutubeSubscribersArtistAnalytics_artist_youtube_artist_analytics[];
}

export interface YoutubeSubscribersArtistAnalytics {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: YoutubeSubscribersArtistAnalytics_artist;
}

export interface YoutubeSubscribersArtistAnalyticsVariables {
  id: string;
  input?: YoutubeArtistAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OneTimePassword
// ====================================================

export interface OneTimePassword_mfa {
  __typename: "MFA";
  id: string;
  is_admin: boolean;
  phone: string | null;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
  /**
   * User's OTP MFA method
   */
  otp_mfa_method: OtpMethodType | null;
  /**
   * Whether or not the user's OTP has expired
   */
  otp_expired: boolean;
  /**
   * The secret key for authentication
   */
  otp_authenticator_secret: string | null;
}

export interface OneTimePassword {
  /**
   * Information used to populate the MFA/OTP screen when a user is prompted to enter MFA information
   */
  mfa: OneTimePassword_mfa;
  observer_signed_in: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendOTPCode
// ====================================================

export interface SendOTPCode_sendOTPCode_user_mfa {
  __typename: "MFA";
  id: string;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
  /**
   * Whether or not the user's OTP has expired
   */
  otp_expired: boolean;
}

export interface SendOTPCode_sendOTPCode_user {
  __typename: "User";
  id: string;
  phone: string | null;
  /**
   * MFA is normally queried from root query mfa field, but this mfa field is nested
   * under User so can more easily be used from a mutation that returns a User.
   */
  mfa: SendOTPCode_sendOTPCode_user_mfa;
}

export interface SendOTPCode_sendOTPCode_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SendOTPCode_sendOTPCode {
  __typename: "SendOTPCodePayload";
  user: SendOTPCode_sendOTPCode_user | null;
  errors: SendOTPCode_sendOTPCode_errors[] | null;
}

export interface SendOTPCode {
  /**
   * Send Twilio Verification Code for OTP MFA.
   */
  sendOTPCode: SendOTPCode_sendOTPCode;
}

export interface SendOTPCodeVariables {
  input: SendOTPCodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationInviteUserQuery
// ====================================================

export interface OrganizationInviteUserQuery_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface OrganizationInviteUserQuery_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationInviteUserQuery_me_organization | null;
}

export interface OrganizationInviteUserQuery {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationInviteUserQuery_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: OrganizationInviteUser
// ====================================================

export interface OrganizationInviteUser_inviteOrganizationUser_user {
  __typename: "User";
  id: string;
}

export interface OrganizationInviteUser_inviteOrganizationUser_artist {
  __typename: "Artist";
  id: string;
}

export interface OrganizationInviteUser_inviteOrganizationUser_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface OrganizationInviteUser_inviteOrganizationUser {
  __typename: "InviteOrganizationUserPayload";
  user: OrganizationInviteUser_inviteOrganizationUser_user | null;
  artist: OrganizationInviteUser_inviteOrganizationUser_artist | null;
  errors: OrganizationInviteUser_inviteOrganizationUser_errors[] | null;
}

export interface OrganizationInviteUser {
  /**
   * Invite a User to an Organization. Used when a user chooses to invite another potential user to a label/organization.
   */
  inviteOrganizationUser: OrganizationInviteUser_inviteOrganizationUser;
}

export interface OrganizationInviteUserVariables {
  input: InviteOrganizationUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditOrganizationUser
// ====================================================

export interface EditOrganizationUser_editOrganizationUser_user {
  __typename: "User";
  id: string;
}

export interface EditOrganizationUser_editOrganizationUser_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface EditOrganizationUser_editOrganizationUser {
  __typename: "EditOrganizationUserPayload";
  /**
   * User
   */
  user: EditOrganizationUser_editOrganizationUser_user | null;
  errors: EditOrganizationUser_editOrganizationUser_errors[] | null;
}

export interface EditOrganizationUser {
  /**
   * Default description for "EditOrganizationUser" mutation.
   */
  editOrganizationUser: EditOrganizationUser_editOrganizationUser;
}

export interface EditOrganizationUserVariables {
  input: EditOrganizationUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewAssetRevenue
// ====================================================

export interface OverviewAssetRevenue_me_asset_revenues {
  __typename: "AssetRevenue";
  id: string;
  asset_type: AssetType;
  total_client_revenue: number;
  total_net_revenue: number;
}

export interface OverviewAssetRevenue_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * revenue for assets
   */
  asset_revenues: OverviewAssetRevenue_me_asset_revenues[];
}

export interface OverviewAssetRevenue {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewAssetRevenue_me;
}

export interface OverviewAssetRevenueVariables {
  input?: AssetRevenuesCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MonthlyNetworkRevenues
// ====================================================

export interface MonthlyNetworkRevenues_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface MonthlyNetworkRevenues_me_balance_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface MonthlyNetworkRevenues_me_monthly_network_revenues_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface MonthlyNetworkRevenues_me_monthly_network_revenues_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface MonthlyNetworkRevenues_me_monthly_network_revenues {
  __typename: "MonthlyNetworkRevenue";
  id: string;
  date: Scalar$DateTime;
  total_client_revenue: number;
  total_net_revenue: number;
  streams_net_revenue: number;
  downloads_net_revenue: number;
  network: MonthlyNetworkRevenues_me_monthly_network_revenues_network | null;
  artist: MonthlyNetworkRevenues_me_monthly_network_revenues_artist | null;
}

export interface MonthlyNetworkRevenues_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * List out all Artists for the current user
   */
  artists: MonthlyNetworkRevenues_me_artists[];
  /**
   * The networks the user has made revenue from
   */
  balance_networks: MonthlyNetworkRevenues_me_balance_networks[];
  /**
   * Month Revenue for Networks
   */
  monthly_network_revenues: MonthlyNetworkRevenues_me_monthly_network_revenues[];
}

export interface MonthlyNetworkRevenues {
  /**
   * Get information about the currently logged in user
   */
  me: MonthlyNetworkRevenues_me;
}

export interface MonthlyNetworkRevenuesVariables {
  monthlyNetworkInput?: MonthlyNetworkRevenuesCursorInput | null;
  audioOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewNetworkRevenue
// ====================================================

export interface OverviewNetworkRevenue_me_network_revenues_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface OverviewNetworkRevenue_me_network_revenues {
  __typename: "NetworkRevenue";
  downloads_client_revenue: number;
  downloads_net_revenue: number;
  id: string;
  streams_client_revenue: number;
  streams_net_revenue: number;
  total_downloads_discrete: number;
  total_streams_discrete: number;
  network: OverviewNetworkRevenue_me_network_revenues_network;
}

export interface OverviewNetworkRevenue_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * revenue for networks
   */
  network_revenues: OverviewNetworkRevenue_me_network_revenues[];
}

export interface OverviewNetworkRevenue {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewNetworkRevenue_me;
}

export interface OverviewNetworkRevenueVariables {
  input?: NetworkRevenuesCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewTopArtists
// ====================================================

export interface OverviewTopArtists_me_top_artists_artist {
  __typename: "Artist";
  id: string;
  thumbnail_url: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface OverviewTopArtists_me_top_artists {
  __typename: "TopArtist";
  id: string;
  client_net_revenue_cumulative: number;
  artist: OverviewTopArtists_me_top_artists_artist;
}

export interface OverviewTopArtists_me {
  __typename: "User";
  id: string;
  /**
   * Any more top medias in this user
   */
  top_artists_any_more_results: boolean;
  /**
   * Count of top artists for the user
   */
  top_artists_count: number;
  /**
   * Top artists for the user
   */
  top_artists: OverviewTopArtists_me_top_artists[];
}

export interface OverviewTopArtists {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewTopArtists_me;
}

export interface OverviewTopArtistsVariables {
  input?: TopArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewRevenue
// ====================================================

export interface OverviewRevenue_me_top_medias {
  __typename: "TopMedia";
  id: string;
  client_net_revenue_cumulative: number;
}

export interface OverviewRevenue_me_top_albums {
  __typename: "TopAlbum";
  id: string;
}

export interface OverviewRevenue_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has a feature
   */
  has_feature_revenue_albums: boolean;
  /**
   * Number of top medias in this user
   */
  top_medias_count: number;
  /**
   * Top medias by earnings in this user
   */
  top_medias: OverviewRevenue_me_top_medias[];
  /**
   * Top albums for the user
   */
  top_albums: OverviewRevenue_me_top_albums[];
}

export interface OverviewRevenue {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewRevenue_me;
}

export interface OverviewRevenueVariables {
  topMediasInput?: TopMediasCursorInput | null;
  topAlbumsInput?: TopAlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewSummaryCards
// ====================================================

export interface OverviewSummaryCards_me_lifetime_quarterly_earnings {
  __typename: "QuarterlyEarnings";
  id: string;
  gross_earnings: number;
  quarter_date: Scalar$DateTime;
}

export interface OverviewSummaryCards_me_top_artists {
  __typename: "TopArtist";
  id: string;
  client_gross_revenue_cumulative: number;
}

export interface OverviewSummaryCards_me_top_medias {
  __typename: "TopMedia";
  id: string;
  client_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
}

export interface OverviewSummaryCards_me_top_albums {
  __typename: "TopAlbum";
  id: string;
  client_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
}

export interface OverviewSummaryCards_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * pretty_client_revenue using materialized views and only including locked-in revenue (not including pending revenue)
   */
  pretty_client_revenue_royalty_center: string;
  pretty_available_balance: string;
  /**
   * Earnings for the quarters in the current year
   */
  lifetime_quarterly_earnings: OverviewSummaryCards_me_lifetime_quarterly_earnings[];
  /**
   * Top artists for the user
   */
  top_artists: OverviewSummaryCards_me_top_artists[];
  /**
   * Top medias by earnings in this user
   */
  top_medias: OverviewSummaryCards_me_top_medias[];
  /**
   * Top albums for the user
   */
  top_albums: OverviewSummaryCards_me_top_albums[];
}

export interface OverviewSummaryCards_branding {
  __typename: "Branding";
  id: string;
  tipalti_faq_url: string;
}

export interface OverviewSummaryCards {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewSummaryCards_me;
  branding: OverviewSummaryCards_branding;
}

export interface OverviewSummaryCardsVariables {
  lifetimeQuarterlyEarningsInput?: QuarterlyEarningsCursorInput | null;
  topArtistsInput?: TopArtistsCursorInput | null;
  topMediasInput?: TopMediasCursorInput | null;
  topAlbumsInput?: TopAlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewTopAlbums
// ====================================================

export interface OverviewTopAlbums_me_top_albums_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface OverviewTopAlbums_me_top_albums_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface OverviewTopAlbums_me_top_albums_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface OverviewTopAlbums_me_top_albums_album {
  __typename: "Album";
  id: string;
  artist: OverviewTopAlbums_me_top_albums_album_artist;
  metadata: OverviewTopAlbums_me_top_albums_album_metadata;
  file_upload: OverviewTopAlbums_me_top_albums_album_file_upload | null;
}

export interface OverviewTopAlbums_me_top_albums {
  __typename: "TopAlbum";
  id: string;
  client_net_revenue_cumulative: number;
  album: OverviewTopAlbums_me_top_albums_album;
}

export interface OverviewTopAlbums_me {
  __typename: "User";
  id: string;
  /**
   * Any more top medias in this user
   */
  top_albums_any_more_results: boolean;
  /**
   * Count of top albums for the user
   */
  top_albums_count: number;
  /**
   * Top albums for the user
   */
  top_albums: OverviewTopAlbums_me_top_albums[];
}

export interface OverviewTopAlbums {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewTopAlbums_me;
}

export interface OverviewTopAlbumsVariables {
  input?: TopAlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewTopMedias
// ====================================================

export interface OverviewTopMedias_me_top_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface OverviewTopMedias_me_top_medias_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface OverviewTopMedias_me_top_medias_media {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  metadata: OverviewTopMedias_me_top_medias_media_metadata | null;
  artist: OverviewTopMedias_me_top_medias_media_artist | null;
}

export interface OverviewTopMedias_me_top_medias {
  __typename: "TopMedia";
  id: string;
  client_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
  media: OverviewTopMedias_me_top_medias_media;
}

export interface OverviewTopMedias_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Any more top medias in this user
   */
  top_medias_any_more_results: boolean;
  /**
   * Number of top medias in this user
   */
  top_medias_count: number;
  /**
   * Top medias by earnings in this user
   */
  top_medias: OverviewTopMedias_me_top_medias[];
}

export interface OverviewTopMedias {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewTopMedias_me;
}

export interface OverviewTopMediasVariables {
  input?: TopMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewTopCountries
// ====================================================

export interface OverviewTopCountries_me_top_geocountries_country {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface OverviewTopCountries_me_top_geocountries {
  __typename: "TopGeocountry";
  id: string;
  client_revenue_cumulative: number;
  net_revenue_cumulative: number;
  country: OverviewTopCountries_me_top_geocountries_country;
}

export interface OverviewTopCountries_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Count of top artists for the user
   */
  top_geocountries_count: number;
  /**
   * Any more top medias in this user
   */
  top_geocountries_any_more_results: boolean;
  /**
   * Top countries for the user
   */
  top_geocountries: OverviewTopCountries_me_top_geocountries[];
}

export interface OverviewTopCountries {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewTopCountries_me;
}

export interface OverviewTopCountriesVariables {
  input?: TopGeocountryCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentMethodPickerAddressFields
// ====================================================

export interface PaymentMethodPickerAddressFields_territories_provinces {
  __typename: "Province";
  id: string;
  name: string;
}

export interface PaymentMethodPickerAddressFields_territories {
  __typename: "Territory";
  id: string;
  name: string;
  /**
   * Gets all the available states or provinces for a given country territory. Null if not US.
   */
  provinces: PaymentMethodPickerAddressFields_territories_provinces[] | null;
}

export interface PaymentMethodPickerAddressFields {
  /**
   * Gets all the available ownership territories from our system
   */
  territories: PaymentMethodPickerAddressFields_territories[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PaymentMethodPicker
// ====================================================

export interface PaymentMethodPicker_i18n_VydiaFAQ {
  __typename: "VydiaFAQ";
  pciComplianceInfo: string;
}

export interface PaymentMethodPicker_i18n {
  __typename: "I18n";
  VydiaFAQ: PaymentMethodPicker_i18n_VydiaFAQ;
}

export interface PaymentMethodPicker_me_creditCards {
  __typename: "PaymentMethodCreditCard";
  id: string;
  display: string;
}

export interface PaymentMethodPicker_me_paypalAccounts_paymentAddress {
  __typename: "PaymentAddress";
  id: string;
  streetAddress: string;
  extendedAddress: string | null;
  locality: string;
  region: string;
  countryCode: string;
  postalCode: string;
}

export interface PaymentMethodPicker_me_paypalAccounts {
  __typename: "PaymentMethodPaypalAccount";
  id: string;
  display: string;
  paymentAddress: PaymentMethodPicker_me_paypalAccounts_paymentAddress | null;
}

export interface PaymentMethodPicker_me {
  __typename: "User";
  id: string;
  /**
   * Token for payments processed to braintree
   */
  braintreeClientToken: string;
  /**
   * List of credit cards
   */
  creditCards: PaymentMethodPicker_me_creditCards[];
  /**
   * List of Paypal accounts
   */
  paypalAccounts: PaymentMethodPicker_me_paypalAccounts[];
}

export interface PaymentMethodPicker {
  /**
   * Get translation strings for screens and components.
   */
  i18n: PaymentMethodPicker_i18n;
  /**
   * Get information about the currently logged in user
   */
  me: PaymentMethodPicker_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayorFtuiModal
// ====================================================

export interface PayorFtuiModal_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has seen a feature
   */
  has_seen_royalty_center_payor: boolean;
}

export interface PayorFtuiModal {
  /**
   * Get information about the currently logged in user
   */
  me: PayorFtuiModal_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateArtistAppleEid
// ====================================================

export interface UpdateArtistAppleEid_updateArtistAppleEid_artist {
  __typename: "Artist";
  id: string;
  apple_eid: string | null;
}

export interface UpdateArtistAppleEid_updateArtistAppleEid_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateArtistAppleEid_updateArtistAppleEid {
  __typename: "UpdateArtistAppleEidPayload";
  artist: UpdateArtistAppleEid_updateArtistAppleEid_artist | null;
  errors: UpdateArtistAppleEid_updateArtistAppleEid_errors[] | null;
}

export interface UpdateArtistAppleEid {
  /**
   * Default description for "UpdateArtistAppleEid" mutation.
   */
  updateArtistAppleEid: UpdateArtistAppleEid_updateArtistAppleEid;
}

export interface UpdateArtistAppleEidVariables {
  input: UpdateArtistAppleEidInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateArtistSpotifyEid
// ====================================================

export interface UpdateArtistSpotifyEid_updateArtistSpotifyEid_artist {
  __typename: "Artist";
  id: string;
  spotify_eid: string | null;
}

export interface UpdateArtistSpotifyEid_updateArtistSpotifyEid_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateArtistSpotifyEid_updateArtistSpotifyEid {
  __typename: "UpdateArtistSpotifyEidPayload";
  artist: UpdateArtistSpotifyEid_updateArtistSpotifyEid_artist | null;
  errors: UpdateArtistSpotifyEid_updateArtistSpotifyEid_errors[] | null;
}

export interface UpdateArtistSpotifyEid {
  /**
   * Default description for "UpdateArtistSpotifyEid" mutation.
   */
  updateArtistSpotifyEid: UpdateArtistSpotifyEid_updateArtistSpotifyEid;
}

export interface UpdateArtistSpotifyEidVariables {
  input: UpdateArtistSpotifyEidInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateMessage
// ====================================================

export interface CreateMessage_createMessage_message_author {
  __typename: "User";
  id: string;
  is_admin: boolean;
  display_name_or_email: string;
  /**
   * Is the user an owner or admin of the organization
   */
  role_owner_or_admin: boolean;
}

export interface CreateMessage_createMessage_message_file_uploads {
  __typename: "FileUpload";
  id: string;
  original_path_url: string | null;
  title: string | null;
}

export interface CreateMessage_createMessage_message {
  __typename: "Message";
  id: string;
  /**
   * Message content
   */
  content: string;
  /**
   * When the message was created
   */
  created_at: Scalar$DateTime;
  /**
   * When the message was read
   */
  read_at: Scalar$DateTime | null;
  author: CreateMessage_createMessage_message_author | null;
  file_uploads: CreateMessage_createMessage_message_file_uploads[] | null;
}

export interface CreateMessage_createMessage_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface CreateMessage_createMessage {
  __typename: "CreateMessagePayload";
  message: CreateMessage_createMessage_message | null;
  errors: CreateMessage_createMessage_errors[] | null;
}

export interface CreateMessage {
  /**
   * Create a Message
   */
  createMessage: CreateMessage_createMessage;
}

export interface CreateMessageVariables {
  input: CreateMessageInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: MarkAsRead
// ====================================================

export interface MarkAsRead_markAsRead_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface MarkAsRead_markAsRead {
  __typename: "MarkAsReadPayload";
  /**
   * Initial number of unread messages
   */
  initial_count: number | null;
  /**
   * Toggles between sync and async
   */
  synchronous: boolean | null;
  errors: MarkAsRead_markAsRead_errors[] | null;
}

export interface MarkAsRead {
  /**
   * Mark message as read if < 100 count, otherwise queue a worker which will mark the large amount of messages as read asynchronously.
   */
  markAsRead: MarkAsRead_markAsRead;
}

export interface MarkAsReadVariables {
  input: MarkAsReadInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductMediaMessagesQuery
// ====================================================

export interface ProductMediaMessagesQuery_media_user {
  __typename: "User";
  id: string;
}

export interface ProductMediaMessagesQuery_media_messages_author {
  __typename: "User";
  id: string;
  is_admin: boolean;
  display_name_or_email: string;
  /**
   * Is the user an owner or admin of the organization
   */
  role_owner_or_admin: boolean;
}

export interface ProductMediaMessagesQuery_media_messages_file_uploads {
  __typename: "FileUpload";
  id: string;
  original_path_url: string | null;
  title: string | null;
}

export interface ProductMediaMessagesQuery_media_messages {
  __typename: "Message";
  id: string;
  /**
   * Message content
   */
  content: string;
  /**
   * When the message was created
   */
  created_at: Scalar$DateTime;
  /**
   * When the message was read
   */
  read_at: Scalar$DateTime | null;
  author: ProductMediaMessagesQuery_media_messages_author | null;
  file_uploads: ProductMediaMessagesQuery_media_messages_file_uploads[] | null;
}

export interface ProductMediaMessagesQuery_media {
  __typename: "Media";
  id: string;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  user: ProductMediaMessagesQuery_media_user;
  /**
   * count of media messages
   */
  messages_count: number;
  /**
   * any more media messages
   */
  messages_any_more_results: boolean;
  /**
   * media messages
   */
  messages: ProductMediaMessagesQuery_media_messages[];
  /**
   * count of media messages
   */
  unread_messages: number;
}

export interface ProductMediaMessagesQuery {
  /**
   * Find the current media by ID
   */
  media: ProductMediaMessagesQuery_media;
}

export interface ProductMediaMessagesQueryVariables {
  id: string;
  input?: MessagesCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductAlbumMessagesQuery
// ====================================================

export interface ProductAlbumMessagesQuery_album_user {
  __typename: "User";
  id: string;
}

export interface ProductAlbumMessagesQuery_album_messages_author {
  __typename: "User";
  id: string;
  is_admin: boolean;
  display_name_or_email: string;
  /**
   * Is the user an owner or admin of the organization
   */
  role_owner_or_admin: boolean;
}

export interface ProductAlbumMessagesQuery_album_messages_file_uploads {
  __typename: "FileUpload";
  id: string;
  original_path_url: string | null;
  title: string | null;
}

export interface ProductAlbumMessagesQuery_album_messages {
  __typename: "Message";
  id: string;
  /**
   * Message content
   */
  content: string;
  /**
   * When the message was created
   */
  created_at: Scalar$DateTime;
  /**
   * When the message was read
   */
  read_at: Scalar$DateTime | null;
  author: ProductAlbumMessagesQuery_album_messages_author | null;
  file_uploads: ProductAlbumMessagesQuery_album_messages_file_uploads[] | null;
}

export interface ProductAlbumMessagesQuery_album {
  __typename: "Album";
  id: string;
  user: ProductAlbumMessagesQuery_album_user;
  /**
   * count of album messages
   */
  messages_count: number;
  /**
   * any more album messages
   */
  messages_any_more_results: boolean;
  /**
   * album messages
   */
  messages: ProductAlbumMessagesQuery_album_messages[];
  /**
   * count of album messages
   */
  unread_messages: number;
}

export interface ProductAlbumMessagesQuery {
  /**
   * Find the current album by ID
   */
  album: ProductAlbumMessagesQuery_album;
}

export interface ProductAlbumMessagesQueryVariables {
  id: string;
  input?: MessagesCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ProductReleaseMessagesQuery
// ====================================================

export interface ProductReleaseMessagesQuery_release_user {
  __typename: "User";
  id: string;
}

export interface ProductReleaseMessagesQuery_release_messages_author {
  __typename: "User";
  id: string;
  is_admin: boolean;
  display_name_or_email: string;
  /**
   * Is the user an owner or admin of the organization
   */
  role_owner_or_admin: boolean;
}

export interface ProductReleaseMessagesQuery_release_messages_file_uploads {
  __typename: "FileUpload";
  id: string;
  original_path_url: string | null;
  title: string | null;
}

export interface ProductReleaseMessagesQuery_release_messages {
  __typename: "Message";
  id: string;
  /**
   * Message content
   */
  content: string;
  /**
   * When the message was created
   */
  created_at: Scalar$DateTime;
  /**
   * When the message was read
   */
  read_at: Scalar$DateTime | null;
  author: ProductReleaseMessagesQuery_release_messages_author | null;
  file_uploads: ProductReleaseMessagesQuery_release_messages_file_uploads[] | null;
}

export interface ProductReleaseMessagesQuery_release {
  __typename: "Release";
  id: string;
  user: ProductReleaseMessagesQuery_release_user;
  /**
   * Returns true if platform admins completed their work on this release
   */
  admin_archived: boolean;
  /**
   * count of release messages
   */
  messages_count: number;
  /**
   * any more release messages
   */
  messages_any_more_results: boolean;
  /**
   * release messages
   */
  messages: ProductReleaseMessagesQuery_release_messages[];
  /**
   * count of release messages
   */
  unread_messages: number;
}

export interface ProductReleaseMessagesQuery {
  /**
   * find a release by id
   */
  release: ProductReleaseMessagesQuery_release;
}

export interface ProductReleaseMessagesQueryVariables {
  id: string;
  input?: MessagesCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReleaseCalendarEvents
// ====================================================

export interface ReleaseCalendarEvents_me_releases_v2_release_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface ReleaseCalendarEvents_me_releases_v2_release_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ReleaseCalendarEvents_me_releases_v2_release {
  __typename: "Release";
  id: string;
  /**
   * The most recent time the release was confirmed
   */
  created_at: Scalar$DateTime;
  /**
   * Returns a value that can either be Video | Audio
   */
  type: string;
  /**
   * The networks the release will be released to
   */
  networks: ReleaseCalendarEvents_me_releases_v2_release_networks[];
  artist: ReleaseCalendarEvents_me_releases_v2_release_artist | null;
}

export interface ReleaseCalendarEvents_me_releases_v2 {
  __typename: "ReleaseCalendarItem";
  id: string;
  /**
   * Title of the release
   */
  title: string;
  /**
   * This is true since we do not support time for release
   */
  all_day: boolean;
  /**
   * Start time of the event
   */
  start_time: Scalar$DateTime | null;
  /**
   * End time of the event
   */
  end_time: Scalar$DateTime | null;
  /**
   * Status of the Release
   */
  status: ReleaseCalendarStatus;
  /**
   * Release object
   */
  release: ReleaseCalendarEvents_me_releases_v2_release;
}

export interface ReleaseCalendarEvents_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Count of Releases for a user
   */
  action_required_count: number;
  /**
   * List out all Releases for the current user
   */
  releases_v2: ReleaseCalendarEvents_me_releases_v2[] | null;
}

export interface ReleaseCalendarEvents {
  /**
   * Get information about the currently logged in user
   */
  me: ReleaseCalendarEvents_me;
}

export interface ReleaseCalendarEventsVariables {
  input?: ReleasesV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReleaseDetails
// ====================================================

export interface ReleaseDetails_release_media_broadcasts_through_release_network {
  __typename: "Network";
  id: string;
  /**
   * Network only allows one deploy
   */
  deploy_only_once: boolean;
  /**
   * If network is social network
   */
  is_social: boolean;
}

export interface ReleaseDetails_release_media_broadcasts_through_release {
  __typename: "Broadcast";
  id: string;
  /**
   * Network associated with a broadcast
   */
  network: ReleaseDetails_release_media_broadcasts_through_release_network;
}

export interface ReleaseDetails_release_media {
  __typename: "Media";
  id: string;
  file_song_name: string;
  /**
   * List of all broadcasts completed through a release for a media
   */
  broadcasts_through_release: ReleaseDetails_release_media_broadcasts_through_release[];
}

export interface ReleaseDetails_release_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface ReleaseDetails_release_album_broadcasts_network {
  __typename: "Network";
  id: string;
  /**
   * Network only allows one deploy
   */
  deploy_only_once: boolean;
}

export interface ReleaseDetails_release_album_broadcasts {
  __typename: "Broadcast";
  id: string;
  /**
   * Network associated with a broadcast
   */
  network: ReleaseDetails_release_album_broadcasts_network;
}

export interface ReleaseDetails_release_album {
  __typename: "Album";
  id: string;
  metadata: ReleaseDetails_release_album_metadata;
  broadcasts: ReleaseDetails_release_album_broadcasts[];
}

export interface ReleaseDetails_release_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ReleaseDetails_release_broadcasts_network {
  __typename: "Network";
  id: string;
  /**
   * Image URL for this network
   */
  image_url: string;
  name: string;
}

export interface ReleaseDetails_release_broadcasts {
  __typename: "Broadcast";
  id: string;
  status: string;
  /**
   * Network associated with a broadcast
   */
  network: ReleaseDetails_release_broadcasts_network;
}

export interface ReleaseDetails_release {
  __typename: "Release";
  id: string;
  /**
   * Time when the earliest broadcast is deployed
   */
  earliest_deploy_at: Scalar$DateTime | null;
  /**
   * If the release has any premium networks
   */
  is_premium: boolean;
  status: string;
  /**
   * Returns a value that can either be Video | Audio
   */
  type: string;
  /**
   * Returns a media that is associated with the release, mutually exclusive with Album
   */
  media: ReleaseDetails_release_media | null;
  /**
   * Returns an album that is associated with the release, mutually exclusive with Release
   */
  album: ReleaseDetails_release_album | null;
  artist: ReleaseDetails_release_artist | null;
  /**
   * List of all broadcasts that are created with a release
   */
  broadcasts: ReleaseDetails_release_broadcasts[];
  /**
   * count of release messages
   */
  unread_release_messages: number;
}

export interface ReleaseDetails {
  /**
   * find a release by id
   */
  release: ReleaseDetails_release;
}

export interface ReleaseDetailsVariables {
  releaseId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableNetworks
// ====================================================

export interface AvailableNetworks_me_available_networks {
  __typename: "Network";
  id: string;
}

export interface AvailableNetworks_me {
  __typename: "User";
  id: string;
  /**
   * List of networks available for a user
   */
  available_networks: AvailableNetworks_me_available_networks[];
}

export interface AvailableNetworks {
  /**
   * Get information about the currently logged in user
   */
  me: AvailableNetworks_me;
}

export interface AvailableNetworksVariables {
  networkType?: NetworkType | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaReleasesQuery
// ====================================================

export interface MediaReleasesQuery_media_releases {
  __typename: "Release";
  id: string;
  /**
   * Time when the earliest broadcast is deployed
   */
  earliest_deploy_at: Scalar$DateTime | null;
  /**
   * count of release messages
   */
  messages_count: number;
  /**
   * count of release messages
   */
  unread_messages: number;
}

export interface MediaReleasesQuery_media {
  __typename: "Media";
  id: string;
  /**
   * List of releases for the media
   */
  releases: MediaReleasesQuery_media_releases[];
}

export interface MediaReleasesQuery {
  /**
   * Find the current media by ID
   */
  media: MediaReleasesQuery_media;
}

export interface MediaReleasesQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumReleasesQuery
// ====================================================

export interface AlbumReleasesQuery_album_releases {
  __typename: "Release";
  id: string;
  /**
   * Time when the earliest broadcast is deployed
   */
  earliest_deploy_at: Scalar$DateTime | null;
  /**
   * count of release messages
   */
  messages_count: number;
  /**
   * count of release messages
   */
  unread_messages: number;
}

export interface AlbumReleasesQuery_album {
  __typename: "Album";
  id: string;
  /**
   * List of releases for the album
   */
  releases: AlbumReleasesQuery_album_releases[];
}

export interface AlbumReleasesQuery {
  /**
   * Find the current album by ID
   */
  album: AlbumReleasesQuery_album;
}

export interface AlbumReleasesQueryVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SecuritySettingsPartial
// ====================================================

export interface SecuritySettingsPartial_mfa {
  __typename: "MFA";
  id: string;
  phone: string | null;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
}

export interface SecuritySettingsPartial {
  /**
   * Information used to populate the MFA/OTP screen when a user is prompted to enter MFA information
   */
  mfa: SecuritySettingsPartial_mfa;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SelectArtist
// ====================================================

export interface SelectArtist_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface SelectArtist {
  /**
   * Get information about the currently logged in user
   */
  me: SelectArtist_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DowngradeModal
// ====================================================

export interface DowngradeModal_me_active_storage_subscription {
  __typename: "Subscription";
  id: string;
  /**
   * Status of the subscription like Pending | Past Due | Active | Cancelled
   */
  status: string;
}

export interface DowngradeModal_me_active_storage_tier_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Name for every TierPermission
   */
  name: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
}

export interface DowngradeModal_me_active_storage_tier {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: DowngradeModal_me_active_storage_tier_tier_permission;
}

export interface DowngradeModal_me {
  __typename: "User";
  id: string;
  /**
   * current storage subscription a user is subscribed to
   */
  active_storage_subscription: DowngradeModal_me_active_storage_subscription | null;
  /**
   * Active Plan a user is subscribed to, returns only a StorageTier
   */
  active_storage_tier: DowngradeModal_me_active_storage_tier;
  current_storage_usage: number;
}

export interface DowngradeModal_storage_tier_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Name for every TierPermission
   */
  name: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
}

export interface DowngradeModal_storage_tier {
  __typename: "StorageTier";
  id: string;
  /**
   * Flag for a storage tier is free or paid, default is free and any other tiers are paid
   */
  is_default: boolean;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: DowngradeModal_storage_tier_tier_permission;
}

export interface DowngradeModal {
  /**
   * Get information about the currently logged in user
   */
  me: DowngradeModal_me;
  /**
   * Fetch details about a particular tier ID
   */
  storage_tier: DowngradeModal_storage_tier;
}

export interface DowngradeModalVariables {
  tierID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StorageTierModal
// ====================================================

export interface StorageTierModal_me_active_storage_tier_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Name for every TierPermission
   */
  name: string;
  /**
   * Price for a TierPermission
   */
  yearly_price: number;
  description: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
  /**
   * Maximum number of artists allowed
   */
  max_artist_count: number | null;
}

export interface StorageTierModal_me_active_storage_tier {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: StorageTierModal_me_active_storage_tier_tier_permission;
}

export interface StorageTierModal_me_next_required_storage_tier_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Name for every TierPermission
   */
  name: string;
  /**
   * Price for a TierPermission
   */
  yearly_price: number;
  description: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
  /**
   * Maximum number of artists allowed
   */
  max_artist_count: number | null;
}

export interface StorageTierModal_me_next_required_storage_tier {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: StorageTierModal_me_next_required_storage_tier_tier_permission;
}

export interface StorageTierModal_me {
  __typename: "User";
  id: string;
  /**
   * Count of payment methods of this user
   */
  payment_methods_count: number;
  /**
   * Flag for a user exceeded their storage limit.
   */
  exceeded_storage_limit: boolean;
  /**
   * User should not be shown anything related to Vydia plan
   */
  has_hidden_payment_plan: boolean;
  current_storage_usage: number;
  /**
   * Active Plan a user is subscribed to, returns only a StorageTier
   */
  active_storage_tier: StorageTierModal_me_active_storage_tier;
  /**
   * next StorageTier from the available storage tiers
   */
  next_required_storage_tier: StorageTierModal_me_next_required_storage_tier | null;
}

export interface StorageTierModal {
  /**
   * Get information about the currently logged in user
   */
  me: StorageTierModal_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SwitchUmbrellaLinkedUserModal
// ====================================================

export interface SwitchUmbrellaLinkedUserModal_me {
  __typename: "User";
  id: string;
}

export interface SwitchUmbrellaLinkedUserModal_umbrella_available_users_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
  /**
   * WARNING: Most of the time you should use the field branding.logo_url instead!!!
   */
  logo_url: string;
}

export interface SwitchUmbrellaLinkedUserModal_umbrella_available_users {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  /**
   * Whether the user is the main umbrella user for a series of linked users
   */
  is_umbrella_owner: boolean;
  /**
   * An Organization a user is a member of
   */
  organization: SwitchUmbrellaLinkedUserModal_umbrella_available_users_organization | null;
}

export interface SwitchUmbrellaLinkedUserModal {
  /**
   * Get information about the currently logged in user
   */
  me: SwitchUmbrellaLinkedUserModal_me;
  /**
   * Get linked users for a user that this user is an umbrella user with access over the linked users. Includes the main umbrella user and current user
   */
  umbrella_available_users: SwitchUmbrellaLinkedUserModal_umbrella_available_users[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SwitchUmbrellaUser
// ====================================================

export interface SwitchUmbrellaUser_switchUmbrellaUser_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SwitchUmbrellaUser_switchUmbrellaUser {
  __typename: "SwitchUmbrellaUserPayload";
  errors: SwitchUmbrellaUser_switchUmbrellaUser_errors[] | null;
}

export interface SwitchUmbrellaUser {
  /**
   * When signed in as an Umbrella User, switch to a linked user's account.
   */
  switchUmbrellaUser: SwitchUmbrellaUser_switchUmbrellaUser;
}

export interface SwitchUmbrellaUserVariables {
  input: SwitchUmbrellaUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TipaltiFtuiModal
// ====================================================

export interface TipaltiFtuiModal_me_payee_invites_cursor_payee_invites_payor {
  __typename: "Payor";
  id: string;
  fullName: string | null;
  /**
   * Email Address for a payor
   */
  email: string;
}

export interface TipaltiFtuiModal_me_payee_invites_cursor_payee_invites {
  __typename: "PayeeInvite";
  id: string;
  /**
   * The payor user
   */
  payor: TipaltiFtuiModal_me_payee_invites_cursor_payee_invites_payor;
}

export interface TipaltiFtuiModal_me_payee_invites_cursor {
  __typename: "PayeeInvites";
  /**
   * List of payee invites
   */
  payee_invites: TipaltiFtuiModal_me_payee_invites_cursor_payee_invites[];
}

export interface TipaltiFtuiModal_me {
  __typename: "User";
  id: string;
  /**
   * User has been onboarded to tipalti
   */
  is_payable: boolean;
  /**
   * List out all the PayeeInvites for a User
   */
  payee_invites_cursor: TipaltiFtuiModal_me_payee_invites_cursor;
}

export interface TipaltiFtuiModal {
  /**
   * Get information about the currently logged in user
   */
  me: TipaltiFtuiModal_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopAlbumsPicker
// ====================================================

export interface TopAlbumsPicker_me_organization_albums_v2_cursor_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface TopAlbumsPicker_me_organization_albums_v2_cursor_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface TopAlbumsPicker_me_organization_albums_v2_cursor_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface TopAlbumsPicker_me_organization_albums_v2_cursor {
  __typename: "Album";
  id: string;
  user_id: string;
  artist: TopAlbumsPicker_me_organization_albums_v2_cursor_artist;
  metadata: TopAlbumsPicker_me_organization_albums_v2_cursor_metadata;
  file_upload: TopAlbumsPicker_me_organization_albums_v2_cursor_file_upload | null;
}

export interface TopAlbumsPicker_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of albums for the organization
   */
  albums_v2_count: number;
  /**
   * Any more albums in this organization
   */
  albums_v2_any_more_results: boolean;
  /**
   * List out all the albums for the current organization
   */
  albums_v2_cursor: TopAlbumsPicker_me_organization_albums_v2_cursor[];
}

export interface TopAlbumsPicker_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: TopAlbumsPicker_me_organization | null;
}

export interface TopAlbumsPicker {
  /**
   * Get information about the currently logged in user
   */
  me: TopAlbumsPicker_me;
}

export interface TopAlbumsPickerVariables {
  input?: AlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewTopAlbumsPicker
// ====================================================

export interface OverviewTopAlbumsPicker_me_albums_v2_cursor_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface OverviewTopAlbumsPicker_me_albums_v2_cursor_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface OverviewTopAlbumsPicker_me_albums_v2_cursor_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface OverviewTopAlbumsPicker_me_albums_v2_cursor {
  __typename: "Album";
  id: string;
  user_id: string;
  artist: OverviewTopAlbumsPicker_me_albums_v2_cursor_artist;
  metadata: OverviewTopAlbumsPicker_me_albums_v2_cursor_metadata;
  file_upload: OverviewTopAlbumsPicker_me_albums_v2_cursor_file_upload | null;
}

export interface OverviewTopAlbumsPicker_me {
  __typename: "User";
  id: string;
  /**
   * Count of albums for the user
   */
  albums_v2_count: number;
  /**
   * Any more albums in this user
   */
  albums_v2_any_more_results: boolean;
  /**
   * List out all the albums for the current user
   */
  albums_v2_cursor: OverviewTopAlbumsPicker_me_albums_v2_cursor[];
}

export interface OverviewTopAlbumsPicker {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewTopAlbumsPicker_me;
}

export interface OverviewTopAlbumsPickerVariables {
  input?: AlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopArtistsPicker
// ====================================================

export interface TopArtistsPicker_me_organization_top_artists_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  thumbnail_url: string;
  /**
   * Count of top artists for the organization
   */
  top_medias_count: number;
  user_id: string;
}

export interface TopArtistsPicker_me_organization_top_artists {
  __typename: "TopArtist";
  id: string;
  artist: TopArtistsPicker_me_organization_top_artists_artist;
}

export interface TopArtistsPicker_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top artists for the organization
   */
  top_artists_count: number;
  /**
   * Any more top medias in this organization
   */
  top_artists_any_more_results: boolean;
  /**
   * Top artists for the organization
   */
  top_artists: TopArtistsPicker_me_organization_top_artists[];
}

export interface TopArtistsPicker_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: TopArtistsPicker_me_organization | null;
}

export interface TopArtistsPicker {
  /**
   * Get information about the currently logged in user
   */
  me: TopArtistsPicker_me;
}

export interface TopArtistsPickerVariables {
  input?: TopArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewTopArtistsPicker
// ====================================================

export interface OverviewTopArtistsPicker_me_top_artists_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  thumbnail_url: string;
  /**
   * Count of top artists for the organization
   */
  top_medias_count: number;
  user_id: string;
}

export interface OverviewTopArtistsPicker_me_top_artists {
  __typename: "TopArtist";
  id: string;
  artist: OverviewTopArtistsPicker_me_top_artists_artist;
}

export interface OverviewTopArtistsPicker_me {
  __typename: "User";
  id: string;
  /**
   * Count of top artists for the user
   */
  top_artists_count: number;
  /**
   * Any more top medias in this user
   */
  top_artists_any_more_results: boolean;
  /**
   * Top artists for the user
   */
  top_artists: OverviewTopArtistsPicker_me_top_artists[];
}

export interface OverviewTopArtistsPicker {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewTopArtistsPicker_me;
}

export interface OverviewTopArtistsPickerVariables {
  input?: TopArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopMediasPicker
// ====================================================

export interface TopMediasPicker_me_organization_top_medias_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface TopMediasPicker_me_organization_top_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface TopMediasPicker_me_organization_top_medias_media {
  __typename: "Media";
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  artist: TopMediasPicker_me_organization_top_medias_media_artist | null;
  metadata: TopMediasPicker_me_organization_top_medias_media_metadata | null;
  user_id: string;
}

export interface TopMediasPicker_me_organization_top_medias {
  __typename: "TopMedia";
  id: string;
  media: TopMediasPicker_me_organization_top_medias_media;
}

export interface TopMediasPicker_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top medias in this organization
   */
  top_medias_count: number;
  /**
   * Any more top medias in this organization
   */
  top_medias_any_more_results: boolean;
  /**
   * Top medias by earnings in this organization
   */
  top_medias: TopMediasPicker_me_organization_top_medias[];
}

export interface TopMediasPicker_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: TopMediasPicker_me_organization | null;
}

export interface TopMediasPicker {
  /**
   * Get information about the currently logged in user
   */
  me: TopMediasPicker_me;
}

export interface TopMediasPickerVariables {
  input?: TopMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewTopMediasPicker
// ====================================================

export interface OverviewTopMediasPicker_me_top_medias_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface OverviewTopMediasPicker_me_top_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface OverviewTopMediasPicker_me_top_medias_media {
  __typename: "Media";
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  artist: OverviewTopMediasPicker_me_top_medias_media_artist | null;
  metadata: OverviewTopMediasPicker_me_top_medias_media_metadata | null;
  user_id: string;
}

export interface OverviewTopMediasPicker_me_top_medias {
  __typename: "TopMedia";
  id: string;
  media: OverviewTopMediasPicker_me_top_medias_media;
}

export interface OverviewTopMediasPicker_me {
  __typename: "User";
  id: string;
  /**
   * Number of top medias in this user
   */
  top_medias_count: number;
  /**
   * Any more top medias in this user
   */
  top_medias_any_more_results: boolean;
  /**
   * Top medias by earnings in this user
   */
  top_medias: OverviewTopMediasPicker_me_top_medias[];
}

export interface OverviewTopMediasPicker {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewTopMediasPicker_me;
}

export interface OverviewTopMediasPickerVariables {
  input?: TopMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopUsersPicker
// ====================================================

export interface TopUsersPicker_me_organization_top_users_user {
  __typename: "User";
  id: string;
  full_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface TopUsersPicker_me_organization_top_users {
  __typename: "TopUser";
  id: string;
  user: TopUsersPicker_me_organization_top_users_user;
}

export interface TopUsersPicker_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top users in organization
   */
  top_users_count: number;
  /**
   * Any more top users in organization
   */
  top_users_any_more_results: boolean;
  /**
   * Top users in organization
   */
  top_users: TopUsersPicker_me_organization_top_users[];
}

export interface TopUsersPicker_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: TopUsersPicker_me_organization | null;
}

export interface TopUsersPicker {
  /**
   * Get information about the currently logged in user
   */
  me: TopUsersPicker_me;
}

export interface TopUsersPickerVariables {
  input?: TopUsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UnarchivePayeeInvite
// ====================================================

export interface UnarchivePayeeInvite_unarchivePayeeInvite_payee_invite_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface UnarchivePayeeInvite_unarchivePayeeInvite_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: UnarchivePayeeInvite_unarchivePayeeInvite_payee_invite_payee | null;
}

export interface UnarchivePayeeInvite_unarchivePayeeInvite_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UnarchivePayeeInvite_unarchivePayeeInvite {
  __typename: "UnarchivePayeeInvitePayload";
  payee_invite: UnarchivePayeeInvite_unarchivePayeeInvite_payee_invite | null;
  errors: UnarchivePayeeInvite_unarchivePayeeInvite_errors[] | null;
}

export interface UnarchivePayeeInvite {
  /**
   * Default description for "UnarchivePayeeInvite" mutation.
   */
  unarchivePayeeInvite: UnarchivePayeeInvite_unarchivePayeeInvite;
}

export interface UnarchivePayeeInviteVariables {
  input: UnarchivePayeeInviteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VideoUploaderFtuiModal
// ====================================================

export interface VideoUploaderFtuiModal_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has seen a feature
   */
  has_seen_d2_video_uploader: boolean;
}

export interface VideoUploaderFtuiModal {
  /**
   * Get information about the currently logged in user
   */
  me: VideoUploaderFtuiModal_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AssetVersion
// ====================================================

export interface AssetVersion {
  /**
   * Webpack asset version hashed paths for the current & latest front end assets
   */
  webpack_asset_uris: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SessionNotices
// ====================================================

export interface SessionNotices_session_notices {
  __typename: "Notice";
  type: NoticeType;
  message: string;
  unescaped: boolean;
}

export interface SessionNotices_session {
  __typename: "Session";
  /**
   * Cookie session ID. Note: This is NOT a user_id, not a Primary Key of any record in the database.
   */
  id: string;
  /**
   * UI banner notices to display to the user in the Application UI.
   * NOTE: Once queried, the notices are removed from the session cookie immediately.
   */
  notices: SessionNotices_session_notices[];
}

export interface SessionNotices {
  /**
   * Query information from the session cookie, such as notices and messages that should be displayed in the UI to the application user.
   */
  session: SessionNotices_session;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DowngradeStorageTier
// ====================================================

export interface DowngradeStorageTier_downgradeStorageTier_subscription {
  __typename: "Subscription";
  id: string;
  /**
   * When the user downgrades to a storage tier, It is downgraded at the end of the subscription. The storage it should downgrade to, is stored at this field
   */
  downgrade_to_storage_tier_id: string | null;
}

export interface DowngradeStorageTier_downgradeStorageTier_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface DowngradeStorageTier_downgradeStorageTier {
  __typename: "DowngradeStorageTierPayload";
  subscription: DowngradeStorageTier_downgradeStorageTier_subscription | null;
  errors: DowngradeStorageTier_downgradeStorageTier_errors[] | null;
}

export interface DowngradeStorageTier {
  /**
   * Downgrade a StorageTier for a Subscription. This mutation returns a Subscription object. Used when a user chooses a lower storage plan.
   */
  downgradeStorageTier: DowngradeStorageTier_downgradeStorageTier;
}

export interface DowngradeStorageTierVariables {
  input: DowngradeStorageTierInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PresignedUrls
// ====================================================

export interface PresignedUrls_presignedUrl_presigned_urls {
  __typename: "PresignedUrl";
  id: string;
  /**
   * URL for an S3 private object that is public for a week time
   */
  presigned_url: string;
  /**
   * Relative URL path for the file
   */
  path: string;
  order: number | null;
}

export interface PresignedUrls_presignedUrl_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface PresignedUrls_presignedUrl {
  __typename: "PresignedUrlPayload";
  presigned_urls: PresignedUrls_presignedUrl_presigned_urls[] | null;
  errors: PresignedUrls_presignedUrl_errors[] | null;
}

export interface PresignedUrls {
  /**
   * Provides urls that can be used by JS to upload directly to S3.
   */
  presignedUrl: PresignedUrls_presignedUrl;
}

export interface PresignedUrlsVariables {
  input: PresignedUrlInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ObserveUser
// ====================================================

export interface ObserveUser_observeUser_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ObserveUser_observeUser_user {
  __typename: "User";
  id: string;
}

export interface ObserveUser_observeUser {
  __typename: "ObserveUserPayload";
  errors: ObserveUser_observeUser_errors[] | null;
  user: ObserveUser_observeUser_user | null;
}

export interface ObserveUser {
  /**
   * Observe another User. Used when an organization's admin chooses to use the platform under another organization user's account.
   */
  observeUser: ObserveUser_observeUser;
}

export interface ObserveUserVariables {
  input: ObserveUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendWelcomeInvitation
// ====================================================

export interface ResendWelcomeInvitation_resendWelcomeInvitation_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ResendWelcomeInvitation_resendWelcomeInvitation {
  __typename: "ResendWelcomeInvitationPayload";
  errors: ResendWelcomeInvitation_resendWelcomeInvitation_errors[] | null;
}

export interface ResendWelcomeInvitation {
  /**
   * Resend the invitation email to a user who has not yet accepted their invite and confirmed their email.
   */
  resendWelcomeInvitation: ResendWelcomeInvitation_resendWelcomeInvitation;
}

export interface ResendWelcomeInvitationVariables {
  input: ResendWelcomeInvitationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResendPayeeInvite
// ====================================================

export interface ResendPayeeInvite_resendPayeeInvite_payee_invite_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface ResendPayeeInvite_resendPayeeInvite_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: ResendPayeeInvite_resendPayeeInvite_payee_invite_payee | null;
}

export interface ResendPayeeInvite_resendPayeeInvite_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ResendPayeeInvite_resendPayeeInvite {
  __typename: "ResendPayeeInvitePayload";
  payee_invite: ResendPayeeInvite_resendPayeeInvite_payee_invite | null;
  errors: ResendPayeeInvite_resendPayeeInvite_errors[] | null;
}

export interface ResendPayeeInvite {
  /**
   * Resend a PayeeInvite. This mutation returns a PayeeInvite. Used when a royalty center payor needs to send another email notice to a potential payee.
   */
  resendPayeeInvite: ResendPayeeInvite_resendPayeeInvite;
}

export interface ResendPayeeInviteVariables {
  input: ResendPayeeInviteInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SendRoyaltyCenterPaymentHistory
// ====================================================

export interface SendRoyaltyCenterPaymentHistory_sendRoyaltyCenterPaymentHistory_current_user {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface SendRoyaltyCenterPaymentHistory_sendRoyaltyCenterPaymentHistory_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SendRoyaltyCenterPaymentHistory_sendRoyaltyCenterPaymentHistory {
  __typename: "SendRoyaltyCenterPaymentHistoryPayload";
  /**
   * If the request is authenticated, the current_user response object will contain the authenticated User object.
   */
  current_user: SendRoyaltyCenterPaymentHistory_sendRoyaltyCenterPaymentHistory_current_user | null;
  errors: SendRoyaltyCenterPaymentHistory_sendRoyaltyCenterPaymentHistory_errors[] | null;
}

export interface SendRoyaltyCenterPaymentHistory {
  /**
   * Send a royalty center payment history email. Used in the royalty center to send the payment logs of a specific payee.
   */
  sendRoyaltyCenterPaymentHistory: SendRoyaltyCenterPaymentHistory_sendRoyaltyCenterPaymentHistory;
}

export interface SendRoyaltyCenterPaymentHistoryVariables {
  input: SendRoyaltyCenterPaymentHistoryInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreatePayeeAssetSplits
// ====================================================

export interface CreatePayeeAssetSplits_createPayeeAssetSplits_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_type: string | null;
  resource_id: string | null;
}

export interface CreatePayeeAssetSplits_createPayeeAssetSplits_payee_asset_splits_payee_invite_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface CreatePayeeAssetSplits_createPayeeAssetSplits_payee_asset_splits_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: CreatePayeeAssetSplits_createPayeeAssetSplits_payee_asset_splits_payee_invite_payee | null;
}

export interface CreatePayeeAssetSplits_createPayeeAssetSplits_payee_asset_splits {
  __typename: "PayeeAssetSplit";
  id: string;
  /**
   * Payee Invitation
   */
  payee_invite: CreatePayeeAssetSplits_createPayeeAssetSplits_payee_asset_splits_payee_invite;
}

export interface CreatePayeeAssetSplits_createPayeeAssetSplits {
  __typename: "CreatePayeeAssetSplitsPayload";
  errors: CreatePayeeAssetSplits_createPayeeAssetSplits_errors[] | null;
  payee_asset_splits: CreatePayeeAssetSplits_createPayeeAssetSplits_payee_asset_splits[] | null;
}

export interface CreatePayeeAssetSplits {
  /**
   * Create a PayeeAssetSplit. This mutation returns a PayeeAssetSplit object.
   */
  createPayeeAssetSplits: CreatePayeeAssetSplits_createPayeeAssetSplits;
}

export interface CreatePayeeAssetSplitsVariables {
  input: CreatePayeeAssetSplitsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSeenFeatures
// ====================================================

export interface UpdateSeenFeatures_updateSeenFeatures_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateSeenFeatures_updateSeenFeatures {
  __typename: "UpdateSeenFeaturesPayload";
  errors: UpdateSeenFeatures_updateSeenFeatures_errors[] | null;
}

export interface UpdateSeenFeatures {
  /**
   * Update seen_features. Used to keep track of what flows / features a user has experienced in order to stop redundant informational UI elements.
   */
  updateSeenFeatures: UpdateSeenFeatures_updateSeenFeatures;
}

export interface UpdateSeenFeaturesVariables {
  input: UpdateSeenFeaturesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CountriesQuery
// ====================================================

export interface CountriesQuery_countries {
  __typename: "Country";
  id: string;
  name: string;
  code: CountryCode;
}

export interface CountriesQuery {
  /**
   * Objects of country
   */
  countries: CountriesQuery_countries[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateSlugMutation
// ====================================================

export interface GenerateSlugMutation_generateAlbumSlug_album_extra_links_file_upload {
  __typename: "FileUpload";
  id: string;
  title: string | null;
}

export interface GenerateSlugMutation_generateAlbumSlug_album_extra_links {
  __typename: "ExtraLink";
  id: string;
  title: string | null;
  description: string | null;
  type: ExtraLinkType | null;
  button_text: string | null;
  order: number;
  url: string | null;
  file_upload: GenerateSlugMutation_generateAlbumSlug_album_extra_links_file_upload | null;
}

export interface GenerateSlugMutation_generateAlbumSlug_album_partners_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface GenerateSlugMutation_generateAlbumSlug_album_partners {
  __typename: "AlbumPartnerData";
  id: string;
  failed_at: Scalar$DateTime | null;
  is_live: boolean | null;
  order: number | null;
  /**
   * corresponding network object to this data
   */
  network: GenerateSlugMutation_generateAlbumSlug_album_partners_network;
  partner_url: string;
  is_available_for_presave: boolean;
  link_action: AlbumPartnerDataLinkActionType;
  is_user_added: boolean | null;
}

export interface GenerateSlugMutation_generateAlbumSlug_album {
  __typename: "Album";
  id: string;
  slug: string | null;
  smartlink_url: string;
  artist_id: string;
  is_distributed: boolean;
  /**
   * boolean value to see if the album has tracks
   */
  has_tracks: boolean;
  private_url: string | null;
  slug_updated_at: Scalar$DateTime | null;
  smartlinks_subdomain: string | null;
  /**
   * Checks if there are any links to display on smartlinks
   */
  extra_links_enabled: boolean;
  /**
   * Extra links title shown on smartlinks page if enabled
   */
  extra_links_title: string | null;
  extra_links: GenerateSlugMutation_generateAlbumSlug_album_extra_links[];
  /**
   * list of partners an album is released to
   */
  partners: GenerateSlugMutation_generateAlbumSlug_album_partners[];
}

export interface GenerateSlugMutation_generateAlbumSlug_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface GenerateSlugMutation_generateAlbumSlug {
  __typename: "GenerateAlbumSlugPayload";
  /**
   * Album
   */
  album: GenerateSlugMutation_generateAlbumSlug_album | null;
  errors: GenerateSlugMutation_generateAlbumSlug_errors[] | null;
}

export interface GenerateSlugMutation {
  /**
   * Generate Album Slug and queue workers for creating empty album partner datas for available networks
   */
  generateAlbumSlug: GenerateSlugMutation_generateAlbumSlug;
}

export interface GenerateSlugMutationVariables {
  input: GenerateAlbumSlugInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAlbumLinks
// ====================================================

export interface UpdateAlbumLinks_updateAlbumLinks_album_extra_links_file_upload {
  __typename: "FileUpload";
  id: string;
  title: string | null;
}

export interface UpdateAlbumLinks_updateAlbumLinks_album_extra_links {
  __typename: "ExtraLink";
  id: string;
  title: string | null;
  description: string | null;
  order: number;
  button_text: string | null;
  type: ExtraLinkType | null;
  file_upload: UpdateAlbumLinks_updateAlbumLinks_album_extra_links_file_upload | null;
}

export interface UpdateAlbumLinks_updateAlbumLinks_album {
  __typename: "Album";
  id: string;
  /**
   * Checks if there are any links to display on smartlinks
   */
  extra_links_enabled: boolean;
  /**
   * Extra links title shown on smartlinks page if enabled
   */
  extra_links_title: string | null;
  extra_links: UpdateAlbumLinks_updateAlbumLinks_album_extra_links[];
  smartlink_url: string;
}

export interface UpdateAlbumLinks_updateAlbumLinks_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface UpdateAlbumLinks_updateAlbumLinks {
  __typename: "UpdateAlbumLinksPayload";
  album: UpdateAlbumLinks_updateAlbumLinks_album | null;
  errors: UpdateAlbumLinks_updateAlbumLinks_errors[] | null;
}

export interface UpdateAlbumLinks {
  /**
   * Update album links. This mutation returns an Album. Used when a user updates the links on an album
   */
  updateAlbumLinks: UpdateAlbumLinks_updateAlbumLinks;
}

export interface UpdateAlbumLinksVariables {
  input: UpdateAlbumLinksInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPasswordMutation
// ====================================================

export interface ResetPasswordMutation_sendResetPasswordEmail_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ResetPasswordMutation_sendResetPasswordEmail {
  __typename: "SendResetPasswordEmailPayload";
  errors: ResetPasswordMutation_sendResetPasswordEmail_errors[] | null;
}

export interface ResetPasswordMutation {
  /**
   * Send a password reset email. Used on the sign in screen to send a password reset email to the user's associated address
   */
  sendResetPasswordEmail: ResetPasswordMutation_sendResetPasswordEmail;
}

export interface ResetPasswordMutationVariables {
  input: SendResetPasswordEmailInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserAccountDetails
// ====================================================

export interface UserAccountDetails_me_signed_agreements_excluding_terms_and_conditions {
  __typename: "SignedAgreement";
  id: string;
}

export interface UserAccountDetails_me_signed_cla_releases {
  __typename: "Release";
  id: string;
}

export interface UserAccountDetails_me {
  __typename: "User";
  id: string;
  /**
   * When the user created their profile
   */
  created_at: Scalar$DateTime;
  /**
   * Whether or not the user can edit their details
   */
  can_edit_details: boolean;
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * Whether or not the user is connected to youtube
   */
  youtube_connected: boolean;
  /**
   * Youtube OAuth connection url
   */
  youtube_authentication_url: string;
  /**
   * Flag for a user has a feature
   */
  has_feature_skip_socials: boolean;
  /**
   * Whether or not the user has ever signed a universal cla
   */
  has_signed_ucla: boolean;
  /**
   * If the user has signed agreements excluding terms and conditions
   */
  signed_agreements_excluding_terms_and_conditions: UserAccountDetails_me_signed_agreements_excluding_terms_and_conditions[];
  /**
   * The user's signed cla releases
   */
  signed_cla_releases: UserAccountDetails_me_signed_cla_releases[];
}

export interface UserAccountDetails_mfa {
  __typename: "MFA";
  id: string;
  is_admin: boolean;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
  /**
   * User's OTP MFA method
   */
  otp_mfa_method: OtpMethodType | null;
}

export interface UserAccountDetails {
  /**
   * Get information about the currently logged in user
   */
  me: UserAccountDetails_me;
  /**
   * Information used to populate the MFA/OTP screen when a user is prompted to enter MFA information
   */
  mfa: UserAccountDetails_mfa;
  observer_signed_in: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ToggleOtpMfa
// ====================================================

export interface ToggleOtpMfa_toggleOtpMfa_user_mfa {
  __typename: "MFA";
  id: string;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
}

export interface ToggleOtpMfa_toggleOtpMfa_user {
  __typename: "User";
  id: string;
  /**
   * MFA is normally queried from root query mfa field, but this mfa field is nested
   * under User so can more easily be used from a mutation that returns a User.
   */
  mfa: ToggleOtpMfa_toggleOtpMfa_user_mfa;
}

export interface ToggleOtpMfa_toggleOtpMfa_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ToggleOtpMfa_toggleOtpMfa {
  __typename: "ToggleOtpMfaPayload";
  user: ToggleOtpMfa_toggleOtpMfa_user | null;
  errors: ToggleOtpMfa_toggleOtpMfa_errors[] | null;
}

export interface ToggleOtpMfa {
  /**
   * Toggle OTP MFA on or off
   */
  toggleOtpMfa: ToggleOtpMfa_toggleOtpMfa;
}

export interface ToggleOtpMfaVariables {
  input: ToggleOtpMfaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: VerifyOTPCode
// ====================================================

export interface VerifyOTPCode_verifyOTPCode_user_mfa {
  __typename: "MFA";
  id: string;
  phone: string | null;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
  /**
   * Whether or not the user's OTP has expired
   */
  otp_expired: boolean;
}

export interface VerifyOTPCode_verifyOTPCode_user {
  __typename: "User";
  id: string;
  /**
   * MFA is normally queried from root query mfa field, but this mfa field is nested
   * under User so can more easily be used from a mutation that returns a User.
   */
  mfa: VerifyOTPCode_verifyOTPCode_user_mfa;
}

export interface VerifyOTPCode_verifyOTPCode_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface VerifyOTPCode_verifyOTPCode {
  __typename: "VerifyOTPCodePayload";
  user: VerifyOTPCode_verifyOTPCode_user | null;
  errors: VerifyOTPCode_verifyOTPCode_errors[] | null;
}

export interface VerifyOTPCode {
  /**
   * Verify Twilio OTP code for MFA.
   */
  verifyOTPCode: VerifyOTPCode_verifyOTPCode;
}

export interface VerifyOTPCodeVariables {
  input: VerifyOTPCodeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserNeedsOtp
// ====================================================

export interface UserNeedsOtp {
  user_needs_otp: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserHasHiddenPlan
// ====================================================

export interface UserHasHiddenPlan_me_or_null {
  __typename: "User";
  id: string;
  /**
   * User should not be shown anything related to Vydia plan
   */
  has_hidden_payment_plan: boolean;
}

export interface UserHasHiddenPlan {
  /**
   * Gets the currently logged in user, returning null if no user is signed in
   */
  me_or_null: UserHasHiddenPlan_me_or_null | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CurrentUserRole
// ====================================================

export interface CurrentUserRole_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface CurrentUserRole {
  /**
   * Get information about the currently logged in user
   */
  me: CurrentUserRole_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAlbumPartnerData
// ====================================================

export interface UpdateAlbumPartnerData_updateAlbumPartnerData_album_partners_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface UpdateAlbumPartnerData_updateAlbumPartnerData_album_partners {
  __typename: "AlbumPartnerData";
  id: string;
  order: number | null;
  /**
   * corresponding network object to this data
   */
  network: UpdateAlbumPartnerData_updateAlbumPartnerData_album_partners_network;
  partner_url: string;
  is_available_for_presave: boolean;
  link_action: AlbumPartnerDataLinkActionType;
}

export interface UpdateAlbumPartnerData_updateAlbumPartnerData_album {
  __typename: "Album";
  id: string;
  /**
   * list of partners an album is released to
   */
  partners: UpdateAlbumPartnerData_updateAlbumPartnerData_album_partners[];
}

export interface UpdateAlbumPartnerData_updateAlbumPartnerData_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface UpdateAlbumPartnerData_updateAlbumPartnerData {
  __typename: "UpdateAlbumPartnerDataPayload";
  album: UpdateAlbumPartnerData_updateAlbumPartnerData_album | null;
  errors: UpdateAlbumPartnerData_updateAlbumPartnerData_errors[] | null;
}

export interface UpdateAlbumPartnerData {
  /**
   * Update album partner data on album
   */
  updateAlbumPartnerData: UpdateAlbumPartnerData_updateAlbumPartnerData;
}

export interface UpdateAlbumPartnerDataVariables {
  input: UpdateAlbumPartnerDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateUser
// ====================================================

export interface UpdateUser_updateUser_user_mfa {
  __typename: "MFA";
  id: string;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
}

export interface UpdateUser_updateUser_user {
  __typename: "User";
  id: string;
  first_name: string | null;
  last_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
  phone: string | null;
  /**
   * MFA is normally queried from root query mfa field, but this mfa field is nested
   * under User so can more easily be used from a mutation that returns a User.
   */
  mfa: UpdateUser_updateUser_user_mfa;
}

export interface UpdateUser_updateUser_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateUser_updateUser {
  __typename: "UpdateUserPayload";
  user: UpdateUser_updateUser_user | null;
  errors: UpdateUser_updateUser_errors[] | null;
}

export interface UpdateUser {
  /**
   * Update a User. This mutation returns a User object.
   */
  updateUser: UpdateUser_updateUser;
}

export interface UpdateUserVariables {
  input: UpdateUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserAccountEditDetails
// ====================================================

export interface UserAccountEditDetails_me_signed_agreements_excluding_terms_and_conditions {
  __typename: "SignedAgreement";
  id: string;
}

export interface UserAccountEditDetails_me_signed_cla_releases {
  __typename: "Release";
  id: string;
}

export interface UserAccountEditDetails_me {
  __typename: "User";
  id: string;
  /**
   * When the user created their profile
   */
  created_at: Scalar$DateTime;
  first_name: string | null;
  last_name: string | null;
  phone: string | null;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * If the user has signed agreements excluding terms and conditions
   */
  signed_agreements_excluding_terms_and_conditions: UserAccountEditDetails_me_signed_agreements_excluding_terms_and_conditions[];
  /**
   * The user's signed cla releases
   */
  signed_cla_releases: UserAccountEditDetails_me_signed_cla_releases[];
}

export interface UserAccountEditDetails_mfa {
  __typename: "MFA";
  id: string;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
}

export interface UserAccountEditDetails {
  /**
   * Get information about the currently logged in user
   */
  me: UserAccountEditDetails_me;
  /**
   * Information used to populate the MFA/OTP screen when a user is prompted to enter MFA information
   */
  mfa: UserAccountEditDetails_mfa;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectService
// ====================================================

export interface DisconnectService_disconnectService_user {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user is connected to youtube
   */
  youtube_connected: boolean;
}

export interface DisconnectService_disconnectService_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface DisconnectService_disconnectService {
  __typename: "DisconnectServicePayload";
  user: DisconnectService_disconnectService_user | null;
  errors: DisconnectService_disconnectService_errors[] | null;
}

export interface DisconnectService {
  /**
   * Disconnect Youtube Login from the current User. Used when a user chooses to disconnect youtube login on their account.
   */
  disconnectService: DisconnectService_disconnectService;
}

export interface DisconnectServiceVariables {
  input: DisconnectServiceInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountActivity
// ====================================================

export interface AccountActivity_notifications_cursor_notifications_A2VConversionFailed {
  __typename: "A2VConversionFailed" | "AdminPayeeAssetSplitTerminated" | "AdminUpdatedComposerName" | "ArtistUpdated" | "AuthError" | "CashAdvanceRecoupmentCreated" | "ChangedCredentials" | "DailymotionAuthError" | "DeploymentConfirmed" | "DeploymentScheduled" | "FacebookAuthError" | "Generic" | "MergeCompleted" | "MergeConflict" | "NewAdminMessage" | "NewConflict" | "NewFacebookImport" | "NewInstagramImport" | "NewMergeRequest" | "NewMessage" | "NewYoutubeImport" | "OrganizationInviteAccepted" | "OrganizationReleaseCreated" | "PayeeAssetSplitExpiredForPayee" | "PayeeAssetSplitExpiredForPayor" | "PayeeAssetSplitTerminated" | "ProcessingFinished" | "RoyaltyCenterTipaltiAdded" | "StatusUpdated" | "TipaltiPayeeDetailsUpdated" | "TwitterAuthError" | "UploadFailed" | "UploadFinished" | "YoutubeAuthError";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
}

export interface AccountActivity_notifications_cursor_notifications_AdminAddedComposer_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminAddedComposer_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminAddedComposer_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminAddedComposer_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminAddedComposer_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminAddedComposer {
  __typename: "AdminAddedComposer";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminAddedComposer_media;
}

export interface AccountActivity_notifications_cursor_notifications_AdminAssertedRights_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminAssertedRights_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminAssertedRights_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminAssertedRights_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminAssertedRights_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminAssertedRights {
  __typename: "AdminAssertedRights";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminAssertedRights_media;
}

export interface AccountActivity_notifications_cursor_notifications_AdminCreatedPolicies_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminCreatedPolicies_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminCreatedPolicies_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminCreatedPolicies_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminCreatedPolicies_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminCreatedPolicies {
  __typename: "AdminCreatedPolicies";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminCreatedPolicies_media;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedComposer_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedComposer_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedComposer_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminUpdatedComposer_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminUpdatedComposer_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedComposer {
  __typename: "AdminUpdatedComposer";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminUpdatedComposer_media;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedComposerGeoRestrictions_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedComposerGeoRestrictions_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedComposerGeoRestrictions_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminUpdatedComposerGeoRestrictions_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminUpdatedComposerGeoRestrictions_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedComposerGeoRestrictions {
  __typename: "AdminUpdatedComposerGeoRestrictions";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminUpdatedComposerGeoRestrictions_media;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedOwnership_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedOwnership_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedOwnership_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminUpdatedOwnership_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminUpdatedOwnership_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedOwnership {
  __typename: "AdminUpdatedOwnership";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminUpdatedOwnership_media;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedPolicies_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedPolicies_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedPolicies_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminUpdatedPolicies_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminUpdatedPolicies_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminUpdatedPolicies {
  __typename: "AdminUpdatedPolicies";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminUpdatedPolicies_media;
}

export interface AccountActivity_notifications_cursor_notifications_UserAddedComposer_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserAddedComposer_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserAddedComposer_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_UserAddedComposer_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_UserAddedComposer_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_UserAddedComposer {
  __typename: "UserAddedComposer";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_UserAddedComposer_media;
}

export interface AccountActivity_notifications_cursor_notifications_UserAssertedRights_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserAssertedRights_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserAssertedRights_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_UserAssertedRights_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_UserAssertedRights_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_UserAssertedRights {
  __typename: "UserAssertedRights";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_UserAssertedRights_media;
}

export interface AccountActivity_notifications_cursor_notifications_UserCreatedPolicies_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserCreatedPolicies_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserCreatedPolicies_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_UserCreatedPolicies_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_UserCreatedPolicies_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_UserCreatedPolicies {
  __typename: "UserCreatedPolicies";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_UserCreatedPolicies_media;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposer_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposer_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposer_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_UserUpdatedComposer_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_UserUpdatedComposer_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposer {
  __typename: "UserUpdatedComposer";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_UserUpdatedComposer_media;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposerGeoRestrictions_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposerGeoRestrictions_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposerGeoRestrictions_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_UserUpdatedComposerGeoRestrictions_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_UserUpdatedComposerGeoRestrictions_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposerGeoRestrictions {
  __typename: "UserUpdatedComposerGeoRestrictions";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_UserUpdatedComposerGeoRestrictions_media;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposerName_composer {
  __typename: "Composer";
  id: string;
  full_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedComposerName {
  __typename: "UserUpdatedComposerName";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  composer: AccountActivity_notifications_cursor_notifications_UserUpdatedComposerName_composer;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedOwnership_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedOwnership_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedOwnership_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_UserUpdatedOwnership_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_UserUpdatedOwnership_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedOwnership {
  __typename: "UserUpdatedOwnership";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_UserUpdatedOwnership_media;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedPolicies_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedPolicies_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedPolicies_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_UserUpdatedPolicies_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_UserUpdatedPolicies_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_UserUpdatedPolicies {
  __typename: "UserUpdatedPolicies";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_UserUpdatedPolicies_media;
}

export interface AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitAccepted_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitAccepted_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitAccepted_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitAccepted_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitAccepted_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitAccepted {
  __typename: "AdminPayeeAssetSplitAccepted";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitAccepted_media;
}

export interface AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitRejected_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitRejected_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitRejected_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitRejected_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitRejected_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitRejected {
  __typename: "AdminPayeeAssetSplitRejected";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitRejected_media;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitAccepted_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitAccepted_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitAccepted_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitAccepted_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitAccepted_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitAccepted {
  __typename: "PayeeAssetSplitAccepted";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitAccepted_media;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitExistingUserInvite_nullableMedia_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitExistingUserInvite_nullableMedia_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitExistingUserInvite_nullableMedia {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitExistingUserInvite_nullableMedia_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitExistingUserInvite_nullableMedia_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitExistingUserInvite {
  __typename: "PayeeAssetSplitExistingUserInvite";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  nullableMedia: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitExistingUserInvite_nullableMedia | null;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitRejected_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitRejected_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitRejected_media {
  __typename: "Media";
  id: string;
  /**
   * Finds if this media is owned by current user or a shared media
   */
  owned_by_current_user: boolean | null;
  artist: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitRejected_media_artist | null;
  metadata: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitRejected_media_metadata | null;
}

export interface AccountActivity_notifications_cursor_notifications_PayeeAssetSplitRejected {
  __typename: "PayeeAssetSplitRejected";
  id: string;
  created_at: Scalar$DateTime;
  plain_text: string;
  /**
   * Which user (or observer) actually performed the action
   */
  acted_user_name: string;
  /**
   * Should display logo of Vydia or WL instead of Avatar?
   */
  display_logo: boolean;
  media: AccountActivity_notifications_cursor_notifications_PayeeAssetSplitRejected_media;
}

export type AccountActivity_notifications_cursor_notifications = AccountActivity_notifications_cursor_notifications_A2VConversionFailed | AccountActivity_notifications_cursor_notifications_AdminAddedComposer | AccountActivity_notifications_cursor_notifications_AdminAssertedRights | AccountActivity_notifications_cursor_notifications_AdminCreatedPolicies | AccountActivity_notifications_cursor_notifications_AdminUpdatedComposer | AccountActivity_notifications_cursor_notifications_AdminUpdatedComposerGeoRestrictions | AccountActivity_notifications_cursor_notifications_AdminUpdatedOwnership | AccountActivity_notifications_cursor_notifications_AdminUpdatedPolicies | AccountActivity_notifications_cursor_notifications_UserAddedComposer | AccountActivity_notifications_cursor_notifications_UserAssertedRights | AccountActivity_notifications_cursor_notifications_UserCreatedPolicies | AccountActivity_notifications_cursor_notifications_UserUpdatedComposer | AccountActivity_notifications_cursor_notifications_UserUpdatedComposerGeoRestrictions | AccountActivity_notifications_cursor_notifications_UserUpdatedComposerName | AccountActivity_notifications_cursor_notifications_UserUpdatedOwnership | AccountActivity_notifications_cursor_notifications_UserUpdatedPolicies | AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitAccepted | AccountActivity_notifications_cursor_notifications_AdminPayeeAssetSplitRejected | AccountActivity_notifications_cursor_notifications_PayeeAssetSplitAccepted | AccountActivity_notifications_cursor_notifications_PayeeAssetSplitExistingUserInvite | AccountActivity_notifications_cursor_notifications_PayeeAssetSplitRejected;

export interface AccountActivity_notifications_cursor {
  __typename: "Notifications";
  count: number;
  /**
   * List of notifications
   */
  notifications: AccountActivity_notifications_cursor_notifications[];
}

export interface AccountActivity {
  /**
   * List out all the notifications for the current user
   */
  notifications_cursor: AccountActivity_notifications_cursor;
}

export interface AccountActivityVariables {
  offset: number;
  limit: number;
  searchTerm?: string | null;
  sortOrder?: SortOrder | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountDeletionRequest
// ====================================================

export interface AccountDeletionRequest_me {
  __typename: "User";
  id: string;
  first_name: string | null;
}

export interface AccountDeletionRequest {
  /**
   * Get information about the currently logged in user
   */
  me: AccountDeletionRequest_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AccountDeleteRequest
// ====================================================

export interface AccountDeleteRequest_deleteUserRequest_user {
  __typename: "User";
  id: string;
  /**
   * When the user requested to delete their account at
   */
  requested_delete_at: Scalar$DateTime | null;
}

export interface AccountDeleteRequest_deleteUserRequest_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AccountDeleteRequest_deleteUserRequest {
  __typename: "DeleteUserRequestPayload";
  user: AccountDeleteRequest_deleteUserRequest_user | null;
  errors: AccountDeleteRequest_deleteUserRequest_errors[] | null;
}

export interface AccountDeleteRequest {
  /**
   * Send a User deletion request. This mutation returns a User object. Used when a user account deletion is requested through the platform.
   */
  deleteUserRequest: AccountDeleteRequest_deleteUserRequest;
}

export interface AccountDeleteRequestVariables {
  input: DeleteUserRequestInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountDeletionWarning
// ====================================================

export interface AccountDeletionWarning_me {
  __typename: "User";
  id: string;
  first_name: string | null;
}

export interface AccountDeletionWarning {
  /**
   * Get information about the currently logged in user
   */
  me: AccountDeletionWarning_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AccountSignedAgreement
// ====================================================

export interface AccountSignedAgreement_me_signed_agreements_cursor_signed_agreements_agreement {
  __typename: "Agreement";
  id: string;
  pretty_category: string;
}

export interface AccountSignedAgreement_me_signed_agreements_cursor_signed_agreements {
  __typename: "SignedAgreement";
  id: string;
  date: Scalar$DateTime | null;
  /**
   * URL where the SignedAgreement PDF can be downloaded. Expires after a while
   */
  s3_presigned_url: string | null;
  active: boolean;
  type: SignedAgreementType;
  /**
   * The Agreement that was used as a template for this instance of a SignedAgreement
   */
  agreement: AccountSignedAgreement_me_signed_agreements_cursor_signed_agreements_agreement | null;
}

export interface AccountSignedAgreement_me_signed_agreements_cursor {
  __typename: "SignedAgreements";
  /**
   * List of SignedAgreements
   */
  signed_agreements: AccountSignedAgreement_me_signed_agreements_cursor_signed_agreements[];
}

export interface AccountSignedAgreement_me {
  __typename: "User";
  id: string;
  /**
   * List out all the SignedAgreements for a User
   */
  signed_agreements_cursor: AccountSignedAgreement_me_signed_agreements_cursor;
}

export interface AccountSignedAgreement {
  /**
   * Get information about the currently logged in user
   */
  me: AccountSignedAgreement_me;
}

export interface AccountSignedAgreementVariables {
  agreement_types?: SignedAgreementType[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RevenuePredictions
// ====================================================

export interface RevenuePredictions_revenue_predictions_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
  name: string;
}

export interface RevenuePredictions_revenue_predictions {
  __typename: "RevenuePrediction";
  id: string;
  date: Scalar$Date;
  /**
   * Revenue using rps.actual_revenue (when available)
   */
  revenue_using_actual_rps: number | null;
  /**
   * Revenue using rps ensemble predictions (when available). In the case of YouTube, this may be the value directly from the youtube_videos_asset_estimated_revenue_bycountry_bydate_discrete report.
   */
  revenue_using_predicted_rps: number | null;
  streams: number | null;
  network: RevenuePredictions_revenue_predictions_network;
}

export interface RevenuePredictions {
  /**
   * The title of the resource
   */
  revenue_predictions_resource_title: string;
  /**
   * Predict the revenue for the whole organization, or filter by various IDs
   */
  revenue_predictions: RevenuePredictions_revenue_predictions[];
}

export interface RevenuePredictionsVariables {
  input: RevenuePredictionsCursorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RevenueActuals
// ====================================================

export interface RevenueActuals_me {
  __typename: "User";
  id: string;
  is_admin: boolean;
  is_whitelabel: boolean;
}

export interface RevenueActuals_revenue_actuals_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
  name: string;
}

export interface RevenueActuals_revenue_actuals {
  __typename: "RevenueActual";
  id: string;
  date: Scalar$Date;
  client_revenue: number | null;
  org_revenue: number | null;
  revenue: number | null;
  streams: number | null;
  /**
   * The network this revenue data is for. If NULL, it likely means the deploy_name is 'other' to indicate 'rest of networks'
   */
  network: RevenueActuals_revenue_actuals_network | null;
}

export interface RevenueActuals {
  /**
   * Get information about the currently logged in user
   */
  me: RevenueActuals_me;
  /**
   * Get the revenue numbers for the whole organization, or filter by various IDs
   */
  revenue_actuals: RevenueActuals_revenue_actuals[];
}

export interface RevenueActualsVariables {
  input: RevenueActualsCursorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SignatureCreation
// ====================================================

export interface SignatureCreation_me {
  __typename: "User";
  id: string;
  is_admin: boolean;
  is_whitelabel: boolean;
}

export interface SignatureCreation {
  /**
   * Get information about the currently logged in user
   */
  me: SignatureCreation_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CreateAlbumMediasList
// ====================================================

export interface CreateAlbumMediasList_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface CreateAlbumMediasList_me {
  __typename: "User";
  id: string;
  /**
   * List out all Artists for the current user
   */
  artists: CreateAlbumMediasList_me_artists[];
  /**
   * Count of medias
   */
  medias_v2_count: number;
}

export interface CreateAlbumMediasList {
  /**
   * Get information about the currently logged in user
   */
  me: CreateAlbumMediasList_me;
}

export interface CreateAlbumMediasListVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CreateAlbumTrackList
// ====================================================

export interface CreateAlbumTrackList_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
}

export interface CreateAlbumTrackList_me_medias_v2 {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
  metadata: CreateAlbumTrackList_me_medias_v2_metadata | null;
}

export interface CreateAlbumTrackList_me_medias_v2_sr_territories_intersection {
  __typename: "Territory";
  id: string;
  name: string;
}

export interface CreateAlbumTrackList_me {
  __typename: "User";
  id: string;
  /**
   * Count of medias
   */
  medias_v2_count: number;
  /**
   * Medias of the user
   */
  medias_v2: CreateAlbumTrackList_me_medias_v2[];
  /**
   * List of territories that all medias have sound recording rights in
   */
  medias_v2_sr_territories_intersection: CreateAlbumTrackList_me_medias_v2_sr_territories_intersection[];
}

export interface CreateAlbumTrackList {
  /**
   * Get information about the currently logged in user
   */
  me: CreateAlbumTrackList_me;
}

export interface CreateAlbumTrackListVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateAlbum
// ====================================================

export interface CreateAlbum_createAlbum_album {
  __typename: "Album";
  id: string;
}

export interface CreateAlbum_createAlbum_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface CreateAlbum_createAlbum {
  __typename: "CreateAlbumPayload";
  album: CreateAlbum_createAlbum_album | null;
  errors: CreateAlbum_createAlbum_errors[] | null;
}

export interface CreateAlbum {
  /**
   * Create an Album. This mutation returns an Album object. Used in the album creation flow.
   */
  createAlbum: CreateAlbum_createAlbum;
}

export interface CreateAlbumVariables {
  input: CreateAlbumInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateUpc
// ====================================================

export interface GenerateUpc_generateUpc_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface GenerateUpc_generateUpc {
  __typename: "GenerateUpcPayload";
  /**
   * Generated UPC
   */
  upc: string;
  errors: GenerateUpc_generateUpc_errors[] | null;
}

export interface GenerateUpc {
  /**
   * Generate a UPC. Used to create a universal product code for a metadata.
   */
  generateUpc: GenerateUpc_generateUpc;
}

export interface GenerateUpcVariables {
  input: GenerateUpcInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CreateAlbumForm
// ====================================================

export interface CreateAlbumForm_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface CreateAlbumForm {
  /**
   * Gets all the available Genres from our system
   */
  genres: CreateAlbumForm_genres[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SingleTrack
// ====================================================

export interface SingleTrack_media_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface SingleTrack_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  collaborating_artists: SingleTrack_media_metadata_collaborating_artists[];
  copyright_name: string;
  copyright_year: number | null;
  /**
   * Genre enum for the media associated to this metadata
   */
  genre_enum: Genre | null;
  label: string;
  /**
   * Secondary Genre enum for the media associated to this metadata
   */
  secondary_genre_enum: Genre | null;
}

export interface SingleTrack_media {
  __typename: "Media";
  id: string;
  artist_id: string | null;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
  metadata: SingleTrack_media_metadata | null;
}

export interface SingleTrack {
  /**
   * Find the current media by ID
   */
  media: SingleTrack_media;
}

export interface SingleTrackVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumCreation
// ====================================================

export interface AlbumCreation_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface AlbumCreation {
  /**
   * Get information about the currently logged in user
   */
  me: AlbumCreation_me;
  observer_signed_in: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsHeader
// ====================================================

export interface AlbumDetailsHeader_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has a feature
   */
  has_feature_d2_album_stat_tabs: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_d2_revenue_albums: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_marketing: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_event_notifications: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_smartlink_tab: boolean;
}

export interface AlbumDetailsHeader_album_artist {
  __typename: "Artist";
  id: string;
}

export interface AlbumDetailsHeader_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AlbumDetailsHeader_album_file_upload {
  __typename: "FileUpload";
  id: string;
  header_album_artwork_url: string | null;
}

export interface AlbumDetailsHeader_album_releases {
  __typename: "Release";
  id: string;
}

export interface AlbumDetailsHeader_album {
  __typename: "Album";
  id: string;
  artist: AlbumDetailsHeader_album_artist;
  metadata: AlbumDetailsHeader_album_metadata;
  file_upload: AlbumDetailsHeader_album_file_upload | null;
  /**
   * List of releases for the album
   */
  releases: AlbumDetailsHeader_album_releases[];
  is_smartlink_eligible: boolean;
}

export interface AlbumDetailsHeader {
  /**
   * Get information about the currently logged in user
   */
  me: AlbumDetailsHeader_me;
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsHeader_album;
}

export interface AlbumDetailsHeaderVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsArtworkCard
// ====================================================

export interface AlbumDetailsArtworkCard_me {
  __typename: "User";
  id: string;
  /**
   * Checks if user or current observer has a permission
   */
  canUpdateArtwork: boolean;
}

export interface AlbumDetailsArtworkCard_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AlbumDetailsArtworkCard_album {
  __typename: "Album";
  id: string;
  is_distributed: boolean;
  file_upload: AlbumDetailsArtworkCard_album_file_upload | null;
}

export interface AlbumDetailsArtworkCard {
  /**
   * Get information about the currently logged in user
   */
  me: AlbumDetailsArtworkCard_me;
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsArtworkCard_album;
}

export interface AlbumDetailsArtworkCardVariables {
  albumId: string;
  size?: ThumbnailSize | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAlbumArtwork
// ====================================================

export interface UpdateAlbumArtwork_updateAlbumArtwork_album_file_upload {
  __typename: "FileUpload";
  id: string;
  header_album_artwork_url: string | null;
  album_artwork_url: string | null;
}

export interface UpdateAlbumArtwork_updateAlbumArtwork_album {
  __typename: "Album";
  id: string;
  file_upload: UpdateAlbumArtwork_updateAlbumArtwork_album_file_upload | null;
}

export interface UpdateAlbumArtwork_updateAlbumArtwork_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateAlbumArtwork_updateAlbumArtwork {
  __typename: "UpdateAlbumArtworkPayload";
  album: UpdateAlbumArtwork_updateAlbumArtwork_album | null;
  errors: UpdateAlbumArtwork_updateAlbumArtwork_errors[] | null;
}

export interface UpdateAlbumArtwork {
  /**
   * Update an Album's artwork. This mutation returns an Album. Used in when editing an Album's metadata and changing the artwork.
   */
  updateAlbumArtwork: UpdateAlbumArtwork_updateAlbumArtwork;
}

export interface UpdateAlbumArtworkVariables {
  input: UpdateAlbumArtworkInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsArtworkEditCard
// ====================================================

export interface AlbumDetailsArtworkEditCard_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AlbumDetailsArtworkEditCard_album {
  __typename: "Album";
  id: string;
  file_upload: AlbumDetailsArtworkEditCard_album_file_upload | null;
}

export interface AlbumDetailsArtworkEditCard {
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsArtworkEditCard_album;
}

export interface AlbumDetailsArtworkEditCardVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsInfoTabDestinationsCard
// ====================================================

export interface AlbumDetailsInfoTabDestinationsCard_album_broadcasts_cursor_social_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AlbumDetailsInfoTabDestinationsCard_album_broadcasts_cursor_social {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  /**
   * Get the size of the thumbnail
   */
  thumbnail_url: string;
  /**
   * Title of this Social
   */
  title: string | null;
  /**
   * Network Object for a network associated with a social
   */
  network: AlbumDetailsInfoTabDestinationsCard_album_broadcasts_cursor_social_network | null;
}

export interface AlbumDetailsInfoTabDestinationsCard_album_broadcasts_cursor_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface AlbumDetailsInfoTabDestinationsCard_album_broadcasts_cursor {
  __typename: "Broadcast";
  id: string;
  video_url: string | null;
  status: string;
  deploy_at: Scalar$DateTime | null;
  distributed_at: Scalar$DateTime | null;
  /**
   * Determines which social does this broadcast belong to
   */
  social: AlbumDetailsInfoTabDestinationsCard_album_broadcasts_cursor_social | null;
  /**
   * Network associated with a broadcast
   */
  network: AlbumDetailsInfoTabDestinationsCard_album_broadcasts_cursor_network;
}

export interface AlbumDetailsInfoTabDestinationsCard_album {
  __typename: "Album";
  id: string;
  /**
   * Count of broadcasts for an album
   */
  broadcasts_count: number;
  /**
   * Broadcasts for an album
   */
  broadcasts_cursor: AlbumDetailsInfoTabDestinationsCard_album_broadcasts_cursor[];
}

export interface AlbumDetailsInfoTabDestinationsCard {
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsInfoTabDestinationsCard_album;
}

export interface AlbumDetailsInfoTabDestinationsCardVariables {
  albumId: string;
  input: BroadcastsCursorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsInformationCard
// ====================================================

export interface AlbumDetailsInformationCard_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AlbumDetailsInformationCard_album_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface AlbumDetailsInformationCard_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
  genre: string | null;
  secondary_genre: string | null;
  label: string | null;
  copyright_name: string | null;
  copyright_year: number | null;
  /**
   * locations that this album is valid in, if it is worldwide, the array is ["WW"]
   */
  territories: string[];
  upc: string | null;
  /**
   * List of Collaborating Artists for the album this metadata is associated with
   */
  collaborating_artists: AlbumDetailsInformationCard_album_metadata_collaborating_artists[];
}

export interface AlbumDetailsInformationCard_album {
  __typename: "Album";
  id: string;
  is_distributed: boolean;
  /**
   * boolean value to see if the album has tracks
   */
  has_tracks: boolean;
  artist: AlbumDetailsInformationCard_album_artist;
  metadata: AlbumDetailsInformationCard_album_metadata;
  private_url: string | null;
  slug: string | null;
  slug_updated_at: Scalar$DateTime | null;
  smartlink_url: string;
}

export interface AlbumDetailsInformationCard {
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsInformationCard_album;
}

export interface AlbumDetailsInformationCardVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAlbumMetadata
// ====================================================

export interface UpdateAlbumMetadata_updateAlbumMetadata_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface UpdateAlbumMetadata_updateAlbumMetadata_album_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface UpdateAlbumMetadata_updateAlbumMetadata_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
  genre: string | null;
  secondary_genre: string | null;
  label: string | null;
  copyright_name: string | null;
  copyright_year: number | null;
  /**
   * locations that this album is valid in, if it is worldwide, the array is ["WW"]
   */
  territories: string[];
  upc: string | null;
  /**
   * List of Collaborating Artists for the album this metadata is associated with
   */
  collaborating_artists: UpdateAlbumMetadata_updateAlbumMetadata_album_metadata_collaborating_artists[];
}

export interface UpdateAlbumMetadata_updateAlbumMetadata_album {
  __typename: "Album";
  id: string;
  artist: UpdateAlbumMetadata_updateAlbumMetadata_album_artist;
  metadata: UpdateAlbumMetadata_updateAlbumMetadata_album_metadata;
}

export interface UpdateAlbumMetadata_updateAlbumMetadata_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface UpdateAlbumMetadata_updateAlbumMetadata {
  __typename: "UpdateAlbumMetadataPayload";
  album: UpdateAlbumMetadata_updateAlbumMetadata_album | null;
  errors: UpdateAlbumMetadata_updateAlbumMetadata_errors[] | null;
}

export interface UpdateAlbumMetadata {
  /**
   * Update an AlbumMetadata. This mutation returns an Album. Used when a user updates the information on an album's metadata (AlbumMetadata record).
   */
  updateAlbumMetadata: UpdateAlbumMetadata_updateAlbumMetadata;
}

export interface UpdateAlbumMetadataVariables {
  input: UpdateAlbumMetadataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsInformationEditCard
// ====================================================

export interface AlbumDetailsInformationEditCard_album_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * apple id
   */
  apple_eid: string | null;
  /**
   * apple new
   */
  apple_new: boolean | null;
  /**
   * spotify id
   */
  spotify_eid: string | null;
  /**
   * spotify new
   */
  spotify_new: boolean | null;
}

export interface AlbumDetailsInformationEditCard_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
  genre: string | null;
  secondary_genre: string | null;
  /**
   * Secondary Genre enum for the media associated to this metadata
   */
  secondary_genre_enum: Genre | null;
  /**
   * Genre for the album associated with this metadata
   */
  genre_enum: Genre | null;
  label: string | null;
  copyright_name: string | null;
  copyright_year: number | null;
  upc: string | null;
  /**
   * locations that this album is valid in, if it is worldwide, the array is ["WW"]
   */
  territories: string[];
  /**
   * List of Collaborating Artists for the album this metadata is associated with
   */
  collaborating_artists: AlbumDetailsInformationEditCard_album_metadata_collaborating_artists[];
}

export interface AlbumDetailsInformationEditCard_album {
  __typename: "Album";
  id: string;
  artist_id: string;
  is_distributed: boolean;
  /**
   * boolean value to see if album is a single
   */
  one_track_single: boolean;
  metadata: AlbumDetailsInformationEditCard_album_metadata;
}

export interface AlbumDetailsInformationEditCard_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AlbumDetailsInformationEditCard_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface AlbumDetailsInformationEditCard_territories {
  __typename: "Territory";
  id: string;
  name: string;
}

export interface AlbumDetailsInformationEditCard {
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsInformationEditCard_album;
  /**
   * List out all Artists for the current user
   */
  artists: AlbumDetailsInformationEditCard_artists[];
  /**
   * Gets all the available Genres from our system
   */
  genres: AlbumDetailsInformationEditCard_genres[];
  /**
   * Gets all the available ownership territories from our system
   */
  territories: AlbumDetailsInformationEditCard_territories[];
}

export interface AlbumDetailsInformationEditCardVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsInfoTab
// ====================================================

export interface AlbumDetailsInfoTab_me {
  __typename: "User";
  id: string;
  /**
   * Checks if user or current observer has a permission
   */
  canUpdateArtwork: boolean;
  /**
   * Flag for a user has a feature
   */
  hasFeatureMarketing: boolean;
  /**
   * Flag for a user has a feature
   */
  hasFeatureSmartlinksTab: boolean;
  /**
   * Flag for a user has a feature
   */
  hasFeatureSmartlinks: boolean;
}

export interface AlbumDetailsInfoTab_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AlbumDetailsInfoTab_album_extra_links_file_upload {
  __typename: "FileUpload";
  id: string;
  title: string | null;
  extra_link_image_url: string | null;
}

export interface AlbumDetailsInfoTab_album_extra_links {
  __typename: "ExtraLink";
  id: string;
  title: string | null;
  description: string | null;
  type: ExtraLinkType | null;
  button_text: string | null;
  order: number;
  url: string | null;
  file_upload: AlbumDetailsInfoTab_album_extra_links_file_upload | null;
}

export interface AlbumDetailsInfoTab_album_video_album_media {
  __typename: "Media";
  id: string;
}

export interface AlbumDetailsInfoTab_album_releases {
  __typename: "Release";
  id: string;
  /**
   * count of release messages
   */
  messages_count: number;
}

export interface AlbumDetailsInfoTab_album {
  __typename: "Album";
  id: string;
  /**
   * Checks if there are any links to display on smartlinks
   */
  extra_links_enabled: boolean;
  /**
   * Extra links title shown on smartlinks page if enabled
   */
  extra_links_title: string | null;
  is_distributed: boolean;
  /**
   * boolean value to see if the album has tracks
   */
  has_tracks: boolean;
  private_url: string | null;
  slug_updated_at: Scalar$DateTime | null;
  slug: string | null;
  smartlink_url: string;
  smart_link_subdomain: string | null;
  artist: AlbumDetailsInfoTab_album_artist;
  extra_links: AlbumDetailsInfoTab_album_extra_links[];
  video_album: boolean;
  video_album_media: AlbumDetailsInfoTab_album_video_album_media | null;
  /**
   * List of releases for the album
   */
  releases: AlbumDetailsInfoTab_album_releases[];
}

export interface AlbumDetailsInfoTab {
  /**
   * Get information about the currently logged in user
   */
  me: AlbumDetailsInfoTab_me;
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsInfoTab_album;
}

export interface AlbumDetailsInfoTabVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksGoogleAnalyticsCountriesTable
// ====================================================

export interface SmartlinksGoogleAnalyticsCountriesTable_me_album_smartlinks_google_analytics {
  __typename: "SmartlinksGoogleAnalytics";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of clickthroughs on this albums smartlink page
   */
  clickthroughs_cumulative: number;
  /**
   * Country of client
   */
  country: string | null;
  /**
   * Country code of client
   */
  country_code: string | null;
  /**
   * Percentage of total clickthroughs from specific network or country
   */
  percentage_total_clickthroughs: number;
  /**
   * Number of presaves on this albums smartlink page
   */
  presaves_cumulative: number | null;
  /**
   * Number of unique clickthroughs on this albums smartlink page
   */
  total_unique_clickthroughs_cumulative: number;
}

export interface SmartlinksGoogleAnalyticsCountriesTable_me_album {
  __typename: "Album";
  id: string;
  /**
   * google analytic statistics on album's smartlinks page
   */
  smartlinks_google_analytics: SmartlinksGoogleAnalyticsCountriesTable_me_album_smartlinks_google_analytics[];
  /**
   * Count of google analytic statistics
   */
  smartlinks_google_analytics_count: number;
}

export interface SmartlinksGoogleAnalyticsCountriesTable_me {
  __typename: "User";
  id: string;
  /**
   * Get single album by id
   */
  album: SmartlinksGoogleAnalyticsCountriesTable_me_album | null;
}

export interface SmartlinksGoogleAnalyticsCountriesTable {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinksGoogleAnalyticsCountriesTable_me;
}

export interface SmartlinksGoogleAnalyticsCountriesTableVariables {
  albumId: string;
  input: SmartlinksGoogleAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksGoogleAnalyticsNetworkTable
// ====================================================

export interface SmartlinksGoogleAnalyticsNetworkTable_me_album_smartlinks_google_analytics_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface SmartlinksGoogleAnalyticsNetworkTable_me_album_smartlinks_google_analytics {
  __typename: "SmartlinksGoogleAnalytics";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of clickthroughs on this albums smartlink page
   */
  clickthroughs_cumulative: number;
  /**
   * Corresponding Network of play/download
   */
  network: SmartlinksGoogleAnalyticsNetworkTable_me_album_smartlinks_google_analytics_network | null;
  /**
   * Network type of (presave or clickthrough)
   */
  network_type: string | null;
  /**
   * Percentage of total clickthroughs from specific network or country
   */
  percentage_total_clickthroughs: number;
  /**
   * Number of presaves on this albums smartlink page
   */
  presaves_cumulative: number | null;
  /**
   * Number of unique clickthroughs on this albums smartlink page
   */
  total_unique_clickthroughs_cumulative: number;
}

export interface SmartlinksGoogleAnalyticsNetworkTable_me_album {
  __typename: "Album";
  id: string;
  /**
   * google analytic statistics on album's smartlinks page
   */
  smartlinks_google_analytics: SmartlinksGoogleAnalyticsNetworkTable_me_album_smartlinks_google_analytics[];
  /**
   * Count of google analytic statistics
   */
  smartlinks_google_analytics_count: number;
}

export interface SmartlinksGoogleAnalyticsNetworkTable_me {
  __typename: "User";
  id: string;
  /**
   * Get single album by id
   */
  album: SmartlinksGoogleAnalyticsNetworkTable_me_album | null;
}

export interface SmartlinksGoogleAnalyticsNetworkTable {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinksGoogleAnalyticsNetworkTable_me;
}

export interface SmartlinksGoogleAnalyticsNetworkTableVariables {
  albumId: string;
  input: SmartlinksGoogleAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksAnalyticsTotals
// ====================================================

export interface SmartlinksAnalyticsTotals_me_album_smartlinks_google_analytics_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface SmartlinksAnalyticsTotals_me_album_smartlinks_google_analytics {
  __typename: "SmartlinksGoogleAnalytics";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of clickthroughs on this albums smartlink page
   */
  clickthroughs_cumulative: number;
  /**
   * Number of total clickthroughs on this albums smartlink page
   */
  total_clickthroughs_cumulative: number;
  /**
   * Country of client
   */
  country: string | null;
  /**
   * Number of downloads on this albums smartlink page
   */
  downloads_cumulative: number | null;
  /**
   * Corresponding Network of play/download
   */
  network: SmartlinksAnalyticsTotals_me_album_smartlinks_google_analytics_network | null;
  /**
   * Number of page views on this albums smartlink page
   */
  unique_page_views_cumulative: number | null;
  /**
   * Percentage of total clickthroughs from specific network or country
   */
  percentage_total_clickthroughs: number;
  /**
   * Number of presaves on this albums smartlink page
   */
  presaves_cumulative: number | null;
  /**
   * Number of streams on this albums smartlink page
   */
  streams_cumulative: number | null;
  /**
   * Number of unique clickthroughs on this albums smartlink page
   */
  unique_clickthroughs_cumulative: number | null;
  /**
   * Number of unique clickthroughs on this albums smartlink page
   */
  total_unique_clickthroughs_cumulative: number;
  /**
   * Top Country by clickthroughs
   */
  top_country: string | null;
}

export interface SmartlinksAnalyticsTotals_me_album {
  __typename: "Album";
  id: string;
  /**
   * google analytic statistics on album's smartlinks page
   */
  smartlinks_google_analytics: SmartlinksAnalyticsTotals_me_album_smartlinks_google_analytics[];
}

export interface SmartlinksAnalyticsTotals_me {
  __typename: "User";
  id: string;
  /**
   * Get single album by id
   */
  album: SmartlinksAnalyticsTotals_me_album | null;
}

export interface SmartlinksAnalyticsTotals {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinksAnalyticsTotals_me;
}

export interface SmartlinksAnalyticsTotalsVariables {
  albumId: string;
  input: SmartlinksGoogleAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MarketingQuery
// ====================================================

export interface MarketingQuery_album {
  __typename: "Album";
  id: string;
  /**
   * boolean value to see if the album has tracks
   */
  has_tracks: boolean;
  /**
   * Count of google analytic statistics
   */
  smartlinks_google_analytics_count: number;
}

export interface MarketingQuery {
  /**
   * Find the current album by ID
   */
  album: MarketingQuery_album;
}

export interface MarketingQueryVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: GenerateAnalyticsCsv
// ====================================================

export interface GenerateAnalyticsCsv_generateAnalyticsCsv_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface GenerateAnalyticsCsv_generateAnalyticsCsv {
  __typename: "GenerateAnalyticsCsvPayload";
  /**
   * Signed url to csv file on s3
   */
  csv_link: string;
  errors: GenerateAnalyticsCsv_generateAnalyticsCsv_errors[] | null;
}

export interface GenerateAnalyticsCsv {
  /**
   * Returns a link to a csv file.
   */
  generateAnalyticsCsv: GenerateAnalyticsCsv_generateAnalyticsCsv;
}

export interface GenerateAnalyticsCsvVariables {
  input: GenerateAnalyticsCsvInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConnectShopifyStore
// ====================================================

export interface ConnectShopifyStore_connectShopifyStore_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ConnectShopifyStore_connectShopifyStore {
  __typename: "ConnectShopifyStorePayload";
  /**
   * redirect url for shopify oauth
   */
  redirect_url: string | null;
  errors: ConnectShopifyStore_connectShopifyStore_errors[] | null;
}

export interface ConnectShopifyStore {
  /**
   * Connects a shopify store to the album's artist.
   */
  connectShopifyStore: ConnectShopifyStore_connectShopifyStore;
}

export interface ConnectShopifyStoreVariables {
  input: ConnectShopifyStoreInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateShopifyStoreProducts
// ====================================================

export interface UpdateShopifyStoreProducts_updateShopifyStoreProducts_album_shopify_store_products {
  __typename: "ShopifyProduct";
  id: string;
  description: string | null;
  image_url: string | null;
  online_store_url: string | null;
  price: string | null;
  selected: boolean;
  shop_name: string;
  title: string;
}

export interface UpdateShopifyStoreProducts_updateShopifyStoreProducts_album {
  __typename: "Album";
  id: string;
  /**
   * Shopify products in the albums artist's store. Queries the Shopify API directly
   */
  shopify_store_products: UpdateShopifyStoreProducts_updateShopifyStoreProducts_album_shopify_store_products[];
  /**
   * Show Shopify store on Smartlinks
   */
  show_shopify_feed: boolean;
}

export interface UpdateShopifyStoreProducts_updateShopifyStoreProducts_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateShopifyStoreProducts_updateShopifyStoreProducts {
  __typename: "UpdateShopifyStoreProductsPayload";
  album: UpdateShopifyStoreProducts_updateShopifyStoreProducts_album | null;
  errors: UpdateShopifyStoreProducts_updateShopifyStoreProducts_errors[] | null;
}

export interface UpdateShopifyStoreProducts {
  /**
   * Update album shopify store products to show. This mutation returns an Album. Used to select which shopify products to show on an album smartlink.
   */
  updateShopifyStoreProducts: UpdateShopifyStoreProducts_updateShopifyStoreProducts;
}

export interface UpdateShopifyStoreProductsVariables {
  input: UpdateShopifyStoreProductsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateShowShopifyFeed
// ====================================================

export interface UpdateShowShopifyFeed_updateShowShopifyFeed_album {
  __typename: "Album";
  id: string;
  /**
   * Show Shopify store on Smartlinks
   */
  show_shopify_feed: boolean;
}

export interface UpdateShowShopifyFeed_updateShowShopifyFeed_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateShowShopifyFeed_updateShowShopifyFeed {
  __typename: "UpdateShowShopifyFeedPayload";
  album: UpdateShowShopifyFeed_updateShowShopifyFeed_album | null;
  errors: UpdateShowShopifyFeed_updateShowShopifyFeed_errors[] | null;
}

export interface UpdateShowShopifyFeed {
  /**
   * Update album links. This mutation returns an Album. Used when a user updates the links on an album
   */
  updateShowShopifyFeed: UpdateShowShopifyFeed_updateShowShopifyFeed;
}

export interface UpdateShowShopifyFeedVariables {
  input: UpdateShowShopifyFeedInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShopifyStoreProducts
// ====================================================

export interface ShopifyStoreProducts_album_shopify_store_products {
  __typename: "ShopifyProduct";
  id: string;
  description: string | null;
  image_url: string | null;
  online_store_url: string | null;
  price: string | null;
  selected: boolean;
  shop_name: string;
  title: string;
}

export interface ShopifyStoreProducts_album {
  __typename: "Album";
  id: string;
  has_shopify_store: boolean;
  /**
   * Connected shopify store domain
   */
  shopify_store_domain: string | null;
  /**
   * boolean result of remaining list of products
   */
  shopify_store_products_any_more_results: boolean;
  /**
   * The end cursor for shopify pagination results
   */
  shopify_store_products_end_cursor: string | null;
  /**
   * Shopify products in the albums artist's store. Queries the Shopify API directly
   */
  shopify_store_products: ShopifyStoreProducts_album_shopify_store_products[];
  /**
   * Show Shopify store on Smartlinks
   */
  show_shopify_feed: boolean;
}

export interface ShopifyStoreProducts {
  /**
   * Find the current album by ID
   */
  album: ShopifyStoreProducts_album;
}

export interface ShopifyStoreProductsVariables {
  albumId: string;
  input: ShopifyStoreProductsCursorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublishAlbumSuccess
// ====================================================

export interface PublishAlbumSuccess_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface PublishAlbumSuccess_album {
  __typename: "Album";
  id: string;
  metadata: PublishAlbumSuccess_album_metadata;
}

export interface PublishAlbumSuccess {
  /**
   * Find the current album by ID
   */
  album: PublishAlbumSuccess_album;
}

export interface PublishAlbumSuccessVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublishAlbumSuccessV2
// ====================================================

export interface PublishAlbumSuccessV2_release_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface PublishAlbumSuccessV2_release_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface PublishAlbumSuccessV2_release_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface PublishAlbumSuccessV2_release_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface PublishAlbumSuccessV2_release_album {
  __typename: "Album";
  id: string;
  artist: PublishAlbumSuccessV2_release_album_artist;
  metadata: PublishAlbumSuccessV2_release_album_metadata;
  file_upload: PublishAlbumSuccessV2_release_album_file_upload | null;
}

export interface PublishAlbumSuccessV2_release_user {
  __typename: "User";
  id: string;
  /**
   * Default phone number for sms notifications
   */
  default_sms_number: string | null;
}

export interface PublishAlbumSuccessV2_release {
  __typename: "Release";
  id: string;
  /**
   * Time when the earliest broadcast is deployed
   */
  earliest_deploy_at: Scalar$DateTime | null;
  /**
   * The networks the release will be released to
   */
  networks: PublishAlbumSuccessV2_release_networks[];
  /**
   * Returns an album that is associated with the release, mutually exclusive with Release
   */
  album: PublishAlbumSuccessV2_release_album | null;
  user: PublishAlbumSuccessV2_release_user;
}

export interface PublishAlbumSuccessV2 {
  /**
   * find a release by id
   */
  release: PublishAlbumSuccessV2_release;
}

export interface PublishAlbumSuccessV2Variables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateEventNotificationSubscription
// ====================================================

export interface CreateEventNotificationSubscription_createEventNotificationSubscription_event_notification_subscription {
  __typename: "EventNotificationSubscription";
  id: string;
}

export interface CreateEventNotificationSubscription_createEventNotificationSubscription_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface CreateEventNotificationSubscription_createEventNotificationSubscription {
  __typename: "CreateEventNotificationSubscriptionPayload";
  event_notification_subscription: CreateEventNotificationSubscription_createEventNotificationSubscription_event_notification_subscription | null;
  errors: CreateEventNotificationSubscription_createEventNotificationSubscription_errors[] | null;
}

export interface CreateEventNotificationSubscription {
  /**
   * Default description for "CreateEventNotificationSubscription" mutation.
   */
  createEventNotificationSubscription: CreateEventNotificationSubscription_createEventNotificationSubscription;
}

export interface CreateEventNotificationSubscriptionVariables {
  input: CreateEventNotificationSubscriptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateAlbumEventNotificationSubscription
// ====================================================

export interface UpdateAlbumEventNotificationSubscription_updateEventNotificationSubscription_updated_event_notification_subscriptions {
  __typename: "EventNotificationSubscription";
  id: string;
}

export interface UpdateAlbumEventNotificationSubscription_updateEventNotificationSubscription_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface UpdateAlbumEventNotificationSubscription_updateEventNotificationSubscription {
  __typename: "UpdateEventNotificationSubscriptionPayload";
  updated_event_notification_subscriptions: UpdateAlbumEventNotificationSubscription_updateEventNotificationSubscription_updated_event_notification_subscriptions[] | null;
  errors: UpdateAlbumEventNotificationSubscription_updateEventNotificationSubscription_errors[] | null;
}

export interface UpdateAlbumEventNotificationSubscription {
  /**
   * Default description for "UpdateEventNotificationSubscription" mutation.
   */
  updateEventNotificationSubscription: UpdateAlbumEventNotificationSubscription_updateEventNotificationSubscription;
}

export interface UpdateAlbumEventNotificationSubscriptionVariables {
  input: UpdateEventNotificationSubscriptionInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumReleaseNotifications
// ====================================================

export interface AlbumReleaseNotifications_album_releases_event_notification_subscriptions {
  __typename: "EventNotificationSubscription";
  id: string;
  /**
   * Date the event notification was deactivated
   */
  deactivated_at: Scalar$DateTime | null;
  /**
   * How the user wants to be notified, e.g. email or sms
   */
  service_type: EventNotificationSubscriptionServiceType;
  /**
   * Where the notification should be sent
   */
  destination: string;
}

export interface AlbumReleaseNotifications_album_releases_networks {
  __typename: "Network";
  id: string;
  /**
   * Image URL for this network
   */
  image_url: string;
  name: string;
}

export interface AlbumReleaseNotifications_album_releases {
  __typename: "Release";
  id: string;
  event_notification_subscriptions: AlbumReleaseNotifications_album_releases_event_notification_subscriptions[];
  /**
   * The networks the release will be released to
   */
  networks: AlbumReleaseNotifications_album_releases_networks[];
}

export interface AlbumReleaseNotifications_album {
  __typename: "Album";
  id: string;
  /**
   * List of releases for the album
   */
  releases: AlbumReleaseNotifications_album_releases[];
}

export interface AlbumReleaseNotifications {
  /**
   * Find the current album by ID
   */
  album: AlbumReleaseNotifications_album;
}

export interface AlbumReleaseNotificationsVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateMetaPixelMutation
// ====================================================

export interface UpdateMetaPixelMutation_updateAlbumMetaPixel_album {
  __typename: "Album";
  id: string;
  /**
   * Meta's pixel ID for marketing and ad purchasing for album
   */
  pixel_eid: string | null;
}

export interface UpdateMetaPixelMutation_updateAlbumMetaPixel_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateMetaPixelMutation_updateAlbumMetaPixel {
  __typename: "UpdateAlbumMetaPixelPayload";
  album: UpdateMetaPixelMutation_updateAlbumMetaPixel_album | null;
  errors: UpdateMetaPixelMutation_updateAlbumMetaPixel_errors[] | null;
}

export interface UpdateMetaPixelMutation {
  /**
   * Default description for "UpdateAlbumMetaPixel" mutation.
   */
  updateAlbumMetaPixel: UpdateMetaPixelMutation_updateAlbumMetaPixel;
}

export interface UpdateMetaPixelMutationVariables {
  input: UpdateAlbumMetaPixelInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableSmartlinksQuery
// ====================================================

export interface AvailableSmartlinksQuery_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AvailableSmartlinksQuery_album_extra_links_file_upload {
  __typename: "FileUpload";
  id: string;
  title: string | null;
  extra_link_image_url: string | null;
}

export interface AvailableSmartlinksQuery_album_extra_links {
  __typename: "ExtraLink";
  id: string;
  title: string | null;
  description: string | null;
  type: ExtraLinkType | null;
  button_text: string | null;
  order: number;
  url: string | null;
  file_upload: AvailableSmartlinksQuery_album_extra_links_file_upload | null;
}

export interface AvailableSmartlinksQuery_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AvailableSmartlinksQuery_album_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
}

export interface AvailableSmartlinksQuery_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  label: string | null;
  name: string | null;
  /**
   * List of Collaborating Artists for the album this metadata is associated with
   */
  collaborating_artists: AvailableSmartlinksQuery_album_metadata_collaborating_artists[];
}

export interface AvailableSmartlinksQuery_album_partners_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface AvailableSmartlinksQuery_album_partners {
  __typename: "AlbumPartnerData";
  id: string;
  failed_at: Scalar$DateTime | null;
  is_live: boolean | null;
  order: number | null;
  /**
   * corresponding network object to this data
   */
  network: AvailableSmartlinksQuery_album_partners_network;
  partner_url: string;
  is_available_for_presave: boolean;
  link_action: AlbumPartnerDataLinkActionType;
  is_user_added: boolean | null;
}

export interface AvailableSmartlinksQuery_album {
  __typename: "Album";
  id: string;
  /**
   * Checks if there are any links to display on smartlinks
   */
  extra_links_enabled: boolean;
  /**
   * Extra links title shown on smartlinks page if enabled
   */
  extra_links_title: string | null;
  is_distributed: boolean;
  /**
   * boolean value to see if the album has tracks
   */
  has_tracks: boolean;
  private_url: string | null;
  slug_updated_at: Scalar$DateTime | null;
  slug: string | null;
  smartlink_url: string;
  smart_link_subdomain: string | null;
  /**
   * Meta's pixel ID for marketing and ad purchasing for album
   */
  pixel_eid: string | null;
  artist: AvailableSmartlinksQuery_album_artist;
  extra_links: AvailableSmartlinksQuery_album_extra_links[];
  file_upload: AvailableSmartlinksQuery_album_file_upload | null;
  metadata: AvailableSmartlinksQuery_album_metadata;
  /**
   * list of partners an album is released to
   */
  partners: AvailableSmartlinksQuery_album_partners[];
}

export interface AvailableSmartlinksQuery {
  /**
   * Find the current album by ID
   */
  album: AvailableSmartlinksQuery_album;
}

export interface AvailableSmartlinksQueryVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RescanSmartlinks
// ====================================================

export interface RescanSmartlinks_rescanSmartlinks_album_partners_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface RescanSmartlinks_rescanSmartlinks_album_partners {
  __typename: "AlbumPartnerData";
  id: string;
  failed_at: Scalar$DateTime | null;
  is_available_for_presave: boolean;
  is_live: boolean | null;
  is_user_added: boolean | null;
  link_action: AlbumPartnerDataLinkActionType;
  order: number | null;
  /**
   * corresponding network object to this data
   */
  network: RescanSmartlinks_rescanSmartlinks_album_partners_network;
  partner_url: string;
}

export interface RescanSmartlinks_rescanSmartlinks_album {
  __typename: "Album";
  id: string;
  /**
   * list of partners an album is released to
   */
  partners: RescanSmartlinks_rescanSmartlinks_album_partners[];
}

export interface RescanSmartlinks_rescanSmartlinks_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface RescanSmartlinks_rescanSmartlinks {
  __typename: "RescanSmartlinksPayload";
  album: RescanSmartlinks_rescanSmartlinks_album | null;
  errors: RescanSmartlinks_rescanSmartlinks_errors[] | null;
}

export interface RescanSmartlinks {
  /**
   * Manually re-scan all failed Album Partner Data for a specific album.
   */
  rescanSmartlinks: RescanSmartlinks_rescanSmartlinks;
}

export interface RescanSmartlinksVariables {
  input: RescanSmartlinksInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AlbumDetailsEditTrackListUpdateAlbumMediaIds
// ====================================================

export interface AlbumDetailsEditTrackListUpdateAlbumMediaIds_updateAlbumMediaIds_album {
  __typename: "Album";
  id: string;
}

export interface AlbumDetailsEditTrackListUpdateAlbumMediaIds_updateAlbumMediaIds_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AlbumDetailsEditTrackListUpdateAlbumMediaIds_updateAlbumMediaIds {
  __typename: "UpdateAlbumMediaIdsPayload";
  album: AlbumDetailsEditTrackListUpdateAlbumMediaIds_updateAlbumMediaIds_album | null;
  errors: AlbumDetailsEditTrackListUpdateAlbumMediaIds_updateAlbumMediaIds_errors[] | null;
}

export interface AlbumDetailsEditTrackListUpdateAlbumMediaIds {
  /**
   * Update an Album's associated media. This mutation returns an Album. Used when assigning tracks to an Album in the Album creation flow.
   */
  updateAlbumMediaIds: AlbumDetailsEditTrackListUpdateAlbumMediaIds_updateAlbumMediaIds;
}

export interface AlbumDetailsEditTrackListUpdateAlbumMediaIdsVariables {
  input: UpdateAlbumMediaIdsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsEditTrackListInitial
// ====================================================

export interface AlbumDetailsEditTrackListInitial_album_album_medias_cursor_album_medias_media {
  __typename: "Media";
  id: string;
}

export interface AlbumDetailsEditTrackListInitial_album_album_medias_cursor_album_medias {
  __typename: "AlbumMedia";
  id: string;
  media: AlbumDetailsEditTrackListInitial_album_album_medias_cursor_album_medias_media;
}

export interface AlbumDetailsEditTrackListInitial_album_album_medias_cursor {
  __typename: "AlbumMedias";
  /**
   * List of medias on an album
   */
  album_medias: AlbumDetailsEditTrackListInitial_album_album_medias_cursor_album_medias[];
}

export interface AlbumDetailsEditTrackListInitial_album {
  __typename: "Album";
  id: string;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: AlbumDetailsEditTrackListInitial_album_album_medias_cursor;
}

export interface AlbumDetailsEditTrackListInitial {
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsEditTrackListInitial_album;
}

export interface AlbumDetailsEditTrackListInitialVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsEditTrackListTrackList
// ====================================================

export interface AlbumDetailsEditTrackListTrackList_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AlbumDetailsEditTrackListTrackList_me_medias_v2_file_upload {
  __typename: "FileUpload";
  id: string;
  created_at: Scalar$DateTime | null;
  duration_in_ms: number | null;
}

export interface AlbumDetailsEditTrackListTrackList_me_medias_v2 {
  __typename: "Media";
  id: string;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
  metadata: AlbumDetailsEditTrackListTrackList_me_medias_v2_metadata | null;
  /**
   * Actual file associated to this media
   */
  file_upload: AlbumDetailsEditTrackListTrackList_me_medias_v2_file_upload | null;
}

export interface AlbumDetailsEditTrackListTrackList_me {
  __typename: "User";
  id: string;
  /**
   * Medias of the user
   */
  medias_v2: AlbumDetailsEditTrackListTrackList_me_medias_v2[];
}

export interface AlbumDetailsEditTrackListTrackList {
  /**
   * Get information about the currently logged in user
   */
  me: AlbumDetailsEditTrackListTrackList_me;
}

export interface AlbumDetailsEditTrackListTrackListVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsTracks
// ====================================================

export interface AlbumDetailsTracks_album {
  __typename: "Album";
  id: string;
  is_distributed: boolean;
}

export interface AlbumDetailsTracks {
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsTracks_album;
}

export interface AlbumDetailsTracksVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumDetailsTrackList
// ====================================================

export interface AlbumDetailsTrackList_album_album_medias_cursor_album_medias_media_file_upload {
  __typename: "FileUpload";
  id: string;
  duration_in_ms: number | null;
  created_at: Scalar$DateTime | null;
}

export interface AlbumDetailsTrackList_album_album_medias_cursor_album_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AlbumDetailsTrackList_album_album_medias_cursor_album_medias_media {
  __typename: "Media";
  id: string;
  /**
   * Actual file associated to this media
   */
  file_upload: AlbumDetailsTrackList_album_album_medias_cursor_album_medias_media_file_upload | null;
  metadata: AlbumDetailsTrackList_album_album_medias_cursor_album_medias_media_metadata | null;
}

export interface AlbumDetailsTrackList_album_album_medias_cursor_album_medias {
  __typename: "AlbumMedia";
  id: string;
  /**
   * track number associated with this media in an album
   */
  track_number: number;
  media: AlbumDetailsTrackList_album_album_medias_cursor_album_medias_media;
}

export interface AlbumDetailsTrackList_album_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
  /**
   * List of medias on an album
   */
  album_medias: AlbumDetailsTrackList_album_album_medias_cursor_album_medias[];
}

export interface AlbumDetailsTrackList_album {
  __typename: "Album";
  id: string;
  is_distributed: boolean;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: AlbumDetailsTrackList_album_album_medias_cursor;
}

export interface AlbumDetailsTrackList {
  /**
   * Find the current album by ID
   */
  album: AlbumDetailsTrackList_album;
}

export interface AlbumDetailsTrackListVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistDetailsConnectedAccounts
// ====================================================

export interface ArtistDetailsConnectedAccounts_media_subcategories {
  __typename: "MediaSubCategory";
  id: string;
  label: string;
}

export interface ArtistDetailsConnectedAccounts_artist_premium_socials_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface ArtistDetailsConnectedAccounts_artist_premium_socials_sync_config {
  __typename: "SyncConfig";
  id: string;
  /**
   * Default Subcategory for Social Sync is music
   */
  default_subcategory: string | null;
  /**
   * Human readable default subcategory
   */
  pretty_default_subcategory: string | null;
}

export interface ArtistDetailsConnectedAccounts_artist_premium_socials {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  created_at: Scalar$DateTime;
  /**
   * Network Object for a network associated with a social
   */
  network: ArtistDetailsConnectedAccounts_artist_premium_socials_network | null;
  /**
   * Flag to check if the social is syncing
   */
  is_syncing: boolean | null;
  /**
   * Flag for checking if the social needs reconnection
   */
  needs_reconnect: boolean;
  /**
   * Social Sync Config Object for a social that is opted for syncing
   */
  sync_config: ArtistDetailsConnectedAccounts_artist_premium_socials_sync_config | null;
  /**
   * Get the size of the thumbnail
   */
  thumbnail_url: string;
  /**
   * Title of this Social
   */
  title: string | null;
  /**
   * URL for a social
   */
  url: string | null;
}

export interface ArtistDetailsConnectedAccounts_artist_socials_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface ArtistDetailsConnectedAccounts_artist_socials_sync_config {
  __typename: "SyncConfig";
  id: string;
  /**
   * Default Subcategory for Social Sync is music
   */
  default_subcategory: string | null;
  /**
   * Human readable default subcategory
   */
  pretty_default_subcategory: string | null;
}

export interface ArtistDetailsConnectedAccounts_artist_socials {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  created_at: Scalar$DateTime;
  /**
   * Network Object for a network associated with a social
   */
  network: ArtistDetailsConnectedAccounts_artist_socials_network | null;
  /**
   * Flag to check if the social is syncing
   */
  is_syncing: boolean | null;
  /**
   * Flag for checking if the social needs reconnection
   */
  needs_reconnect: boolean;
  /**
   * Social Sync Config Object for a social that is opted for syncing
   */
  sync_config: ArtistDetailsConnectedAccounts_artist_socials_sync_config | null;
  /**
   * Get the size of the thumbnail
   */
  thumbnail_url: string;
  /**
   * Title of this Social
   */
  title: string | null;
  /**
   * URL for a social
   */
  url: string | null;
}

export interface ArtistDetailsConnectedAccounts_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  can_connect_spotify: boolean;
  youtube_connect_url: string;
  youtube_reconnect_url: string;
  facebook_connect_url: string;
  facebook_reconnect_url: string;
  instagram_connect_url: string;
  /**
   * URL to connect Twitter account to this artist
   */
  twitter_connect_url: string;
  twitter_reconnect_url: string;
  /**
   * URL to connect Spotify account to this artist
   */
  spotify_connect_url: string;
  /**
   * URL to connect Audiomack account to this artist
   */
  audiomack_connect_url: string;
  /**
   * List of premium socials deployable or not for an artist
   */
  premium_socials: ArtistDetailsConnectedAccounts_artist_premium_socials[];
  /**
   * List of socials deployable or not for an artist
   */
  socials: ArtistDetailsConnectedAccounts_artist_socials[];
}

export interface ArtistDetailsConnectedAccounts {
  /**
   * Gets all the available media sub categories for our system
   */
  media_subcategories: ArtistDetailsConnectedAccounts_media_subcategories[];
  /**
   * Find one of the current user's Artists by ID
   */
  artist: ArtistDetailsConnectedAccounts_artist;
}

export interface ArtistDetailsConnectedAccountsVariables {
  id: string;
  successPath: string;
  errorPath: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DisconnectSocialAccountFromArtist
// ====================================================

export interface DisconnectSocialAccountFromArtist_disconnectSocialAccountFromArtist_social_artist {
  __typename: "Artist";
  id: string;
}

export interface DisconnectSocialAccountFromArtist_disconnectSocialAccountFromArtist_social {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  /**
   * Flag to check if this is archived
   */
  archived: boolean;
  /**
   * Artist connected to this social
   */
  artist: DisconnectSocialAccountFromArtist_disconnectSocialAccountFromArtist_social_artist;
}

export interface DisconnectSocialAccountFromArtist_disconnectSocialAccountFromArtist_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface DisconnectSocialAccountFromArtist_disconnectSocialAccountFromArtist {
  __typename: "DisconnectSocialAccountFromArtistPayload";
  social: DisconnectSocialAccountFromArtist_disconnectSocialAccountFromArtist_social | null;
  errors: DisconnectSocialAccountFromArtist_disconnectSocialAccountFromArtist_errors[] | null;
}

export interface DisconnectSocialAccountFromArtist {
  /**
   * Disconnect a social or premium account from an Artist.
   */
  disconnectSocialAccountFromArtist: DisconnectSocialAccountFromArtist_disconnectSocialAccountFromArtist;
}

export interface DisconnectSocialAccountFromArtistVariables {
  input: DisconnectSocialAccountFromArtistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateSocialSyncMutation
// ====================================================

export interface UpdateSocialSyncMutation_updateSocialSync_social_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface UpdateSocialSyncMutation_updateSocialSync_social_sync_config {
  __typename: "SyncConfig";
  id: string;
  /**
   * Default Subcategory for Social Sync is music
   */
  default_subcategory: string | null;
  /**
   * Human readable default subcategory
   */
  pretty_default_subcategory: string | null;
}

export interface UpdateSocialSyncMutation_updateSocialSync_social {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  /**
   * Network Object for a network associated with a social
   */
  network: UpdateSocialSyncMutation_updateSocialSync_social_network | null;
  /**
   * Flag to check if the social is syncing
   */
  is_syncing: boolean | null;
  /**
   * Social Sync Config Object for a social that is opted for syncing
   */
  sync_config: UpdateSocialSyncMutation_updateSocialSync_social_sync_config | null;
}

export interface UpdateSocialSyncMutation_updateSocialSync_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateSocialSyncMutation_updateSocialSync {
  __typename: "UpdateSocialSyncPayload";
  /**
   * Social associated with the sync config
   */
  social: UpdateSocialSyncMutation_updateSocialSync_social | null;
  errors: UpdateSocialSyncMutation_updateSocialSync_errors[] | null;
}

export interface UpdateSocialSyncMutation {
  /**
   * Update the sync settings for a specific social networks to either allow or disallow content syncing.
   */
  updateSocialSync: UpdateSocialSyncMutation_updateSocialSync;
}

export interface UpdateSocialSyncMutationVariables {
  input: UpdateSocialSyncInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistDetailsDefaultMetadata
// ====================================================

export interface ArtistDetailsDefaultMetadata_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  default_label_name: string | null;
  default_copyright_name: string | null;
  default_copyright_year: number | null;
  default_genre: string | null;
  default_secondary_genre: string | null;
  /**
   * Name of the language that corresponds to the default language code
   */
  pretty_default_language: string | null;
}

export interface ArtistDetailsDefaultMetadata {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: ArtistDetailsDefaultMetadata_artist;
}

export interface ArtistDetailsDefaultMetadataVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistDetailsEditDefaultMetadata
// ====================================================

export interface ArtistDetailsEditDefaultMetadata_artist {
  __typename: "Artist";
  id: string;
  default_label_name: string | null;
  default_copyright_name: string | null;
  default_copyright_year: number | null;
  /**
   * Default genre enum for this artist
   */
  default_genre_enum: Genre | null;
  /**
   * Default secondary genre enum for this artist
   */
  default_secondary_genre_enum: Genre | null;
  /**
   * Default language code enum for this artist
   */
  default_language_code_enum: MediaLanguageCode | null;
}

export interface ArtistDetailsEditDefaultMetadata_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface ArtistDetailsEditDefaultMetadata_languages {
  __typename: "MediaLanguage";
  id: string;
  name: string;
}

export interface ArtistDetailsEditDefaultMetadata {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: ArtistDetailsEditDefaultMetadata_artist;
  /**
   * Gets all the available Genres from our system
   */
  genres: ArtistDetailsEditDefaultMetadata_genres[];
  /**
   * Gets all the available languages for an audio track.
   */
  languages: ArtistDetailsEditDefaultMetadata_languages[];
}

export interface ArtistDetailsEditDefaultMetadataVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: EditDefaultMetadataMutation
// ====================================================

export interface EditDefaultMetadataMutation_updateArtist_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  default_label_name: string | null;
  default_copyright_name: string | null;
  default_copyright_year: number | null;
  /**
   * Default genre enum for this artist
   */
  default_genre_enum: Genre | null;
  /**
   * Default secondary genre enum for this artist
   */
  default_secondary_genre_enum: Genre | null;
  /**
   * Default language code enum for this artist
   */
  default_language_code_enum: MediaLanguageCode | null;
  default_genre: string | null;
  default_secondary_genre: string | null;
  /**
   * Name of the language that corresponds to the default language code
   */
  pretty_default_language: string | null;
}

export interface EditDefaultMetadataMutation_updateArtist_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface EditDefaultMetadataMutation_updateArtist {
  __typename: "UpdateArtistPayload";
  artist: EditDefaultMetadataMutation_updateArtist_artist | null;
  errors: EditDefaultMetadataMutation_updateArtist_errors[] | null;
}

export interface EditDefaultMetadataMutation {
  /**
   * Update an Artist. This mutation returns an Artist. Used when a user updates the information on an artist.
   */
  updateArtist: EditDefaultMetadataMutation_updateArtist;
}

export interface EditDefaultMetadataMutationVariables {
  input: UpdateArtistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistDetailsHeader
// ====================================================

export interface ArtistDetailsHeader_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  thumbnail_url: string;
}

export interface ArtistDetailsHeader {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: ArtistDetailsHeader_artist;
}

export interface ArtistDetailsHeaderVariables {
  artistID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistDetailsOverview
// ====================================================

export interface ArtistDetailsOverview_artist_custom_links {
  __typename: "ArtistCustomLink";
  source: string;
  url: string | null;
}

export interface ArtistDetailsOverview_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  /**
   * Biography about the artist
   */
  bio: string;
  bullet_points: string[];
  thumbnail_url: string;
  custom_links: ArtistDetailsOverview_artist_custom_links[];
}

export interface ArtistDetailsOverview_me {
  __typename: "User";
  id: string;
}

export interface ArtistDetailsOverview {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: ArtistDetailsOverview_artist;
  /**
   * Get information about the currently logged in user
   */
  me: ArtistDetailsOverview_me;
}

export interface ArtistDetailsOverviewVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateArtistProfile
// ====================================================

export interface UpdateArtistProfile_updateArtist_artist {
  __typename: "Artist";
  id: string;
  /**
   * Biography about the artist
   */
  bio: string;
  thumbnail_url: string;
}

export interface UpdateArtistProfile_updateArtist_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateArtistProfile_updateArtist {
  __typename: "UpdateArtistPayload";
  artist: UpdateArtistProfile_updateArtist_artist | null;
  errors: UpdateArtistProfile_updateArtist_errors[] | null;
}

export interface UpdateArtistProfile {
  /**
   * Update an Artist. This mutation returns an Artist. Used when a user updates the information on an artist.
   */
  updateArtist: UpdateArtistProfile_updateArtist;
}

export interface UpdateArtistProfileVariables {
  input: UpdateArtistInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistsList
// ====================================================

export interface ArtistsList_me_artists_user {
  __typename: "User";
  id: string;
  full_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface ArtistsList_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * When the artist was created
   */
  created_at: Scalar$DateTime;
  /**
   * Name of an artist
   */
  name: string;
  user_id: string;
  thumbnailUrl: string;
  /**
   * number of social that are active for an artist
   */
  activeSocialCount: number;
  /**
   * number of premium accounts that are active for an artist
   */
  activePremiumCount: number;
  /**
   * Flag to check if any socials needs reconnection
   */
  facebookNeedsReconnect: boolean;
  /**
   * Flag to check if any socials needs reconnection
   */
  youtubeNeedsReconnect: boolean;
  /**
   * Flag to check if any socials needs reconnection
   */
  instagramNeedsReconnect: boolean;
  user: ArtistsList_me_artists_user;
}

export interface ArtistsList_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Flag for a user has a feature
   */
  hasFeatureSkipSocials: boolean;
  /**
   * Count of artists for the user
   */
  artists_count: number;
  /**
   * List out all Artists for the current user
   */
  artists: ArtistsList_me_artists[];
}

export interface ArtistsList {
  /**
   * Get information about the currently logged in user
   */
  me: ArtistsList_me;
}

export interface ArtistsListVariables {
  input: ArtistsCursorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SubmitCaptcha
// ====================================================

export interface SubmitCaptcha_submitCaptcha_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SubmitCaptcha_submitCaptcha {
  __typename: "SubmitCaptchaPayload";
  /**
   * url to return to
   */
  return_to: string | null;
  errors: SubmitCaptcha_submitCaptcha_errors[] | null;
}

export interface SubmitCaptcha {
  /**
   * Handles the user solving a captcha from the takeover screen.
   */
  submitCaptcha: SubmitCaptcha_submitCaptcha;
}

export interface SubmitCaptchaVariables {
  input: SubmitCaptchaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumsList
// ====================================================

export interface AlbumsList_me_albums_v2_cursor_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AlbumsList_me_albums_v2_cursor_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AlbumsList_me_albums_v2_cursor_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AlbumsList_me_albums_v2_cursor_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
}

export interface AlbumsList_me_albums_v2_cursor {
  __typename: "Album";
  artist: AlbumsList_me_albums_v2_cursor_artist;
  file_upload: AlbumsList_me_albums_v2_cursor_file_upload | null;
  id: string;
  current_user_pretty_album_revenue: string;
  created_at: Scalar$DateTime;
  user_id: string;
  archived: boolean;
  metadata: AlbumsList_me_albums_v2_cursor_metadata;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: AlbumsList_me_albums_v2_cursor_album_medias_cursor;
  /**
   * Do any associated releases have notifications enabled?
   */
  has_notification_subscriptions_enabled: boolean;
}

export interface AlbumsList_me {
  __typename: "User";
  id: string;
  /**
   * Count of albums for the user
   */
  albums_v2_count: number;
  /**
   * List out all the albums for the current user
   */
  albums_v2_cursor: AlbumsList_me_albums_v2_cursor[];
}

export interface AlbumsList {
  /**
   * Get information about the currently logged in user
   */
  me: AlbumsList_me;
}

export interface AlbumsListVariables {
  input?: AlbumsCursorInput | null;
  size?: ThumbnailSize | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllAlbumIds
// ====================================================

export interface AllAlbumIds_me_albums_v2_cursor {
  __typename: "Album";
  id: string;
}

export interface AllAlbumIds_me {
  __typename: "User";
  id: string;
  /**
   * Count of albums for the user
   */
  albums_v2_count: number;
  /**
   * List out all the albums for the current user
   */
  albums_v2_cursor: AllAlbumIds_me_albums_v2_cursor[];
}

export interface AllAlbumIds {
  /**
   * Get information about the currently logged in user
   */
  me: AllAlbumIds_me;
}

export interface AllAlbumIdsVariables {
  input?: AlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CompositionMediasList
// ====================================================

export interface CompositionMediasList_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface CompositionMediasList_me_medias_v2_metadata_composition_rights_composer {
  __typename: "Composer";
  id: string;
  full_name: string;
}

export interface CompositionMediasList_me_medias_v2_metadata_composition_rights {
  __typename: "Right";
  id: string;
  /**
   * Composer object for the right
   */
  composer: CompositionMediasList_me_medias_v2_metadata_composition_rights_composer | null;
}

export interface CompositionMediasList_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * Returns an array if composition rights that are asserted by the user of the media associated with this metadata
   */
  composition_rights: CompositionMediasList_me_medias_v2_metadata_composition_rights[];
}

export interface CompositionMediasList_me_medias_v2 {
  __typename: "Media";
  artist: CompositionMediasList_me_medias_v2_artist | null;
  id: string;
  current_user_pretty_media_revenue: string;
  created_at: Scalar$DateTime;
  type: MediaType;
  metadata: CompositionMediasList_me_medias_v2_metadata | null;
}

export interface CompositionMediasList_me {
  __typename: "User";
  id: string;
  /**
   * Count of medias
   */
  medias_v2_count: number;
  /**
   * Medias of the user
   */
  medias_v2: CompositionMediasList_me_medias_v2[];
}

export interface CompositionMediasList {
  /**
   * Get information about the currently logged in user
   */
  me: CompositionMediasList_me;
}

export interface CompositionMediasListVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediasList
// ====================================================

export interface MediasList_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface MediasList_me_medias_v2_user {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface MediasList_me_medias_v2_metadata_policies_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
  name: string;
  /**
   * The values can either be distribute or monetize
   */
  service: string | null;
}

export interface MediasList_me_medias_v2_metadata_policies {
  __typename: "Policy";
  id: string;
  release_confirmed: boolean;
  type: string;
  /**
   * Deployment status of the policy for this metadata policy
   */
  status: MediaPolicyStatus;
  /**
   * Specifies the time when status update has happened
   */
  status_changed_at: Scalar$DateTime;
  network: MediasList_me_medias_v2_metadata_policies_network;
}

export interface MediasList_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * Flag for the media associated with this metadata has full rights to the current user
   */
  has_full_rights: boolean;
  /**
   * Flag for the media associated with this metadata has partial rights to the current user
   */
  has_partial_rights: boolean;
  /**
   * Returns an array of policies 
   */
  policies: MediasList_me_medias_v2_metadata_policies[];
}

export interface MediasList_me_medias_v2 {
  __typename: "Media";
  /**
   * Count of albums for the media
   */
  albums_v2_count: number;
  artist: MediasList_me_medias_v2_artist | null;
  id: string;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  current_user_pretty_media_revenue: string;
  created_at: Scalar$DateTime;
  child_type: string | null;
  desktopThumbnailUrl: string | null;
  mobileThumbnailUrl: string | null;
  original_url: string | null;
  mp4_url: string | null;
  generator: string | null;
  type: MediaType;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
  user: MediasList_me_medias_v2_user;
  metadata: MediasList_me_medias_v2_metadata | null;
}

export interface MediasList_me {
  __typename: "User";
  id: string;
  /**
   * If the user has received any archived payee asset splits
   */
  has_received_archived_payee_asset_split: boolean;
  /**
   * Count of medias
   */
  medias_v2_count: number;
  /**
   * Medias of the user
   */
  medias_v2: MediasList_me_medias_v2[];
}

export interface MediasList_i18n_UserArchive {
  __typename: "UserArchive";
  confirm: string;
  error: string;
}

export interface MediasList_i18n {
  __typename: "I18n";
  UserArchive: MediasList_i18n_UserArchive;
}

export interface MediasList {
  /**
   * Get information about the currently logged in user
   */
  me: MediasList_me;
  /**
   * Get translation strings for screens and components.
   */
  i18n: MediasList_i18n;
}

export interface MediasListVariables {
  input?: MediasV2CursorInput | null;
  albumInput?: AlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SharedMediasList
// ====================================================

export interface SharedMediasList_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface SharedMediasList_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface SharedMediasList_me_medias_v2_user {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface SharedMediasList_me_medias_v2 {
  __typename: "Media";
  id: string;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  created_at: Scalar$DateTime;
  desktopThumbnailUrl: string | null;
  mobileThumbnailUrl: string | null;
  mp4_url: string | null;
  generator: string | null;
  /**
   * The time when the payee asset split associated with the media was terminated
   */
  payee_terminated_at: Scalar$DateTime | null;
  /**
   * Checks if the user of this media archived
   */
  user_archived: boolean;
  /**
   * The amount of balance accumulated by the current user on the shared media. Note that all assets are considered, not only MV & SR.
   */
  shared_earnings: number | null;
  /**
   * Portion of sound recording rights asserted for the current user
   */
  shared_sound_recording_percentage: number | null;
  /**
   * Portion of music video rights asserted for the current user
   */
  shared_music_video_percentage: number | null;
  /**
   * Portion of web rights asserted for the current user
   */
  shared_web_percentage: number | null;
  artist: SharedMediasList_me_medias_v2_artist | null;
  metadata: SharedMediasList_me_medias_v2_metadata | null;
  user: SharedMediasList_me_medias_v2_user;
}

export interface SharedMediasList_me {
  __typename: "User";
  id: string;
  /**
   * If the user has received any archived payee asset splits
   */
  has_received_archived_payee_asset_split: boolean;
  /**
   * Count of medias
   */
  medias_v2_count: number;
  /**
   * Medias of the user
   */
  medias_v2: SharedMediasList_me_medias_v2[];
}

export interface SharedMediasList_i18n_UserArchive {
  __typename: "UserArchive";
  confirm: string;
  error: string;
}

export interface SharedMediasList_i18n {
  __typename: "I18n";
  UserArchive: SharedMediasList_i18n_UserArchive;
}

export interface SharedMediasList {
  /**
   * Get information about the currently logged in user
   */
  me: SharedMediasList_me;
  /**
   * Get translation strings for screens and components.
   */
  i18n: SharedMediasList_i18n;
}

export interface SharedMediasListVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AllMediaIds
// ====================================================

export interface AllMediaIds_me_medias_v2 {
  __typename: "Media";
  id: string;
}

export interface AllMediaIds_me {
  __typename: "User";
  id: string;
  /**
   * Count of medias
   */
  medias_v2_count: number;
  /**
   * Medias of the user
   */
  medias_v2: AllMediaIds_me_medias_v2[];
}

export interface AllMediaIds {
  /**
   * Get information about the currently logged in user
   */
  me: AllMediaIds_me;
}

export interface AllMediaIdsVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VideosSideBar
// ====================================================

export interface VideosSideBar_me {
  __typename: "User";
  id: string;
  /**
   * User should not be shown anything related to Vydia plan
   */
  has_hidden_payment_plan: boolean;
  /**
   * Count of medias
   */
  youtube_sync_medias_count: number;
  /**
   * Count of medias
   */
  facebook_sync_medias_count: number;
  /**
   * Count of medias
   */
  instagram_sync_medias_count: number;
  /**
   * Count of medias
   */
  music_medias_count: number;
  /**
   * Count of medias
   */
  other_medias_count: number;
  /**
   * Count of medias
   */
  a2v_medias_count: number;
  /**
   * Count of medias
   */
  unprotected_medias_count: number;
  /**
   * Count of medias
   */
  shared_with_me_medias_count: number;
  /**
   * Count of medias
   */
  audio_type_medias_count: number;
}

export interface VideosSideBar {
  observer_signed_in: boolean;
  /**
   * Get information about the currently logged in user
   */
  me: VideosSideBar_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: claNotice
// ====================================================

export interface claNotice_me {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user has ever signed an agreement OR Whether or not a user has signed a specific agreement by type
   */
  has_signed_agreement: boolean;
}

export interface claNotice {
  /**
   * Get information about the currently logged in user
   */
  me: claNotice_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteDataMutation
// ====================================================

export interface DeleteDataMutation_deleteData_user {
  __typename: "User";
  id: string;
  /**
   * reasons for delete data request
   */
  delete_data: string[];
}

export interface DeleteDataMutation_deleteData_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface DeleteDataMutation_deleteData {
  __typename: "DeleteDataPayload";
  user: DeleteDataMutation_deleteData_user | null;
  errors: DeleteDataMutation_deleteData_errors[] | null;
}

export interface DeleteDataMutation {
  /**
   * Create a data deletion request. This mutation returns a User object. Used when a user requests that their account be wiped.
   */
  deleteData: DeleteDataMutation_deleteData;
}

export interface DeleteDataMutationVariables {
  input: DeleteDataInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DealsList
// ====================================================

export interface DealsList_me {
  __typename: "User";
  id: string;
  is_admin: boolean;
}

export interface DealsList_deals_hubspot_deal {
  __typename: "HubspotDeal";
  id: string;
  dealname: string;
  url: string;
}

export interface DealsList_deals_advances {
  __typename: "DealAdvanceItem";
  id: string;
  /**
   * The month of the advance (zero-index)
   */
  month: number;
  /**
   * The amount of the advance in dollars
   */
  amount_dollars: number;
}

export interface DealsList_deals_latest_review_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface DealsList_deals_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface DealsList_deals {
  __typename: "Deal";
  id: string;
  /**
   * When the deal was archived
   */
  archived_at: Scalar$DateTime | null;
  /**
   * The predicted borrower recoupment period in months. E.g. how long before borrower has paid back their loan
   */
  borrower_recoupment_period_months: number;
  /**
   * The client's split percent after recoupment
   */
  client_after_recoupment_split_percent: number;
  /**
   * The client's recoupment split percent during recoupment
   */
  client_recoupment_split_percent: number;
  /**
   * When the deal was first drafted
   */
  created_at: Scalar$DateTime;
  /**
   * The predicted internal rate of return (IRR) of the deal
   */
  irr: number;
  /**
   * The HubSpot deal record associated with this deal
   */
  hubspot_deal: DealsList_deals_hubspot_deal | null;
  /**
   * The reviewer may leave a note describing their reasoning
   */
  latest_review_note: string | null;
  /**
   * The latest review status of the deal
   */
  latest_review_status: DealReviewStatus;
  /**
   * The predicted lender payback period in months. E.g. how long before lender breaks even
   */
  lender_payback_period_months: number;
  /**
   * The predicted multiple on invested capital (MOIC) of the deal
   */
  moic: number;
  /**
   * The estimated monthly gross cash flow in dollars at the time of drafting the deal
   */
  monthly_gross_cash_flow_dollars: number;
  /**
   * The number of months to delay the monthly growth/decline rate
   */
  monthly_growth_rate_delay_months: number;
  /**
   * The predicted monthly growth/decline rate of the content each month
   */
  monthly_growth_rate_percent: number;
  /**
   * The net profit in dollars that the lender is predicted to earn by the time that the borrower has paid back the recoupment.
   */
  profit_thru_recoupment: number | null;
  /**
   * The net profit in dollars that the lender is predicted to earn by the end of the deal term.
   */
  profit_thru_term: number | null;
  /**
   * The term in months for the deal
   */
  term_months: number;
  /**
   * The title of the deal as entered by the creator
   */
  title: string;
  /**
   * When the deal was last updated
   */
  updated_at: Scalar$DateTime;
  media_id: string | null;
  organization_id: string | null;
  user_id: string | null;
  /**
   * The advances of the deal
   */
  advances: DealsList_deals_advances[];
  /**
   * The latest review author of the deal. Null if no reviews have been made yet.
   */
  latest_review_author: DealsList_deals_latest_review_author | null;
  /**
   * The author user of the deal
   */
  author: DealsList_deals_author;
}

export interface DealsList {
  /**
   * Get information about the currently logged in user
   */
  me: DealsList_me;
  /**
   * Count Deals via cursor (Admin Field)
   */
  deals_count: number;
  /**
   * List Deals via cursor (Admin Field)
   */
  deals: DealsList_deals[];
}

export interface DealsListVariables {
  input: DealsCursorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DealEdit
// ====================================================

export interface DealEdit_deal_hubspot_deal {
  __typename: "HubspotDeal";
  id: string;
  dealname: string;
  url: string;
}

export interface DealEdit_deal_advances {
  __typename: "DealAdvanceItem";
  id: string;
  /**
   * The month of the advance (zero-index)
   */
  month: number;
  /**
   * The amount of the advance in dollars
   */
  amount_dollars: number;
}

export interface DealEdit_deal_latest_review_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface DealEdit_deal_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface DealEdit_deal {
  __typename: "Deal";
  id: string;
  /**
   * When the deal was archived
   */
  archived_at: Scalar$DateTime | null;
  /**
   * The predicted borrower recoupment period in months. E.g. how long before borrower has paid back their loan
   */
  borrower_recoupment_period_months: number;
  /**
   * The client's split percent after recoupment
   */
  client_after_recoupment_split_percent: number;
  /**
   * The client's recoupment split percent during recoupment
   */
  client_recoupment_split_percent: number;
  /**
   * When the deal was first drafted
   */
  created_at: Scalar$DateTime;
  /**
   * The predicted internal rate of return (IRR) of the deal
   */
  irr: number;
  /**
   * The HubSpot deal record associated with this deal
   */
  hubspot_deal: DealEdit_deal_hubspot_deal | null;
  /**
   * The reviewer may leave a note describing their reasoning
   */
  latest_review_note: string | null;
  /**
   * The latest review status of the deal
   */
  latest_review_status: DealReviewStatus;
  /**
   * The predicted lender payback period in months. E.g. how long before lender breaks even
   */
  lender_payback_period_months: number;
  /**
   * The predicted multiple on invested capital (MOIC) of the deal
   */
  moic: number;
  /**
   * The estimated monthly gross cash flow in dollars at the time of drafting the deal
   */
  monthly_gross_cash_flow_dollars: number;
  /**
   * The number of months to delay the monthly growth/decline rate
   */
  monthly_growth_rate_delay_months: number;
  /**
   * The predicted monthly growth/decline rate of the content each month
   */
  monthly_growth_rate_percent: number;
  /**
   * The net profit in dollars that the lender is predicted to earn by the time that the borrower has paid back the recoupment.
   */
  profit_thru_recoupment: number | null;
  /**
   * The net profit in dollars that the lender is predicted to earn by the end of the deal term.
   */
  profit_thru_term: number | null;
  /**
   * The term in months for the deal
   */
  term_months: number;
  /**
   * The title of the deal as entered by the creator
   */
  title: string;
  /**
   * When the deal was last updated
   */
  updated_at: Scalar$DateTime;
  media_id: string | null;
  organization_id: string | null;
  user_id: string | null;
  /**
   * The advances of the deal
   */
  advances: DealEdit_deal_advances[];
  /**
   * The latest review author of the deal. Null if no reviews have been made yet.
   */
  latest_review_author: DealEdit_deal_latest_review_author | null;
  /**
   * The author user of the deal
   */
  author: DealEdit_deal_author;
}

export interface DealEdit {
  /**
   * Find a deal by ID
   */
  deal: DealEdit_deal;
}

export interface DealEditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: HubspotDeals
// ====================================================

export interface HubspotDeals_hubspot_deals_cursor {
  __typename: "HubspotDeal";
  id: string;
  dealname: string;
  url: string;
}

export interface HubspotDeals {
  /**
   * Search for deals in Hubspot by name
   */
  hubspot_deals_cursor: HubspotDeals_hubspot_deals_cursor[];
}

export interface HubspotDealsVariables {
  input: HubspotDealsCursorInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateDeal
// ====================================================

export interface CreateDeal_createDeal_deal_hubspot_deal {
  __typename: "HubspotDeal";
  id: string;
  dealname: string;
  url: string;
}

export interface CreateDeal_createDeal_deal_advances {
  __typename: "DealAdvanceItem";
  id: string;
  /**
   * The month of the advance (zero-index)
   */
  month: number;
  /**
   * The amount of the advance in dollars
   */
  amount_dollars: number;
}

export interface CreateDeal_createDeal_deal_latest_review_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface CreateDeal_createDeal_deal_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface CreateDeal_createDeal_deal {
  __typename: "Deal";
  id: string;
  /**
   * When the deal was archived
   */
  archived_at: Scalar$DateTime | null;
  /**
   * The predicted borrower recoupment period in months. E.g. how long before borrower has paid back their loan
   */
  borrower_recoupment_period_months: number;
  /**
   * The client's split percent after recoupment
   */
  client_after_recoupment_split_percent: number;
  /**
   * The client's recoupment split percent during recoupment
   */
  client_recoupment_split_percent: number;
  /**
   * When the deal was first drafted
   */
  created_at: Scalar$DateTime;
  /**
   * The predicted internal rate of return (IRR) of the deal
   */
  irr: number;
  /**
   * The HubSpot deal record associated with this deal
   */
  hubspot_deal: CreateDeal_createDeal_deal_hubspot_deal | null;
  /**
   * The reviewer may leave a note describing their reasoning
   */
  latest_review_note: string | null;
  /**
   * The latest review status of the deal
   */
  latest_review_status: DealReviewStatus;
  /**
   * The predicted lender payback period in months. E.g. how long before lender breaks even
   */
  lender_payback_period_months: number;
  /**
   * The predicted multiple on invested capital (MOIC) of the deal
   */
  moic: number;
  /**
   * The estimated monthly gross cash flow in dollars at the time of drafting the deal
   */
  monthly_gross_cash_flow_dollars: number;
  /**
   * The number of months to delay the monthly growth/decline rate
   */
  monthly_growth_rate_delay_months: number;
  /**
   * The predicted monthly growth/decline rate of the content each month
   */
  monthly_growth_rate_percent: number;
  /**
   * The net profit in dollars that the lender is predicted to earn by the time that the borrower has paid back the recoupment.
   */
  profit_thru_recoupment: number | null;
  /**
   * The net profit in dollars that the lender is predicted to earn by the end of the deal term.
   */
  profit_thru_term: number | null;
  /**
   * The term in months for the deal
   */
  term_months: number;
  /**
   * The title of the deal as entered by the creator
   */
  title: string;
  /**
   * When the deal was last updated
   */
  updated_at: Scalar$DateTime;
  media_id: string | null;
  organization_id: string | null;
  user_id: string | null;
  /**
   * The advances of the deal
   */
  advances: CreateDeal_createDeal_deal_advances[];
  /**
   * The latest review author of the deal. Null if no reviews have been made yet.
   */
  latest_review_author: CreateDeal_createDeal_deal_latest_review_author | null;
  /**
   * The author user of the deal
   */
  author: CreateDeal_createDeal_deal_author;
}

export interface CreateDeal_createDeal_errors {
  __typename: "UserError";
  resource_id: string | null;
  resource_type: string | null;
  key: string | null;
  messages: string[];
}

export interface CreateDeal_createDeal {
  __typename: "CreateDealPayload";
  deal: CreateDeal_createDeal_deal | null;
  errors: CreateDeal_createDeal_errors[] | null;
}

export interface CreateDeal {
  /**
   * Create a Deal
   */
  createDeal: CreateDeal_createDeal;
}

export interface CreateDealVariables {
  input: CreateDealInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateDeal
// ====================================================

export interface UpdateDeal_updateDeal_deal_hubspot_deal {
  __typename: "HubspotDeal";
  id: string;
  dealname: string;
  url: string;
}

export interface UpdateDeal_updateDeal_deal_advances {
  __typename: "DealAdvanceItem";
  id: string;
  /**
   * The month of the advance (zero-index)
   */
  month: number;
  /**
   * The amount of the advance in dollars
   */
  amount_dollars: number;
}

export interface UpdateDeal_updateDeal_deal_latest_review_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface UpdateDeal_updateDeal_deal_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface UpdateDeal_updateDeal_deal {
  __typename: "Deal";
  id: string;
  /**
   * When the deal was archived
   */
  archived_at: Scalar$DateTime | null;
  /**
   * The predicted borrower recoupment period in months. E.g. how long before borrower has paid back their loan
   */
  borrower_recoupment_period_months: number;
  /**
   * The client's split percent after recoupment
   */
  client_after_recoupment_split_percent: number;
  /**
   * The client's recoupment split percent during recoupment
   */
  client_recoupment_split_percent: number;
  /**
   * When the deal was first drafted
   */
  created_at: Scalar$DateTime;
  /**
   * The predicted internal rate of return (IRR) of the deal
   */
  irr: number;
  /**
   * The HubSpot deal record associated with this deal
   */
  hubspot_deal: UpdateDeal_updateDeal_deal_hubspot_deal | null;
  /**
   * The reviewer may leave a note describing their reasoning
   */
  latest_review_note: string | null;
  /**
   * The latest review status of the deal
   */
  latest_review_status: DealReviewStatus;
  /**
   * The predicted lender payback period in months. E.g. how long before lender breaks even
   */
  lender_payback_period_months: number;
  /**
   * The predicted multiple on invested capital (MOIC) of the deal
   */
  moic: number;
  /**
   * The estimated monthly gross cash flow in dollars at the time of drafting the deal
   */
  monthly_gross_cash_flow_dollars: number;
  /**
   * The number of months to delay the monthly growth/decline rate
   */
  monthly_growth_rate_delay_months: number;
  /**
   * The predicted monthly growth/decline rate of the content each month
   */
  monthly_growth_rate_percent: number;
  /**
   * The net profit in dollars that the lender is predicted to earn by the time that the borrower has paid back the recoupment.
   */
  profit_thru_recoupment: number | null;
  /**
   * The net profit in dollars that the lender is predicted to earn by the end of the deal term.
   */
  profit_thru_term: number | null;
  /**
   * The term in months for the deal
   */
  term_months: number;
  /**
   * The title of the deal as entered by the creator
   */
  title: string;
  /**
   * When the deal was last updated
   */
  updated_at: Scalar$DateTime;
  media_id: string | null;
  organization_id: string | null;
  user_id: string | null;
  /**
   * The advances of the deal
   */
  advances: UpdateDeal_updateDeal_deal_advances[];
  /**
   * The latest review author of the deal. Null if no reviews have been made yet.
   */
  latest_review_author: UpdateDeal_updateDeal_deal_latest_review_author | null;
  /**
   * The author user of the deal
   */
  author: UpdateDeal_updateDeal_deal_author;
}

export interface UpdateDeal_updateDeal_errors {
  __typename: "UserError";
  resource_id: string | null;
  resource_type: string | null;
  key: string | null;
  messages: string[];
}

export interface UpdateDeal_updateDeal {
  __typename: "UpdateDealPayload";
  deal: UpdateDeal_updateDeal_deal | null;
  errors: UpdateDeal_updateDeal_errors[] | null;
}

export interface UpdateDeal {
  /**
   * Update a Deal
   */
  updateDeal: UpdateDeal_updateDeal;
}

export interface UpdateDealVariables {
  input: UpdateDealInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: DPA
// ====================================================

export interface DPA_branding {
  __typename: "Branding";
  id: string;
  render_dpa: string;
}

export interface DPA {
  branding: DPA_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EarningsStatementIngestion
// ====================================================

export interface EarningsStatementIngestion_me {
  __typename: "User";
  id: string;
  /**
   * Checks if user or current observer has a permission
   */
  canIngestRoyalties: boolean;
  is_admin: boolean;
}

export interface EarningsStatementIngestion_groups_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface EarningsStatementIngestion_groups {
  __typename: "Group";
  id: string;
  name: string;
  type: string | null;
  is_album_revenue: boolean;
  network: EarningsStatementIngestion_groups_network;
}

export interface EarningsStatementIngestion {
  /**
   * Get information about the currently logged in user
   */
  me: EarningsStatementIngestion_me;
  /**
   * List out all the groups for periods
   */
  groups: EarningsStatementIngestion_groups[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsByAgegroupOrganization
// ====================================================

export interface AudioAnalyticsByAgegroupOrganization_me_organization_audio_analytics_by_agegroup {
  __typename: "AudioAnalyticsByAgegroup";
  id: string;
  /**
   * number of streams
   */
  total_streams: number;
  /**
   * number of downloads
   */
  total_downloads: number | null;
  /**
   * age group
   */
  age_group: string;
}

export interface AudioAnalyticsByAgegroupOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Audio analytics by age group for an organization
   */
  audio_analytics_by_agegroup: AudioAnalyticsByAgegroupOrganization_me_organization_audio_analytics_by_agegroup[];
}

export interface AudioAnalyticsByAgegroupOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AudioAnalyticsByAgegroupOrganization_me_organization | null;
}

export interface AudioAnalyticsByAgegroupOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsByAgegroupOrganization_me;
}

export interface AudioAnalyticsByAgegroupOrganizationVariables {
  input?: AudioAnalyticsByAgegroupCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsByAgegroupUser
// ====================================================

export interface AudioAnalyticsByAgegroupUser_me_audio_analytics_by_agegroup {
  __typename: "AudioAnalyticsByAgegroup";
  id: string;
  /**
   * number of streams
   */
  total_streams: number;
  /**
   * number of downloads
   */
  total_downloads: number | null;
  /**
   * age group
   */
  age_group: string;
}

export interface AudioAnalyticsByAgegroupUser_me {
  __typename: "User";
  id: string;
  /**
   * Audio analytics by age group for a user
   */
  audio_analytics_by_agegroup: AudioAnalyticsByAgegroupUser_me_audio_analytics_by_agegroup[];
}

export interface AudioAnalyticsByAgegroupUser {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsByAgegroupUser_me;
}

export interface AudioAnalyticsByAgegroupUserVariables {
  input?: AudioAnalyticsByAgegroupCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsByDeviceOrganization
// ====================================================

export interface AudioAnalyticsByDeviceOrganization_me_organization_audio_analytics_by_device {
  __typename: "AudioAnalyticsByDevice";
  id: string;
  /**
   * total streams
   */
  total_streams: number;
  /**
   * type of device
   */
  device_type: string;
}

export interface AudioAnalyticsByDeviceOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Audio analytics by device for a user
   */
  audio_analytics_by_device: AudioAnalyticsByDeviceOrganization_me_organization_audio_analytics_by_device[];
}

export interface AudioAnalyticsByDeviceOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AudioAnalyticsByDeviceOrganization_me_organization | null;
}

export interface AudioAnalyticsByDeviceOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsByDeviceOrganization_me;
}

export interface AudioAnalyticsByDeviceOrganizationVariables {
  input?: AudioAnalyticsByDeviceCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsByDeviceUser
// ====================================================

export interface AudioAnalyticsByDeviceUser_me_audio_analytics_by_device {
  __typename: "AudioAnalyticsByDevice";
  id: string;
  /**
   * total streams
   */
  total_streams: number;
  /**
   * type of device
   */
  device_type: string;
}

export interface AudioAnalyticsByDeviceUser_me {
  __typename: "User";
  id: string;
  /**
   * Audio analytics by device for a user
   */
  audio_analytics_by_device: AudioAnalyticsByDeviceUser_me_audio_analytics_by_device[];
}

export interface AudioAnalyticsByDeviceUser {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsByDeviceUser_me;
}

export interface AudioAnalyticsByDeviceUserVariables {
  input?: AudioAnalyticsByDeviceCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsByGenderOrganization
// ====================================================

export interface AudioAnalyticsByGenderOrganization_me_organization_audio_analytics_by_gender {
  __typename: "AudioAnalyticsByGender";
  id: string;
  /**
   * total downloads
   */
  total_downloads: number | null;
  /**
   * total streams
   */
  total_streams: number;
  /**
   * type of gender
   */
  gender: string;
}

export interface AudioAnalyticsByGenderOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Audio analytics by gender for an organization
   */
  audio_analytics_by_gender: AudioAnalyticsByGenderOrganization_me_organization_audio_analytics_by_gender[];
}

export interface AudioAnalyticsByGenderOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AudioAnalyticsByGenderOrganization_me_organization | null;
}

export interface AudioAnalyticsByGenderOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsByGenderOrganization_me;
}

export interface AudioAnalyticsByGenderOrganizationVariables {
  input?: AudioAnalyticsByGenderCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsByGenderUser
// ====================================================

export interface AudioAnalyticsByGenderUser_me_audio_analytics_by_gender {
  __typename: "AudioAnalyticsByGender";
  id: string;
  /**
   * total downloads
   */
  total_downloads: number | null;
  /**
   * total streams
   */
  total_streams: number;
  /**
   * type of gender
   */
  gender: string;
}

export interface AudioAnalyticsByGenderUser_me {
  __typename: "User";
  id: string;
  /**
   * Audio analytics by gender for a user
   */
  audio_analytics_by_gender: AudioAnalyticsByGenderUser_me_audio_analytics_by_gender[];
}

export interface AudioAnalyticsByGenderUser {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsByGenderUser_me;
}

export interface AudioAnalyticsByGenderUserVariables {
  input?: AudioAnalyticsByGenderCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsOrganization
// ====================================================

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsOrganization_me_organization_audio_analytics_downloads_network;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsOrganization_me_organization_audio_analytics_streams_network;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics_downloads_network;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics_streams_network;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics {
  __typename: "DailyAudioAnalytics";
  id: string;
  date: Scalar$DateTime;
  /**
   * downloads metric
   */
  downloads: AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics_downloads[];
  /**
   * streams metric
   */
  streams: AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics_streams[];
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics {
  __typename: "AudioAnalytics";
  id: string;
  /**
   * downloads metric
   */
  downloads: AudioAnalyticsOrganization_me_organization_audio_analytics_downloads[];
  /**
   * streams metric
   */
  streams: AudioAnalyticsOrganization_me_organization_audio_analytics_streams[];
  /**
   * daily audio analytics
   */
  daily_audio_analytics: AudioAnalyticsOrganization_me_organization_audio_analytics_daily_audio_analytics[];
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics_downloads_network;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics_streams_network;
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics {
  __typename: "DailyAudioAnalytics";
  id: string;
  date: Scalar$DateTime;
  /**
   * downloads metric
   */
  downloads: AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics_downloads[];
  /**
   * streams metric
   */
  streams: AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics_streams[];
}

export interface AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart {
  __typename: "AudioAnalytics";
  id: string;
  /**
   * daily audio analytics
   */
  daily_audio_analytics: AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart_daily_audio_analytics[];
}

export interface AudioAnalyticsOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of audio analytics for an organization
   */
  audio_analytics_count: number;
  /**
   * Audio analytics for an organization
   */
  audio_analytics: AudioAnalyticsOrganization_me_organization_audio_analytics;
  /**
   * Audio analytics for an organization
   */
  audio_analytics_for_chart: AudioAnalyticsOrganization_me_organization_audio_analytics_for_chart;
}

export interface AudioAnalyticsOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AudioAnalyticsOrganization_me_organization | null;
}

export interface AudioAnalyticsOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsOrganization_me;
}

export interface AudioAnalyticsOrganizationVariables {
  chartInput?: AudioAnalyticsCursorInput | null;
  input?: AudioAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsUser
// ====================================================

export interface AudioAnalyticsUser_me_audio_analytics_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsUser_me_audio_analytics_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsUser_me_audio_analytics_downloads_network;
}

export interface AudioAnalyticsUser_me_audio_analytics_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsUser_me_audio_analytics_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsUser_me_audio_analytics_streams_network;
}

export interface AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics_downloads_network;
}

export interface AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics_streams_network;
}

export interface AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics {
  __typename: "DailyAudioAnalytics";
  id: string;
  date: Scalar$DateTime;
  /**
   * downloads metric
   */
  downloads: AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics_downloads[];
  /**
   * streams metric
   */
  streams: AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics_streams[];
}

export interface AudioAnalyticsUser_me_audio_analytics {
  __typename: "AudioAnalytics";
  id: string;
  /**
   * downloads metric
   */
  downloads: AudioAnalyticsUser_me_audio_analytics_downloads[];
  /**
   * streams metric
   */
  streams: AudioAnalyticsUser_me_audio_analytics_streams[];
  /**
   * daily audio analytics
   */
  daily_audio_analytics: AudioAnalyticsUser_me_audio_analytics_daily_audio_analytics[];
}

export interface AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics_downloads_network;
}

export interface AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics_streams_network;
}

export interface AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics {
  __typename: "DailyAudioAnalytics";
  id: string;
  date: Scalar$DateTime;
  /**
   * downloads metric
   */
  downloads: AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics_downloads[];
  /**
   * streams metric
   */
  streams: AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics_streams[];
}

export interface AudioAnalyticsUser_me_audio_analytics_for_chart {
  __typename: "AudioAnalytics";
  id: string;
  /**
   * daily audio analytics
   */
  daily_audio_analytics: AudioAnalyticsUser_me_audio_analytics_for_chart_daily_audio_analytics[];
}

export interface AudioAnalyticsUser_me {
  __typename: "User";
  id: string;
  /**
   * Number of audio analytics for a user
   */
  audio_analytics_count: number;
  /**
   * Audio analytics for a user
   */
  audio_analytics: AudioAnalyticsUser_me_audio_analytics;
  /**
   * Audio analytics for a user
   */
  audio_analytics_for_chart: AudioAnalyticsUser_me_audio_analytics_for_chart;
}

export interface AudioAnalyticsUser {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsUser_me;
}

export interface AudioAnalyticsUserVariables {
  chartInput?: AudioAnalyticsCursorInput | null;
  input?: AudioAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsScreen
// ====================================================

export interface AudioAnalyticsScreen_me {
  __typename: "User";
  id: string;
  /**
   * Has analytics for v2 audio analytics screen
   */
  has_audio_analytics: boolean;
}

export interface AudioAnalyticsScreen {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsScreen_me;
}

export interface AudioAnalyticsScreenVariables {
  albumId?: string | null;
  artistId?: string | null;
  mediaId?: string | null;
  userId?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsOrganizationSkipsAndSavesChart
// ====================================================

export interface AudioAnalyticsOrganizationSkipsAndSavesChart_me_organization_audio_analytics_daily_audio_analytics {
  __typename: "DailyAudioAnalytics";
  id: string;
  date: Scalar$DateTime;
  /**
   * total for skips for all networks
   */
  total_skips: number | null;
  /**
   * total for saves for all networks
   */
  total_saves: number | null;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesChart_me_organization_audio_analytics {
  __typename: "AudioAnalytics";
  id: string;
  /**
   * daily audio analytics
   */
  daily_audio_analytics: AudioAnalyticsOrganizationSkipsAndSavesChart_me_organization_audio_analytics_daily_audio_analytics[];
}

export interface AudioAnalyticsOrganizationSkipsAndSavesChart_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Audio analytics for an organization
   */
  audio_analytics: AudioAnalyticsOrganizationSkipsAndSavesChart_me_organization_audio_analytics;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesChart_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AudioAnalyticsOrganizationSkipsAndSavesChart_me_organization | null;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesChart {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsOrganizationSkipsAndSavesChart_me;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesChartVariables {
  input?: AudioAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsOrganizationSkipsAndSavesMap
// ====================================================

export interface AudioAnalyticsOrganizationSkipsAndSavesMap_me_organization_analytics_top_geocountries_country {
  __typename: "AnalyticsCountry";
  id: string;
  name: string;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesMap_me_organization_analytics_top_geocountries {
  __typename: "AnalyticsTopGeocountry";
  id: string;
  country: AudioAnalyticsOrganizationSkipsAndSavesMap_me_organization_analytics_top_geocountries_country;
  /**
   * total for skips for all networks for all dates
   */
  total_skips: number | null;
  /**
   * total for saves for all networks for all dates
   */
  total_saves: number | null;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesMap_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Audio analytics for top countries for a organization
   */
  analytics_top_geocountries: AudioAnalyticsOrganizationSkipsAndSavesMap_me_organization_analytics_top_geocountries[];
}

export interface AudioAnalyticsOrganizationSkipsAndSavesMap_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AudioAnalyticsOrganizationSkipsAndSavesMap_me_organization | null;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesMap {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsOrganizationSkipsAndSavesMap_me;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesMapVariables {
  input?: AnalyticsTopGeocountryCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsOrganizationSkipsAndSavesTable
// ====================================================

export interface AudioAnalyticsOrganizationSkipsAndSavesTable_me_organization_audio_analytics_daily_audio_analytics_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesTable_me_organization_audio_analytics_daily_audio_analytics {
  __typename: "DailyAudioAnalytics";
  id: string;
  date: Scalar$DateTime;
  /**
   * total for skips for all networks
   */
  total_skips: number | null;
  /**
   * total for saves for all networks
   */
  total_saves: number | null;
  /**
   * streams metric
   */
  streams: AudioAnalyticsOrganizationSkipsAndSavesTable_me_organization_audio_analytics_daily_audio_analytics_streams[];
}

export interface AudioAnalyticsOrganizationSkipsAndSavesTable_me_organization_audio_analytics {
  __typename: "AudioAnalytics";
  id: string;
  /**
   * daily audio analytics
   */
  daily_audio_analytics: AudioAnalyticsOrganizationSkipsAndSavesTable_me_organization_audio_analytics_daily_audio_analytics[];
}

export interface AudioAnalyticsOrganizationSkipsAndSavesTable_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of audio analytics for an organization
   */
  audio_analytics_count: number;
  /**
   * Audio analytics for an organization
   */
  audio_analytics: AudioAnalyticsOrganizationSkipsAndSavesTable_me_organization_audio_analytics;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesTable_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AudioAnalyticsOrganizationSkipsAndSavesTable_me_organization | null;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesTable {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsOrganizationSkipsAndSavesTable_me;
}

export interface AudioAnalyticsOrganizationSkipsAndSavesTableVariables {
  input?: AudioAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsUserSkipsAndSavesChart
// ====================================================

export interface AudioAnalyticsUserSkipsAndSavesChart_me_audio_analytics_daily_audio_analytics {
  __typename: "DailyAudioAnalytics";
  id: string;
  date: Scalar$DateTime;
  /**
   * total for skips for all networks
   */
  total_skips: number | null;
  /**
   * total for saves for all networks
   */
  total_saves: number | null;
}

export interface AudioAnalyticsUserSkipsAndSavesChart_me_audio_analytics {
  __typename: "AudioAnalytics";
  id: string;
  /**
   * daily audio analytics
   */
  daily_audio_analytics: AudioAnalyticsUserSkipsAndSavesChart_me_audio_analytics_daily_audio_analytics[];
}

export interface AudioAnalyticsUserSkipsAndSavesChart_me {
  __typename: "User";
  id: string;
  /**
   * Audio analytics for a user
   */
  audio_analytics: AudioAnalyticsUserSkipsAndSavesChart_me_audio_analytics;
}

export interface AudioAnalyticsUserSkipsAndSavesChart {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsUserSkipsAndSavesChart_me;
}

export interface AudioAnalyticsUserSkipsAndSavesChartVariables {
  input?: AudioAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsUserSkipsAndSavesTable
// ====================================================

export interface AudioAnalyticsUserSkipsAndSavesTable_me_audio_analytics_daily_audio_analytics_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
}

export interface AudioAnalyticsUserSkipsAndSavesTable_me_audio_analytics_daily_audio_analytics {
  __typename: "DailyAudioAnalytics";
  id: string;
  date: Scalar$DateTime;
  /**
   * total for skips for all networks
   */
  total_skips: number | null;
  /**
   * total for saves for all networks
   */
  total_saves: number | null;
  /**
   * streams metric
   */
  streams: AudioAnalyticsUserSkipsAndSavesTable_me_audio_analytics_daily_audio_analytics_streams[];
}

export interface AudioAnalyticsUserSkipsAndSavesTable_me_audio_analytics {
  __typename: "AudioAnalytics";
  id: string;
  /**
   * daily audio analytics
   */
  daily_audio_analytics: AudioAnalyticsUserSkipsAndSavesTable_me_audio_analytics_daily_audio_analytics[];
}

export interface AudioAnalyticsUserSkipsAndSavesTable_me {
  __typename: "User";
  id: string;
  /**
   * Number of audio analytics for a user
   */
  audio_analytics_count: number;
  /**
   * Audio analytics for a user
   */
  audio_analytics: AudioAnalyticsUserSkipsAndSavesTable_me_audio_analytics;
}

export interface AudioAnalyticsUserSkipsAndSavesTable {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsUserSkipsAndSavesTable_me;
}

export interface AudioAnalyticsUserSkipsAndSavesTableVariables {
  input?: AudioAnalyticsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsUserSkipsAndSavesMap
// ====================================================

export interface AudioAnalyticsUserSkipsAndSavesMap_me_analytics_top_geocountries_country {
  __typename: "AnalyticsCountry";
  id: string;
  name: string;
}

export interface AudioAnalyticsUserSkipsAndSavesMap_me_analytics_top_geocountries {
  __typename: "AnalyticsTopGeocountry";
  id: string;
  country: AudioAnalyticsUserSkipsAndSavesMap_me_analytics_top_geocountries_country;
  /**
   * total for skips for all networks for all dates
   */
  total_skips: number | null;
  /**
   * total for saves for all networks for all dates
   */
  total_saves: number | null;
}

export interface AudioAnalyticsUserSkipsAndSavesMap_me {
  __typename: "User";
  id: string;
  /**
   * Audio analytics for top countries for a user
   */
  analytics_top_geocountries: AudioAnalyticsUserSkipsAndSavesMap_me_analytics_top_geocountries[];
}

export interface AudioAnalyticsUserSkipsAndSavesMap {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsUserSkipsAndSavesMap_me;
}

export interface AudioAnalyticsUserSkipsAndSavesMapVariables {
  input?: AnalyticsTopGeocountryCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsOrganizationStreamSource
// ====================================================

export interface AudioAnalyticsOrganizationStreamSource_me_organization_audio_analytics_by_source {
  __typename: "AudioAnalyticsBySource";
  id: string;
  /**
   * source of the stream
   */
  source: string;
  /**
   * total streams
   */
  streams: number;
}

export interface AudioAnalyticsOrganizationStreamSource_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Audio analytics by source for an organization
   */
  audio_analytics_by_source: AudioAnalyticsOrganizationStreamSource_me_organization_audio_analytics_by_source[];
}

export interface AudioAnalyticsOrganizationStreamSource_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AudioAnalyticsOrganizationStreamSource_me_organization | null;
}

export interface AudioAnalyticsOrganizationStreamSource {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsOrganizationStreamSource_me;
}

export interface AudioAnalyticsOrganizationStreamSourceVariables {
  input?: AudioAnalyticsBySourceCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioAnalyticsUserStreamSource
// ====================================================

export interface AudioAnalyticsUserStreamSource_me_audio_analytics_by_source {
  __typename: "AudioAnalyticsBySource";
  id: string;
  /**
   * source of the stream
   */
  source: string;
  /**
   * total streams
   */
  streams: number;
}

export interface AudioAnalyticsUserStreamSource_me {
  __typename: "User";
  id: string;
  /**
   * Audio analytics by source for a user
   */
  audio_analytics_by_source: AudioAnalyticsUserStreamSource_me_audio_analytics_by_source[];
}

export interface AudioAnalyticsUserStreamSource {
  /**
   * Get information about the currently logged in user
   */
  me: AudioAnalyticsUserStreamSource_me;
}

export interface AudioAnalyticsUserStreamSourceVariables {
  input?: AudioAnalyticsBySourceCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopAlbumsOrganization
// ====================================================

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_downloads_network;
}

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_streams_network;
}

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_album {
  __typename: "Album";
  id: string;
  file_upload: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_album_file_upload | null;
  user_id: string;
  metadata: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_album_metadata;
  artist: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_album_artist;
}

export interface AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums {
  __typename: "AnalyticsTopAlbums";
  id: string;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_streams[];
  album: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums_album;
}

export interface AnalyticsTopAlbumsOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top albums in this organization
   */
  analytics_top_albums_count: number;
  /**
   * Audio analytics for top albums in this organization
   */
  analytics_top_albums: AnalyticsTopAlbumsOrganization_me_organization_analytics_top_albums[];
}

export interface AnalyticsTopAlbumsOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AnalyticsTopAlbumsOrganization_me_organization | null;
}

export interface AnalyticsTopAlbumsOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopAlbumsOrganization_me;
}

export interface AnalyticsTopAlbumsOrganizationVariables {
  input?: AnalyticsTopAlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopAlbumsUser
// ====================================================

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopAlbumsUser_me_analytics_top_albums_downloads_network;
}

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopAlbumsUser_me_analytics_top_albums_streams_network;
}

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums_album {
  __typename: "Album";
  id: string;
  file_upload: AnalyticsTopAlbumsUser_me_analytics_top_albums_album_file_upload | null;
  user_id: string;
  metadata: AnalyticsTopAlbumsUser_me_analytics_top_albums_album_metadata;
  artist: AnalyticsTopAlbumsUser_me_analytics_top_albums_album_artist;
}

export interface AnalyticsTopAlbumsUser_me_analytics_top_albums {
  __typename: "AnalyticsTopAlbums";
  id: string;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopAlbumsUser_me_analytics_top_albums_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopAlbumsUser_me_analytics_top_albums_streams[];
  album: AnalyticsTopAlbumsUser_me_analytics_top_albums_album;
}

export interface AnalyticsTopAlbumsUser_me {
  __typename: "User";
  id: string;
  /**
   * Number of top albums for a user
   */
  analytics_top_albums_count: number;
  /**
   * Audio analytics for top albums for a user
   */
  analytics_top_albums: AnalyticsTopAlbumsUser_me_analytics_top_albums[];
}

export interface AnalyticsTopAlbumsUser {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopAlbumsUser_me;
}

export interface AnalyticsTopAlbumsUserVariables {
  input?: AnalyticsTopAlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopArtistsOrganization
// ====================================================

export interface AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_downloads_network;
}

export interface AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_streams_network;
}

export interface AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_artist {
  __typename: "Artist";
  id: string;
  thumbnail_url: string;
  /**
   * Name of an artist
   */
  name: string;
  user_id: string;
}

export interface AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists {
  __typename: "AnalyticsTopArtist";
  id: string;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_streams[];
  artist: AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists_artist;
}

export interface AnalyticsTopArtistsOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top artists in this organization
   */
  analytics_top_artists_count: number;
  /**
   * Audio analytics for top artists in this organization
   */
  analytics_top_artists: AnalyticsTopArtistsOrganization_me_organization_analytics_top_artists[];
}

export interface AnalyticsTopArtistsOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AnalyticsTopArtistsOrganization_me_organization | null;
}

export interface AnalyticsTopArtistsOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopArtistsOrganization_me;
}

export interface AnalyticsTopArtistsOrganizationVariables {
  input?: AnalyticsTopArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopArtistsUser
// ====================================================

export interface AnalyticsTopArtistsUser_me_analytics_top_artists_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopArtistsUser_me_analytics_top_artists_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopArtistsUser_me_analytics_top_artists_downloads_network;
}

export interface AnalyticsTopArtistsUser_me_analytics_top_artists_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopArtistsUser_me_analytics_top_artists_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopArtistsUser_me_analytics_top_artists_streams_network;
}

export interface AnalyticsTopArtistsUser_me_analytics_top_artists_artist {
  __typename: "Artist";
  id: string;
  thumbnail_url: string;
  /**
   * Name of an artist
   */
  name: string;
  user_id: string;
}

export interface AnalyticsTopArtistsUser_me_analytics_top_artists {
  __typename: "AnalyticsTopArtist";
  id: string;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopArtistsUser_me_analytics_top_artists_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopArtistsUser_me_analytics_top_artists_streams[];
  artist: AnalyticsTopArtistsUser_me_analytics_top_artists_artist;
}

export interface AnalyticsTopArtistsUser_me {
  __typename: "User";
  id: string;
  /**
   * Number of top artists for a user
   */
  analytics_top_artists_count: number;
  /**
   * Audio analytics for top artists for a user
   */
  analytics_top_artists: AnalyticsTopArtistsUser_me_analytics_top_artists[];
}

export interface AnalyticsTopArtistsUser {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopArtistsUser_me;
}

export interface AnalyticsTopArtistsUserVariables {
  input?: AnalyticsTopArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopContentOrganization
// ====================================================

export interface AnalyticsTopContentOrganization_me_organization_analytics_top_content_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopContentOrganization_me_organization_analytics_top_content_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopContentOrganization_me_organization_analytics_top_content_downloads_network;
}

export interface AnalyticsTopContentOrganization_me_organization_analytics_top_content_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopContentOrganization_me_organization_analytics_top_content_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopContentOrganization_me_organization_analytics_top_content_streams_network;
}

export interface AnalyticsTopContentOrganization_me_organization_analytics_top_content_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AnalyticsTopContentOrganization_me_organization_analytics_top_content_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AnalyticsTopContentOrganization_me_organization_analytics_top_content_media {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  user_id: string;
  metadata: AnalyticsTopContentOrganization_me_organization_analytics_top_content_media_metadata | null;
  artist: AnalyticsTopContentOrganization_me_organization_analytics_top_content_media_artist | null;
}

export interface AnalyticsTopContentOrganization_me_organization_analytics_top_content {
  __typename: "AnalyticsTopContent";
  id: string;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopContentOrganization_me_organization_analytics_top_content_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopContentOrganization_me_organization_analytics_top_content_streams[];
  media: AnalyticsTopContentOrganization_me_organization_analytics_top_content_media;
}

export interface AnalyticsTopContentOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top medias in this organization
   */
  analytics_top_content_count: number;
  /**
   * Audio analytics for top medias in this organization
   */
  analytics_top_content: AnalyticsTopContentOrganization_me_organization_analytics_top_content[];
}

export interface AnalyticsTopContentOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AnalyticsTopContentOrganization_me_organization | null;
}

export interface AnalyticsTopContentOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopContentOrganization_me;
}

export interface AnalyticsTopContentOrganizationVariables {
  input?: AnalyticsTopContentCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopContentUser
// ====================================================

export interface AnalyticsTopContentUser_me_analytics_top_content_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopContentUser_me_analytics_top_content_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopContentUser_me_analytics_top_content_downloads_network;
}

export interface AnalyticsTopContentUser_me_analytics_top_content_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopContentUser_me_analytics_top_content_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopContentUser_me_analytics_top_content_streams_network;
}

export interface AnalyticsTopContentUser_me_analytics_top_content_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AnalyticsTopContentUser_me_analytics_top_content_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AnalyticsTopContentUser_me_analytics_top_content_media {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  user_id: string;
  metadata: AnalyticsTopContentUser_me_analytics_top_content_media_metadata | null;
  artist: AnalyticsTopContentUser_me_analytics_top_content_media_artist | null;
}

export interface AnalyticsTopContentUser_me_analytics_top_content {
  __typename: "AnalyticsTopContent";
  id: string;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopContentUser_me_analytics_top_content_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopContentUser_me_analytics_top_content_streams[];
  media: AnalyticsTopContentUser_me_analytics_top_content_media;
}

export interface AnalyticsTopContentUser_me {
  __typename: "User";
  id: string;
  /**
   * Number of top medias for a user
   */
  analytics_top_content_count: number;
  /**
   * Audio analytics for top medias for a user
   */
  analytics_top_content: AnalyticsTopContentUser_me_analytics_top_content[];
}

export interface AnalyticsTopContentUser {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopContentUser_me;
}

export interface AnalyticsTopContentUserVariables {
  input?: AnalyticsTopContentCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopGeocountriesOrganization
// ====================================================

export interface AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_downloads_network;
}

export interface AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_streams_network;
}

export interface AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_country {
  __typename: "AnalyticsCountry";
  id: string;
  code: AnalyticsCountryCode;
  name: string;
}

export interface AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries {
  __typename: "AnalyticsTopGeocountry";
  id: string;
  /**
   * total for downloads for all networks for all dates
   */
  total_downloads: number | null;
  /**
   * total for streams for all networks for all dates
   */
  total_streams: number;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_streams[];
  country: AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries_country;
}

export interface AnalyticsTopGeocountriesOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top countries for a organization
   */
  analytics_top_geocountries_count: number;
  /**
   * Audio analytics for top countries for a organization
   */
  analytics_top_geocountries: AnalyticsTopGeocountriesOrganization_me_organization_analytics_top_geocountries[];
}

export interface AnalyticsTopGeocountriesOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AnalyticsTopGeocountriesOrganization_me_organization | null;
}

export interface AnalyticsTopGeocountriesOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopGeocountriesOrganization_me;
}

export interface AnalyticsTopGeocountriesOrganizationVariables {
  input?: AnalyticsTopGeocountryCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopGeocountriesUser
// ====================================================

export interface AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_downloads_network;
}

export interface AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_streams_network;
}

export interface AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_country {
  __typename: "AnalyticsCountry";
  id: string;
  code: AnalyticsCountryCode;
  name: string;
}

export interface AnalyticsTopGeocountriesUser_me_analytics_top_geocountries {
  __typename: "AnalyticsTopGeocountry";
  id: string;
  /**
   * total for downloads for all networks for all dates
   */
  total_downloads: number | null;
  /**
   * total for streams for all networks for all dates
   */
  total_streams: number;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_streams[];
  country: AnalyticsTopGeocountriesUser_me_analytics_top_geocountries_country;
}

export interface AnalyticsTopGeocountriesUser_me {
  __typename: "User";
  id: string;
  /**
   * Number of top countries for a user
   */
  analytics_top_geocountries_count: number;
  /**
   * Audio analytics for top countries for a user
   */
  analytics_top_geocountries: AnalyticsTopGeocountriesUser_me_analytics_top_geocountries[];
}

export interface AnalyticsTopGeocountriesUser {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopGeocountriesUser_me;
}

export interface AnalyticsTopGeocountriesUserVariables {
  input?: AnalyticsTopGeocountryCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AnalyticsTopUsersOrganization
// ====================================================

export interface AnalyticsTopUsersOrganization_me_organization_analytics_top_users_downloads_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopUsersOrganization_me_organization_analytics_top_users_downloads {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopUsersOrganization_me_organization_analytics_top_users_downloads_network;
}

export interface AnalyticsTopUsersOrganization_me_organization_analytics_top_users_streams_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface AnalyticsTopUsersOrganization_me_organization_analytics_top_users_streams {
  __typename: "AnalyticsMetric";
  id: string;
  /**
   * value
   */
  value: number | null;
  /**
   * network
   */
  network: AnalyticsTopUsersOrganization_me_organization_analytics_top_users_streams_network;
}

export interface AnalyticsTopUsersOrganization_me_organization_analytics_top_users_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface AnalyticsTopUsersOrganization_me_organization_analytics_top_users {
  __typename: "AnalyticsTopUser";
  id: string;
  /**
   * downloads metric
   */
  downloads: AnalyticsTopUsersOrganization_me_organization_analytics_top_users_downloads[];
  /**
   * streams metric
   */
  streams: AnalyticsTopUsersOrganization_me_organization_analytics_top_users_streams[];
  user: AnalyticsTopUsersOrganization_me_organization_analytics_top_users_user;
}

export interface AnalyticsTopUsersOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top users in this organization
   */
  analytics_top_users_count: number;
  /**
   * Audio analytics for top users in this organization
   */
  analytics_top_users: AnalyticsTopUsersOrganization_me_organization_analytics_top_users[];
}

export interface AnalyticsTopUsersOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AnalyticsTopUsersOrganization_me_organization | null;
}

export interface AnalyticsTopUsersOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: AnalyticsTopUsersOrganization_me;
}

export interface AnalyticsTopUsersOrganizationVariables {
  input?: AnalyticsTopUsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FanDrillDownByDate
// ====================================================

export interface FanDrillDownByDate_me_artist_grouped_fans {
  __typename: "GroupedFans";
  id: string;
  date: Scalar$DateTime;
  cumulative_fan_count: number | null;
}

export interface FanDrillDownByDate_me_artist {
  __typename: "Artist";
  id: string;
  /**
   * List out all fans by date for the current artist
   */
  grouped_fans: FanDrillDownByDate_me_artist_grouped_fans[];
}

export interface FanDrillDownByDate_me {
  __typename: "User";
  id: string;
  /**
   * Get single artist by id
   */
  artist: FanDrillDownByDate_me_artist | null;
}

export interface FanDrillDownByDate {
  /**
   * Get information about the currently logged in user
   */
  me: FanDrillDownByDate_me;
}

export interface FanDrillDownByDateVariables {
  artistId: string;
  fansByDateInput?: GroupedFansCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FansByTerritory
// ====================================================

export interface FansByTerritory_me_artist_grouped_fans_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface FansByTerritory_me_artist_grouped_fans {
  __typename: "GroupedFans";
  id: string;
  cumulative_fan_count: number | null;
  geocountry: FansByTerritory_me_artist_grouped_fans_geocountry;
}

export interface FansByTerritory_me_artist {
  __typename: "Artist";
  id: string;
  /**
   * List out all fans by date for the current artist
   */
  grouped_fans: FansByTerritory_me_artist_grouped_fans[];
}

export interface FansByTerritory_me {
  __typename: "User";
  id: string;
  /**
   * Get single artist by id
   */
  artist: FansByTerritory_me_artist | null;
}

export interface FansByTerritory {
  /**
   * Get information about the currently logged in user
   */
  me: FansByTerritory_me;
}

export interface FansByTerritoryVariables {
  artistId: string;
  fansByDateInput?: GroupedFansCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FanDrillDown
// ====================================================

export interface FanDrillDown_me_artist_fans_networks {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface FanDrillDown_me_artist_fans_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface FanDrillDown_me_artist_fans {
  __typename: "Fan";
  id: string;
  name: string | null;
  email: string;
  created_at: Scalar$DateTime;
  networks: FanDrillDown_me_artist_fans_networks[];
  geocountry: FanDrillDown_me_artist_fans_geocountry;
}

export interface FanDrillDown_me_artist {
  __typename: "Artist";
  id: string;
  /**
   * List out all fans for the current artist
   */
  fans: FanDrillDown_me_artist_fans[];
  /**
   * Number of fans for an artist
   */
  fans_count: number;
}

export interface FanDrillDown_me {
  __typename: "User";
  id: string;
  /**
   * Get single artist by id
   */
  artist: FanDrillDown_me_artist | null;
}

export interface FanDrillDown {
  /**
   * Get information about the currently logged in user
   */
  me: FanDrillDown_me;
}

export interface FanDrillDownVariables {
  artistId: string;
  fanInput?: FansCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FanIndex
// ====================================================

export interface FanIndex_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  /**
   * Number of fans for an artist
   */
  sortable_fans_count: number | null;
  thumbnail_url: string;
}

export interface FanIndex_me {
  __typename: "User";
  id: string;
  /**
   * List out all Artists for the current user
   */
  artists: FanIndex_me_artists[];
  /**
   * Count of artists for the user
   */
  artists_count: number;
}

export interface FanIndex {
  /**
   * Get information about the currently logged in user
   */
  me: FanIndex_me;
}

export interface FanIndexVariables {
  input?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PlaylistAnalytics
// ====================================================

export interface PlaylistAnalytics_me_artistsWithPlaylists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface PlaylistAnalytics_me {
  __typename: "User";
  id: string;
  /**
   * List out all Artists for the current user
   */
  artistsWithPlaylists: PlaylistAnalytics_me_artistsWithPlaylists[];
}

export interface PlaylistAnalytics {
  /**
   * Get information about the currently logged in user
   */
  me: PlaylistAnalytics_me;
}

export interface PlaylistAnalyticsVariables {
  artistAnalyticsInput?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinkAlbumMetadata
// ====================================================

export interface SmartlinkAlbumMetadata_me_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface SmartlinkAlbumMetadata_me_album {
  __typename: "Album";
  id: string;
  metadata: SmartlinkAlbumMetadata_me_album_metadata;
  smartlink_url: string;
}

export interface SmartlinkAlbumMetadata_me {
  __typename: "User";
  id: string;
  /**
   * Get single album by id
   */
  album: SmartlinkAlbumMetadata_me_album | null;
}

export interface SmartlinkAlbumMetadata {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinkAlbumMetadata_me;
}

export interface SmartlinkAlbumMetadataVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartLinkAnalyticsTopAlbumsOrganization
// ====================================================

export interface SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics_album {
  __typename: "Album";
  id: string;
  user_id: string;
  metadata: SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics_album_metadata;
  artist: SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics_album_artist;
  file_upload: SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics_album_file_upload | null;
}

export interface SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
  /**
   * Total presaves for a Smart Links
   */
  total_presaves: number | null;
  /**
   * Album corresponding to the returned Smart Links analytics
   */
  album: SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics_album;
}

export interface SmartLinkAnalyticsTopAlbumsOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of Smart Link Analytics for releases in this organization
   */
  smart_links_analytics_count: number;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartLinkAnalyticsTopAlbumsOrganization_me_organization_smart_links_analytics[];
}

export interface SmartLinkAnalyticsTopAlbumsOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: SmartLinkAnalyticsTopAlbumsOrganization_me_organization | null;
}

export interface SmartLinkAnalyticsTopAlbumsOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: SmartLinkAnalyticsTopAlbumsOrganization_me;
}

export interface SmartLinkAnalyticsTopAlbumsOrganizationVariables {
  input: SmartLinksAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartLinkAnalyticsTopAlbumsUser
// ====================================================

export interface SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics_album {
  __typename: "Album";
  id: string;
  user_id: string;
  metadata: SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics_album_metadata;
  artist: SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics_album_artist;
  file_upload: SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics_album_file_upload | null;
}

export interface SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
  /**
   * Total presaves for a Smart Links
   */
  total_presaves: number | null;
  /**
   * Album corresponding to the returned Smart Links analytics
   */
  album: SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics_album;
}

export interface SmartLinkAnalyticsTopAlbumsUser_me {
  __typename: "User";
  id: string;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartLinkAnalyticsTopAlbumsUser_me_smart_links_analytics[];
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
}

export interface SmartLinkAnalyticsTopAlbumsUser {
  /**
   * Get information about the currently logged in user
   */
  me: SmartLinkAnalyticsTopAlbumsUser_me;
}

export interface SmartLinkAnalyticsTopAlbumsUserVariables {
  input: SmartLinksAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinkArtistMetadata
// ====================================================

export interface SmartlinkArtistMetadata_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface SmartlinkArtistMetadata {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: SmartlinkArtistMetadata_artist;
}

export interface SmartlinkArtistMetadataVariables {
  artistId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinkUserMetadata
// ====================================================

export interface SmartlinkUserMetadata_user_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface SmartlinkUserMetadata_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  /**
   * List out all Artists for the current user
   */
  artists: SmartlinkUserMetadata_user_artists[];
}

export interface SmartlinkUserMetadata {
  /**
   * Find a User by ID
   */
  user: SmartlinkUserMetadata_user;
}

export interface SmartlinkUserMetadataVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartLinkAnalyticsTopDestinationsUser
// ====================================================

export interface SmartLinkAnalyticsTopDestinationsUser_me_smart_links_analytics_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartLinkAnalyticsTopDestinationsUser_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
  /**
   * Network for specific analytics on Smart links
   */
  network: SmartLinkAnalyticsTopDestinationsUser_me_smart_links_analytics_network | null;
}

export interface SmartLinkAnalyticsTopDestinationsUser_me {
  __typename: "User";
  id: string;
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartLinkAnalyticsTopDestinationsUser_me_smart_links_analytics[];
}

export interface SmartLinkAnalyticsTopDestinationsUser {
  /**
   * Get information about the currently logged in user
   */
  me: SmartLinkAnalyticsTopDestinationsUser_me;
}

export interface SmartLinkAnalyticsTopDestinationsUserVariables {
  input: SmartLinksAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartLinkAnalyticsTopDestinationsOrganization
// ====================================================

export interface SmartLinkAnalyticsTopDestinationsOrganization_me_organization_smart_links_analytics_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartLinkAnalyticsTopDestinationsOrganization_me_organization_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
  /**
   * Network for specific analytics on Smart links
   */
  network: SmartLinkAnalyticsTopDestinationsOrganization_me_organization_smart_links_analytics_network | null;
}

export interface SmartLinkAnalyticsTopDestinationsOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of Smart Link Analytics for releases in this organization
   */
  smart_links_analytics_count: number;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartLinkAnalyticsTopDestinationsOrganization_me_organization_smart_links_analytics[];
}

export interface SmartLinkAnalyticsTopDestinationsOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: SmartLinkAnalyticsTopDestinationsOrganization_me_organization | null;
}

export interface SmartLinkAnalyticsTopDestinationsOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: SmartLinkAnalyticsTopDestinationsOrganization_me;
}

export interface SmartLinkAnalyticsTopDestinationsOrganizationVariables {
  input: SmartLinksAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartLinkAnalyticsTopPresavesNetworksUser
// ====================================================

export interface SmartLinkAnalyticsTopPresavesNetworksUser_me_smart_links_analytics_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartLinkAnalyticsTopPresavesNetworksUser_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total presaves for a Smart Links
   */
  total_presaves: number | null;
  /**
   * Network for specific analytics on Smart links
   */
  network: SmartLinkAnalyticsTopPresavesNetworksUser_me_smart_links_analytics_network | null;
}

export interface SmartLinkAnalyticsTopPresavesNetworksUser_me {
  __typename: "User";
  id: string;
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartLinkAnalyticsTopPresavesNetworksUser_me_smart_links_analytics[];
}

export interface SmartLinkAnalyticsTopPresavesNetworksUser {
  /**
   * Get information about the currently logged in user
   */
  me: SmartLinkAnalyticsTopPresavesNetworksUser_me;
}

export interface SmartLinkAnalyticsTopPresavesNetworksUserVariables {
  input: SmartLinksAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartLinkAnalyticsTopPresavesNetworksOrganization
// ====================================================

export interface SmartLinkAnalyticsTopPresavesNetworksOrganization_me_organization_smart_links_analytics_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartLinkAnalyticsTopPresavesNetworksOrganization_me_organization_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total presaves for a Smart Links
   */
  total_presaves: number | null;
  /**
   * Network for specific analytics on Smart links
   */
  network: SmartLinkAnalyticsTopPresavesNetworksOrganization_me_organization_smart_links_analytics_network | null;
}

export interface SmartLinkAnalyticsTopPresavesNetworksOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of Smart Link Analytics for releases in this organization
   */
  smart_links_analytics_count: number;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartLinkAnalyticsTopPresavesNetworksOrganization_me_organization_smart_links_analytics[];
}

export interface SmartLinkAnalyticsTopPresavesNetworksOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: SmartLinkAnalyticsTopPresavesNetworksOrganization_me_organization | null;
}

export interface SmartLinkAnalyticsTopPresavesNetworksOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: SmartLinkAnalyticsTopPresavesNetworksOrganization_me;
}

export interface SmartLinkAnalyticsTopPresavesNetworksOrganizationVariables {
  input: SmartLinksAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartLinkAnalyticsTopTerritoriesOrganization
// ====================================================

export interface SmartLinkAnalyticsTopTerritoriesOrganization_me_organization_smart_links_analytics_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface SmartLinkAnalyticsTopTerritoriesOrganization_me_organization_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
  /**
   * Country specific analytics for Smart links releases
   */
  geocountry: SmartLinkAnalyticsTopTerritoriesOrganization_me_organization_smart_links_analytics_geocountry;
}

export interface SmartLinkAnalyticsTopTerritoriesOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of Smart Link Analytics for releases in this organization
   */
  smart_links_analytics_count: number;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartLinkAnalyticsTopTerritoriesOrganization_me_organization_smart_links_analytics[];
}

export interface SmartLinkAnalyticsTopTerritoriesOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: SmartLinkAnalyticsTopTerritoriesOrganization_me_organization | null;
}

export interface SmartLinkAnalyticsTopTerritoriesOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: SmartLinkAnalyticsTopTerritoriesOrganization_me;
}

export interface SmartLinkAnalyticsTopTerritoriesOrganizationVariables {
  input: SmartLinksAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartLinkAnalyticsTopTerritoriesUser
// ====================================================

export interface SmartLinkAnalyticsTopTerritoriesUser_me_smart_links_analytics_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface SmartLinkAnalyticsTopTerritoriesUser_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
  /**
   * Country specific analytics for Smart links releases
   */
  geocountry: SmartLinkAnalyticsTopTerritoriesUser_me_smart_links_analytics_geocountry;
}

export interface SmartLinkAnalyticsTopTerritoriesUser_me {
  __typename: "User";
  id: string;
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartLinkAnalyticsTopTerritoriesUser_me_smart_links_analytics[];
}

export interface SmartLinkAnalyticsTopTerritoriesUser {
  /**
   * Get information about the currently logged in user
   */
  me: SmartLinkAnalyticsTopTerritoriesUser_me;
}

export interface SmartLinkAnalyticsTopTerritoriesUserVariables {
  input: SmartLinksAnalyticsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksAnalyticsCountryTable
// ====================================================

export interface SmartlinksAnalyticsCountryTable_me_smart_links_analytics_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface SmartlinksAnalyticsCountryTable_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Country specific analytics for Smart links releases
   */
  geocountry: SmartlinksAnalyticsCountryTable_me_smart_links_analytics_geocountry;
}

export interface SmartlinksAnalyticsCountryTable_me_all_smartlinks_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface SmartlinksAnalyticsCountryTable_me_all_smartlinks {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Country specific analytics for Smart links releases
   */
  geocountry: SmartlinksAnalyticsCountryTable_me_all_smartlinks_geocountry;
}

export interface SmartlinksAnalyticsCountryTable_me {
  __typename: "User";
  id: string;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartlinksAnalyticsCountryTable_me_smart_links_analytics[];
  /**
   * Smart Link Analytics for User's releases
   */
  all_smartlinks: SmartlinksAnalyticsCountryTable_me_all_smartlinks[];
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
}

export interface SmartlinksAnalyticsCountryTable {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinksAnalyticsCountryTable_me;
}

export interface SmartlinksAnalyticsCountryTableVariables {
  input?: SmartLinksAnalyticsInput | null;
  donutInput?: SmartLinksAnalyticsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksAnalyticsCountryVisitsTable
// ====================================================

export interface SmartlinksAnalyticsCountryVisitsTable_me_smart_links_analytics_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface SmartlinksAnalyticsCountryVisitsTable_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
  /**
   * Country specific analytics for Smart links releases
   */
  geocountry: SmartlinksAnalyticsCountryVisitsTable_me_smart_links_analytics_geocountry;
}

export interface SmartlinksAnalyticsCountryVisitsTable_me_all_smartlinks_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface SmartlinksAnalyticsCountryVisitsTable_me_all_smartlinks {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
  /**
   * Country specific analytics for Smart links releases
   */
  geocountry: SmartlinksAnalyticsCountryVisitsTable_me_all_smartlinks_geocountry;
}

export interface SmartlinksAnalyticsCountryVisitsTable_me {
  __typename: "User";
  id: string;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartlinksAnalyticsCountryVisitsTable_me_smart_links_analytics[];
  /**
   * Smart Link Analytics for User's releases
   */
  all_smartlinks: SmartlinksAnalyticsCountryVisitsTable_me_all_smartlinks[];
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
}

export interface SmartlinksAnalyticsCountryVisitsTable {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinksAnalyticsCountryVisitsTable_me;
}

export interface SmartlinksAnalyticsCountryVisitsTableVariables {
  input?: SmartLinksAnalyticsInput | null;
  donutInput?: SmartLinksAnalyticsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksAnalyticsNetworkTable
// ====================================================

export interface SmartlinksAnalyticsNetworkTable_me_smart_links_analytics_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartlinksAnalyticsNetworkTable_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Network for specific analytics on Smart links
   */
  network: SmartlinksAnalyticsNetworkTable_me_smart_links_analytics_network | null;
}

export interface SmartlinksAnalyticsNetworkTable_me_all_smartlinks_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartlinksAnalyticsNetworkTable_me_all_smartlinks {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Network for specific analytics on Smart links
   */
  network: SmartlinksAnalyticsNetworkTable_me_all_smartlinks_network | null;
}

export interface SmartlinksAnalyticsNetworkTable_me {
  __typename: "User";
  id: string;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartlinksAnalyticsNetworkTable_me_smart_links_analytics[];
  /**
   * Smart Link Analytics for User's releases
   */
  all_smartlinks: SmartlinksAnalyticsNetworkTable_me_all_smartlinks[];
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
}

export interface SmartlinksAnalyticsNetworkTable {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinksAnalyticsNetworkTable_me;
}

export interface SmartlinksAnalyticsNetworkTableVariables {
  input?: SmartLinksAnalyticsInput | null;
  donutInput?: SmartLinksAnalyticsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksAnalyticsPresavesTable
// ====================================================

export interface SmartlinksAnalyticsPresavesTable_me_smart_links_analytics_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartlinksAnalyticsPresavesTable_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Network for specific analytics on Smart links
   */
  network: SmartlinksAnalyticsPresavesTable_me_smart_links_analytics_network | null;
  /**
   * Total presaves for a Smart Links
   */
  total_presaves: number | null;
}

export interface SmartlinksAnalyticsPresavesTable_me_all_smartlinks_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Social icon URL for this network or image url if social icon doesnt exist
   */
  social_icon_url_with_fallback: string;
}

export interface SmartlinksAnalyticsPresavesTable_me_all_smartlinks {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Network for specific analytics on Smart links
   */
  network: SmartlinksAnalyticsPresavesTable_me_all_smartlinks_network | null;
  /**
   * Total presaves for a Smart Links
   */
  total_presaves: number | null;
}

export interface SmartlinksAnalyticsPresavesTable_me {
  __typename: "User";
  id: string;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartlinksAnalyticsPresavesTable_me_smart_links_analytics[];
  /**
   * Smart Link Analytics for User's releases
   */
  all_smartlinks: SmartlinksAnalyticsPresavesTable_me_all_smartlinks[];
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
}

export interface SmartlinksAnalyticsPresavesTable {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinksAnalyticsPresavesTable_me;
}

export interface SmartlinksAnalyticsPresavesTableVariables {
  input?: SmartLinksAnalyticsInput | null;
  donutInput?: SmartLinksAnalyticsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SmartlinksAnalyticsDrillDownTotals
// ====================================================

export interface SmartlinksAnalyticsDrillDownTotals_me_smart_links_analytics {
  __typename: "SmartLinksAnalytics";
  /**
   * Object's Identifier
   */
  id: string;
  /**
   * Total presaves for a Smart Links
   */
  total_presaves: number | null;
  /**
   * Total unique clickthroughs for a Smart Links
   */
  unique_total_clickthroughs: number | null;
  /**
   * Total unique visits for a Smart Links
   */
  unique_total_visits: number | null;
}

export interface SmartlinksAnalyticsDrillDownTotals_me {
  __typename: "User";
  id: string;
  /**
   * Smart Link Analytics for User's releases
   */
  smart_links_analytics: SmartlinksAnalyticsDrillDownTotals_me_smart_links_analytics[];
  /**
   * Number of Smart Link Analytics for releases for a user
   */
  smart_links_analytics_count: number;
}

export interface SmartlinksAnalyticsDrillDownTotals {
  /**
   * Get information about the currently logged in user
   */
  me: SmartlinksAnalyticsDrillDownTotals_me;
}

export interface SmartlinksAnalyticsDrillDownTotalsVariables {
  input?: SmartLinksAnalyticsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaInsightsAppleMusicTracks
// ====================================================

export interface MediaInsightsAppleMusicTracks_media_network_analytics_FacebookVideoAnalytics {
  __typename: "FacebookVideoAnalytics" | "InstagramStoryAnalytics" | "InstagramVideoAnalytics" | "SpotifyTrackAnalytics" | "VevoVideoAnalytics" | "YoutubeVideoAnalytics";
  id: string;
}

export interface MediaInsightsAppleMusicTracks_media_network_analytics_AppleMusicTrackAnalytics_data_points {
  __typename: "AppleMusicDataPoint";
  date: Scalar$DateTime;
  streams: number;
  listeners: number;
}

export interface MediaInsightsAppleMusicTracks_media_network_analytics_AppleMusicTrackAnalytics {
  __typename: "AppleMusicTrackAnalytics";
  id: string;
  total_streams: number;
  total_listeners: number;
  data_points: (MediaInsightsAppleMusicTracks_media_network_analytics_AppleMusicTrackAnalytics_data_points | null)[];
}

export type MediaInsightsAppleMusicTracks_media_network_analytics = MediaInsightsAppleMusicTracks_media_network_analytics_FacebookVideoAnalytics | MediaInsightsAppleMusicTracks_media_network_analytics_AppleMusicTrackAnalytics;

export interface MediaInsightsAppleMusicTracks_media {
  __typename: "Media";
  id: string;
  type: MediaType;
  /**
   * Analytics for media on web and mobile are fetched here
   */
  network_analytics: MediaInsightsAppleMusicTracks_media_network_analytics;
}

export interface MediaInsightsAppleMusicTracks {
  /**
   * Find the current media by ID
   */
  media: MediaInsightsAppleMusicTracks_media;
}

export interface MediaInsightsAppleMusicTracksVariables {
  id: string;
  timePeriod: number;
  network: NetworkAnalyticsNetworkType;
  dateResolution: NetworkAnalyticsDateResolution;
  dataDelay: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaInsightsFacebookVideos
// ====================================================

export interface MediaInsightsFacebookVideos_media_network_analytics_AppleMusicTrackAnalytics {
  __typename: "AppleMusicTrackAnalytics" | "InstagramStoryAnalytics" | "InstagramVideoAnalytics" | "SpotifyTrackAnalytics" | "VevoVideoAnalytics" | "YoutubeVideoAnalytics";
  id: string;
}

export interface MediaInsightsFacebookVideos_media_network_analytics_FacebookVideoAnalytics_data_points {
  __typename: "FacebookDataPoint";
  date: Scalar$DateTime;
  views: number;
  likes: number;
  shares: number;
}

export interface MediaInsightsFacebookVideos_media_network_analytics_FacebookVideoAnalytics {
  __typename: "FacebookVideoAnalytics";
  id: string;
  total_views: number;
  total_likes: number;
  total_shares: number;
  data_points: (MediaInsightsFacebookVideos_media_network_analytics_FacebookVideoAnalytics_data_points | null)[];
}

export type MediaInsightsFacebookVideos_media_network_analytics = MediaInsightsFacebookVideos_media_network_analytics_AppleMusicTrackAnalytics | MediaInsightsFacebookVideos_media_network_analytics_FacebookVideoAnalytics;

export interface MediaInsightsFacebookVideos_media {
  __typename: "Media";
  id: string;
  /**
   * Analytics for media on web and mobile are fetched here
   */
  network_analytics: MediaInsightsFacebookVideos_media_network_analytics;
}

export interface MediaInsightsFacebookVideos {
  /**
   * Find the current media by ID
   */
  media: MediaInsightsFacebookVideos_media;
}

export interface MediaInsightsFacebookVideosVariables {
  id: string;
  dataDelay: number;
  timePeriod: number;
  network: NetworkAnalyticsNetworkType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaInsightsInstagramStories
// ====================================================

export interface MediaInsightsInstagramStories_media_network_analytics_AppleMusicTrackAnalytics {
  __typename: "AppleMusicTrackAnalytics" | "FacebookVideoAnalytics" | "InstagramVideoAnalytics" | "SpotifyTrackAnalytics" | "VevoVideoAnalytics" | "YoutubeVideoAnalytics";
  id: string;
}

export interface MediaInsightsInstagramStories_media_network_analytics_InstagramStoryAnalytics {
  __typename: "InstagramStoryAnalytics";
  id: string;
  impressions: number | null;
  reach: number | null;
  exits: number | null;
  taps_back: number | null;
  taps_forward: number | null;
}

export type MediaInsightsInstagramStories_media_network_analytics = MediaInsightsInstagramStories_media_network_analytics_AppleMusicTrackAnalytics | MediaInsightsInstagramStories_media_network_analytics_InstagramStoryAnalytics;

export interface MediaInsightsInstagramStories_media {
  __typename: "Media";
  id: string;
  /**
   * Analytics for media on web and mobile are fetched here
   */
  network_analytics: MediaInsightsInstagramStories_media_network_analytics;
}

export interface MediaInsightsInstagramStories {
  /**
   * Find the current media by ID
   */
  media: MediaInsightsInstagramStories_media;
}

export interface MediaInsightsInstagramStoriesVariables {
  id: string;
  network: NetworkAnalyticsNetworkType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaInsightsInstagramVideos
// ====================================================

export interface MediaInsightsInstagramVideos_media_network_analytics_AppleMusicTrackAnalytics {
  __typename: "AppleMusicTrackAnalytics" | "FacebookVideoAnalytics" | "InstagramStoryAnalytics" | "SpotifyTrackAnalytics" | "VevoVideoAnalytics" | "YoutubeVideoAnalytics";
  id: string;
}

export interface MediaInsightsInstagramVideos_media_network_analytics_InstagramVideoAnalytics_data_points {
  __typename: "InstagramDataPoint";
  date: Scalar$DateTime;
  views: number;
  impressions: number;
  reach: number;
  engagements: number;
}

export interface MediaInsightsInstagramVideos_media_network_analytics_InstagramVideoAnalytics {
  __typename: "InstagramVideoAnalytics";
  id: string;
  total_views: number;
  total_impressions: number;
  total_reach: number;
  total_engagements: number;
  data_points: (MediaInsightsInstagramVideos_media_network_analytics_InstagramVideoAnalytics_data_points | null)[];
}

export type MediaInsightsInstagramVideos_media_network_analytics = MediaInsightsInstagramVideos_media_network_analytics_AppleMusicTrackAnalytics | MediaInsightsInstagramVideos_media_network_analytics_InstagramVideoAnalytics;

export interface MediaInsightsInstagramVideos_media {
  __typename: "Media";
  id: string;
  /**
   * Analytics for media on web and mobile are fetched here
   */
  network_analytics: MediaInsightsInstagramVideos_media_network_analytics;
}

export interface MediaInsightsInstagramVideos {
  /**
   * Find the current media by ID
   */
  media: MediaInsightsInstagramVideos_media;
}

export interface MediaInsightsInstagramVideosVariables {
  id: string;
  dataDelay: number;
  timePeriod: number;
  network: NetworkAnalyticsNetworkType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaAnalytics
// ====================================================

export interface MediaAnalytics_media_insights_available_networks_with_oldest_date_of_data {
  __typename: "InsightsAvailableNetwork";
  id: string;
  network_name: NetworkAnalyticsNetworkType;
  oldest_date: Scalar$DateTime;
}

export interface MediaAnalytics_media {
  __typename: "Media";
  id: string;
  /**
   * List of networks for which analytics are available with the oldest date of data
   */
  insights_available_networks_with_oldest_date_of_data: MediaAnalytics_media_insights_available_networks_with_oldest_date_of_data[];
}

export interface MediaAnalytics {
  /**
   * Find the current media by ID
   */
  media: MediaAnalytics_media;
}

export interface MediaAnalyticsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaInsightsSpotifyTracks
// ====================================================

export interface MediaInsightsSpotifyTracks_media_network_analytics_AppleMusicTrackAnalytics {
  __typename: "AppleMusicTrackAnalytics" | "FacebookVideoAnalytics" | "InstagramStoryAnalytics" | "InstagramVideoAnalytics" | "VevoVideoAnalytics" | "YoutubeVideoAnalytics";
  id: string;
}

export interface MediaInsightsSpotifyTracks_media_network_analytics_SpotifyTrackAnalytics_data_points {
  __typename: "SpotifyDataPoint";
  date: Scalar$DateTime;
  streams: number;
}

export interface MediaInsightsSpotifyTracks_media_network_analytics_SpotifyTrackAnalytics {
  __typename: "SpotifyTrackAnalytics";
  id: string;
  total_streams: number;
  data_points: (MediaInsightsSpotifyTracks_media_network_analytics_SpotifyTrackAnalytics_data_points | null)[];
}

export type MediaInsightsSpotifyTracks_media_network_analytics = MediaInsightsSpotifyTracks_media_network_analytics_AppleMusicTrackAnalytics | MediaInsightsSpotifyTracks_media_network_analytics_SpotifyTrackAnalytics;

export interface MediaInsightsSpotifyTracks_media {
  __typename: "Media";
  id: string;
  /**
   * Analytics for media on web and mobile are fetched here
   */
  network_analytics: MediaInsightsSpotifyTracks_media_network_analytics;
}

export interface MediaInsightsSpotifyTracks {
  /**
   * Find the current media by ID
   */
  media: MediaInsightsSpotifyTracks_media;
}

export interface MediaInsightsSpotifyTracksVariables {
  id: string;
  timePeriod: number;
  network: NetworkAnalyticsNetworkType;
  dateResolution: NetworkAnalyticsDateResolution;
  dataDelay: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaInsightsVevoVideos
// ====================================================

export interface MediaInsightsVevoVideos_media_network_analytics_AppleMusicTrackAnalytics {
  __typename: "AppleMusicTrackAnalytics" | "FacebookVideoAnalytics" | "InstagramStoryAnalytics" | "InstagramVideoAnalytics" | "SpotifyTrackAnalytics" | "YoutubeVideoAnalytics";
  id: string;
}

export interface MediaInsightsVevoVideos_media_network_analytics_VevoVideoAnalytics_data_points {
  __typename: "YoutubeDataPoint";
  date: Scalar$DateTime;
  views: number;
  shares: number;
  likes: number;
  subscriptions_driven: number;
}

export interface MediaInsightsVevoVideos_media_network_analytics_VevoVideoAnalytics {
  __typename: "VevoVideoAnalytics";
  id: string;
  total_views: number;
  total_shares: number;
  total_likes: number;
  total_subscriptions_driven: number;
  data_points: (MediaInsightsVevoVideos_media_network_analytics_VevoVideoAnalytics_data_points | null)[];
}

export type MediaInsightsVevoVideos_media_network_analytics = MediaInsightsVevoVideos_media_network_analytics_AppleMusicTrackAnalytics | MediaInsightsVevoVideos_media_network_analytics_VevoVideoAnalytics;

export interface MediaInsightsVevoVideos_media {
  __typename: "Media";
  id: string;
  /**
   * Analytics for media on web and mobile are fetched here
   */
  network_analytics: MediaInsightsVevoVideos_media_network_analytics;
}

export interface MediaInsightsVevoVideos {
  /**
   * Find the current media by ID
   */
  media: MediaInsightsVevoVideos_media;
}

export interface MediaInsightsVevoVideosVariables {
  id: string;
  timePeriod: number;
  network: NetworkAnalyticsNetworkType;
  dateResolution: NetworkAnalyticsDateResolution;
  dataDelay: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaInsightsYoutubeVideos
// ====================================================

export interface MediaInsightsYoutubeVideos_media_network_analytics_AppleMusicTrackAnalytics {
  __typename: "AppleMusicTrackAnalytics" | "FacebookVideoAnalytics" | "InstagramStoryAnalytics" | "InstagramVideoAnalytics" | "SpotifyTrackAnalytics" | "VevoVideoAnalytics";
  id: string;
}

export interface MediaInsightsYoutubeVideos_media_network_analytics_YoutubeVideoAnalytics_data_points {
  __typename: "YoutubeDataPoint";
  date: Scalar$DateTime;
  views: number;
  shares: number;
  likes: number;
  subscriptions_driven: number;
}

export interface MediaInsightsYoutubeVideos_media_network_analytics_YoutubeVideoAnalytics {
  __typename: "YoutubeVideoAnalytics";
  id: string;
  total_views: number;
  total_shares: number;
  total_likes: number;
  total_subscriptions_driven: number;
  data_points: (MediaInsightsYoutubeVideos_media_network_analytics_YoutubeVideoAnalytics_data_points | null)[];
}

export type MediaInsightsYoutubeVideos_media_network_analytics = MediaInsightsYoutubeVideos_media_network_analytics_AppleMusicTrackAnalytics | MediaInsightsYoutubeVideos_media_network_analytics_YoutubeVideoAnalytics;

export interface MediaInsightsYoutubeVideos_media {
  __typename: "Media";
  id: string;
  /**
   * Analytics for media on web and mobile are fetched here
   */
  network_analytics: MediaInsightsYoutubeVideos_media_network_analytics;
}

export interface MediaInsightsYoutubeVideos {
  /**
   * Find the current media by ID
   */
  media: MediaInsightsYoutubeVideos_media;
}

export interface MediaInsightsYoutubeVideosVariables {
  id: string;
  timePeriod: number;
  network: NetworkAnalyticsNetworkType;
  dateResolution: NetworkAnalyticsDateResolution;
  dataDelay: number;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaDetailsCreateReleaseModal
// ====================================================

export interface MediaDetailsCreateReleaseModal_media_metadata {
  __typename: "Metadata";
  id: string;
  lyrics: string;
}

export interface MediaDetailsCreateReleaseModal_media {
  __typename: "Media";
  id: string;
  /**
   * Media has a broadcast that was distributed to a social
   */
  has_distributed_to_social: boolean;
  /**
   * Media has a broadcast that was distributed to a social
   */
  has_distributed_to_partner: boolean;
  /**
   * Media has a broadcast that was distributed to a social
   */
  has_distributed_to_network: boolean;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  metadata: MediaDetailsCreateReleaseModal_media_metadata | null;
}

export interface MediaDetailsCreateReleaseModal_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has a feature
   */
  has_feature_distribute_service_access: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_skip_socials: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_paid_services: boolean;
  /**
   * User has a paid storage tier or storage override
   */
  is_paid_storage_user: boolean;
}

export interface MediaDetailsCreateReleaseModal {
  /**
   * Find the current media by ID
   */
  media: MediaDetailsCreateReleaseModal_media;
  /**
   * Get information about the currently logged in user
   */
  me: MediaDetailsCreateReleaseModal_me;
}

export interface MediaDetailsCreateReleaseModalVariables {
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaDetailsHeader
// ====================================================

export interface MediaDetailsHeader_me {
  __typename: "User";
  id: string;
  /**
   * Flag for a user has a feature
   */
  has_feature_d2_dev: boolean;
}

export interface MediaDetailsHeader_media_artist {
  __typename: "Artist";
  id: string;
}

export interface MediaDetailsHeader_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface MediaDetailsHeader_media {
  __typename: "Media";
  id: string;
  invalid_reasons: InvalidMediaReason[];
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  artist: MediaDetailsHeader_media_artist | null;
  metadata: MediaDetailsHeader_media_metadata | null;
  thumbnail_url: string | null;
  type: MediaType;
  has_available_audio_analytics: boolean;
  has_available_video_analytics: boolean;
  has_facebook_analytics: boolean;
}

export interface MediaDetailsHeader {
  /**
   * Get information about the currently logged in user
   */
  me: MediaDetailsHeader_me;
  /**
   * Find the current media by ID
   */
  media: MediaDetailsHeader_media;
}

export interface MediaDetailsHeaderVariables {
  mediaID: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaMetadataEditForAudio
// ====================================================

export interface MediaMetadataEditForAudio_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface MediaMetadataEditForAudio_media_languages {
  __typename: "MediaLanguage";
  id: string;
  name: string;
}

export interface MediaMetadataEditForAudio_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface MediaMetadataEditForAudio_media_metadata_additionalArtists {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface MediaMetadataEditForAudio_media_metadata_additionalCredits {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface MediaMetadataEditForAudio_media_metadata_writerCredits {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface MediaMetadataEditForAudio_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * TikTok start time
   */
  clip_start_time: Scalar$TrackPosition | null;
  copyright_name: string;
  copyright_year: number | null;
  /**
   * Origin Country of recording
   */
  country_of_recording: string | null;
  /**
   * Country of recording country code enum for the metadata
   */
  country_of_recording_enum: CountryCode | null;
  /**
   * Genre enum for the media associated to this metadata
   */
  genre_enum: Genre | null;
  /**
   * Secondary Genre enum for the media associated to this metadata
   */
  secondary_genre_enum: Genre | null;
  /**
   * Flag for the media associated with this metadata is a cover
   */
  is_cover: boolean | null;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
  /**
   * Unique Musical Work Code
   */
  iswc: string | null;
  label: string;
  /**
   * Two letter language code to identify the language that this media is in
   */
  language_code: string | null;
  /**
   * Language code enum for the media associated to this metadata
   */
  language_code_enum: MediaLanguageCode | null;
  lyrics: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  additionalArtists: MediaMetadataEditForAudio_media_metadata_additionalArtists[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  additionalCredits: MediaMetadataEditForAudio_media_metadata_additionalCredits[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  writerCredits: MediaMetadataEditForAudio_media_metadata_writerCredits[];
}

export interface MediaMetadataEditForAudio_media {
  __typename: "Media";
  id: string;
  pending_sync_intervention: boolean;
  artist: MediaMetadataEditForAudio_media_artist | null;
  metadata: MediaMetadataEditForAudio_media_metadata | null;
}

export interface MediaMetadataEditForAudio {
  /**
   * Gets all the available Genres from our system
   */
  genres: MediaMetadataEditForAudio_genres[];
  /**
   * Gets all the available languages for an audio track.
   */
  media_languages: MediaMetadataEditForAudio_media_languages[];
  /**
   * Find the current media by ID
   */
  media: MediaMetadataEditForAudio_media;
}

export interface MediaMetadataEditForAudioVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaMetadataEditForComp
// ====================================================

export interface MediaMetadataEditForComp_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface MediaMetadataEditForComp_media_languages {
  __typename: "MediaLanguage";
  id: string;
  name: string;
}

export interface MediaMetadataEditForComp_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface MediaMetadataEditForComp_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  copyright_name: string;
  copyright_year: number | null;
  /**
   * Genre enum for the media associated to this metadata
   */
  genre_enum: Genre | null;
  /**
   * Secondary Genre enum for the media associated to this metadata
   */
  secondary_genre_enum: Genre | null;
  /**
   * Unique Musical Work Code
   */
  iswc: string | null;
  label: string;
  lyrics: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface MediaMetadataEditForComp_media {
  __typename: "Media";
  id: string;
  artist: MediaMetadataEditForComp_media_artist | null;
  metadata: MediaMetadataEditForComp_media_metadata | null;
}

export interface MediaMetadataEditForComp {
  /**
   * Gets all the available Genres from our system
   */
  genres: MediaMetadataEditForComp_genres[];
  /**
   * Gets all the available languages for an audio track.
   */
  media_languages: MediaMetadataEditForComp_media_languages[];
  /**
   * Find the current media by ID
   */
  media: MediaMetadataEditForComp_media;
}

export interface MediaMetadataEditForCompVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaMetadataEditForVideo
// ====================================================

export interface MediaMetadataEditForVideo_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface MediaMetadataEditForVideo_media_subcategories {
  __typename: "MediaSubCategory";
  id: string;
  label: string;
}

export interface MediaMetadataEditForVideo_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface MediaMetadataEditForVideo_media_metadata_additionalArtists {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface MediaMetadataEditForVideo_media_metadata_additionalCredits {
  __typename: "CollaboratingArtist";
  /**
   * apple id
   */
  apple_eid: string | null;
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
  /**
   * spotify id
   */
  spotify_eid: string | null;
}

export interface MediaMetadataEditForVideo_media_metadata_writerCredits {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface MediaMetadataEditForVideo_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Album Name that this media metadata is associated with
   */
  album: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * Unique Musical Work Code
   */
  iswc: string | null;
  copyright_name: string;
  copyright_year: number | null;
  director: string;
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  additionalArtists: MediaMetadataEditForVideo_media_metadata_additionalArtists[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  additionalCredits: MediaMetadataEditForVideo_media_metadata_additionalCredits[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  writerCredits: MediaMetadataEditForVideo_media_metadata_writerCredits[];
  /**
   * Genre for the media associated to this metadata
   */
  genre: string;
  /**
   * Genre enum for the media associated to this metadata
   */
  genre_enum: Genre | null;
  label: string;
  lyrics: string;
  production_company: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * Unique code for video
   */
  video_isrc: string;
  video_producer: string;
  /**
   * Flag for the media associated with this metadata is a cover
   */
  is_cover: boolean | null;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
}

export interface MediaMetadataEditForVideo_media {
  __typename: "Media";
  id: string;
  /**
   * Flag for this media's category can be changed
   */
  can_change_category: boolean;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  pending_sync_intervention: boolean;
  /**
   * Subcategory of the media
   */
  subcategory: string | null;
  video_type: SupportedVideo | null;
  artist: MediaMetadataEditForVideo_media_artist | null;
  metadata: MediaMetadataEditForVideo_media_metadata | null;
}

export interface MediaMetadataEditForVideo_current_observer {
  __typename: "User";
  id: string;
  is_admin: boolean;
}

export interface MediaMetadataEditForVideo {
  /**
   * Gets all the available Genres from our system
   */
  genres: MediaMetadataEditForVideo_genres[];
  /**
   * Gets all the available media sub categories for our system
   */
  media_subcategories: MediaMetadataEditForVideo_media_subcategories[];
  /**
   * Find the current media by ID
   */
  media: MediaMetadataEditForVideo_media;
  /**
   * Gets all the supported video types
   */
  supported_video_types: SupportedVideo[];
  current_observer: MediaMetadataEditForVideo_current_observer | null;
}

export interface MediaMetadataEditForVideoVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PostHistory
// ====================================================

export interface PostHistory_media_broadcasts_cursor_social_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface PostHistory_media_broadcasts_cursor_social {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  /**
   * Get the size of the thumbnail
   */
  thumbnail_url: string;
  /**
   * Title of this Social
   */
  title: string | null;
  /**
   * Network Object for a network associated with a social
   */
  network: PostHistory_media_broadcasts_cursor_social_network | null;
}

export interface PostHistory_media_broadcasts_cursor_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface PostHistory_media_broadcasts_cursor {
  __typename: "Broadcast";
  id: string;
  video_url: string | null;
  status: string;
  deploy_at: Scalar$DateTime | null;
  distributed_at: Scalar$DateTime | null;
  /**
   * Determines which social does this broadcast belong to
   */
  social: PostHistory_media_broadcasts_cursor_social | null;
  /**
   * Network associated with a broadcast
   */
  network: PostHistory_media_broadcasts_cursor_network;
}

export interface PostHistory_media {
  __typename: "Media";
  id: string;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  /**
   * Count of broadcasts for a media
   */
  broadcasts_count: number;
  /**
   * Broadcasts for a media
   */
  broadcasts_cursor: PostHistory_media_broadcasts_cursor[];
}

export interface PostHistory {
  /**
   * Find the current media by ID
   */
  media: PostHistory_media;
}

export interface PostHistoryVariables {
  id: string;
  input?: BroadcastsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaInfoTab
// ====================================================

export interface MediaInfoTab_media_subcategories {
  __typename: "MediaSubCategory";
  id: string;
  label: string;
}

export interface MediaInfoTab_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface MediaInfoTab_media_file_upload {
  __typename: "FileUpload";
  id: string;
  duration_in_ms: number | null;
  file_extension: string | null;
  size_in_bytes: number | null;
  type: string | null;
}

export interface MediaInfoTab_media_metadata_policies_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
  name: string;
  /**
   * The values can either be distribute or monetize
   */
  service: string | null;
}

export interface MediaInfoTab_media_metadata_policies {
  __typename: "Policy";
  id: string;
  type: string;
  /**
   * Deployment status of the policy for this metadata policy
   */
  status: MediaPolicyStatus;
  /**
   * Specifies the time when status update has happened
   */
  status_changed_at: Scalar$DateTime;
  network: MediaInfoTab_media_metadata_policies_network;
}

export interface MediaInfoTab_media_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface MediaInfoTab_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Album Name that this media metadata is associated with
   */
  album: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * Unique Musical Work Code
   */
  iswc: string | null;
  /**
   * TikTok start time
   */
  clip_start_time: Scalar$TrackPosition | null;
  copyright_name: string;
  copyright_year: number | null;
  /**
   * Origin Country of recording
   */
  country_of_recording: string | null;
  /**
   * Country of recording country code enum for the metadata
   */
  country_of_recording_enum: CountryCode | null;
  director: string;
  /**
   * Flag for the media associated with this metadata has full rights to the current user
   */
  has_full_rights: boolean;
  /**
   * Flag for the media associated with this metadata is a cover
   */
  is_cover: boolean | null;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
  /**
   * Genre for the media associated to this metadata
   */
  genre: string;
  secondary_genre: string | null;
  /**
   * Flag for the media associated with this metadata has partial rights to the current user
   */
  has_partial_rights: boolean;
  label: string;
  /**
   * Name of the language that corresponds to the metadata's language code
   */
  pretty_language: string | null;
  lyrics: string;
  production_company: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * Unique code for video
   */
  video_isrc: string;
  video_producer: string;
  /**
   * Returns an array of policies 
   */
  policies: MediaInfoTab_media_metadata_policies[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  collaborating_artists: MediaInfoTab_media_metadata_collaborating_artists[];
}

export interface MediaInfoTab_media_releases {
  __typename: "Release";
  id: string;
  /**
   * count of release messages
   */
  messages_count: number;
}

export interface MediaInfoTab_media_albums_v2_cursor_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface MediaInfoTab_media_albums_v2_cursor_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface MediaInfoTab_media_albums_v2_cursor {
  __typename: "Album";
  id: string;
  metadata: MediaInfoTab_media_albums_v2_cursor_metadata;
  file_upload: MediaInfoTab_media_albums_v2_cursor_file_upload | null;
}

export interface MediaInfoTab_media {
  __typename: "Media";
  id: string;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  /**
   * Subcategory of the media
   */
  subcategory: string | null;
  /**
   * If true, a file has been uploaded and is being imported into the system. If false, either there is no file or it's already been imported and can be viewed.
   */
  is_importing: boolean;
  is_distributed: boolean;
  pretty_client_revenue: string;
  created_at: Scalar$DateTime;
  child_type: string | null;
  mp4_url: string | null;
  thumbnail_url: string | null;
  original_url: string | null;
  generator: string | null;
  /**
   * Random string that acts a identification key on user front
   */
  upload_key: string;
  type: MediaType;
  belongs_to_published_album: boolean;
  video_type: SupportedVideo | null;
  artist: MediaInfoTab_media_artist | null;
  /**
   * Actual file associated to this media
   */
  file_upload: MediaInfoTab_media_file_upload | null;
  metadata: MediaInfoTab_media_metadata | null;
  /**
   * List of releases for the media
   */
  releases: MediaInfoTab_media_releases[];
  /**
   * Count of albums for the media
   */
  albums_v2_count: number;
  /**
   * List out all the albums for media
   */
  albums_v2_cursor: MediaInfoTab_media_albums_v2_cursor[];
}

export interface MediaInfoTab {
  /**
   * Gets all the available media sub categories for our system
   */
  media_subcategories: MediaInfoTab_media_subcategories[];
  /**
   * Find the current media by ID
   */
  media: MediaInfoTab_media;
}

export interface MediaInfoTabVariables {
  id: string;
  input?: AlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: EditMediaOwnershipAndPolicies
// ====================================================

export interface EditMediaOwnershipAndPolicies_media_file_upload {
  __typename: "FileUpload";
  id: string;
  duration_in_ms: number | null;
}

export interface EditMediaOwnershipAndPolicies_media_metadata_rights_composer {
  __typename: "Composer";
  id: string;
}

export interface EditMediaOwnershipAndPolicies_media_metadata_rights {
  __typename: "Right";
  id: string;
  type: RightType;
  /**
   * percentage of ownership owned by an artist that asserted this right
   */
  ownership_percentage: number;
  owner_distributes_revenue: boolean | null;
  /**
   * List of country codes where the restrictions apply
   */
  geo_restrictions: string[];
  /**
   * Flag for the restriction includes a region
   */
  geo_include: boolean;
  /**
   * Composer object for the right
   */
  composer: EditMediaOwnershipAndPolicies_media_metadata_rights_composer | null;
}

export interface EditMediaOwnershipAndPolicies_media_metadata_policies_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface EditMediaOwnershipAndPolicies_media_metadata_policies {
  __typename: "Policy";
  id: string;
  /**
   * Values are permitted only to Permit | Monetize | Block
   */
  type_2: MediaPolicyType;
  /**
   * Deployment status of the policy for this metadata policy
   */
  status: MediaPolicyStatus;
  network: EditMediaOwnershipAndPolicies_media_metadata_policies_network;
}

export interface EditMediaOwnershipAndPolicies_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Returns an array if rights that are asserted by the user of the media associated with this metadata
   */
  rights: EditMediaOwnershipAndPolicies_media_metadata_rights[];
  /**
   * Returns an array of policies 
   */
  policies: EditMediaOwnershipAndPolicies_media_metadata_policies[];
  /**
   * Flag for the media associated with this metadata is a cover
   */
  is_cover: boolean | null;
}

export interface EditMediaOwnershipAndPolicies_media {
  __typename: "Media";
  id: string;
  /**
   * Flag to indicate whether or not the media can be represented with Music Video Rights
   */
  supports_music_video_rights: boolean;
  /**
   * Flag to indicate whether or not the media can be represented with Sound Recording Rights
   */
  supports_sound_recording_rights: boolean;
  /**
   * Flag to indicate whether or not the media can be represented with Composition Rights
   */
  supports_composition_rights: boolean;
  /**
   * Flag to indicate whether or not the media can be represented with Web Rights (a.k.a Exclusive Rights)
   */
  supports_web_rights: boolean;
  /**
   * Actual file associated to this media
   */
  file_upload: EditMediaOwnershipAndPolicies_media_file_upload | null;
  metadata: EditMediaOwnershipAndPolicies_media_metadata | null;
}

export interface EditMediaOwnershipAndPolicies {
  /**
   * Find the current media by ID
   */
  media: EditMediaOwnershipAndPolicies_media;
}

export interface EditMediaOwnershipAndPoliciesVariables {
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaOwnershipAndPolicies
// ====================================================

export interface MediaOwnershipAndPolicies_media_metadata_rights_composer {
  __typename: "Composer";
  id: string;
  email: string | null;
  first_name: string;
  middle_name: string | null;
  last_name: string;
  full_name: string;
  ipi: string | null;
  perf_rights_org_name: string;
  publisher: string;
  publisher_ipi: string | null;
}

export interface MediaOwnershipAndPolicies_media_metadata_rights {
  __typename: "Right";
  id: string;
  type: RightType;
  /**
   * percentage of ownership owned by an artist that asserted this right
   */
  ownership_percentage: number;
  /**
   * List of country codes where the restrictions apply
   */
  geo_restrictions: string[];
  /**
   * Flag for the restriction includes a region
   */
  geo_include: boolean;
  owner_distributes_revenue: boolean | null;
  /**
   * Composer object for the right
   */
  composer: MediaOwnershipAndPolicies_media_metadata_rights_composer | null;
}

export interface MediaOwnershipAndPolicies_media_metadata_policies_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface MediaOwnershipAndPolicies_media_metadata_policies {
  __typename: "Policy";
  id: string;
  /**
   * Values are permitted only to Permit | Monetize | Block
   */
  type_2: MediaPolicyType;
  /**
   * Values are permitted to only be From Inception | From Specific Date | No Claim
   */
  claim_type: SoundExchangePolicyType | null;
  /**
   * Start date for SoundExchange policies if claiming from a specific date
   */
  claim_from_date: Scalar$Date | null;
  /**
   * Deployment status of the policy for this metadata policy
   */
  status: MediaPolicyStatus;
  release_confirmed: boolean;
  network: MediaOwnershipAndPolicies_media_metadata_policies_network;
}

export interface MediaOwnershipAndPolicies_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Returns an array if rights that are asserted by the user of the media associated with this metadata
   */
  rights: MediaOwnershipAndPolicies_media_metadata_rights[];
  /**
   * Returns an array of policies 
   */
  policies: MediaOwnershipAndPolicies_media_metadata_policies[];
}

export interface MediaOwnershipAndPolicies_media {
  __typename: "Media";
  id: string;
  /**
   * Flag to indicate whether or not the media can be represented with Music Video Rights
   */
  supports_music_video_rights: boolean;
  /**
   * Flag to indicate whether or not the media can be represented with Sound Recording Rights
   */
  supports_sound_recording_rights: boolean;
  /**
   * Flag to indicate whether or not the media can be represented with Composition Rights
   */
  supports_composition_rights: boolean;
  /**
   * Flag to indicate whether or not the media can be represented with Web Rights (a.k.a Exclusive Rights)
   */
  supports_web_rights: boolean;
  metadata: MediaOwnershipAndPolicies_media_metadata | null;
}

export interface MediaOwnershipAndPolicies {
  /**
   * Find the current media by ID
   */
  media: MediaOwnershipAndPolicies_media;
}

export interface MediaOwnershipAndPoliciesVariables {
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MediaDetails
// ====================================================

export interface MediaDetails_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface MediaDetails_media {
  __typename: "Media";
  id: string;
  type: MediaType;
}

export interface MediaDetails {
  /**
   * Get information about the currently logged in user
   */
  me: MediaDetails_me;
  /**
   * Find the current media by ID
   */
  media: MediaDetails_media;
}

export interface MediaDetailsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MerchItemEdit
// ====================================================

export interface MerchItemEdit_merch_item_design_variants {
  __typename: "DesignVariant";
  id: string;
  accepted_at: Scalar$DateTime | null;
  /**
   * image of the design, smaller and thumbnail sized. Shown displayed on the actual merch. Must be null during initial generation, because the id is used in the s3 key in the URL.
   */
  design_image_thumbnail_url: string | null;
  /**
   * image of the design. Must be null during initial generation, because the id is used in the s3 key in the URL.
   */
  design_image_print_url: string | null;
  canvas_color: string;
  /**
   * s3 path to a custom image to use for the design variant, uploaded by the user, or a vydia designer. Null if no custom image is used by this variant.
   */
  custom_image_s3_path: string | null;
}

export interface MerchItemEdit_merch_item {
  __typename: "MerchItem";
  id: string;
  album_id: string;
  design_variants: MerchItemEdit_merch_item_design_variants[];
}

export interface MerchItemEdit {
  /**
   * Find the current merch item by ID
   */
  merch_item: MerchItemEdit_merch_item;
}

export interface MerchItemEditVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MerchAgreement
// ====================================================

export interface MerchAgreement_me {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user has ever signed an agreement OR Whether or not a user has signed a specific agreement by type
   */
  has_signed_merch_agreement: boolean;
  render_agreement_by_type: string;
}

export interface MerchAgreement {
  /**
   * Get information about the currently logged in user
   */
  me: MerchAgreement_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: RegenerateMerchItemVariants
// ====================================================

export interface RegenerateMerchItemVariants_regenerateMerchItemVariants_merch_item_design_variants {
  __typename: "DesignVariant";
  id: string;
  accepted_at: Scalar$DateTime | null;
  /**
   * image of the design, smaller and thumbnail sized. Shown displayed on the actual merch. Must be null during initial generation, because the id is used in the s3 key in the URL.
   */
  design_image_thumbnail_url: string | null;
  /**
   * image of the design. Must be null during initial generation, because the id is used in the s3 key in the URL.
   */
  design_image_print_url: string | null;
  canvas_color: string;
  /**
   * s3 path to a custom image to use for the design variant, uploaded by the user, or a vydia designer. Null if no custom image is used by this variant.
   */
  custom_image_s3_path: string | null;
}

export interface RegenerateMerchItemVariants_regenerateMerchItemVariants_merch_item {
  __typename: "MerchItem";
  id: string;
  album_id: string;
  design_variants: RegenerateMerchItemVariants_regenerateMerchItemVariants_merch_item_design_variants[];
}

export interface RegenerateMerchItemVariants_regenerateMerchItemVariants_errors {
  __typename: "UserError";
  resource_id: string | null;
  resource_type: string | null;
  key: string | null;
  messages: string[];
}

export interface RegenerateMerchItemVariants_regenerateMerchItemVariants {
  __typename: "RegenerateMerchItemVariantsPayload";
  merch_item: RegenerateMerchItemVariants_regenerateMerchItemVariants_merch_item | null;
  errors: RegenerateMerchItemVariants_regenerateMerchItemVariants_errors[] | null;
}

export interface RegenerateMerchItemVariants {
  /**
   * Generate new design variants for a merch item. This is invoked once automatically when the SmartLink is ready, and can be invoked manually in the MerchItemEdit screen.
   */
  regenerateMerchItemVariants: RegenerateMerchItemVariants_regenerateMerchItemVariants;
}

export interface RegenerateMerchItemVariantsVariables {
  input: RegenerateMerchItemVariantsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptMerchAgreement
// ====================================================

export interface AcceptMerchAgreement_acceptAgreementByType_signed_agreement {
  __typename: "SignedAgreement";
  id: string;
}

export interface AcceptMerchAgreement_acceptAgreementByType_errors {
  __typename: "UserError";
  resource_id: string | null;
  resource_type: string | null;
  key: string | null;
  messages: string[];
}

export interface AcceptMerchAgreement_acceptAgreementByType {
  __typename: "AcceptAgreementByTypePayload";
  /**
   * The new signed_agreement, if created.
   */
  signed_agreement: AcceptMerchAgreement_acceptAgreementByType_signed_agreement | null;
  errors: AcceptMerchAgreement_acceptAgreementByType_errors[] | null;
}

export interface AcceptMerchAgreement {
  /**
   * Accept an agreement by type, for when the agreement type is not one of the types that has a dedicated mutation.
   */
  acceptAgreementByType: AcceptMerchAgreement_acceptAgreementByType;
}

export interface AcceptMerchAgreementVariables {
  input: AcceptAgreementByTypeInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmMerchItemDesignVariant
// ====================================================

export interface ConfirmMerchItemDesignVariant_confirmMerchItemDesignVariant_merch_item_design_variants {
  __typename: "DesignVariant";
  id: string;
  accepted_at: Scalar$DateTime | null;
  /**
   * image of the design, smaller and thumbnail sized. Shown displayed on the actual merch. Must be null during initial generation, because the id is used in the s3 key in the URL.
   */
  design_image_thumbnail_url: string | null;
  /**
   * image of the design. Must be null during initial generation, because the id is used in the s3 key in the URL.
   */
  design_image_print_url: string | null;
  canvas_color: string;
  /**
   * s3 path to a custom image to use for the design variant, uploaded by the user, or a vydia designer. Null if no custom image is used by this variant.
   */
  custom_image_s3_path: string | null;
}

export interface ConfirmMerchItemDesignVariant_confirmMerchItemDesignVariant_merch_item {
  __typename: "MerchItem";
  id: string;
  album_id: string;
  design_variants: ConfirmMerchItemDesignVariant_confirmMerchItemDesignVariant_merch_item_design_variants[];
}

export interface ConfirmMerchItemDesignVariant_confirmMerchItemDesignVariant_errors {
  __typename: "UserError";
  resource_id: string | null;
  resource_type: string | null;
  key: string | null;
  messages: string[];
}

export interface ConfirmMerchItemDesignVariant_confirmMerchItemDesignVariant {
  __typename: "ConfirmMerchItemDesignVariantPayload";
  merch_item: ConfirmMerchItemDesignVariant_confirmMerchItemDesignVariant_merch_item | null;
  errors: ConfirmMerchItemDesignVariant_confirmMerchItemDesignVariant_errors[] | null;
}

export interface ConfirmMerchItemDesignVariant {
  /**
   * Confirm the selected design variant for a merch item, and make it available for sale on the Shopify store.
   */
  confirmMerchItemDesignVariant: ConfirmMerchItemDesignVariant_confirmMerchItemDesignVariant;
}

export interface ConfirmMerchItemDesignVariantVariables {
  input: ConfirmMerchItemDesignVariantInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationAdvances
// ====================================================

export interface OrganizationAdvances_me_organization_cash_advance_recoupments_cursor_recoupment_items {
  __typename: "RecoupmentItem";
  id: string;
  description: string;
  category: string;
  amount: number;
}

export interface OrganizationAdvances_me_organization_cash_advance_recoupments_cursor_user {
  __typename: "User";
  id: string;
  full_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface OrganizationAdvances_me_organization_cash_advance_recoupments_cursor {
  __typename: "CashAdvanceRecoupment";
  id: string;
  before_split: number;
  created_at: Scalar$DateTime;
  recouped_at: Scalar$DateTime | null;
  pretty_total_amount: string;
  pretty_amount_recouped: string;
  /**
   * Returns an array of recoupment items
   */
  recoupment_items: OrganizationAdvances_me_organization_cash_advance_recoupments_cursor_recoupment_items[];
  user: OrganizationAdvances_me_organization_cash_advance_recoupments_cursor_user;
}

export interface OrganizationAdvances_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of cash advance recoupments for organization
   */
  completed_count: number;
  /**
   * Number of cash advance recoupments for organization
   */
  cash_advance_recoupments_count: number;
  /**
   * Cash advance recoupments for organization
   */
  cash_advance_recoupments_cursor: OrganizationAdvances_me_organization_cash_advance_recoupments_cursor[];
}

export interface OrganizationAdvances_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationAdvances_me_organization | null;
}

export interface OrganizationAdvances {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationAdvances_me;
}

export interface OrganizationAdvancesVariables {
  input?: CashAdvanceRecoupmentsCursorInput | null;
  completed_input?: CashAdvanceRecoupmentsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationDetailsHeader
// ====================================================

export interface OrganizationDetailsHeader_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface OrganizationDetailsHeader_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationDetailsHeader_me_organization | null;
}

export interface OrganizationDetailsHeader {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationDetailsHeader_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationDetails
// ====================================================

export interface OrganizationDetails_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface OrganizationDetails_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationDetails_me_organization | null;
}

export interface OrganizationDetails {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationDetails_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: UpdateOrganization
// ====================================================

export interface UpdateOrganization_updateOrganization_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface UpdateOrganization_updateOrganization_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface UpdateOrganization_updateOrganization {
  __typename: "UpdateOrganizationPayload";
  organization: UpdateOrganization_updateOrganization_organization | null;
  errors: UpdateOrganization_updateOrganization_errors[] | null;
}

export interface UpdateOrganization {
  /**
   * Update an Organization. This mutation returns an Organization object.
   */
  updateOrganization: UpdateOrganization_updateOrganization;
}

export interface UpdateOrganizationVariables {
  input: UpdateOrganizationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationUsers
// ====================================================

export interface OrganizationUsers_me_organization_users_cursor {
  __typename: "User";
  id: string;
  first_name: string | null;
  last_name: string | null;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * Flag for the email is confirmed
   */
  email_confirmed: boolean;
  /**
   * When the user created their profile
   */
  created_at: Scalar$DateTime;
  /**
   * pretty_client_revenue using materialized views and only including locked-in revenue (not including pending revenue)
   */
  pretty_client_revenue_royalty_center: string;
  organization_royalty_split: number | null;
  /**
   * Does this user have any medias with assets that have claims on them?
   */
  any_claims_on_my_own_assets: boolean;
}

export interface OrganizationUsers_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of users in organization
   */
  all_users_count: number;
  /**
   * Number of users in organization
   */
  accepted_users_count: number;
  /**
   * Number of users in organization
   */
  pending_users_count: number;
  /**
   * Number of users in organization
   */
  users_count: number;
  /**
   * Users in organization
   */
  users_cursor: OrganizationUsers_me_organization_users_cursor[];
}

export interface OrganizationUsers_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationUsers_me_organization | null;
}

export interface OrganizationUsers {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationUsers_me;
}

export interface OrganizationUsersVariables {
  input?: UsersCursorInput | null;
  all_users_input?: UsersCursorInput | null;
  accepted_users_input?: UsersCursorInput | null;
  pending_users_input?: UsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationUser
// ====================================================

export interface OrganizationUser_me_organization_user {
  __typename: "User";
  id: string;
  /**
   * User's native country code if one is present
   */
  native_country_code: CountryCode | null;
  /**
   * Email Address for a user
   */
  email: string;
  first_name: string | null;
  last_name: string | null;
  /**
   * Should client receive the payments? or the organization
   */
  has_label_revenue: boolean;
  /**
   * Flag for the email is confirmed
   */
  email_confirmed: boolean;
  /**
   * Count of medias
   */
  medias_v2_count: number;
  organization_royalty_split: number | null;
  phone: string | null;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface OrganizationUser_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * User in this organization
   */
  user: OrganizationUser_me_organization_user | null;
}

export interface OrganizationUser_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationUser_me_organization | null;
}

export interface OrganizationUser {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationUser_me;
}

export interface OrganizationUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PasswordConfirmation
// ====================================================

export interface PasswordConfirmation {
  password_authorized_until: Scalar$DateTime | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ConfirmPassword
// ====================================================

export interface ConfirmPassword_confirmPassword_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ConfirmPassword_confirmPassword {
  __typename: "ConfirmPasswordPayload";
  errors: ConfirmPassword_confirmPassword_errors[] | null;
  /**
   * Authorized until timestamp
   */
  password_authorized_until: Scalar$DateTime | null;
}

export interface ConfirmPassword {
  /**
   * Confirm a password for a User. This mutation returns a User object. Used in the password confirmation screen.
   */
  confirmPassword: ConfirmPassword_confirmPassword;
}

export interface ConfirmPasswordVariables {
  input: ConfirmPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ResendPayeeInviteButton
// ====================================================

export interface ResendPayeeInviteButton_me_payee_invite_payee_assets_cursor {
  __typename: "PayeeAssets";
  count: number;
}

export interface ResendPayeeInviteButton_me_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  payee_assets_cursor: ResendPayeeInviteButton_me_payee_invite_payee_assets_cursor;
}

export interface ResendPayeeInviteButton_me {
  __typename: "User";
  id: string;
  /**
   * Get payee invite by id
   */
  payee_invite: ResendPayeeInviteButton_me_payee_invite | null;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface ResendPayeeInviteButton {
  /**
   * Get information about the currently logged in user
   */
  me: ResendPayeeInviteButton_me;
}

export interface ResendPayeeInviteButtonVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayeeDetailsPayeeInfoCard
// ====================================================

export interface PayeeDetailsPayeeInfoCard_me_payee_invite_payee {
  __typename: "User";
  id: string;
  /**
   * Is a charitable user
   */
  charity: boolean;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
  full_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
  phone: string | null;
}

export interface PayeeDetailsPayeeInfoCard_me_payee_invite_active_assets {
  __typename: "PayeeAssets";
  count: number;
}

export interface PayeeDetailsPayeeInfoCard_me_payee_invite_pending_assets {
  __typename: "PayeeAssets";
  count: number;
}

export interface PayeeDetailsPayeeInfoCard_me_payee_invite_accepted_assets {
  __typename: "PayeeAssets";
  count: number;
}

export interface PayeeDetailsPayeeInfoCard_me_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee phone number
   */
  phone_number: string;
  /**
   * The payee user
   */
  payee: PayeeDetailsPayeeInfoCard_me_payee_invite_payee | null;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  active_assets: PayeeDetailsPayeeInfoCard_me_payee_invite_active_assets;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  pending_assets: PayeeDetailsPayeeInfoCard_me_payee_invite_pending_assets;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  accepted_assets: PayeeDetailsPayeeInfoCard_me_payee_invite_accepted_assets;
  total_earnings: number;
}

export interface PayeeDetailsPayeeInfoCard_me {
  __typename: "User";
  id: string;
  /**
   * Get payee invite by id
   */
  payee_invite: PayeeDetailsPayeeInfoCard_me_payee_invite | null;
}

export interface PayeeDetailsPayeeInfoCard {
  /**
   * Get information about the currently logged in user
   */
  me: PayeeDetailsPayeeInfoCard_me;
}

export interface PayeeDetailsPayeeInfoCardVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: InitialPayeeDetails
// ====================================================

export interface InitialPayeeDetails_me_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee first name
   */
  name: string;
}

export interface InitialPayeeDetails_me_payee_invites_v2_pending_asset_splits {
  __typename: "PayeeAssets";
  count: number;
}

export interface InitialPayeeDetails_me_payee_invites_v2_active_asset_splits {
  __typename: "PayeeAssets";
  count: number;
}

export interface InitialPayeeDetails_me_payee_invites_v2_inactive_asset_splits {
  __typename: "PayeeAssets";
  count: number;
}

export interface InitialPayeeDetails_me_payee_invites_v2 {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  pending_asset_splits: InitialPayeeDetails_me_payee_invites_v2_pending_asset_splits;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  active_asset_splits: InitialPayeeDetails_me_payee_invites_v2_active_asset_splits;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  inactive_asset_splits: InitialPayeeDetails_me_payee_invites_v2_inactive_asset_splits;
}

export interface InitialPayeeDetails_me {
  __typename: "User";
  id: string;
  /**
   * Get payee invite by id
   */
  payee_invite: InitialPayeeDetails_me_payee_invite | null;
  /**
   * List out all the PayeeInvites for a User
   */
  payee_invites_v2: InitialPayeeDetails_me_payee_invites_v2[];
}

export interface InitialPayeeDetails {
  /**
   * Get information about the currently logged in user
   */
  me: InitialPayeeDetails_me;
}

export interface InitialPayeeDetailsVariables {
  payeeInviteId: string;
  input?: PayeeInvitesV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayeeDetailsSharedRoyaltiesCard
// ====================================================

export interface PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor_payee_media_splits_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor_payee_media_splits_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor_payee_media_splits_media {
  __typename: "Media";
  id: string;
  type: MediaType;
  thumbnail_url: string | null;
  artist: PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor_payee_media_splits_media_artist | null;
  metadata: PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor_payee_media_splits_media_metadata | null;
}

export interface PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor_payee_media_splits {
  __typename: "PayeeMediaSplit";
  id: string;
  /**
   * Status of the asset split. One of values in Enum PayeeAssetSplitStatus
   */
  status: string;
  /**
   * Music video royalty percentage on asset split
   */
  music_video_percentage: number | null;
  /**
   * Translated phrase for payee asset split status for music video
   */
  music_video_status_phrase: string;
  /**
   * Sound recording royalty percentage on asset split
   */
  sound_recording_percentage: number | null;
  /**
   * Translated phrase for payee asset split status for sound recording
   */
  sound_recording_status_phrase: string;
  /**
   * The media for the payee invitation
   */
  media: PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor_payee_media_splits_media;
  total_earnings: number;
}

export interface PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor {
  __typename: "PayeeMediaSplits";
  count: number;
  /**
   * List of medias that user is invited to with the corresponding asset splits
   */
  payee_media_splits: PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor_payee_media_splits[];
}

export interface PayeeDetailsSharedRoyaltiesCard_me {
  __typename: "User";
  id: string;
  /**
   * List out all the Medias that a User is invited to
   */
  payee_media_splits_cursor: PayeeDetailsSharedRoyaltiesCard_me_payee_media_splits_cursor;
}

export interface PayeeDetailsSharedRoyaltiesCard {
  /**
   * Get information about the currently logged in user
   */
  me: PayeeDetailsSharedRoyaltiesCard_me;
}

export interface PayeeDetailsSharedRoyaltiesCardVariables {
  limit: number;
  offset: number;
  payeeInviteId: string;
  searchTerm?: string | null;
  sharedAssetStatus?: PayeeAssetSplitStatus | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayeeInvite
// ====================================================

export interface PayeeInvite_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
}

export interface PayeeInvite_branding {
  __typename: "Branding";
  id: string;
  terms_of_use_url: string;
  privacy_policy_url: string;
}

export interface PayeeInvite {
  /**
   * Get payee invite for the code
   */
  payee_invite: PayeeInvite_payee_invite;
  branding: PayeeInvite_branding;
}

export interface PayeeInviteVariables {
  code: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptPayeeInvitation
// ====================================================

export interface AcceptPayeeInvitation_acceptPayeeInvitation_user {
  __typename: "User";
  id: string;
}

export interface AcceptPayeeInvitation_acceptPayeeInvitation_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AcceptPayeeInvitation_acceptPayeeInvitation {
  __typename: "AcceptPayeeInvitationPayload";
  user: AcceptPayeeInvitation_acceptPayeeInvitation_user | null;
  errors: AcceptPayeeInvitation_acceptPayeeInvitation_errors[] | null;
}

export interface AcceptPayeeInvitation {
  /**
   * Accept a PayeeInvite for a User. This mutation returns a User object. Used to accept an invitation to become a payee in the royalty center.
   */
  acceptPayeeInvitation: AcceptPayeeInvitation_acceptPayeeInvitation;
}

export interface AcceptPayeeInvitationVariables {
  input: AcceptPayeeInvitationInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: planDowngrade
// ====================================================

export interface planDowngrade_me {
  __typename: "User";
  id: string;
  first_name: string | null;
}

export interface planDowngrade {
  /**
   * Get information about the currently logged in user
   */
  me: planDowngrade_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Plans
// ====================================================

export interface Plans_me_active_storage_subscription {
  __typename: "Subscription";
  id: string;
  /**
   * When the user downgrades to a storage tier, It is downgraded at the end of the subscription. The storage it should downgrade to, is stored at this field
   */
  downgrade_to_storage_tier_id: string | null;
}

export interface Plans_me_active_storage_tier_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
  /**
   * Price for a TierPermission
   */
  yearly_price: number;
}

export interface Plans_me_active_storage_tier {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: Plans_me_active_storage_tier_tier_permission;
}

export interface Plans_me {
  __typename: "User";
  id: string;
  /**
   * current storage subscription a user is subscribed to
   */
  active_storage_subscription: Plans_me_active_storage_subscription | null;
  /**
   * Active Plan a user is subscribed to, returns only a StorageTier
   */
  active_storage_tier: Plans_me_active_storage_tier;
  current_storage_usage: number;
}

export interface Plans_storage_tiers_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Storage information in bytes
   */
  size_in_bytes: number | null;
  /**
   * Price for a TierPermission
   */
  yearly_price: number;
}

export interface Plans_storage_tiers {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: Plans_storage_tiers_tier_permission;
}

export interface Plans {
  /**
   * Get information about the currently logged in user
   */
  me: Plans_me;
  storage_tiers: Plans_storage_tiers[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PrivacyPolicy
// ====================================================

export interface PrivacyPolicy_branding {
  __typename: "Branding";
  id: string;
  render_privacy_policy: string;
}

export interface PrivacyPolicy {
  branding: PrivacyPolicy_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableNetworkExtrasMedia
// ====================================================

export interface AvailableNetworkExtrasMedia_media_artist_networkExtras {
  __typename: "NetworkExtra";
  id: string;
  type: NetworkExtraType;
  price: number;
  label: string;
  shortLabel: string;
  icon: string;
  description: string;
}

export interface AvailableNetworkExtrasMedia_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Available network extras for a release
   */
  networkExtras: AvailableNetworkExtrasMedia_media_artist_networkExtras[];
}

export interface AvailableNetworkExtrasMedia_media {
  __typename: "Media";
  id: string;
  artist: AvailableNetworkExtrasMedia_media_artist | null;
}

export interface AvailableNetworkExtrasMedia {
  /**
   * Find the current media by ID
   */
  media: AvailableNetworkExtrasMedia_media;
}

export interface AvailableNetworkExtrasMediaVariables {
  mediaId: string;
  networkIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableNetworkExtrasAlbum
// ====================================================

export interface AvailableNetworkExtrasAlbum_album_artist_networkExtras {
  __typename: "NetworkExtra";
  id: string;
  type: NetworkExtraType;
  price: number;
  label: string;
  shortLabel: string;
  icon: string;
  description: string;
}

export interface AvailableNetworkExtrasAlbum_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Available network extras for a release
   */
  networkExtras: AvailableNetworkExtrasAlbum_album_artist_networkExtras[];
}

export interface AvailableNetworkExtrasAlbum_album {
  __typename: "Album";
  id: string;
  artist: AvailableNetworkExtrasAlbum_album_artist;
}

export interface AvailableNetworkExtrasAlbum {
  /**
   * Find the current album by ID
   */
  album: AvailableNetworkExtrasAlbum_album;
}

export interface AvailableNetworkExtrasAlbumVariables {
  albumId: string;
  networkIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdditionalServices
// ====================================================

export interface GetAdditionalServices_media_artist_additional_service_bundles_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  price: number;
}

export interface GetAdditionalServices_media_artist_additional_service_bundles {
  __typename: "AdditionalServiceBundle";
  id: string;
  title: string;
  description: string;
  price: number;
  original_price: number | null;
  image_path: string | null;
  additional_services: GetAdditionalServices_media_artist_additional_service_bundles_additional_services[];
}

export interface GetAdditionalServices_media_artist_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  description: string;
  price: number;
  max_quantity: number | null;
  track_based_quantity: boolean;
  image_path: string | null;
  slug: string | null;
}

export interface GetAdditionalServices_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Additional service bundles for this artist
   */
  additional_service_bundles: GetAdditionalServices_media_artist_additional_service_bundles[];
  /**
   * Additional services for this artist
   */
  additional_services: GetAdditionalServices_media_artist_additional_services[];
}

export interface GetAdditionalServices_media {
  __typename: "Media";
  id: string;
  artist: GetAdditionalServices_media_artist | null;
}

export interface GetAdditionalServices {
  /**
   * Find the current media by ID
   */
  media: GetAdditionalServices_media;
}

export interface GetAdditionalServicesVariables {
  mediaId: string;
  networkIds: string[];
  category: AdditionalServiceCategory;
  type: AdditionalServiceType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdditionalServicesAlbum
// ====================================================

export interface GetAdditionalServicesAlbum_album_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
}

export interface GetAdditionalServicesAlbum_album_artist_additional_service_bundles_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  price: number;
}

export interface GetAdditionalServicesAlbum_album_artist_additional_service_bundles {
  __typename: "AdditionalServiceBundle";
  id: string;
  title: string;
  description: string;
  price: number;
  original_price: number | null;
  image_path: string | null;
  additional_services: GetAdditionalServicesAlbum_album_artist_additional_service_bundles_additional_services[];
}

export interface GetAdditionalServicesAlbum_album_artist_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  description: string;
  price: number;
  max_quantity: number | null;
  track_based_quantity: boolean;
  image_path: string | null;
  slug: string | null;
}

export interface GetAdditionalServicesAlbum_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Additional service bundles for this artist
   */
  additional_service_bundles: GetAdditionalServicesAlbum_album_artist_additional_service_bundles[];
  /**
   * Additional services for this artist
   */
  additional_services: GetAdditionalServicesAlbum_album_artist_additional_services[];
}

export interface GetAdditionalServicesAlbum_album {
  __typename: "Album";
  id: string;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: GetAdditionalServicesAlbum_album_album_medias_cursor;
  artist: GetAdditionalServicesAlbum_album_artist;
}

export interface GetAdditionalServicesAlbum {
  /**
   * Find the current album by ID
   */
  album: GetAdditionalServicesAlbum_album;
}

export interface GetAdditionalServicesAlbumVariables {
  albumId: string;
  networkIds: string[];
  category: AdditionalServiceCategory;
  type: AdditionalServiceType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdditionalServicesCounts
// ====================================================

export interface GetAdditionalServicesCounts_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Count of additional services for this artist
   */
  designServicesCount: number;
  /**
   * Count of additional services for this artist
   */
  securityServicesCount: number;
  /**
   * Count of additional services for this artist
   */
  contentServicesCount: number;
}

export interface GetAdditionalServicesCounts_media {
  __typename: "Media";
  id: string;
  artist: GetAdditionalServicesCounts_media_artist | null;
}

export interface GetAdditionalServicesCounts {
  /**
   * Find the current media by ID
   */
  media: GetAdditionalServicesCounts_media;
}

export interface GetAdditionalServicesCountsVariables {
  mediaId: string;
  networkIds: string[];
  type: AdditionalServiceType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdditionalServicesCountsAlbum
// ====================================================

export interface GetAdditionalServicesCountsAlbum_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Count of additional services for this artist
   */
  designServicesCount: number;
  /**
   * Count of additional services for this artist
   */
  securityServicesCount: number;
  /**
   * Count of additional services for this artist
   */
  contentServicesCount: number;
}

export interface GetAdditionalServicesCountsAlbum_album {
  __typename: "Album";
  id: string;
  artist: GetAdditionalServicesCountsAlbum_album_artist;
}

export interface GetAdditionalServicesCountsAlbum {
  /**
   * Find the current album by ID
   */
  album: GetAdditionalServicesCountsAlbum_album;
}

export interface GetAdditionalServicesCountsAlbumVariables {
  albumId: string;
  networkIds: string[];
  type: AdditionalServiceType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdditionalServicesPages
// ====================================================

export interface GetAdditionalServicesPages_media_artist_additional_service_bundles_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  price: number;
}

export interface GetAdditionalServicesPages_media_artist_additional_service_bundles {
  __typename: "AdditionalServiceBundle";
  id: string;
  title: string;
  description: string;
  price: number;
  original_price: number | null;
  image_path: string | null;
  additional_services: GetAdditionalServicesPages_media_artist_additional_service_bundles_additional_services[];
}

export interface GetAdditionalServicesPages_media_artist_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  description: string;
  price: number;
  max_quantity: number | null;
  track_based_quantity: boolean;
  image_path: string | null;
  slug: string | null;
}

export interface GetAdditionalServicesPages_media_artist_all_additional_service_bundles_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  price: number;
}

export interface GetAdditionalServicesPages_media_artist_all_additional_service_bundles {
  __typename: "AdditionalServiceBundle";
  id: string;
  title: string;
  description: string;
  price: number;
  original_price: number | null;
  image_path: string | null;
  additional_services: GetAdditionalServicesPages_media_artist_all_additional_service_bundles_additional_services[];
}

export interface GetAdditionalServicesPages_media_artist_all_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  description: string;
  price: number;
  max_quantity: number | null;
  track_based_quantity: boolean;
  image_path: string | null;
  slug: string | null;
}

export interface GetAdditionalServicesPages_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Additional service bundles for this artist
   */
  additional_service_bundles: GetAdditionalServicesPages_media_artist_additional_service_bundles[];
  /**
   * Additional services for this artist
   */
  additional_services: GetAdditionalServicesPages_media_artist_additional_services[];
  /**
   * Additional service bundles for this artist
   */
  all_additional_service_bundles: GetAdditionalServicesPages_media_artist_all_additional_service_bundles[];
  /**
   * Additional services for this artist
   */
  all_additional_services: GetAdditionalServicesPages_media_artist_all_additional_services[];
}

export interface GetAdditionalServicesPages_media {
  __typename: "Media";
  id: string;
  artist: GetAdditionalServicesPages_media_artist | null;
}

export interface GetAdditionalServicesPages {
  /**
   * Find the current media by ID
   */
  media: GetAdditionalServicesPages_media;
}

export interface GetAdditionalServicesPagesVariables {
  mediaId: string;
  networkIds: string[];
  recommended?: boolean | null;
  category?: AdditionalServiceCategory | null;
  type: AdditionalServiceType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdditionalServicesAlbumPages
// ====================================================

export interface GetAdditionalServicesAlbumPages_album_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
}

export interface GetAdditionalServicesAlbumPages_album_artist_additional_service_bundles_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  price: number;
}

export interface GetAdditionalServicesAlbumPages_album_artist_additional_service_bundles {
  __typename: "AdditionalServiceBundle";
  id: string;
  title: string;
  description: string;
  price: number;
  original_price: number | null;
  image_path: string | null;
  additional_services: GetAdditionalServicesAlbumPages_album_artist_additional_service_bundles_additional_services[];
}

export interface GetAdditionalServicesAlbumPages_album_artist_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  description: string;
  price: number;
  max_quantity: number | null;
  track_based_quantity: boolean;
  image_path: string | null;
  slug: string | null;
}

export interface GetAdditionalServicesAlbumPages_album_artist_all_additional_service_bundles_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  price: number;
}

export interface GetAdditionalServicesAlbumPages_album_artist_all_additional_service_bundles {
  __typename: "AdditionalServiceBundle";
  id: string;
  title: string;
  description: string;
  price: number;
  original_price: number | null;
  image_path: string | null;
  additional_services: GetAdditionalServicesAlbumPages_album_artist_all_additional_service_bundles_additional_services[];
}

export interface GetAdditionalServicesAlbumPages_album_artist_all_additional_services {
  __typename: "AdditionalService";
  id: string;
  title: string;
  description: string;
  price: number;
  max_quantity: number | null;
  track_based_quantity: boolean;
  image_path: string | null;
  slug: string | null;
}

export interface GetAdditionalServicesAlbumPages_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Additional service bundles for this artist
   */
  additional_service_bundles: GetAdditionalServicesAlbumPages_album_artist_additional_service_bundles[];
  /**
   * Additional services for this artist
   */
  additional_services: GetAdditionalServicesAlbumPages_album_artist_additional_services[];
  /**
   * Additional service bundles for this artist
   */
  all_additional_service_bundles: GetAdditionalServicesAlbumPages_album_artist_all_additional_service_bundles[];
  /**
   * Additional services for this artist
   */
  all_additional_services: GetAdditionalServicesAlbumPages_album_artist_all_additional_services[];
}

export interface GetAdditionalServicesAlbumPages_album {
  __typename: "Album";
  id: string;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: GetAdditionalServicesAlbumPages_album_album_medias_cursor;
  artist: GetAdditionalServicesAlbumPages_album_artist;
}

export interface GetAdditionalServicesAlbumPages {
  /**
   * Find the current album by ID
   */
  album: GetAdditionalServicesAlbumPages_album;
}

export interface GetAdditionalServicesAlbumPagesVariables {
  albumId: string;
  networkIds: string[];
  recommended?: boolean | null;
  category?: AdditionalServiceCategory | null;
  type: AdditionalServiceType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdditionalServicesPagesCounts
// ====================================================

export interface GetAdditionalServicesPagesCounts_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Count of additional services for this artist
   */
  designServicesCount: number;
  /**
   * Count of additional services for this artist
   */
  securityServicesCount: number;
  /**
   * Count of additional services for this artist
   */
  contentServicesCount: number;
}

export interface GetAdditionalServicesPagesCounts_media {
  __typename: "Media";
  id: string;
  artist: GetAdditionalServicesPagesCounts_media_artist | null;
}

export interface GetAdditionalServicesPagesCounts {
  /**
   * Find the current media by ID
   */
  media: GetAdditionalServicesPagesCounts_media;
}

export interface GetAdditionalServicesPagesCountsVariables {
  mediaId: string;
  networkIds: string[];
  type: AdditionalServiceType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAdditionalServicesPagesCountsAlbum
// ====================================================

export interface GetAdditionalServicesPagesCountsAlbum_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Count of additional services for this artist
   */
  designServicesCount: number;
  /**
   * Count of additional services for this artist
   */
  securityServicesCount: number;
  /**
   * Count of additional services for this artist
   */
  contentServicesCount: number;
}

export interface GetAdditionalServicesPagesCountsAlbum_album {
  __typename: "Album";
  id: string;
  artist: GetAdditionalServicesPagesCountsAlbum_album_artist;
}

export interface GetAdditionalServicesPagesCountsAlbum {
  /**
   * Find the current album by ID
   */
  album: GetAdditionalServicesPagesCountsAlbum_album;
}

export interface GetAdditionalServicesPagesCountsAlbumVariables {
  albumId: string;
  networkIds: string[];
  type: AdditionalServiceType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableAudioNetworks
// ====================================================

export interface AvailableAudioNetworks_me_available_networks_network_group_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface AvailableAudioNetworks_me_available_networks_network_group {
  __typename: "NetworkGroup";
  id: string;
  name: string;
  /**
   * The Networks in the group
   */
  networks: AvailableAudioNetworks_me_available_networks_network_group_networks[];
}

export interface AvailableAudioNetworks_me_available_networks {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
  /**
   * The group the network belongs to
   */
  network_group: AvailableAudioNetworks_me_available_networks_network_group | null;
}

export interface AvailableAudioNetworks_me {
  __typename: "User";
  id: string;
  /**
   * List of networks available for a user
   */
  available_networks: AvailableAudioNetworks_me_available_networks[];
}

export interface AvailableAudioNetworks_album_broadcasts_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface AvailableAudioNetworks_album_broadcasts {
  __typename: "Broadcast";
  id: string;
  /**
   * Network associated with a broadcast
   */
  network: AvailableAudioNetworks_album_broadcasts_network;
}

export interface AvailableAudioNetworks_album {
  __typename: "Album";
  id: string;
  broadcasts: AvailableAudioNetworks_album_broadcasts[];
}

export interface AvailableAudioNetworks {
  /**
   * Get information about the currently logged in user
   */
  me: AvailableAudioNetworks_me;
  /**
   * Find the current album by ID
   */
  album: AvailableAudioNetworks_album;
}

export interface AvailableAudioNetworksVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublishDetails
// ====================================================

export interface PublishDetails_networks {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Image URL for this network
   */
  imageUrl: string;
}

export interface PublishDetails {
  networks: PublishDetails_networks[];
}

export interface PublishDetailsVariables {
  selectedNetworkIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublishNetworkSelect
// ====================================================

export interface PublishNetworkSelect_me_available_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface PublishNetworkSelect_me {
  __typename: "User";
  id: string;
  /**
   * List of networks available for a user
   */
  available_networks: PublishNetworkSelect_me_available_networks[];
}

export interface PublishNetworkSelect_media {
  __typename: "Media";
  id: string;
  video_type: SupportedVideo | null;
}

export interface PublishNetworkSelect {
  /**
   * Get information about the currently logged in user
   */
  me: PublishNetworkSelect_me;
  /**
   * Find the current media by ID
   */
  media: PublishNetworkSelect_media;
}

export interface PublishNetworkSelectVariables {
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PublishReleaseDescription
// ====================================================

export interface PublishReleaseDescription_media {
  __typename: "Media";
  id: string;
  social_post_description: string | null;
}

export interface PublishReleaseDescription_branding {
  __typename: "Branding";
  id: string;
  effective_video_description_url: string;
}

export interface PublishReleaseDescription {
  /**
   * Find the current media by ID
   */
  media: PublishReleaseDescription_media;
  branding: PublishReleaseDescription_branding;
}

export interface PublishReleaseDescriptionVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumReviewConfirmation
// ====================================================

export interface AlbumReviewConfirmation_networks {
  __typename: "Network";
  id: string;
  /**
   * Image URL for this network
   */
  image_url: string;
  name: string;
}

export interface AlbumReviewConfirmation_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AlbumReviewConfirmation_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AlbumReviewConfirmation_album_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
}

export interface AlbumReviewConfirmation_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AlbumReviewConfirmation_album {
  __typename: "Album";
  id: string;
  file_upload: AlbumReviewConfirmation_album_file_upload | null;
  metadata: AlbumReviewConfirmation_album_metadata;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: AlbumReviewConfirmation_album_album_medias_cursor;
  artist: AlbumReviewConfirmation_album_artist;
}

export interface AlbumReviewConfirmation {
  networks: AlbumReviewConfirmation_networks[];
  /**
   * Find the current album by ID
   */
  album: AlbumReviewConfirmation_album;
}

export interface AlbumReviewConfirmationVariables {
  networkIds: string[];
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VideoPublishReviewReleaseDetails
// ====================================================

export interface VideoPublishReviewReleaseDetails_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface VideoPublishReviewReleaseDetails_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  songName: string;
}

export interface VideoPublishReviewReleaseDetails_media {
  __typename: "Media";
  id: string;
  thumbnailUrl: string | null;
  type: MediaType;
  artist: VideoPublishReviewReleaseDetails_media_artist | null;
  metadata: VideoPublishReviewReleaseDetails_media_metadata | null;
}

export interface VideoPublishReviewReleaseDetails_networks {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Image URL for this network
   */
  imageUrl: string;
}

export interface VideoPublishReviewReleaseDetails {
  /**
   * Find the current media by ID
   */
  media: VideoPublishReviewReleaseDetails_media;
  networks: VideoPublishReviewReleaseDetails_networks[];
}

export interface VideoPublishReviewReleaseDetailsVariables {
  mediaId: string;
  networkIds: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateRelease
// ====================================================

export interface CreateRelease_createRelease_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_id: string | null;
  resource_type: string | null;
}

export interface CreateRelease_createRelease {
  __typename: "CreateReleasePayload";
  errors: CreateRelease_createRelease_errors[] | null;
}

export interface CreateRelease {
  /**
   * Create a Release. The mutation returns a Transaction and a Release object. Used in the Media (not Album) release flow.
   */
  createRelease: CreateRelease_createRelease;
}

export interface CreateReleaseVariables {
  input: CreateReleaseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: PublishCreateAlbumRelease
// ====================================================

export interface PublishCreateAlbumRelease_createAlbumRelease_release_album {
  __typename: "Album";
  id: string;
}

export interface PublishCreateAlbumRelease_createAlbumRelease_release {
  __typename: "Release";
  id: string;
  /**
   * Returns an album that is associated with the release, mutually exclusive with Release
   */
  album: PublishCreateAlbumRelease_createAlbumRelease_release_album | null;
}

export interface PublishCreateAlbumRelease_createAlbumRelease_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_type: string | null;
  resource_id: string | null;
}

export interface PublishCreateAlbumRelease_createAlbumRelease {
  __typename: "CreateAlbumReleasePayload";
  release: PublishCreateAlbumRelease_createAlbumRelease_release | null;
  errors: PublishCreateAlbumRelease_createAlbumRelease_errors[] | null;
}

export interface PublishCreateAlbumRelease {
  /**
   * Create a new Release for an Album. This mutation returns a Release and Transaction object. Used in the album release creation flow.
   */
  createAlbumRelease: PublishCreateAlbumRelease_createAlbumRelease;
}

export interface PublishCreateAlbumReleaseVariables {
  input: CreateAlbumReleaseInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableSocialNetworks
// ====================================================

export interface AvailableSocialNetworks_me_available_networks {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface AvailableSocialNetworks_me {
  __typename: "User";
  id: string;
  /**
   * List of networks available for a user
   */
  available_networks: AvailableSocialNetworks_me_available_networks[];
  /**
   * Flag for a user has a feature
   */
  has_feature_facebook_deployment: boolean;
}

export interface AvailableSocialNetworks_media_artist_socials {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  /**
   * Associated deployer name
   */
  deployer_name: string | null;
  /**
   * Network ID for a network associated with a social
   */
  network_id: number | null;
  /**
   * Model name on Vydia Platform related to a Social
   */
  social_model_name: string | null;
  /**
   * Get the size of the thumbnail
   */
  thumbnail_url: string;
  /**
   * Title of this Social
   */
  title: string | null;
  release_broadcast_uid: string | null;
}

export interface AvailableSocialNetworks_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  /**
   * List of socials deployable or not for an artist
   */
  socials: AvailableSocialNetworks_media_artist_socials[];
}

export interface AvailableSocialNetworks_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AvailableSocialNetworks_media_broadcasts_through_release_network {
  __typename: "Network";
  id: string;
  /**
   * Network only allows one deploy
   */
  deploy_only_once: boolean;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface AvailableSocialNetworks_media_broadcasts_through_release_social {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  /**
   * Network ID for a network associated with a social
   */
  network_id: number | null;
  release_broadcast_uid: string | null;
}

export interface AvailableSocialNetworks_media_broadcasts_through_release {
  __typename: "Broadcast";
  id: string;
  /**
   * Network associated with a broadcast
   */
  network: AvailableSocialNetworks_media_broadcasts_through_release_network;
  /**
   * Determines which social does this broadcast belong to
   */
  social: AvailableSocialNetworks_media_broadcasts_through_release_social | null;
}

export interface AvailableSocialNetworks_media {
  __typename: "Media";
  id: string;
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  artist: AvailableSocialNetworks_media_artist | null;
  metadata: AvailableSocialNetworks_media_metadata | null;
  /**
   * List of all broadcasts completed through a release for a media
   */
  broadcasts_through_release: AvailableSocialNetworks_media_broadcasts_through_release[];
  social_post_description: string | null;
}

export interface AvailableSocialNetworks {
  /**
   * Get information about the currently logged in user
   */
  me: AvailableSocialNetworks_me;
  /**
   * Find the current media by ID
   */
  media: AvailableSocialNetworks_media;
}

export interface AvailableSocialNetworksVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AvailableVideoNetworks
// ====================================================

export interface AvailableVideoNetworks_me_available_networks_network_group_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface AvailableVideoNetworks_me_available_networks_network_group {
  __typename: "NetworkGroup";
  id: string;
  name: string;
  /**
   * The Networks in the group
   */
  networks: AvailableVideoNetworks_me_available_networks_network_group_networks[];
}

export interface AvailableVideoNetworks_me_available_networks {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
  /**
   * The group the network belongs to
   */
  network_group: AvailableVideoNetworks_me_available_networks_network_group | null;
}

export interface AvailableVideoNetworks_me {
  __typename: "User";
  id: string;
  /**
   * List of networks available for a user
   */
  available_networks: AvailableVideoNetworks_me_available_networks[];
}

export interface AvailableVideoNetworks_media_broadcasts_through_release_network {
  __typename: "Network";
  id: string;
  /**
   * Network only allows one deploy
   */
  deploy_only_once: boolean;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface AvailableVideoNetworks_media_broadcasts_through_release {
  __typename: "Broadcast";
  id: string;
  /**
   * Network associated with a broadcast
   */
  network: AvailableVideoNetworks_media_broadcasts_through_release_network;
}

export interface AvailableVideoNetworks_media {
  __typename: "Media";
  id: string;
  /**
   * List of all broadcasts completed through a release for a media
   */
  broadcasts_through_release: AvailableVideoNetworks_media_broadcasts_through_release[];
}

export interface AvailableVideoNetworks {
  /**
   * Get information about the currently logged in user
   */
  me: AvailableVideoNetworks_me;
  /**
   * Find the current media by ID
   */
  media: AvailableVideoNetworks_media;
}

export interface AvailableVideoNetworksVariables {
  mediaId: string;
  videoType?: SupportedVideo | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CashAdvanceOrganizationUsers
// ====================================================

export interface CashAdvanceOrganizationUsers_me_organization_users_cursor {
  __typename: "User";
  id: string;
  first_name: string | null;
  last_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface CashAdvanceOrganizationUsers_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of users in organization
   */
  users_count: number;
  /**
   * Any more users in organization
   */
  users_any_more_results: boolean;
  /**
   * Users in organization
   */
  users_cursor: CashAdvanceOrganizationUsers_me_organization_users_cursor[];
}

export interface CashAdvanceOrganizationUsers_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: CashAdvanceOrganizationUsers_me_organization | null;
}

export interface CashAdvanceOrganizationUsers {
  /**
   * Get information about the currently logged in user
   */
  me: CashAdvanceOrganizationUsers_me;
}

export interface CashAdvanceOrganizationUsersVariables {
  accepted_users_input?: UsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CashAdvanceOrganizationUser
// ====================================================

export interface CashAdvanceOrganizationUser_me_organization_user {
  __typename: "User";
  id: string;
  first_name: string | null;
  last_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * When the user created their profile
   */
  created_at: Scalar$DateTime;
  organization_royalty_split: number | null;
}

export interface CashAdvanceOrganizationUser_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * User in this organization
   */
  user: CashAdvanceOrganizationUser_me_organization_user | null;
}

export interface CashAdvanceOrganizationUser_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: CashAdvanceOrganizationUser_me_organization | null;
}

export interface CashAdvanceOrganizationUser {
  /**
   * Get information about the currently logged in user
   */
  me: CashAdvanceOrganizationUser_me;
}

export interface CashAdvanceOrganizationUserVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCashAdvanceRecoupment
// ====================================================

export interface CreateCashAdvanceRecoupment_createCashAdvanceRecoupment_cash_advance_recoupment {
  __typename: "CashAdvanceRecoupment";
  id: string;
}

export interface CreateCashAdvanceRecoupment_createCashAdvanceRecoupment_errors {
  __typename: "UserError";
  resource_id: string | null;
  resource_type: string | null;
  key: string | null;
  messages: string[];
}

export interface CreateCashAdvanceRecoupment_createCashAdvanceRecoupment {
  __typename: "CreateCashAdvanceRecoupmentPayload";
  cash_advance_recoupment: CreateCashAdvanceRecoupment_createCashAdvanceRecoupment_cash_advance_recoupment | null;
  errors: CreateCashAdvanceRecoupment_createCashAdvanceRecoupment_errors[] | null;
}

export interface CreateCashAdvanceRecoupment {
  /**
   * Create a CashAdvanceRecoupment. This mutation returns a CashAdvanceRecoupment object. Used in cash advance creation flows.
   */
  createCashAdvanceRecoupment: CreateCashAdvanceRecoupment_createCashAdvanceRecoupment;
}

export interface CreateCashAdvanceRecoupmentVariables {
  input: CreateCashAdvanceRecoupmentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ReleaseCalendar
// ====================================================

export interface ReleaseCalendar_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Count of Releases for a user
   */
  action_required_count: number;
}

export interface ReleaseCalendar {
  /**
   * Get information about the currently logged in user
   */
  me: ReleaseCalendar_me;
}

export interface ReleaseCalendarVariables {
  input?: ReleasesV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: ResetPassword
// ====================================================

export interface ResetPassword_resetPassword_user {
  __typename: "User";
  id: string;
  /**
   * Flag for a user must contact support, set when the user is locked
   */
  must_contact_support: boolean;
  /**
   * Default path a user will enter when signing in
   */
  landing_path: string | null;
}

export interface ResetPassword_resetPassword_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface ResetPassword_resetPassword {
  __typename: "ResetPasswordPayload";
  /**
   * link for the login
   */
  login_link: string | null;
  user: ResetPassword_resetPassword_user | null;
  errors: ResetPassword_resetPassword_errors[] | null;
}

export interface ResetPassword {
  /**
   * Default description for "ResetPassword" mutation.
   */
  resetPassword: ResetPassword_resetPassword;
}

export interface ResetPasswordVariables {
  input: ResetPasswordInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterBarMediaTab
// ====================================================

export interface FilterBarMediaTab_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface FilterBarMediaTab_media {
  __typename: "Media";
  id: string;
  type: MediaType;
  thumbnail_url: string | null;
  metadata: FilterBarMediaTab_media_metadata | null;
}

export interface FilterBarMediaTab {
  /**
   * Find the current media by ID
   */
  media: FilterBarMediaTab_media;
}

export interface FilterBarMediaTabVariables {
  mediaId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterBarAlbumTab
// ====================================================

export interface FilterBarAlbumTab_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface FilterBarAlbumTab_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface FilterBarAlbumTab_album {
  __typename: "Album";
  id: string;
  metadata: FilterBarAlbumTab_album_metadata;
  file_upload: FilterBarAlbumTab_album_file_upload | null;
}

export interface FilterBarAlbumTab {
  /**
   * Find the current album by ID
   */
  album: FilterBarAlbumTab_album;
}

export interface FilterBarAlbumTabVariables {
  albumId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterBarArtistTab
// ====================================================

export interface FilterBarArtistTab_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface FilterBarArtistTab {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: FilterBarArtistTab_artist;
}

export interface FilterBarArtistTabVariables {
  artistId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterBarOrganizationTab
// ====================================================

export interface FilterBarOrganizationTab_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
  /**
   * Number of top users in organization
   */
  top_users_count: number;
}

export interface FilterBarOrganizationTab_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: FilterBarOrganizationTab_me_organization | null;
}

export interface FilterBarOrganizationTab {
  /**
   * Get information about the currently logged in user
   */
  me: FilterBarOrganizationTab_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterBarUserTab
// ====================================================

export interface FilterBarUserTab_me_organization_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  /**
   * Count of top artists for the user
   */
  top_artists_count: number;
}

export interface FilterBarUserTab_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * User in this organization
   */
  user: FilterBarUserTab_me_organization_user | null;
}

export interface FilterBarUserTab_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: FilterBarUserTab_me_organization | null;
}

export interface FilterBarUserTab {
  /**
   * Get information about the currently logged in user
   */
  me: FilterBarUserTab_me;
}

export interface FilterBarUserTabVariables {
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: FilterBarOverviewUserTab
// ====================================================

export interface FilterBarOverviewUserTab_me {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  /**
   * Count of top artists for the user
   */
  top_artists_count: number;
}

export interface FilterBarOverviewUserTab {
  /**
   * Get information about the currently logged in user
   */
  me: FilterBarOverviewUserTab_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserAlbumsSelect
// ====================================================

export interface UserAlbumsSelect_me_albums_v2_cursor_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface UserAlbumsSelect_me_albums_v2_cursor_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface UserAlbumsSelect_me_albums_v2_cursor {
  __typename: "Album";
  id: string;
  metadata: UserAlbumsSelect_me_albums_v2_cursor_metadata;
  artist: UserAlbumsSelect_me_albums_v2_cursor_artist;
}

export interface UserAlbumsSelect_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * List out all the albums for the current user
   */
  albums_v2_cursor: UserAlbumsSelect_me_albums_v2_cursor[];
}

export interface UserAlbumsSelect {
  /**
   * Get information about the currently logged in user
   */
  me: UserAlbumsSelect_me;
}

export interface UserAlbumsSelectVariables {
  input?: AlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrgAlbumsSelect
// ====================================================

export interface OrgAlbumsSelect_me_organization_albums_v2_cursor_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface OrgAlbumsSelect_me_organization_albums_v2_cursor_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface OrgAlbumsSelect_me_organization_albums_v2_cursor {
  __typename: "Album";
  id: string;
  metadata: OrgAlbumsSelect_me_organization_albums_v2_cursor_metadata;
  artist: OrgAlbumsSelect_me_organization_albums_v2_cursor_artist;
}

export interface OrgAlbumsSelect_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * List out all the albums for the current organization
   */
  albums_v2_cursor: OrgAlbumsSelect_me_organization_albums_v2_cursor[];
}

export interface OrgAlbumsSelect_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrgAlbumsSelect_me_organization | null;
}

export interface OrgAlbumsSelect {
  /**
   * Get information about the currently logged in user
   */
  me: OrgAlbumsSelect_me;
}

export interface OrgAlbumsSelectVariables {
  input?: AlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistSelect
// ====================================================

export interface ArtistSelect_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ArtistSelect_me {
  __typename: "User";
  id: string;
  /**
   * List out all Artists for the current user
   */
  artists: ArtistSelect_me_artists[];
}

export interface ArtistSelect {
  /**
   * Get information about the currently logged in user
   */
  me: ArtistSelect_me;
}

export interface ArtistSelectVariables {
  input?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2NetworksSelect
// ====================================================

export interface OverviewV2NetworksSelect_me_royalty_revenues_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface OverviewV2NetworksSelect_me_royalty_revenues {
  __typename: "RoyaltyRevenue";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Network associated with the report
   */
  network: OverviewV2NetworksSelect_me_royalty_revenues_network;
}

export interface OverviewV2NetworksSelect_me {
  __typename: "User";
  id: string;
  /**
   * list out royalty revenues for the user
   */
  royalty_revenues: OverviewV2NetworksSelect_me_royalty_revenues[] | null;
}

export interface OverviewV2NetworksSelect {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2NetworksSelect_me;
}

export interface OverviewV2NetworksSelectVariables {
  input: RoyaltyRevenuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TrackSelect
// ====================================================

export interface TrackSelect_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface TrackSelect_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface TrackSelect_me_medias_v2 {
  __typename: "Media";
  id: string;
  artist: TrackSelect_me_medias_v2_artist | null;
  metadata: TrackSelect_me_medias_v2_metadata | null;
}

export interface TrackSelect_me {
  __typename: "User";
  id: string;
  /**
   * Medias of the user
   */
  medias_v2: TrackSelect_me_medias_v2[];
}

export interface TrackSelect {
  /**
   * Get information about the currently logged in user
   */
  me: TrackSelect_me;
}

export interface TrackSelectVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Users
// ====================================================

export interface Users_me_organization_users_cursor {
  __typename: "User";
  id: string;
  full_name: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface Users_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Users in organization
   */
  users_cursor: Users_me_organization_users_cursor[];
}

export interface Users_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: Users_me_organization | null;
}

export interface Users {
  /**
   * Get information about the currently logged in user
   */
  me: Users_me;
}

export interface UsersVariables {
  input?: UsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: VideoSelect
// ====================================================

export interface VideoSelect_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface VideoSelect_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface VideoSelect_me_medias_v2 {
  __typename: "Media";
  id: string;
  artist: VideoSelect_me_medias_v2_artist | null;
  metadata: VideoSelect_me_medias_v2_metadata | null;
}

export interface VideoSelect_me {
  __typename: "User";
  id: string;
  /**
   * Medias of the user
   */
  medias_v2: VideoSelect_me_medias_v2[];
}

export interface VideoSelect {
  /**
   * Get information about the currently logged in user
   */
  me: VideoSelect_me;
}

export interface VideoSelectVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopAlbum
// ====================================================

export interface TopAlbum_me_organization_top_albums_network_revenues_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface TopAlbum_me_organization_top_albums_network_revenues {
  __typename: "NetworkRevenue";
  downloads_net_revenue: number;
  id: string;
  streams_net_revenue: number;
  total_downloads_discrete: number;
  total_streams_discrete: number;
  network: TopAlbum_me_organization_top_albums_network_revenues_network;
}

export interface TopAlbum_me_organization_top_albums_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface TopAlbum_me_organization_top_albums_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface TopAlbum_me_organization_top_albums_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface TopAlbum_me_organization_top_albums_album {
  __typename: "Album";
  id: string;
  artist: TopAlbum_me_organization_top_albums_album_artist;
  metadata: TopAlbum_me_organization_top_albums_album_metadata;
  file_upload: TopAlbum_me_organization_top_albums_album_file_upload | null;
}

export interface TopAlbum_me_organization_top_albums_top_album_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface TopAlbum_me_organization_top_albums_top_album_medias_media {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  metadata: TopAlbum_me_organization_top_albums_top_album_medias_media_metadata | null;
}

export interface TopAlbum_me_organization_top_albums_top_album_medias {
  __typename: "TopAlbumMedia";
  id: string;
  net_revenue_cumulative: number;
  media: TopAlbum_me_organization_top_albums_top_album_medias_media;
}

export interface TopAlbum_me_organization_top_albums {
  __typename: "TopAlbum";
  id: string;
  net_revenue_cumulative: number;
  /**
   * revenue for networks 
   */
  network_revenues: TopAlbum_me_organization_top_albums_network_revenues[];
  album: TopAlbum_me_organization_top_albums_album;
  /**
   * top media for album
   */
  top_album_medias: TopAlbum_me_organization_top_albums_top_album_medias[];
}

export interface TopAlbum_me_organization_top_geocountries_country {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface TopAlbum_me_organization_top_geocountries {
  __typename: "TopGeocountry";
  id: string;
  net_revenue_cumulative: number;
  country: TopAlbum_me_organization_top_geocountries_country;
}

export interface TopAlbum_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top albums for the organization
   */
  top_albums_count: number;
  /**
   * Top albums for the organization
   */
  top_albums: TopAlbum_me_organization_top_albums[];
  /**
   * Top countries for the organization
   */
  top_geocountries: TopAlbum_me_organization_top_geocountries[];
}

export interface TopAlbum_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: TopAlbum_me_organization | null;
}

export interface TopAlbum {
  /**
   * Get information about the currently logged in user
   */
  me: TopAlbum_me;
}

export interface TopAlbumVariables {
  topAlbumInput?: TopAlbumsCursorInput | null;
  topGeocountryInput?: TopGeocountryCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumReport
// ====================================================

export interface AlbumReport_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top albums for the organization
   */
  top_albums_count: number;
}

export interface AlbumReport_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: AlbumReport_me_organization | null;
}

export interface AlbumReport {
  /**
   * Get information about the currently logged in user
   */
  me: AlbumReport_me;
}

export interface AlbumReportVariables {
  input?: TopAlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AlbumTopMedias
// ====================================================

export interface AlbumTopMedias_me_organization_top_album_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface AlbumTopMedias_me_organization_top_album_medias_media {
  __typename: "Media";
  type: MediaType;
  created_at: Scalar$DateTime;
  id: string;
  metadata: AlbumTopMedias_me_organization_top_album_medias_media_metadata | null;
}

export interface AlbumTopMedias_me_organization_top_album_medias {
  __typename: "TopAlbumMedia";
  id: string;
  streams_net_revenue_cumulative: number;
  downloads_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
  media: AlbumTopMedias_me_organization_top_album_medias_media;
}

export interface AlbumTopMedias_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top album medias in this organization
   */
  top_album_medias_count: number;
  /**
   * Any more top album medias in this organization
   */
  top_album_medias_any_more_results: boolean;
  /**
   * Top album medias by earnings in this organization
   */
  top_album_medias: AlbumTopMedias_me_organization_top_album_medias[];
}

export interface AlbumTopMedias_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: AlbumTopMedias_me_organization | null;
}

export interface AlbumTopMedias {
  /**
   * Get information about the currently logged in user
   */
  me: AlbumTopMedias_me;
}

export interface AlbumTopMediasVariables {
  input?: TopAlbumMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopArtist
// ====================================================

export interface TopArtist_me_organization_top_artists_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  pretty_media_cid_claims_count: string;
}

export interface TopArtist_me_organization_top_artists_user {
  __typename: "User";
  id: string;
  full_name: string | null;
}

export interface TopArtist_me_organization_top_artists_top_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface TopArtist_me_organization_top_artists_network_revenues_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface TopArtist_me_organization_top_artists_network_revenues {
  __typename: "NetworkRevenue";
  downloads_net_revenue: number;
  id: string;
  streams_net_revenue: number;
  total_downloads_discrete: number;
  total_streams_discrete: number;
  network: TopArtist_me_organization_top_artists_network_revenues_network;
}

export interface TopArtist_me_organization_top_artists_top_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface TopArtist_me_organization_top_artists_top_media {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  metadata: TopArtist_me_organization_top_artists_top_media_metadata | null;
}

export interface TopArtist_me_organization_top_artists {
  __typename: "TopArtist";
  id: string;
  net_revenue_cumulative: number;
  streams_net_revenue_cumulative: number;
  downloads_net_revenue_cumulative: number;
  artist: TopArtist_me_organization_top_artists_artist;
  user: TopArtist_me_organization_top_artists_user;
  top_geocountry: TopArtist_me_organization_top_artists_top_geocountry;
  /**
   * revenue for networks 
   */
  network_revenues: TopArtist_me_organization_top_artists_network_revenues[];
  top_geocountry_net_revenue_cumulative: number;
  top_media: TopArtist_me_organization_top_artists_top_media;
  top_media_net_revenue_cumulative: number;
}

export interface TopArtist_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top artists for the organization
   */
  top_artists_count: number;
  /**
   * Top artists for the organization
   */
  top_artists: TopArtist_me_organization_top_artists[];
}

export interface TopArtist_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: TopArtist_me_organization | null;
}

export interface TopArtist {
  /**
   * Get information about the currently logged in user
   */
  me: TopArtist_me;
}

export interface TopArtistVariables {
  input?: TopArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistReport
// ====================================================

export interface ArtistReport_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top artists for the organization
   */
  top_artists_count: number;
}

export interface ArtistReport_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: ArtistReport_me_organization | null;
}

export interface ArtistReport {
  /**
   * Get information about the currently logged in user
   */
  me: ArtistReport_me;
}

export interface ArtistReportVariables {
  input?: TopArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistTopMedias
// ====================================================

export interface ArtistTopMedias_me_organization_top_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface ArtistTopMedias_me_organization_top_medias_media {
  __typename: "Media";
  type: MediaType;
  created_at: Scalar$DateTime;
  id: string;
  metadata: ArtistTopMedias_me_organization_top_medias_media_metadata | null;
}

export interface ArtistTopMedias_me_organization_top_medias {
  __typename: "TopMedia";
  id: string;
  streams_net_revenue_cumulative: number;
  downloads_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
  media: ArtistTopMedias_me_organization_top_medias_media;
}

export interface ArtistTopMedias_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top medias in this organization
   */
  top_medias_count: number;
  /**
   * Any more top medias in this organization
   */
  top_medias_any_more_results: boolean;
  /**
   * Top medias by earnings in this organization
   */
  top_medias: ArtistTopMedias_me_organization_top_medias[];
}

export interface ArtistTopMedias_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: ArtistTopMedias_me_organization | null;
}

export interface ArtistTopMedias {
  /**
   * Get information about the currently logged in user
   */
  me: ArtistTopMedias_me;
}

export interface ArtistTopMediasVariables {
  input?: TopMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ArtistTopMedia
// ====================================================

export interface ArtistTopMedia_me_organization_top_medias_network_revenues_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface ArtistTopMedia_me_organization_top_medias_network_revenues {
  __typename: "NetworkRevenue";
  downloads_net_revenue: number;
  id: string;
  streams_net_revenue: number;
  total_downloads_discrete: number;
  total_streams_discrete: number;
  network: ArtistTopMedia_me_organization_top_medias_network_revenues_network;
}

export interface ArtistTopMedia_me_organization_top_medias_top_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface ArtistTopMedia_me_organization_top_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface ArtistTopMedia_me_organization_top_medias_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ArtistTopMedia_me_organization_top_medias_media {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  pretty_ugc_claims_count: string;
  type: MediaType;
  metadata: ArtistTopMedia_me_organization_top_medias_media_metadata | null;
  artist: ArtistTopMedia_me_organization_top_medias_media_artist | null;
}

export interface ArtistTopMedia_me_organization_top_medias {
  __typename: "TopMedia";
  id: string;
  net_revenue_cumulative: number;
  streams_net_revenue_cumulative: number;
  downloads_net_revenue_cumulative: number;
  /**
   * revenue for networks 
   */
  network_revenues: ArtistTopMedia_me_organization_top_medias_network_revenues[];
  top_geocountry: ArtistTopMedia_me_organization_top_medias_top_geocountry;
  media: ArtistTopMedia_me_organization_top_medias_media;
  total_top_geocountry_net_revenue_cumulative: number;
}

export interface ArtistTopMedia_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top medias in this organization
   */
  top_medias_count: number;
  /**
   * Top medias by earnings in this organization
   */
  top_medias: ArtistTopMedia_me_organization_top_medias[];
}

export interface ArtistTopMedia_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: ArtistTopMedia_me_organization | null;
}

export interface ArtistTopMedia {
  /**
   * Get information about the currently logged in user
   */
  me: ArtistTopMedia_me;
}

export interface ArtistTopMediaVariables {
  input?: TopMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ContentReport
// ====================================================

export interface ContentReport_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top medias in this organization
   */
  top_medias_count: number;
}

export interface ContentReport_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: ContentReport_me_organization | null;
}

export interface ContentReport {
  /**
   * Get information about the currently logged in user
   */
  me: ContentReport_me;
}

export interface ContentReportVariables {
  input?: TopMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: MonthlyOrganizationNetworkRevenues
// ====================================================

export interface MonthlyOrganizationNetworkRevenues_me_organization_balance_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface MonthlyOrganizationNetworkRevenues_me_organization_monthly_network_revenues_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface MonthlyOrganizationNetworkRevenues_me_organization_monthly_network_revenues {
  __typename: "MonthlyNetworkRevenue";
  id: string;
  date: Scalar$DateTime;
  total_client_revenue: number;
  total_net_revenue: number;
  streams_net_revenue: number;
  downloads_net_revenue: number;
  network: MonthlyOrganizationNetworkRevenues_me_organization_monthly_network_revenues_network | null;
}

export interface MonthlyOrganizationNetworkRevenues_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * The networks the organization has made revenue from
   */
  balance_networks: MonthlyOrganizationNetworkRevenues_me_organization_balance_networks[];
  /**
   * Month Revenue for Networks
   */
  monthly_network_revenues: MonthlyOrganizationNetworkRevenues_me_organization_monthly_network_revenues[];
}

export interface MonthlyOrganizationNetworkRevenues_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: MonthlyOrganizationNetworkRevenues_me_organization | null;
}

export interface MonthlyOrganizationNetworkRevenues {
  /**
   * Get information about the currently logged in user
   */
  me: MonthlyOrganizationNetworkRevenues_me;
}

export interface MonthlyOrganizationNetworkRevenuesVariables {
  monthlyNetworkInput?: MonthlyNetworkRevenuesCursorInput | null;
  audioOnly?: boolean | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LabelEarnings
// ====================================================

export interface LabelEarnings_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top albums for the organization
   */
  top_albums_count: number;
  /**
   * Count of top artists for the organization
   */
  top_artists_count: number;
  /**
   * Number of top medias in this organization
   */
  top_medias_count: number;
  /**
   * Number of top users in organization
   */
  top_users_count: number;
}

export interface LabelEarnings_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Flag for a user has a feature
   */
  has_d2_revenue_albums: boolean;
  /**
   * An Organization a user is a member of
   */
  organization: LabelEarnings_me_organization | null;
}

export interface LabelEarnings {
  /**
   * Get information about the currently logged in user
   */
  me: LabelEarnings_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: LabelEarningsSummaryCards
// ====================================================

export interface LabelEarningsSummaryCards_me_organization_quarterly_earnings {
  __typename: "QuarterlyEarnings";
  id: string;
  gross_earnings: number;
  quarter_date: Scalar$DateTime;
}

export interface LabelEarningsSummaryCards_me_organization_lifetime_quarterly_earnings {
  __typename: "QuarterlyEarnings";
  id: string;
  gross_earnings: number;
  quarter_date: Scalar$DateTime;
}

export interface LabelEarningsSummaryCards_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
  /**
   * Total earnings for all top users in the organization
   */
  pretty_gross_revenue_cumulative: string;
  /**
   * Total label earnings for all top users in the organization
   */
  pretty_net_label_revenue_cumulative: string;
  /**
   * Earnings for the quarters in the current year
   */
  quarterly_earnings: LabelEarningsSummaryCards_me_organization_quarterly_earnings[];
  /**
   * Earnings for the quarters in the current year
   */
  lifetime_quarterly_earnings: LabelEarningsSummaryCards_me_organization_lifetime_quarterly_earnings[];
}

export interface LabelEarningsSummaryCards_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: LabelEarningsSummaryCards_me_organization | null;
}

export interface LabelEarningsSummaryCards {
  /**
   * Get information about the currently logged in user
   */
  me: LabelEarningsSummaryCards_me;
}

export interface LabelEarningsSummaryCardsVariables {
  input?: QuarterlyEarningsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopAlbumsOrganization
// ====================================================

export interface TopAlbumsOrganization_me_organization_top_albums_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface TopAlbumsOrganization_me_organization_top_albums_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface TopAlbumsOrganization_me_organization_top_albums_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface TopAlbumsOrganization_me_organization_top_albums_album {
  __typename: "Album";
  id: string;
  created_at: Scalar$DateTime;
  user_id: string;
  metadata: TopAlbumsOrganization_me_organization_top_albums_album_metadata;
  artist: TopAlbumsOrganization_me_organization_top_albums_album_artist;
  file_upload: TopAlbumsOrganization_me_organization_top_albums_album_file_upload | null;
}

export interface TopAlbumsOrganization_me_organization_top_albums {
  __typename: "TopAlbum";
  id: string;
  downloads_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
  streams_net_revenue_cumulative: number;
  album: TopAlbumsOrganization_me_organization_top_albums_album;
}

export interface TopAlbumsOrganization_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top albums for the organization
   */
  top_albums_count: number;
  /**
   * Top albums for the organization
   */
  top_albums: TopAlbumsOrganization_me_organization_top_albums[];
}

export interface TopAlbumsOrganization_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: TopAlbumsOrganization_me_organization | null;
}

export interface TopAlbumsOrganization {
  /**
   * Get information about the currently logged in user
   */
  me: TopAlbumsOrganization_me;
}

export interface TopAlbumsOrganizationVariables {
  input?: TopAlbumsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopArtists
// ====================================================

export interface TopArtists_me_organization_top_artists_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface TopArtists_me_organization_top_artists_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface TopArtists_me_organization_top_artists {
  __typename: "TopArtist";
  id: string;
  net_revenue_cumulative: number;
  streams_net_revenue_cumulative: number;
  downloads_net_revenue_cumulative: number;
  artist: TopArtists_me_organization_top_artists_artist;
  user: TopArtists_me_organization_top_artists_user;
}

export interface TopArtists_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top artists for the organization
   */
  top_artists_count: number;
  /**
   * Any more top medias in this organization
   */
  top_artists_any_more_results: boolean;
  /**
   * Top artists for the organization
   */
  top_artists: TopArtists_me_organization_top_artists[];
}

export interface TopArtists_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: TopArtists_me_organization | null;
}

export interface TopArtists {
  /**
   * Get information about the currently logged in user
   */
  me: TopArtists_me;
}

export interface TopArtistsVariables {
  input?: TopArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopMedias
// ====================================================

export interface TopMedias_me_organization_top_medias_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface TopMedias_me_organization_top_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface TopMedias_me_organization_top_medias_media_user {
  __typename: "User";
  id: string;
}

export interface TopMedias_me_organization_top_medias_media {
  __typename: "Media";
  type: MediaType;
  created_at: Scalar$DateTime;
  id: string;
  artist: TopMedias_me_organization_top_medias_media_artist | null;
  metadata: TopMedias_me_organization_top_medias_media_metadata | null;
  user: TopMedias_me_organization_top_medias_media_user;
}

export interface TopMedias_me_organization_top_medias {
  __typename: "TopMedia";
  id: string;
  streams_net_revenue_cumulative: number;
  downloads_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
  media: TopMedias_me_organization_top_medias_media;
}

export interface TopMedias_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top medias in this organization
   */
  top_medias_count: number;
  /**
   * Any more top medias in this organization
   */
  top_medias_any_more_results: boolean;
  /**
   * Top medias by earnings in this organization
   */
  top_medias: TopMedias_me_organization_top_medias[];
}

export interface TopMedias_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: TopMedias_me_organization | null;
}

export interface TopMedias {
  /**
   * Get information about the currently logged in user
   */
  me: TopMedias_me;
}

export interface TopMediasVariables {
  input?: TopMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopUsers
// ====================================================

export interface TopUsers_me_organization_top_users_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * When the user created their profile
   */
  created_at: Scalar$DateTime;
}

export interface TopUsers_me_organization_top_users {
  __typename: "TopUser";
  id: string;
  user_net_revenue_cumulative: number;
  label_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
  user: TopUsers_me_organization_top_users_user;
}

export interface TopUsers_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top users in organization
   */
  top_users_count: number;
  /**
   * Top users in organization
   */
  top_users: TopUsers_me_organization_top_users[];
}

export interface TopUsers_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: TopUsers_me_organization | null;
}

export interface TopUsers {
  /**
   * Get information about the currently logged in user
   */
  me: TopUsers_me;
}

export interface TopUsersVariables {
  input?: TopUsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserReport
// ====================================================

export interface UserReport_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top users in organization
   */
  top_users_count: number;
}

export interface UserReport_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: UserReport_me_organization | null;
}

export interface UserReport {
  /**
   * Get information about the currently logged in user
   */
  me: UserReport_me;
}

export interface UserReportVariables {
  input?: TopUsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TopUser
// ====================================================

export interface TopUser_me_organization_top_users_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
  phone: string | null;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface TopUser_me_organization_top_users_top_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  thumbnail_url: string;
}

export interface TopUser_me_organization_top_users_top_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface TopUser_me_organization_top_users_top_media {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  metadata: TopUser_me_organization_top_users_top_media_metadata | null;
}

export interface TopUser_me_organization_top_users_top_geocountry {
  __typename: "Geocountry";
  id: string;
  name: string;
  shortcode: string;
}

export interface TopUser_me_organization_top_users {
  __typename: "TopUser";
  id: string;
  user: TopUser_me_organization_top_users_user;
  organization_royalty_split: number | null;
  user_net_revenue_cumulative: number;
  label_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
  top_artist_net_revenue_cumulative: number;
  top_media_net_revenue_cumulative: number;
  top_geocountry_net_revenue_cumulative: number;
  top_artist: TopUser_me_organization_top_users_top_artist;
  top_media: TopUser_me_organization_top_users_top_media;
  top_geocountry: TopUser_me_organization_top_users_top_geocountry;
}

export interface TopUser_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Number of top users in organization
   */
  top_users_count: number;
  /**
   * Any more top users in organization
   */
  top_users_any_more_results: boolean;
  /**
   * Top users in organization
   */
  top_users: TopUser_me_organization_top_users[];
}

export interface TopUser_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: TopUser_me_organization | null;
}

export interface TopUser {
  /**
   * Get information about the currently logged in user
   */
  me: TopUser_me;
}

export interface TopUserVariables {
  input?: TopUsersCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTopArtists
// ====================================================

export interface UserTopArtists_me_organization_user_top_artists_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface UserTopArtists_me_organization_user_top_artists {
  __typename: "TopArtist";
  id: string;
  net_revenue_cumulative: number;
  streams_net_revenue_cumulative: number;
  downloads_net_revenue_cumulative: number;
  artist: UserTopArtists_me_organization_user_top_artists_artist;
}

export interface UserTopArtists_me_organization_user {
  __typename: "User";
  id: string;
  /**
   * Count of top artists for the user
   */
  top_artists_count: number;
  /**
   * Top artists for the user
   */
  top_artists: UserTopArtists_me_organization_user_top_artists[];
}

export interface UserTopArtists_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * User in this organization
   */
  user: UserTopArtists_me_organization_user | null;
}

export interface UserTopArtists_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * An Organization a user is a member of
   */
  organization: UserTopArtists_me_organization | null;
}

export interface UserTopArtists {
  /**
   * Get information about the currently logged in user
   */
  me: UserTopArtists_me;
}

export interface UserTopArtistsVariables {
  input?: TopArtistsCursorInput | null;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTopMedias
// ====================================================

export interface UserTopMedias_me_organization_user_top_medias_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface UserTopMedias_me_organization_user_top_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface UserTopMedias_me_organization_user_top_medias_media {
  __typename: "Media";
  artist: UserTopMedias_me_organization_user_top_medias_media_artist | null;
  type: MediaType;
  created_at: Scalar$DateTime;
  id: string;
  metadata: UserTopMedias_me_organization_user_top_medias_media_metadata | null;
}

export interface UserTopMedias_me_organization_user_top_medias {
  __typename: "TopMedia";
  id: string;
  streams_net_revenue_cumulative: number;
  downloads_net_revenue_cumulative: number;
  net_revenue_cumulative: number;
  media: UserTopMedias_me_organization_user_top_medias_media;
}

export interface UserTopMedias_me_organization_user {
  __typename: "User";
  id: string;
  /**
   * Number of top medias in this user
   */
  top_medias_count: number;
  /**
   * Any more top medias in this user
   */
  top_medias_any_more_results: boolean;
  /**
   * Top medias by earnings in this user
   */
  top_medias: UserTopMedias_me_organization_user_top_medias[];
}

export interface UserTopMedias_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * User in this organization
   */
  user: UserTopMedias_me_organization_user | null;
}

export interface UserTopMedias_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: UserTopMedias_me_organization | null;
}

export interface UserTopMedias {
  /**
   * Get information about the currently logged in user
   */
  me: UserTopMedias_me;
}

export interface UserTopMediasVariables {
  input?: TopMediasCursorInput | null;
  userId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2Filters
// ====================================================

export interface OverviewV2Filters_me_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface OverviewV2Filters_me_media {
  __typename: "Media";
  id: string;
  metadata: OverviewV2Filters_me_media_metadata | null;
}

export interface OverviewV2Filters_me_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface OverviewV2Filters_me_album {
  __typename: "Album";
  id: string;
  metadata: OverviewV2Filters_me_album_metadata;
}

export interface OverviewV2Filters_me_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface OverviewV2Filters_me_user {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface OverviewV2Filters_me {
  __typename: "User";
  id: string;
  /**
   * Get single media by id
   */
  media: OverviewV2Filters_me_media | null;
  /**
   * Get single album by id
   */
  album: OverviewV2Filters_me_album | null;
  /**
   * Get single artist by id
   */
  artist: OverviewV2Filters_me_artist | null;
  /**
   * Get single user by id
   */
  user: OverviewV2Filters_me_user | null;
}

export interface OverviewV2Filters_geocountries {
  __typename: "Geocountry";
  id: string;
  name: string;
}

export interface OverviewV2Filters_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface OverviewV2Filters {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2Filters_me;
  /**
   * Get geocountries by shortcode
   */
  geocountries: OverviewV2Filters_geocountries[] | null;
  networks: OverviewV2Filters_networks[];
}

export interface OverviewV2FiltersVariables {
  mediaId?: string | null;
  albumId?: string | null;
  artistId?: string | null;
  userId?: string | null;
  countries?: string[] | null;
  networkIds?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2AlbumsTable
// ====================================================

export interface OverviewV2AlbumsTable_me_royalty_revenues_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
  /**
   * Date at which this album is released or going to be released
   */
  release_date: Scalar$DateTime | null;
}

export interface OverviewV2AlbumsTable_me_royalty_revenues_album {
  __typename: "Album";
  id: string;
  metadata: OverviewV2AlbumsTable_me_royalty_revenues_album_metadata;
}

export interface OverviewV2AlbumsTable_me_royalty_revenues {
  __typename: "RoyaltyRevenue";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of album sales
   */
  album_units_sold: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Album associated with the revenue
   */
  album: OverviewV2AlbumsTable_me_royalty_revenues_album | null;
}

export interface OverviewV2AlbumsTable_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * list out royalty revenues for the user
   */
  royalty_revenues: OverviewV2AlbumsTable_me_royalty_revenues[] | null;
  /**
   * Count of royalty revenues for an user
   */
  royalty_revenues_count: number;
}

export interface OverviewV2AlbumsTable {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2AlbumsTable_me;
}

export interface OverviewV2AlbumsTableVariables {
  input: RoyaltyRevenuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2CountriesTable
// ====================================================

export interface OverviewV2CountriesTable_me_royalty_revenues {
  __typename: "RoyaltyRevenue";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of album sales
   */
  album_units_sold: number;
  /**
   * Number of track sales
   */
  track_units_sold: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Country name associated with the report
   */
  country_name: string;
  /**
   * Country code associated with the report
   */
  country_code: AnalyticsCountryCode;
}

export interface OverviewV2CountriesTable_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * list out royalty revenues for the user
   */
  royalty_revenues: OverviewV2CountriesTable_me_royalty_revenues[] | null;
  /**
   * Count of royalty revenues for an user
   */
  royalty_revenues_count: number;
}

export interface OverviewV2CountriesTable {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2CountriesTable_me;
}

export interface OverviewV2CountriesTableVariables {
  input: RoyaltyRevenuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2NetworksTable
// ====================================================

export interface OverviewV2NetworksTable_me_royalty_revenues_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface OverviewV2NetworksTable_me_royalty_revenues {
  __typename: "RoyaltyRevenue";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of album sales
   */
  album_units_sold: number;
  /**
   * Number of track sales
   */
  track_units_sold: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Network associated with the report
   */
  network: OverviewV2NetworksTable_me_royalty_revenues_network;
}

export interface OverviewV2NetworksTable_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * list out royalty revenues for the user
   */
  royalty_revenues: OverviewV2NetworksTable_me_royalty_revenues[] | null;
  /**
   * Count of royalty revenues for an user
   */
  royalty_revenues_count: number;
}

export interface OverviewV2NetworksTable {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2NetworksTable_me;
}

export interface OverviewV2NetworksTableVariables {
  input: RoyaltyRevenuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2Graph
// ====================================================

export interface OverviewV2Graph_me_royalty_revenues {
  __typename: "RoyaltyRevenue";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Number of album sales
   */
  album_units_sold: number;
  /**
   * Number of track sales
   */
  track_units_sold: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Date of the report
   */
  date: Scalar$DateTime;
}

export interface OverviewV2Graph_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * list out royalty revenues for the user
   */
  royalty_revenues: OverviewV2Graph_me_royalty_revenues[] | null;
}

export interface OverviewV2Graph {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2Graph_me;
}

export interface OverviewV2GraphVariables {
  input: RoyaltyRevenuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2Totals
// ====================================================

export interface OverviewV2Totals_me_royalty_revenues {
  __typename: "RoyaltyRevenue";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Net track and album sale revenue
   */
  total_net_sales_revenue: number | null;
  /**
   * Agent net track and album sale revenue
   */
  total_agent_net_sales_revenue: number | null;
  /**
   * Gross track and album sale revenue
   */
  total_gross_sales_revenue: number | null;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Net revenue for streams
   */
  streams_net_revenue: number;
  /**
   * Agent net revenue for streams
   */
  streams_agent_net_revenue: number;
  /**
   * Gross revenue for streams
   */
  streams_gross_revenue: number;
  /**
   * Agent net revenue
   */
  agent_net_revenue: number | null;
}

export interface OverviewV2Totals_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * list out royalty revenues for the user
   */
  royalty_revenues: OverviewV2Totals_me_royalty_revenues[] | null;
}

export interface OverviewV2Totals {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2Totals_me;
}

export interface OverviewV2TotalsVariables {
  input: RoyaltyRevenuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2TracksTable
// ====================================================

export interface OverviewV2TracksTable_me_royalty_revenues_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface OverviewV2TracksTable_me_royalty_revenues_media_artist {
  __typename: "Artist";
  id: string;
}

export interface OverviewV2TracksTable_me_royalty_revenues_media {
  __typename: "Media";
  id: string;
  created_at: Scalar$DateTime;
  metadata: OverviewV2TracksTable_me_royalty_revenues_media_metadata | null;
  artist: OverviewV2TracksTable_me_royalty_revenues_media_artist | null;
  user_id: string;
}

export interface OverviewV2TracksTable_me_royalty_revenues {
  __typename: "RoyaltyRevenue";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_units_sold: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Media associated with the revenue
   */
  media: OverviewV2TracksTable_me_royalty_revenues_media | null;
}

export interface OverviewV2TracksTable_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * list out royalty revenues for the user
   */
  royalty_revenues: OverviewV2TracksTable_me_royalty_revenues[] | null;
  /**
   * Count of royalty revenues for an user
   */
  royalty_revenues_count: number;
}

export interface OverviewV2TracksTable {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2TracksTable_me;
}

export interface OverviewV2TracksTableVariables {
  input: RoyaltyRevenuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OverviewV2VideosTable
// ====================================================

export interface OverviewV2VideosTable_me_royalty_revenues_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface OverviewV2VideosTable_me_royalty_revenues_media {
  __typename: "Media";
  id: string;
  created_at: Scalar$DateTime;
  metadata: OverviewV2VideosTable_me_royalty_revenues_media_metadata | null;
}

export interface OverviewV2VideosTable_me_royalty_revenues {
  __typename: "RoyaltyRevenue";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_units_sold: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Media associated with the revenue
   */
  media: OverviewV2VideosTable_me_royalty_revenues_media | null;
}

export interface OverviewV2VideosTable_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * list out royalty revenues for the user
   */
  royalty_revenues: OverviewV2VideosTable_me_royalty_revenues[] | null;
  /**
   * Count of royalty revenues for an user
   */
  royalty_revenues_count: number;
}

export interface OverviewV2VideosTable {
  /**
   * Get information about the currently logged in user
   */
  me: OverviewV2VideosTable_me;
}

export interface OverviewV2VideosTableVariables {
  input: RoyaltyRevenuesInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayeeInviteActionButtons
// ====================================================

export interface PayeeInviteActionButtons_me_payee_invite_pending_assets {
  __typename: "PayeeAssets";
  count: number;
}

export interface PayeeInviteActionButtons_me_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  pending_assets: PayeeInviteActionButtons_me_payee_invite_pending_assets;
  total_earnings: number;
}

export interface PayeeInviteActionButtons_me {
  __typename: "User";
  id: string;
  /**
   * Get payee invite by id
   */
  payee_invite: PayeeInviteActionButtons_me_payee_invite | null;
}

export interface PayeeInviteActionButtons {
  /**
   * Get information about the currently logged in user
   */
  me: PayeeInviteActionButtons_me;
}

export interface PayeeInviteActionButtonsVariables {
  id: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayeesList
// ====================================================

export interface PayeesList_me_payee_invites_v2_payee_asset_splits {
  __typename: "PayeeAssets";
  count: number;
}

export interface PayeesList_me_payee_invites_v2_pending_asset_splits {
  __typename: "PayeeAssets";
  count: number;
}

export interface PayeesList_me_payee_invites_v2_active_asset_splits {
  __typename: "PayeeAssets";
  count: number;
}

export interface PayeesList_me_payee_invites_v2_payee {
  __typename: "User";
  id: string;
  /**
   * Is a charitable user
   */
  charity: boolean;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
  /**
   * Email Address for a user
   */
  email: string;
  first_name: string | null;
  last_name: string | null;
}

export interface PayeesList_me_payee_invites_v2 {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Time when the invite was archived
   */
  archived_at: Scalar$DateTime | null;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee phone number
   */
  phone_number: string;
  /**
   * Time when the invite was created
   */
  created_at: Scalar$DateTime;
  /**
   * Payee invite is or isnt for a charity
   */
  charity: boolean;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  payee_asset_splits: PayeesList_me_payee_invites_v2_payee_asset_splits;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  pending_asset_splits: PayeesList_me_payee_invites_v2_pending_asset_splits;
  /**
   * Assets that the Payee has been invited to share revenue on. Cursor results are of type Asset
   */
  active_asset_splits: PayeesList_me_payee_invites_v2_active_asset_splits;
  /**
   * The payee user
   */
  payee: PayeesList_me_payee_invites_v2_payee | null;
  total_earnings: number;
}

export interface PayeesList_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Count of PayeeInvites for a User
   */
  payee_invites_v2_count: number;
  /**
   * Anymore PayeeInvites for a User
   */
  payee_invites_v2_any_more_results: boolean;
  /**
   * List out all the PayeeInvites for a User
   */
  payee_invites_v2: PayeesList_me_payee_invites_v2[];
}

export interface PayeesList {
  /**
   * Get information about the currently logged in user
   */
  me: PayeesList_me;
}

export interface PayeesListVariables {
  input?: PayeeInvitesV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayeesListActiveCount
// ====================================================

export interface PayeesListActiveCount_me {
  __typename: "User";
  id: string;
  /**
   * Count of PayeeInvites for a User
   */
  payee_invites_v2_count: number;
}

export interface PayeesListActiveCount {
  /**
   * Get information about the currently logged in user
   */
  me: PayeesListActiveCount_me;
}

export interface PayeesListActiveCountVariables {
  input?: PayeeInvitesV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayeesListArchivedCount
// ====================================================

export interface PayeesListArchivedCount_me {
  __typename: "User";
  id: string;
  /**
   * Count of PayeeInvites for a User
   */
  payee_invites_v2_count: number;
}

export interface PayeesListArchivedCount {
  /**
   * Get information about the currently logged in user
   */
  me: PayeesListArchivedCount_me;
}

export interface PayeesListArchivedCountVariables {
  input?: PayeeInvitesV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenterPayorInvites
// ====================================================

export interface RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits_media {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  artist: RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits_media_artist | null;
  metadata: RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits_media_metadata | null;
}

export interface RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits_payor {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits {
  __typename: "PayeeMediaSplit";
  id: string;
  /**
   * Date when payor created payee asset splits
   */
  created_at: Scalar$DateTime;
  /**
   * Music video royalty percentage on asset split
   */
  music_video_percentage: number | null;
  /**
   * Sound recording royalty percentage on asset split
   */
  sound_recording_percentage: number | null;
  /**
   * The media for the payee invitation
   */
  media: RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits_media;
  /**
   * The payor of the payee invitation
   */
  payor: RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits_payor;
}

export interface RoyaltyCenterPayorInvites_me_payee_media_splits_cursor {
  __typename: "PayeeMediaSplits";
  /**
   * List of medias that user is invited to with the corresponding asset splits
   */
  payee_media_splits: RoyaltyCenterPayorInvites_me_payee_media_splits_cursor_payee_media_splits[];
}

export interface RoyaltyCenterPayorInvites_me {
  __typename: "User";
  id: string;
  /**
   * List out all the Medias that a User is invited to
   */
  payee_media_splits_cursor: RoyaltyCenterPayorInvites_me_payee_media_splits_cursor;
}

export interface RoyaltyCenterPayorInvites {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenterPayorInvites_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenter
// ====================================================

export interface RoyaltyCenter_me {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * User has an active cash advance recoupment
   */
  has_active_recoupment: boolean;
  /**
   * Flag for a user has a feature
   */
  has_feature_overview_v2: boolean;
  /**
   * Flag for a user has any feature at all
   */
  has_sales_trends: boolean;
}

export interface RoyaltyCenter {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenter_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ClientRecoupments
// ====================================================

export interface ClientRecoupments_me_cash_advance_recoupments_cursor {
  __typename: "CashAdvanceRecoupment";
  id: string;
  created_at: Scalar$DateTime;
  pretty_total_amount: string;
  pretty_amount_recouped: string;
}

export interface ClientRecoupments_me {
  __typename: "User";
  id: string;
  /**
   * Number of cash advance recoupments for user
   */
  cash_advance_recoupments_count: number;
  /**
   * Cash advance recoupments for user
   */
  cash_advance_recoupments_cursor: ClientRecoupments_me_cash_advance_recoupments_cursor[];
}

export interface ClientRecoupments {
  /**
   * Get information about the currently logged in user
   */
  me: ClientRecoupments_me;
}

export interface ClientRecoupmentsVariables {
  input?: CashAdvanceRecoupmentsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserBalanceMonths
// ====================================================

export interface UserBalanceMonths_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Information about whether or not a month has any balance_adjustments
   */
  balance_activity_months: Scalar$Date[];
}

export interface UserBalanceMonths_me {
  __typename: "User";
  /**
   * Email Address for a user
   */
  email: string;
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: UserBalanceMonths_me_organization | null;
}

export interface UserBalanceMonths_current_observer {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface UserBalanceMonths {
  /**
   * Get information about the currently logged in user
   */
  me: UserBalanceMonths_me;
  observer_signed_in: boolean;
  current_observer: UserBalanceMonths_current_observer | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesTrends
// ====================================================

export interface SalesTrends_me_sales_trends_SalesTrendsByCountry {
  __typename: "SalesTrendsByCountry" | "SalesTrendsByNetwork" | "SalesTrendsTotals";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_sales: number;
  /**
   * Number of album sales
   */
  album_sales: number | null;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
}

export interface SalesTrends_me_sales_trends_SalesTrendsByDate {
  __typename: "SalesTrendsByDate";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_sales: number;
  /**
   * Number of album sales
   */
  album_sales: number | null;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Date of the report
   */
  date: Scalar$DateTime;
}

export type SalesTrends_me_sales_trends = SalesTrends_me_sales_trends_SalesTrendsByCountry | SalesTrends_me_sales_trends_SalesTrendsByDate;

export interface SalesTrends_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * list out sales trends for the user
   */
  sales_trends: SalesTrends_me_sales_trends[] | null;
  /**
   * Count of sales trends for an user
   */
  sales_trends_count: number;
}

export interface SalesTrends {
  /**
   * Get information about the currently logged in user
   */
  me: SalesTrends_me;
}

export interface SalesTrendsVariables {
  input: SalesTrendsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesTrendsTotals
// ====================================================

export interface SalesTrendsTotals_me_sales_trends {
  __typename: "SalesTrendsByCountry" | "SalesTrendsByDate" | "SalesTrendsByNetwork" | "SalesTrendsTotals";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_sales: number;
  /**
   * Number of album sales
   */
  album_sales: number | null;
  /**
   * Number of streams
   */
  streams: number;
}

export interface SalesTrendsTotals_me {
  __typename: "User";
  id: string;
  /**
   * list out sales trends for the user
   */
  sales_trends: SalesTrendsTotals_me_sales_trends[] | null;
}

export interface SalesTrendsTotals {
  /**
   * Get information about the currently logged in user
   */
  me: SalesTrendsTotals_me;
}

export interface SalesTrendsTotalsVariables {
  input: SalesTrendsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesTrendsForHeatMap
// ====================================================

export interface SalesTrendsForHeatMap_me_sales_trends_SalesTrendsByDate {
  __typename: "SalesTrendsByDate" | "SalesTrendsByNetwork" | "SalesTrendsTotals";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_sales: number;
  /**
   * Number of album sales
   */
  album_sales: number | null;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
}

export interface SalesTrendsForHeatMap_me_sales_trends_SalesTrendsByCountry {
  __typename: "SalesTrendsByCountry";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_sales: number;
  /**
   * Number of album sales
   */
  album_sales: number | null;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Country code associated with the report
   */
  country_code: AnalyticsCountryCode;
}

export type SalesTrendsForHeatMap_me_sales_trends = SalesTrendsForHeatMap_me_sales_trends_SalesTrendsByDate | SalesTrendsForHeatMap_me_sales_trends_SalesTrendsByCountry;

export interface SalesTrendsForHeatMap_me {
  __typename: "User";
  id: string;
  /**
   * list out sales trends for the user
   */
  sales_trends: SalesTrendsForHeatMap_me_sales_trends[] | null;
  /**
   * Count of sales trends for an user
   */
  sales_trends_count: number;
}

export interface SalesTrendsForHeatMap {
  /**
   * Get information about the currently logged in user
   */
  me: SalesTrendsForHeatMap_me;
}

export interface SalesTrendsForHeatMapVariables {
  input: SalesTrendsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SalesTrendsForTable
// ====================================================

export interface SalesTrendsForTable_me_sales_trends_SalesTrendsByDate {
  __typename: "SalesTrendsByDate" | "SalesTrendsTotals";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_sales: number;
  /**
   * Number of album sales
   */
  album_sales: number | null;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
}

export interface SalesTrendsForTable_me_sales_trends_SalesTrendsByNetwork_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface SalesTrendsForTable_me_sales_trends_SalesTrendsByNetwork {
  __typename: "SalesTrendsByNetwork";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_sales: number;
  /**
   * Number of album sales
   */
  album_sales: number | null;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Network associated with the report
   */
  network: SalesTrendsForTable_me_sales_trends_SalesTrendsByNetwork_network;
}

export interface SalesTrendsForTable_me_sales_trends_SalesTrendsByCountry {
  __typename: "SalesTrendsByCountry";
  /**
   * Objects Identifier
   */
  id: string;
  /**
   * Number of track sales
   */
  track_sales: number;
  /**
   * Number of album sales
   */
  album_sales: number | null;
  /**
   * Gross revenue for the month
   */
  gross_revenue: number;
  /**
   * Net revenue for the month
   */
  net_revenue: number;
  /**
   * Number of streams
   */
  streams: number;
  /**
   * Country code associated with the report
   */
  country_code: AnalyticsCountryCode;
  /**
   * Country name associated with the report
   */
  country_name: string;
}

export type SalesTrendsForTable_me_sales_trends = SalesTrendsForTable_me_sales_trends_SalesTrendsByDate | SalesTrendsForTable_me_sales_trends_SalesTrendsByNetwork | SalesTrendsForTable_me_sales_trends_SalesTrendsByCountry;

export interface SalesTrendsForTable_me {
  __typename: "User";
  id: string;
  /**
   * list out sales trends for the user
   */
  sales_trends: SalesTrendsForTable_me_sales_trends[] | null;
  /**
   * Count of sales trends for an user
   */
  sales_trends_count: number;
}

export interface SalesTrendsForTable {
  /**
   * Get information about the currently logged in user
   */
  me: SalesTrendsForTable_me;
}

export interface SalesTrendsForTableVariables {
  input: SalesTrendsInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserBalanceHistory
// ====================================================

export interface UserBalanceHistory_me_balance_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface UserBalanceHistory_me_balance_activity_months {
  __typename: "MonthHasData";
  id: string;
  month: Scalar$Date;
  /**
   * Does the month contain any balance adjustments
   */
  has_data: boolean;
}

export interface UserBalanceHistory_me_balance_history_date_summaries_grouped_balance_adjustments {
  __typename: "GroupedBalanceAdjustment";
  id: string;
  balance_adjustment_id: string;
  is_tipalti: boolean;
  type: string;
  amount: number;
  network: string;
  period_date: string | null;
  description: string | null;
}

export interface UserBalanceHistory_me_balance_history_date_summaries {
  __typename: "BalanceHistoryDateSummary";
  id: string;
  date: Scalar$DateTime;
  total_amount: number;
  grouped_balance_adjustments: UserBalanceHistory_me_balance_history_date_summaries_grouped_balance_adjustments[];
}

export interface UserBalanceHistory_me {
  __typename: "User";
  /**
   * Email Address for a user
   */
  email: string;
  id: string;
  /**
   * The networks the user has made revenue from
   */
  balance_networks: UserBalanceHistory_me_balance_networks[];
  /**
   * Information about whether or not a month has any balance_adjustments
   */
  balance_activity_months: UserBalanceHistory_me_balance_activity_months[];
  /**
   * Summaries of all balance adjustments that occurred on specific dates
   */
  balance_history_date_summaries: UserBalanceHistory_me_balance_history_date_summaries[];
}

export interface UserBalanceHistory_current_observer {
  __typename: "User";
  id: string;
  /**
   * Email Address for a user
   */
  email: string;
}

export interface UserBalanceHistory {
  /**
   * Get information about the currently logged in user
   */
  me: UserBalanceHistory_me;
  observer_signed_in: boolean;
  current_observer: UserBalanceHistory_current_observer | null;
}

export interface UserBalanceHistoryVariables {
  input?: BalanceHistoryDateSummariesCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserBalance
// ====================================================

export interface UserBalance_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Total label earnings for all top users in the organization
   */
  net_label_revenue_cumulative: number;
}

export interface UserBalance_me {
  __typename: "User";
  id: string;
  pretty_available_balance: string;
  /**
   * client_revenue using materialized views and only including locked-in revenue (not including pending revenue)
   */
  client_revenue_royalty_center: number;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * User has an active cash advance recoupment
   */
  has_active_recoupment: boolean;
  /**
   * An Organization a user is a member of
   */
  organization: UserBalance_me_organization | null;
}

export interface UserBalance {
  /**
   * Get information about the currently logged in user
   */
  me: UserBalance_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenterPaymentConfirmationAcceptRcaForm
// ====================================================

export interface RoyaltyCenterPaymentConfirmationAcceptRcaForm_me {
  __typename: "User";
  id: string;
  /**
   * Returns a rendered html string of RCA, Royalty Center Authorization
   */
  render_rca: string;
}

export interface RoyaltyCenterPaymentConfirmationAcceptRcaForm {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenterPaymentConfirmationAcceptRcaForm_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenterPaymentConfirmationAuthorization
// ====================================================

export interface RoyaltyCenterPaymentConfirmationAuthorization_me {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user has ever signed an agreement OR Whether or not a user has signed a specific agreement by type
   */
  has_signed_rca: boolean;
}

export interface RoyaltyCenterPaymentConfirmationAuthorization {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenterPaymentConfirmationAuthorization_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenterPaymentConfirmationDetails
// ====================================================

export interface RoyaltyCenterPaymentConfirmationDetails_me_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  /**
   * Payee phone number
   */
  phone_number: string;
  /**
   * Payee email address
   */
  email: string;
}

export interface RoyaltyCenterPaymentConfirmationDetails_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface RoyaltyCenterPaymentConfirmationDetails_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface RoyaltyCenterPaymentConfirmationDetails_me_medias_v2 {
  __typename: "Media";
  id: string;
  thumbnail_url: string | null;
  type: MediaType;
  artist: RoyaltyCenterPaymentConfirmationDetails_me_medias_v2_artist | null;
  metadata: RoyaltyCenterPaymentConfirmationDetails_me_medias_v2_metadata | null;
}

export interface RoyaltyCenterPaymentConfirmationDetails_me {
  __typename: "User";
  id: string;
  /**
   * Get payee invite by id
   */
  payee_invite: RoyaltyCenterPaymentConfirmationDetails_me_payee_invite | null;
  /**
   * Medias of the user
   */
  medias_v2: RoyaltyCenterPaymentConfirmationDetails_me_medias_v2[];
}

export interface RoyaltyCenterPaymentConfirmationDetails {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenterPaymentConfirmationDetails_me;
}

export interface RoyaltyCenterPaymentConfirmationDetailsVariables {
  payeeInviteId: string;
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenterPaymentCreationChooseMedia
// ====================================================

export interface RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_assets {
  __typename: "Asset";
  id: string;
  type: AssetType;
}

export interface RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_music_video_remaining_payee_percentage {
  __typename: "MediaRemainingPayeePercentage";
  id: string;
  remaining_percentage: number | null;
  reason: RemainingPayeePercentageReason | null;
}

export interface RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_sound_recording_remaining_payee_percentage {
  __typename: "MediaRemainingPayeePercentage";
  id: string;
  remaining_percentage: number | null;
  reason: RemainingPayeePercentageReason | null;
}

export interface RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2 {
  __typename: "Media";
  id: string;
  artist: RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_artist | null;
  /**
   * The assets associated to this media
   */
  assets: RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_assets[];
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  /**
   * Error that would be occur if assets were created for this media
   */
  create_media_assets_error_phrase: string | null;
  /**
   * Determines if this media can share royalties
   */
  can_share_royalties: boolean;
  metadata: RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_metadata | null;
  /**
   * The remaining percentage amount that is yet to be allocated to payees
   */
  music_video_remaining_payee_percentage: RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_music_video_remaining_payee_percentage;
  /**
   * Allows a media to be selectable in the asset split creation
   */
  payee_already_invited: boolean;
  /**
   * The remaining percentage amount that is yet to be allocated to payees
   */
  sound_recording_remaining_payee_percentage: RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2_sound_recording_remaining_payee_percentage;
  thumbnail_url: string | null;
  type: MediaType;
}

export interface RoyaltyCenterPaymentCreationChooseMedia_me {
  __typename: "User";
  id: string;
  /**
   * Any more results of medias
   */
  medias_v2_any_more_results: boolean;
  /**
   * Medias of the user
   */
  medias_v2: RoyaltyCenterPaymentCreationChooseMedia_me_medias_v2[];
}

export interface RoyaltyCenterPaymentCreationChooseMedia {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenterPaymentCreationChooseMedia_me;
}

export interface RoyaltyCenterPaymentCreationChooseMediaVariables {
  limit: number;
  offset: number;
  payeeInviteId: string;
  searchTerm?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenterPaymentCreationChoosePayeeCard
// ====================================================

export interface RoyaltyCenterPaymentCreationChoosePayeeCard_me_payee_invites_cursor_payee_invites_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCard_me_payee_invites_cursor_payee_invites {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: RoyaltyCenterPaymentCreationChoosePayeeCard_me_payee_invites_cursor_payee_invites_payee | null;
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCard_me_payee_invites_cursor {
  __typename: "PayeeInvites";
  count: number;
  /**
   * List of payee invites
   */
  payee_invites: RoyaltyCenterPaymentCreationChoosePayeeCard_me_payee_invites_cursor_payee_invites[];
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCard_me_charity_invites_cursor_payee_invites_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCard_me_charity_invites_cursor_payee_invites {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: RoyaltyCenterPaymentCreationChoosePayeeCard_me_charity_invites_cursor_payee_invites_payee | null;
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCard_me_charity_invites_cursor {
  __typename: "PayeeInvites";
  count: number;
  /**
   * List of payee invites
   */
  payee_invites: RoyaltyCenterPaymentCreationChoosePayeeCard_me_charity_invites_cursor_payee_invites[];
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCard_me_charitable_users {
  __typename: "User";
  id: string;
  /**
   * The name of the charity
   */
  charity_name: string;
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCard_me {
  __typename: "User";
  id: string;
  /**
   * List out all the PayeeInvites for a User
   */
  payee_invites_cursor: RoyaltyCenterPaymentCreationChoosePayeeCard_me_payee_invites_cursor;
  /**
   * List out all the PayeeInvites for a User
   */
  charity_invites_cursor: RoyaltyCenterPaymentCreationChoosePayeeCard_me_charity_invites_cursor;
  /**
   * User accounts that are charitably
   */
  charitable_users: RoyaltyCenterPaymentCreationChoosePayeeCard_me_charitable_users[];
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCard {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenterPaymentCreationChoosePayeeCard_me;
}

export interface RoyaltyCenterPaymentCreationChoosePayeeCardVariables {
  searchTerm?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenterSplits
// ====================================================

export interface RoyaltyCenterSplits_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface RoyaltyCenterSplits_me_medias_v2_assets {
  __typename: "Asset";
  id: string;
  type: AssetType;
}

export interface RoyaltyCenterSplits_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface RoyaltyCenterSplits_me_medias_v2_music_video_remaining_payee_percentage {
  __typename: "MediaRemainingPayeePercentage";
  id: string;
  remaining_percentage: number | null;
  reason: RemainingPayeePercentageReason | null;
}

export interface RoyaltyCenterSplits_me_medias_v2_sound_recording_remaining_payee_percentage {
  __typename: "MediaRemainingPayeePercentage";
  id: string;
  remaining_percentage: number | null;
  reason: RemainingPayeePercentageReason | null;
}

export interface RoyaltyCenterSplits_me_medias_v2 {
  __typename: "Media";
  id: string;
  artist: RoyaltyCenterSplits_me_medias_v2_artist | null;
  /**
   * The assets associated to this media
   */
  assets: RoyaltyCenterSplits_me_medias_v2_assets[];
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  /**
   * Error that would be occur if assets were created for this media
   */
  create_media_assets_error_phrase: string | null;
  /**
   * Determines if this media can share royalties
   */
  can_share_royalties: boolean;
  metadata: RoyaltyCenterSplits_me_medias_v2_metadata | null;
  /**
   * The remaining percentage amount that is yet to be allocated to payees
   */
  music_video_remaining_payee_percentage: RoyaltyCenterSplits_me_medias_v2_music_video_remaining_payee_percentage;
  /**
   * Allows a media to be selectable in the asset split creation
   */
  payee_already_invited: boolean;
  /**
   * The remaining percentage amount that is yet to be allocated to payees
   */
  sound_recording_remaining_payee_percentage: RoyaltyCenterSplits_me_medias_v2_sound_recording_remaining_payee_percentage;
  thumbnail_url: string | null;
  type: MediaType;
}

export interface RoyaltyCenterSplits_me_payee_invite_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface RoyaltyCenterSplits_me_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: RoyaltyCenterSplits_me_payee_invite_payee | null;
}

export interface RoyaltyCenterSplits_me {
  __typename: "User";
  id: string;
  /**
   * Medias of the user
   */
  medias_v2: RoyaltyCenterSplits_me_medias_v2[];
  /**
   * Get payee invite by id
   */
  payee_invite: RoyaltyCenterSplits_me_payee_invite | null;
}

export interface RoyaltyCenterSplits {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenterSplits_me;
}

export interface RoyaltyCenterSplitsVariables {
  input?: MediasV2CursorInput | null;
  payeeInviteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: PayeeInviteSticky
// ====================================================

export interface PayeeInviteSticky_me_payee_invite_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface PayeeInviteSticky_me_payee_invite {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: PayeeInviteSticky_me_payee_invite_payee | null;
}

export interface PayeeInviteSticky_me {
  __typename: "User";
  id: string;
  /**
   * Get payee invite by id
   */
  payee_invite: PayeeInviteSticky_me_payee_invite | null;
}

export interface PayeeInviteSticky {
  /**
   * Get information about the currently logged in user
   */
  me: PayeeInviteSticky_me;
}

export interface PayeeInviteStickyVariables {
  payeeInviteId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RoyaltyCenterPaymentCreation
// ====================================================

export interface RoyaltyCenterPaymentCreation_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface RoyaltyCenterPaymentCreation {
  /**
   * Get information about the currently logged in user
   */
  me: RoyaltyCenterPaymentCreation_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: ShareAlbum
// ====================================================

export interface ShareAlbum_shared_album_partners_network {
  __typename: "Network";
  id: string;
  name: string;
}

export interface ShareAlbum_shared_album_partners {
  __typename: "AlbumPartnerData";
  id: string;
  name: string;
  partner_url: string;
  partner_eid: string | null;
  hide_release_link: boolean;
  type: string;
  /**
   * corresponding network object to this data
   */
  network: ShareAlbum_shared_album_partners_network;
}

export interface ShareAlbum_shared_album_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface ShareAlbum_shared_album_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ShareAlbum_shared_album_album_medias_cursor_album_medias_media_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface ShareAlbum_shared_album_album_medias_cursor_album_medias_media_file_upload {
  __typename: "FileUpload";
  id: string;
  duration_in_ms: number | null;
  created_at: Scalar$DateTime | null;
}

export interface ShareAlbum_shared_album_album_medias_cursor_album_medias_media_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface ShareAlbum_shared_album_album_medias_cursor_album_medias_media_metadata_artistRoles {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
}

export interface ShareAlbum_shared_album_album_medias_cursor_album_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
  copyright_name: string;
  copyright_year: number | null;
  /**
   * Genre for the media associated to this metadata
   */
  genre: string;
  label: string;
  lyrics: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  collaborating_artists: ShareAlbum_shared_album_album_medias_cursor_album_medias_media_metadata_collaborating_artists[];
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  artistRoles: ShareAlbum_shared_album_album_medias_cursor_album_medias_media_metadata_artistRoles[];
}

export interface ShareAlbum_shared_album_album_medias_cursor_album_medias_media {
  __typename: "Media";
  id: string;
  original_url: string | null;
  artist: ShareAlbum_shared_album_album_medias_cursor_album_medias_media_artist | null;
  /**
   * Actual file associated to this media
   */
  file_upload: ShareAlbum_shared_album_album_medias_cursor_album_medias_media_file_upload | null;
  metadata: ShareAlbum_shared_album_album_medias_cursor_album_medias_media_metadata | null;
}

export interface ShareAlbum_shared_album_album_medias_cursor_album_medias {
  __typename: "AlbumMedia";
  id: string;
  /**
   * track number associated with this media in an album
   */
  track_number: number;
  media: ShareAlbum_shared_album_album_medias_cursor_album_medias_media;
}

export interface ShareAlbum_shared_album_album_medias_cursor {
  __typename: "AlbumMedias";
  count: number;
  /**
   * List of medias on an album
   */
  album_medias: ShareAlbum_shared_album_album_medias_cursor_album_medias[];
}

export interface ShareAlbum_shared_album_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface ShareAlbum_shared_album_metadata_additionalMainArtists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * Name of the Artist
   */
  name: string;
}

export interface ShareAlbum_shared_album_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
  genre: string | null;
  label: string | null;
  copyright_name: string | null;
  copyright_year: number | null;
  /**
   * locations that this album is valid in, if it is worldwide, the array is ["WW"]
   */
  territories: string[];
  upc: string | null;
  /**
   * Date at which this album is released or going to be released
   */
  release_date: Scalar$DateTime | null;
  /**
   * List of Collaborating Artists for the album this metadata is associated with
   */
  collaborating_artists: ShareAlbum_shared_album_metadata_collaborating_artists[];
  /**
   * List of Collaborating Artists for the album this metadata is associated with
   */
  additionalMainArtists: ShareAlbum_shared_album_metadata_additionalMainArtists[];
}

export interface ShareAlbum_shared_album {
  __typename: "Album";
  id: string;
  is_distributed: boolean;
  /**
   * list of partners an album is released to
   */
  partners: ShareAlbum_shared_album_partners[];
  file_upload: ShareAlbum_shared_album_file_upload | null;
  artist: ShareAlbum_shared_album_artist;
  /**
   * Album can have a list of medias and are surfed through as a cursor
   */
  album_medias_cursor: ShareAlbum_shared_album_album_medias_cursor;
  metadata: ShareAlbum_shared_album_metadata;
}

export interface ShareAlbum {
  /**
   * Find the shared album by encoded ID
   */
  shared_album: ShareAlbum_shared_album | null;
}

export interface ShareAlbumVariables {
  secret: string;
  artistRoles?: string[] | null;
  performerRole?: string[] | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OAuthUrls
// ====================================================

export interface OAuthUrls {
  /**
   * Login URL for Facebook OAuth
   */
  facebook_login_url: string;
  /**
   * Login URL for YouTube OAuth
   */
  youtube_login_url: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SignInPreloadWebpackAssets
// ====================================================

export interface SignInPreloadWebpackAssets {
  /**
   * Webpack asset version hashed paths for the current & latest front end assets
   */
  d2_js: string[];
  /**
   * Webpack asset version hashed paths for the current & latest front end assets
   */
  d2_css: string[];
  /**
   * Webpack asset version hashed paths for the current & latest front end assets
   */
  application_js: string[];
  /**
   * Webpack asset version hashed paths for the current & latest front end assets
   */
  application_css: string[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SignInFeatures
// ====================================================

export interface SignInFeatures_branding_carousel_items {
  __typename: "CarouselItem";
  /**
   * Returns a custom ID for body and title
   */
  id: string;
  /**
   * Main text of accordion item
   */
  body: string;
  /**
   * Title of accordion item
   */
  title: string;
}

export interface SignInFeatures_branding {
  __typename: "Branding";
  id: string;
  sign_in_carousel_text_color: string | null;
  carousel_items: SignInFeatures_branding_carousel_items[] | null;
}

export interface SignInFeatures {
  branding: SignInFeatures_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignInUserMutation
// ====================================================

export interface SignInUserMutation_signInUser_organization {
  __typename: "Organization";
  id: string;
  /**
   * Organization's dashboard url
   */
  dashboard_url: string | null;
  /**
   * Name of the organization
   */
  name: string;
}

export interface SignInUserMutation_signInUser_user {
  __typename: "User";
  id: string;
  send_secure_login: boolean;
  /**
   * Default path a user will enter when signing in
   */
  landing_path: string | null;
  /**
   * Flag for a user must contact support, set when the user is locked
   */
  must_contact_support: boolean;
}

export interface SignInUserMutation_signInUser_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SignInUserMutation_signInUser {
  __typename: "SignInUserPayload";
  /**
   * link for the login
   */
  login_link: string | null;
  /**
   * organization
   */
  organization: SignInUserMutation_signInUser_organization | null;
  user: SignInUserMutation_signInUser_user | null;
  errors: SignInUserMutation_signInUser_errors[] | null;
}

export interface SignInUserMutation {
  /**
   * Sign in a user. This mutation returns a User. Used in the main login screen to sign users into the platform.
   */
  signInUser: SignInUserMutation_signInUser;
}

export interface SignInUserMutationVariables {
  input: SignInUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateWhitelabelUserMutation
// ====================================================

export interface CreateWhitelabelUserMutation_createWhitelabelUser_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface CreateWhitelabelUserMutation_createWhitelabelUser {
  __typename: "CreateWhitelabelUserPayload";
  errors: CreateWhitelabelUserMutation_createWhitelabelUser_errors[] | null;
}

export interface CreateWhitelabelUserMutation {
  /**
   * Create a whitelabel user. This mutation returns a User. Used in the main login screen to sign up new WL users who have has_open_signup enabled.
   */
  createWhitelabelUser: CreateWhitelabelUserMutation_createWhitelabelUser;
}

export interface CreateWhitelabelUserMutationVariables {
  input: CreateWhitelabelUserInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SpotifyForArtists
// ====================================================

export interface SpotifyForArtists_me_artists_to_connect {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  /**
   * URL to connect Spotify account to this artist
   */
  spotify_connect_url: string;
}

export interface SpotifyForArtists_me_artists_to_maybe_reconnect_active_spotify_users {
  __typename: "SpotifyUser";
  id: string;
}

export interface SpotifyForArtists_me_artists_to_maybe_reconnect {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  /**
   * URL to connect Spotify account to this artist
   */
  spotify_connect_url: string;
  active_spotify_users: SpotifyForArtists_me_artists_to_maybe_reconnect_active_spotify_users[];
}

export interface SpotifyForArtists_me {
  __typename: "User";
  id: string;
  /**
   * List out all Artists for the current user
   */
  artists_to_connect: SpotifyForArtists_me_artists_to_connect[];
  /**
   * List out all Artists for the current user
   */
  artists_to_maybe_reconnect: SpotifyForArtists_me_artists_to_maybe_reconnect[];
}

export interface SpotifyForArtists {
  /**
   * Get information about the currently logged in user
   */
  me: SpotifyForArtists_me;
}

export interface SpotifyForArtistsVariables {
  artistsWithSpotifyInput?: ArtistsCursorInput | null;
  artistsWithoutSpotifyInput?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Statements
// ====================================================

export interface Statements_statement_groups_statements {
  __typename: "Statement";
  /**
   * Custom ID for Statement
   */
  id: string;
  date: Scalar$DateTime;
  network: string;
  status: StatementStatus;
}

export interface Statements_statement_groups {
  __typename: "StatementGroup";
  /**
   * Custom ID for Statement Group
   */
  id: string;
  /**
   * Either the date or network for the statement group
   */
  title: string;
  /**
   * The statements in the statement group
   */
  statements: Statements_statement_groups_statements[];
}

export interface Statements {
  /**
   * Grouped statements
   */
  statement_groups: Statements_statement_groups[];
  /**
   * Number of grouped statements
   */
  statement_groups_count: number;
}

export interface StatementsVariables {
  input?: StatementsCursorInput | null;
  countInput?: StatementsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: StatementNetworks
// ====================================================

export interface StatementNetworks_statement_networks {
  __typename: "Network";
  id: string;
  name: string;
}

export interface StatementNetworks_default_statement_networks {
  __typename: "Network";
  id: string;
}

export interface StatementNetworks {
  /**
   * Networks that have a group connected
   */
  statement_networks: StatementNetworks_statement_networks[];
  /**
   * Default Networks (top earning)
   */
  default_statement_networks: StatementNetworks_default_statement_networks[];
  /**
   * List of years for period
   */
  period_years: number[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: storageSuccess
// ====================================================

export interface storageSuccess_me_active_storage_subscription_downgrade_to_storage_tier_tier_permission {
  __typename: "TierPermission";
  id: string;
  /**
   * Price for a TierPermission
   */
  yearly_price: number;
}

export interface storageSuccess_me_active_storage_subscription_downgrade_to_storage_tier {
  __typename: "StorageTier";
  id: string;
  /**
   * Tier Permission associated to a storage tier
   */
  tier_permission: storageSuccess_me_active_storage_subscription_downgrade_to_storage_tier_tier_permission;
}

export interface storageSuccess_me_active_storage_subscription {
  __typename: "Subscription";
  /**
   * When the user downgrades to a storage tier, It is downgraded at the end of the subscription. The storage it should downgrade to, is stored at this field
   */
  downgrade_to_storage_tier_id: string | null;
  downgrade_to_storage_tier: storageSuccess_me_active_storage_subscription_downgrade_to_storage_tier | null;
  id: string;
  /**
   * Time when this subscription renews at
   */
  renew_at: Scalar$DateTime;
}

export interface storageSuccess_me {
  __typename: "User";
  /**
   * current storage subscription a user is subscribed to
   */
  active_storage_subscription: storageSuccess_me_active_storage_subscription | null;
  id: string;
}

export interface storageSuccess {
  /**
   * Get information about the currently logged in user
   */
  me: storageSuccess_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SubProcessors
// ====================================================

export interface SubProcessors_branding {
  __typename: "Branding";
  id: string;
  render_sub_processors: string;
}

export interface SubProcessors {
  branding: SubProcessors_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TipaltiTaxForm
// ====================================================

export interface TipaltiTaxForm_me_tipalti_user_data {
  __typename: "TipaltiUser";
  id: string;
  tipalti_user_name: string;
  must_submit_tax_form: boolean;
  submitted_tax_form_type: TaxFormType | null;
  address_line_one: string | null;
  address_line_two: string | null;
  address_country: string | null;
  tax_id: string | null;
  tax_id_type: TaxIdType | null;
  electronic_signature: string | null;
  signature_date: Scalar$DateTime | null;
  treaty_country: string | null;
}

export interface TipaltiTaxForm_me {
  __typename: "User";
  id: string;
  /**
   * When the user created their profile
   */
  created_at: Scalar$DateTime;
  /**
   * url for tipalti registration
   */
  tipalti_url: string;
  /**
   * Tipalti data for user's tax documents
   */
  tipalti_user_data: TipaltiTaxForm_me_tipalti_user_data | null;
}

export interface TipaltiTaxForm {
  /**
   * Get information about the currently logged in user
   */
  me: TipaltiTaxForm_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SignAffidavit
// ====================================================

export interface SignAffidavit_signAffidavit_user_tipalti_user_data {
  __typename: "TipaltiUser";
  id: string;
  must_submit_tax_form: boolean;
  tipalti_user_name: string;
}

export interface SignAffidavit_signAffidavit_user {
  __typename: "User";
  id: string;
  /**
   * Tipalti data for user's tax documents
   */
  tipalti_user_data: SignAffidavit_signAffidavit_user_tipalti_user_data | null;
}

export interface SignAffidavit_signAffidavit_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface SignAffidavit_signAffidavit {
  __typename: "SignAffidavitPayload";
  user: SignAffidavit_signAffidavit_user | null;
  errors: SignAffidavit_signAffidavit_errors[] | null;
}

export interface SignAffidavit {
  /**
   * Default description for "SignAffidavit" mutation.
   */
  signAffidavit: SignAffidavit_signAffidavit;
}

export interface SignAffidavitVariables {
  input: SignAffidavitInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Terms
// ====================================================

export interface Terms_branding {
  __typename: "Branding";
  id: string;
  render_terms_and_conditions: string;
}

export interface Terms {
  branding: Terms_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: termsAndConditions
// ====================================================

export interface termsAndConditions_i18n_TermsAndConditions {
  __typename: "TermsAndConditions";
  accept: string;
  acceptText: string;
  notice: string;
  noticeHeader: string;
  signout: string;
  tandc: string;
  tandcHeader: string;
}

export interface termsAndConditions_i18n {
  __typename: "I18n";
  TermsAndConditions: termsAndConditions_i18n_TermsAndConditions;
}

export interface termsAndConditions_mfa {
  __typename: "MFA";
  id: string;
  /**
   * Whether or not the user has turned on OTP mfa
   */
  otp_mfa: boolean;
}

export interface termsAndConditions {
  /**
   * Get translation strings for screens and components.
   */
  i18n: termsAndConditions_i18n;
  /**
   * Information used to populate the MFA/OTP screen when a user is prompted to enter MFA information
   */
  mfa: termsAndConditions_mfa;
  /**
   * Returns a rendered html string of Terms and Conditions
   */
  render_terms_and_conditions: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: TipaltiSignup
// ====================================================

export interface TipaltiSignup_me {
  __typename: "User";
  id: string;
  /**
   * url for tipalti registration
   */
  tipalti_url: string;
  /**
   * User has been onboarded to tipalti
   */
  is_payable: boolean;
}

export interface TipaltiSignup_branding {
  __typename: "Branding";
  id: string;
  tipalti_faq_url: string;
}

export interface TipaltiSignup {
  /**
   * Get information about the currently logged in user
   */
  me: TipaltiSignup_me;
  branding: TipaltiSignup_branding;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: AcceptUcla
// ====================================================

export interface AcceptUcla_acceptUcla_current_user {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user need to sign a new universal cla
   */
  must_sign_ucla: boolean;
  /**
   * When a user is not able to upload content due to a pending agreement from their label's owner
   */
  requires_label_owner_agreement: boolean;
}

export interface AcceptUcla_acceptUcla_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
}

export interface AcceptUcla_acceptUcla {
  __typename: "AcceptUclaPayload";
  /**
   * If the request is authenticated, the current_user response object will contain the authenticated User object.
   */
  current_user: AcceptUcla_acceptUcla_current_user | null;
  errors: AcceptUcla_acceptUcla_errors[] | null;
}

export interface AcceptUcla {
  /**
   * Accept a UCLA for a User. This mutation returns a User object. Used to specifically accept a UCLA agreement once reviewed by the user.
   */
  acceptUcla: AcceptUcla_acceptUcla;
}

export interface AcceptUclaVariables {
  input: AcceptUclaInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: RenderUcla
// ====================================================

export interface RenderUcla_me {
  __typename: "User";
  id: string;
  render_ucla: string;
}

export interface RenderUcla {
  /**
   * Get information about the currently logged in user
   */
  me: RenderUcla_me;
}

export interface RenderUclaVariables {
  entityName: string;
  signatoryTitle: string;
  firstName: string;
  lastName: string;
  address: string;
  city: string;
  state: string;
  country: string;
  postal: string;
  clientType: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: Ucla
// ====================================================

export interface Ucla_i18n_ContentLicenseAgreement {
  __typename: "ContentLicenseAgreement";
  individual: string;
  entity: string;
}

export interface Ucla_i18n_UCLAConfirmationSection {
  __typename: "UCLAConfirmationSection";
  agree: string;
  byClicking: string;
  clientIntent: string;
  clientRepresents: string;
  legalEntity: string;
  naturalPerson: string;
  preferPhysicalSignature: string;
}

export interface Ucla_i18n {
  __typename: "I18n";
  ContentLicenseAgreement: Ucla_i18n_ContentLicenseAgreement;
  UCLAConfirmationSection: Ucla_i18n_UCLAConfirmationSection;
}

export interface Ucla_territories_provinces {
  __typename: "Province";
  id: string;
  name: string;
}

export interface Ucla_territories {
  __typename: "Territory";
  id: string;
  name: string;
  /**
   * Gets all the available states or provinces for a given country territory. Null if not US.
   */
  provinces: Ucla_territories_provinces[] | null;
}

export interface Ucla_me {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user need to sign a new universal cla
   */
  must_sign_ucla: boolean;
  /**
   * When a user is not able to upload content due to a pending agreement from their label's owner
   */
  requires_label_owner_agreement: boolean;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface Ucla_branding {
  __typename: "Branding";
  id: string;
  faq_url: string;
}

export interface Ucla {
  /**
   * Get translation strings for screens and components.
   */
  i18n: Ucla_i18n;
  /**
   * Gets all the available ownership territories from our system
   */
  territories: Ucla_territories[];
  /**
   * Get information about the currently logged in user
   */
  me: Ucla_me;
  branding: Ucla_branding;
  observer_signed_in: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UploadMetadataComposition
// ====================================================

export interface UploadMetadataComposition_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  default_label_name: string | null;
  default_copyright_name: string | null;
  default_copyright_year: number | null;
  /**
   * Default genre enum for this artist
   */
  default_genre_enum: Genre | null;
  /**
   * Default secondary genre enum for this artist
   */
  default_secondary_genre_enum: Genre | null;
  /**
   * Default language code enum for this artist
   */
  default_language_code_enum: MediaLanguageCode | null;
}

export interface UploadMetadataComposition_me {
  __typename: "User";
  id: string;
  /**
   * List out all Artists for the current user
   */
  artists: UploadMetadataComposition_me_artists[];
}

export interface UploadMetadataComposition_media_languages {
  __typename: "MediaLanguage";
  id: string;
  name: string;
}

export interface UploadMetadataComposition_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface UploadMetadataComposition_media_subcategories {
  __typename: "MediaSubCategory";
  id: string;
  label: string;
}

export interface UploadMetadataComposition {
  /**
   * Get information about the currently logged in user
   */
  me: UploadMetadataComposition_me;
  /**
   * Gets all the available languages for an audio track.
   */
  media_languages: UploadMetadataComposition_media_languages[];
  /**
   * Gets all the available Genres from our system
   */
  genres: UploadMetadataComposition_genres[];
  /**
   * Gets all the available media sub categories for our system
   */
  media_subcategories: UploadMetadataComposition_media_subcategories[];
}

export interface UploadMetadataCompositionVariables {
  input?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: CreateCompositionContent
// ====================================================

export interface CreateCompositionContent_createCompContent_media {
  __typename: "Media";
  id: string;
}

export interface CreateCompositionContent_createCompContent_errors {
  __typename: "UserError";
  key: string | null;
  messages: string[];
  resource_type: string | null;
  resource_id: string | null;
}

export interface CreateCompositionContent_createCompContent {
  __typename: "CreateCompContentPayload";
  media: CreateCompositionContent_createCompContent_media | null;
  errors: CreateCompositionContent_createCompContent_errors[] | null;
}

export interface CreateCompositionContent {
  /**
   * Creates a media representing composition only content and returns the media
   */
  createCompContent: CreateCompositionContent_createCompContent;
}

export interface CreateCompositionContentVariables {
  input: CreateCompContentInput;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: AudioMetadatas
// ====================================================

export interface AudioMetadatas_me_medias_v2_albums_v2_cursor_file_upload {
  __typename: "FileUpload";
  id: string;
  album_artwork_url: string | null;
}

export interface AudioMetadatas_me_medias_v2_albums_v2_cursor_metadata {
  __typename: "AlbumMetadata";
  id: string;
  name: string | null;
}

export interface AudioMetadatas_me_medias_v2_albums_v2_cursor {
  __typename: "Album";
  id: string;
  file_upload: AudioMetadatas_me_medias_v2_albums_v2_cursor_file_upload | null;
  metadata: AudioMetadatas_me_medias_v2_albums_v2_cursor_metadata;
}

export interface AudioMetadatas_me_medias_v2_artist {
  __typename: "Artist";
  id: string;
  apple_eid: string | null;
  spotify_eid: string | null;
  /**
   * Name of an artist
   */
  name: string;
}

export interface AudioMetadatas_me_medias_v2_metadata_collaborating_artists {
  __typename: "CollaboratingArtist";
  id: string;
  /**
   * apple id
   */
  apple_eid: string | null;
  /**
   * spotify id
   */
  spotify_eid: string | null;
  /**
   * Name of the Artist
   */
  name: string;
  role: string;
}

export interface AudioMetadatas_me_medias_v2_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Unique code for audio
   */
  audio_isrc: string;
  copyright_name: string;
  copyright_year: number | null;
  /**
   * Genre for the media associated to this metadata
   */
  genre: string;
  /**
   * Flag for the media associated with this metadata is a cover
   */
  is_cover: boolean | null;
  /**
   * Indicates if the media associated with this metadata is explicit or not
   */
  explicit_type: ExplicitType;
  label: string;
  lyrics: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
  /**
   * List of Collaborating Artists for the media this metadata is associated with
   */
  collaborating_artists: AudioMetadatas_me_medias_v2_metadata_collaborating_artists[];
}

export interface AudioMetadatas_me_medias_v2 {
  __typename: "Media";
  id: string;
  /**
   * List out all the albums for media
   */
  albums_v2_cursor: AudioMetadatas_me_medias_v2_albums_v2_cursor[];
  artist: AudioMetadatas_me_medias_v2_artist | null;
  metadata: AudioMetadatas_me_medias_v2_metadata | null;
}

export interface AudioMetadatas_me {
  __typename: "User";
  id: string;
  /**
   * Medias of the user
   */
  medias_v2: AudioMetadatas_me_medias_v2[];
}

export interface AudioMetadatas_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface AudioMetadatas_additional_artist_roles {
  __typename: "CollaboratingArtistRoleObject";
  id: string;
  /**
   * Unique role name
   */
  name: string;
}

export interface AudioMetadatas_credit_roles {
  __typename: "CollaboratingArtistRoleObject";
  id: string;
  /**
   * Unique role name
   */
  name: string;
}

export interface AudioMetadatas {
  /**
   * Get information about the currently logged in user
   */
  me: AudioMetadatas_me;
  /**
   * Gets all the available Genres from our system
   */
  genres: AudioMetadatas_genres[];
  /**
   * Get all collaborating artist roles of type "additional_artist" for specified content_type
   */
  additional_artist_roles: AudioMetadatas_additional_artist_roles[];
  /**
   * Get all collaborating artist roles of type "credit" for specified content_type
   */
  credit_roles: AudioMetadatas_credit_roles[];
}

export interface AudioMetadatasVariables {
  input?: MediasV2CursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UploadMetadata
// ====================================================

export interface UploadMetadata_me_artists {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
  default_label_name: string | null;
  default_copyright_name: string | null;
  default_copyright_year: number | null;
  /**
   * Default genre enum for this artist
   */
  default_genre_enum: Genre | null;
  /**
   * Default secondary genre enum for this artist
   */
  default_secondary_genre_enum: Genre | null;
  /**
   * Default language code enum for this artist
   */
  default_language_code_enum: MediaLanguageCode | null;
  apple_eid: string | null;
  spotify_eid: string | null;
}

export interface UploadMetadata_me {
  __typename: "User";
  id: string;
  /**
   * List out all Artists for the current user
   */
  artists: UploadMetadata_me_artists[];
}

export interface UploadMetadata_media_languages {
  __typename: "MediaLanguage";
  id: string;
  name: string;
}

export interface UploadMetadata_genres {
  __typename: "GenreObject";
  id: Genre;
  name: string;
}

export interface UploadMetadata_media_subcategories {
  __typename: "MediaSubCategory";
  id: string;
  label: string;
}

export interface UploadMetadata {
  /**
   * Get information about the currently logged in user
   */
  me: UploadMetadata_me;
  /**
   * Gets all the available languages for an audio track.
   */
  media_languages: UploadMetadata_media_languages[];
  /**
   * Gets all the available Genres from our system
   */
  genres: UploadMetadata_genres[];
  /**
   * Gets all the available media sub categories for our system
   */
  media_subcategories: UploadMetadata_media_subcategories[];
}

export interface UploadMetadataVariables {
  input?: ArtistsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: SupportedVideoTypes
// ====================================================

export interface SupportedVideoTypes {
  /**
   * Gets all the supported video types
   */
  supported_video_types: SupportedVideo[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UploadScreen
// ====================================================

export interface UploadScreen_me {
  __typename: "User";
  id: string;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
}

export interface UploadScreen {
  /**
   * Get information about the currently logged in user
   */
  me: UploadScreen_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UploadDefaultMetadata
// ====================================================

export interface UploadDefaultMetadata_artist {
  __typename: "Artist";
  id: string;
  default_label_name: string | null;
  default_copyright_name: string | null;
  default_copyright_year: number | null;
  /**
   * Default genre enum for this artist
   */
  default_genre_enum: Genre | null;
  /**
   * Default secondary genre enum for this artist
   */
  default_secondary_genre_enum: Genre | null;
  /**
   * Default language code enum for this artist
   */
  default_language_code_enum: MediaLanguageCode | null;
}

export interface UploadDefaultMetadata {
  /**
   * Find one of the current user's Artists by ID
   */
  artist: UploadDefaultMetadata_artist;
}

export interface UploadDefaultMetadataVariables {
  artistId: string;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserEmailConfirmed
// ====================================================

export interface UserEmailConfirmed_me {
  __typename: "User";
  /**
   * Email Address for a user
   */
  email: string;
  /**
   * Flag for the email is confirmed
   */
  email_confirmed: boolean;
  id: string;
  is_admin: boolean;
  is_whitelabel: boolean;
  /**
   * Default path a user will enter when signing in
   */
  landing_path: string | null;
}

export interface UserEmailConfirmed {
  /**
   * Get information about the currently logged in user
   */
  me: UserEmailConfirmed_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationCurrentBalanceBox
// ====================================================

export interface OrganizationCurrentBalanceBox_me_organization_owner {
  __typename: "User";
  id: string;
  pretty_available_balance_inexact: string;
}

export interface OrganizationCurrentBalanceBox_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Owner of this organization
   */
  owner: OrganizationCurrentBalanceBox_me_organization_owner | null;
}

export interface OrganizationCurrentBalanceBox_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationCurrentBalanceBox_me_organization | null;
}

export interface OrganizationCurrentBalanceBox {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationCurrentBalanceBox_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserCurrentBalanceBox
// ====================================================

export interface UserCurrentBalanceBox_me {
  __typename: "User";
  id: string;
  pretty_available_balance_inexact: string;
}

export interface UserCurrentBalanceBox {
  /**
   * Get information about the currently logged in user
   */
  me: UserCurrentBalanceBox_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationLifetimeEarningsBox
// ====================================================

export interface OrganizationLifetimeEarningsBox_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Total label earnings for all top users in the organization (inexact)
   */
  pretty_net_label_inexact_revenue_cumulative: string;
}

export interface OrganizationLifetimeEarningsBox_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationLifetimeEarningsBox_me_organization | null;
}

export interface OrganizationLifetimeEarningsBox {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationLifetimeEarningsBox_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserLifetimeEarningsBox
// ====================================================

export interface UserLifetimeEarningsBox_me {
  __typename: "User";
  id: string;
  /**
   * pretty_client_revenue_inexact using materialized views and only including locked-in revenue (not including pending revenue)
   */
  pretty_client_revenue_inexact_royalty_center: string;
}

export interface UserLifetimeEarningsBox {
  /**
   * Get information about the currently logged in user
   */
  me: UserLifetimeEarningsBox_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: WidgetDashboard
// ====================================================

export interface WidgetDashboard_me_recommendation_banners {
  __typename: "RecommendationBanner";
  id: string;
  background_image_svg: string | null;
  button_primary_text: string | null;
  button_primary_url: string | null;
  button_secondary_text: string | null;
  button_secondary_url: string | null;
  open_primary_in_new_window: boolean | null;
  open_secondary_in_new_window: boolean | null;
  title_large: string | null;
  title_small: string | null;
  subtitle: string | null;
}

export interface WidgetDashboard_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Name of the organization
   */
  name: string;
}

export interface WidgetDashboard_me_socials_needing_reconnect_AudiomackUser_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface WidgetDashboard_me_socials_needing_reconnect_AudiomackUser {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel";
  id: string;
  /**
   * Artist connected to this social
   */
  artist: WidgetDashboard_me_socials_needing_reconnect_AudiomackUser_artist;
  reconnect_url: string | null;
  /**
   * Model name on Vydia Platform related to a Social
   */
  social_model_name: string | null;
  /**
   * Title of this Social
   */
  title: string | null;
}

export interface WidgetDashboard_me_socials_needing_reconnect_YoutubeChannel_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface WidgetDashboard_me_socials_needing_reconnect_YoutubeChannel {
  __typename: "YoutubeChannel";
  id: string;
  /**
   * Artist connected to this social
   */
  artist: WidgetDashboard_me_socials_needing_reconnect_YoutubeChannel_artist;
  /**
   * Email used for YouTube
   */
  email: string | null;
  reconnect_url: string | null;
  /**
   * Model name on Vydia Platform related to a Social
   */
  social_model_name: string | null;
  /**
   * Channel Title
   */
  title: string | null;
}

export type WidgetDashboard_me_socials_needing_reconnect = WidgetDashboard_me_socials_needing_reconnect_AudiomackUser | WidgetDashboard_me_socials_needing_reconnect_YoutubeChannel;

export interface WidgetDashboard_me {
  __typename: "User";
  id: string;
  /**
   * Has analytics for v2 audio analytics screen
   */
  has_audio_analytics: boolean;
  is_whitelabel: boolean;
  /**
   * The role of this user within the Organization/Label
   */
  role: UserRole;
  /**
   * Banners to show for a given input
   */
  recommendation_banners: WidgetDashboard_me_recommendation_banners[] | null;
  /**
   * Count of Releases for a user
   */
  actionRequiredCount: number;
  /**
   * Has analytics for specified network name
   */
  hasSpotifyAnalytics: boolean;
  /**
   * Has analytics for specified network name
   */
  hasAppleAnalytics: boolean;
  /**
   * Has analytics for specified network name
   */
  hasAmazonAnalytics: boolean;
  /**
   * Whether or not the user needs to acknowledge an address change
   */
  must_acknowledge_address_change: boolean;
  /**
   * An Organization a user is a member of
   */
  organization: WidgetDashboard_me_organization | null;
  /**
   * Socials across all of the user's artists that need reconnect
   */
  socials_needing_reconnect: WidgetDashboard_me_socials_needing_reconnect[] | null;
}

export interface WidgetDashboard {
  /**
   * Get information about the currently logged in user
   */
  me: WidgetDashboard_me;
  observer_signed_in: boolean;
}

export interface WidgetDashboardVariables {
  successPath?: string | null;
  errorPath?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationRankedMedias
// ====================================================

export interface OrganizationRankedMedias_me_organization_ranked_medias_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface OrganizationRankedMedias_me_organization_ranked_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface OrganizationRankedMedias_me_organization_ranked_medias_media {
  __typename: "Media";
  id: string;
  metadata: OrganizationRankedMedias_me_organization_ranked_medias_media_metadata | null;
}

export interface OrganizationRankedMedias_me_organization_ranked_medias_user {
  __typename: "User";
  id: string;
}

export interface OrganizationRankedMedias_me_organization_ranked_medias {
  __typename: "RankedMedia";
  id: string;
  todays_rank: number;
  yesterdays_rank: number;
  todays_streams: number;
  updated_at: Scalar$DateTime;
  artist: OrganizationRankedMedias_me_organization_ranked_medias_artist;
  media: OrganizationRankedMedias_me_organization_ranked_medias_media;
  user: OrganizationRankedMedias_me_organization_ranked_medias_user;
}

export interface OrganizationRankedMedias_me_organization {
  __typename: "Organization";
  id: string;
  /**
   * Count of top medias in this organization
   */
  ranked_medias_count: number;
  /**
   * Top medias by streams in this organization
   */
  ranked_medias: OrganizationRankedMedias_me_organization_ranked_medias[];
}

export interface OrganizationRankedMedias_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationRankedMedias_me_organization | null;
}

export interface OrganizationRankedMedias {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationRankedMedias_me;
}

export interface OrganizationRankedMediasVariables {
  input?: RankedMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserRankedMedias
// ====================================================

export interface UserRankedMedias_me_ranked_medias_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface UserRankedMedias_me_ranked_medias_media_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface UserRankedMedias_me_ranked_medias_media {
  __typename: "Media";
  id: string;
  metadata: UserRankedMedias_me_ranked_medias_media_metadata | null;
}

export interface UserRankedMedias_me_ranked_medias_user {
  __typename: "User";
  id: string;
}

export interface UserRankedMedias_me_ranked_medias {
  __typename: "RankedMedia";
  id: string;
  todays_rank: number;
  yesterdays_rank: number;
  todays_streams: number;
  updated_at: Scalar$DateTime;
  artist: UserRankedMedias_me_ranked_medias_artist;
  media: UserRankedMedias_me_ranked_medias_media;
  user: UserRankedMedias_me_ranked_medias_user;
}

export interface UserRankedMedias_me {
  __typename: "User";
  id: string;
  /**
   * Count of top medias for a user
   */
  ranked_medias_count: number;
  /**
   * Top medias by streams for a user
   */
  ranked_medias: UserRankedMedias_me_ranked_medias[];
}

export interface UserRankedMedias {
  /**
   * Get information about the currently logged in user
   */
  me: UserRankedMedias_me;
}

export interface UserRankedMediasVariables {
  input?: RankedMediasCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationStorageUsageBox
// ====================================================

export interface OrganizationStorageUsageBox_me_organization {
  __typename: "Organization";
  id: string;
  current_storage_usage: number;
}

export interface OrganizationStorageUsageBox_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationStorageUsageBox_me_organization | null;
}

export interface OrganizationStorageUsageBox {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationStorageUsageBox_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserStorageUsageBox
// ====================================================

export interface UserStorageUsageBox_me {
  __typename: "User";
  id: string;
  current_storage_usage: number;
}

export interface UserStorageUsageBox {
  /**
   * Get information about the currently logged in user
   */
  me: UserStorageUsageBox_me;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: OrganizationTotalConflictsBox
// ====================================================

export interface OrganizationTotalConflictsBox_me_organization_youtube_conflict_totals {
  __typename: "YoutubeConflictTotals";
  id: string;
  total_active_conflicts: number;
}

export interface OrganizationTotalConflictsBox_me_organization {
  __typename: "Organization";
  id: string;
  youtube_conflict_totals: OrganizationTotalConflictsBox_me_organization_youtube_conflict_totals[];
}

export interface OrganizationTotalConflictsBox_me {
  __typename: "User";
  id: string;
  /**
   * An Organization a user is a member of
   */
  organization: OrganizationTotalConflictsBox_me_organization | null;
}

export interface OrganizationTotalConflictsBox {
  /**
   * Get information about the currently logged in user
   */
  me: OrganizationTotalConflictsBox_me;
}

export interface OrganizationTotalConflictsBoxVariables {
  input?: YoutubeConflictsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: UserTotalConflictsBox
// ====================================================

export interface UserTotalConflictsBox_me_youtube_conflict_totals {
  __typename: "YoutubeConflictTotals";
  id: string;
  total_active_conflicts: number;
}

export interface UserTotalConflictsBox_me {
  __typename: "User";
  id: string;
  youtube_conflict_totals: UserTotalConflictsBox_me_youtube_conflict_totals[];
}

export interface UserTotalConflictsBox {
  /**
   * Get information about the currently logged in user
   */
  me: UserTotalConflictsBox_me;
}

export interface UserTotalConflictsBoxVariables {
  input?: YoutubeConflictsCursorInput | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: PayeeInviteFragment
// ====================================================

export interface PayeeInviteFragment_payee {
  __typename: "User";
  id: string;
  /**
   * A custom external url for this User.
   */
  external_url: string | null;
  /**
   * Image url for the user's avatar(s)
   */
  image_url: string | null;
}

export interface PayeeInviteFragment {
  __typename: "PayeeInvite";
  id: string;
  /**
   * Payee email address
   */
  email: string;
  /**
   * Payee first name
   */
  first_name: string;
  /**
   * Payee last name
   */
  last_name: string;
  payee_id: string | null;
  /**
   * The payee user
   */
  payee: PayeeInviteFragment_payee | null;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: UserMustSignUcla
// ====================================================

export interface UserMustSignUcla {
  __typename: "User";
  id: string;
  /**
   * Whether or not the user need to sign a new universal cla
   */
  must_sign_ucla: boolean;
  /**
   * When a user is not able to upload content due to a pending agreement from their label's owner
   */
  requires_label_owner_agreement: boolean;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: ReleaseHistoryBroadcastFragment
// ====================================================

export interface ReleaseHistoryBroadcastFragment_social_network {
  __typename: "Network";
  id: string;
  name: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
}

export interface ReleaseHistoryBroadcastFragment_social {
  __typename: "AudiomackUser" | "DailymotionUser" | "FacebookPage" | "InstagramBusinessAccount" | "InstagramUser" | "SpotifyUser" | "TwitterUser" | "VevoYoutubeChannel" | "YoutubeChannel";
  id: string;
  /**
   * Get the size of the thumbnail
   */
  thumbnail_url: string;
  /**
   * Title of this Social
   */
  title: string | null;
  /**
   * Network Object for a network associated with a social
   */
  network: ReleaseHistoryBroadcastFragment_social_network | null;
}

export interface ReleaseHistoryBroadcastFragment_network {
  __typename: "Network";
  id: string;
  /**
   * Name of the deployer
   */
  deployer_name: string;
  name: string;
  /**
   * Image URL for this network
   */
  image_url: string;
}

export interface ReleaseHistoryBroadcastFragment {
  __typename: "Broadcast";
  id: string;
  video_url: string | null;
  status: string;
  deploy_at: Scalar$DateTime | null;
  distributed_at: Scalar$DateTime | null;
  /**
   * Determines which social does this broadcast belong to
   */
  social: ReleaseHistoryBroadcastFragment_social | null;
  /**
   * Network associated with a broadcast
   */
  network: ReleaseHistoryBroadcastFragment_network;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: DealFragment
// ====================================================

export interface DealFragment_hubspot_deal {
  __typename: "HubspotDeal";
  id: string;
  dealname: string;
  url: string;
}

export interface DealFragment_advances {
  __typename: "DealAdvanceItem";
  id: string;
  /**
   * The month of the advance (zero-index)
   */
  month: number;
  /**
   * The amount of the advance in dollars
   */
  amount_dollars: number;
}

export interface DealFragment_latest_review_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface DealFragment_author {
  __typename: "User";
  id: string;
  full_name_or_email: string;
}

export interface DealFragment {
  __typename: "Deal";
  id: string;
  /**
   * When the deal was archived
   */
  archived_at: Scalar$DateTime | null;
  /**
   * The predicted borrower recoupment period in months. E.g. how long before borrower has paid back their loan
   */
  borrower_recoupment_period_months: number;
  /**
   * The client's split percent after recoupment
   */
  client_after_recoupment_split_percent: number;
  /**
   * The client's recoupment split percent during recoupment
   */
  client_recoupment_split_percent: number;
  /**
   * When the deal was first drafted
   */
  created_at: Scalar$DateTime;
  /**
   * The predicted internal rate of return (IRR) of the deal
   */
  irr: number;
  /**
   * The HubSpot deal record associated with this deal
   */
  hubspot_deal: DealFragment_hubspot_deal | null;
  /**
   * The reviewer may leave a note describing their reasoning
   */
  latest_review_note: string | null;
  /**
   * The latest review status of the deal
   */
  latest_review_status: DealReviewStatus;
  /**
   * The predicted lender payback period in months. E.g. how long before lender breaks even
   */
  lender_payback_period_months: number;
  /**
   * The predicted multiple on invested capital (MOIC) of the deal
   */
  moic: number;
  /**
   * The estimated monthly gross cash flow in dollars at the time of drafting the deal
   */
  monthly_gross_cash_flow_dollars: number;
  /**
   * The number of months to delay the monthly growth/decline rate
   */
  monthly_growth_rate_delay_months: number;
  /**
   * The predicted monthly growth/decline rate of the content each month
   */
  monthly_growth_rate_percent: number;
  /**
   * The net profit in dollars that the lender is predicted to earn by the time that the borrower has paid back the recoupment.
   */
  profit_thru_recoupment: number | null;
  /**
   * The net profit in dollars that the lender is predicted to earn by the end of the deal term.
   */
  profit_thru_term: number | null;
  /**
   * The term in months for the deal
   */
  term_months: number;
  /**
   * The title of the deal as entered by the creator
   */
  title: string;
  /**
   * When the deal was last updated
   */
  updated_at: Scalar$DateTime;
  media_id: string | null;
  organization_id: string | null;
  user_id: string | null;
  /**
   * The advances of the deal
   */
  advances: DealFragment_advances[];
  /**
   * The latest review author of the deal. Null if no reviews have been made yet.
   */
  latest_review_author: DealFragment_latest_review_author | null;
  /**
   * The author user of the deal
   */
  author: DealFragment_author;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MerchItemFragment
// ====================================================

export interface MerchItemFragment_design_variants {
  __typename: "DesignVariant";
  id: string;
  accepted_at: Scalar$DateTime | null;
  /**
   * image of the design, smaller and thumbnail sized. Shown displayed on the actual merch. Must be null during initial generation, because the id is used in the s3 key in the URL.
   */
  design_image_thumbnail_url: string | null;
  /**
   * image of the design. Must be null during initial generation, because the id is used in the s3 key in the URL.
   */
  design_image_print_url: string | null;
  canvas_color: string;
  /**
   * s3 path to a custom image to use for the design variant, uploaded by the user, or a vydia designer. Null if no custom image is used by this variant.
   */
  custom_image_s3_path: string | null;
}

export interface MerchItemFragment {
  __typename: "MerchItem";
  id: string;
  album_id: string;
  design_variants: MerchItemFragment_design_variants[];
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL fragment: MediaSplit
// ====================================================

export interface MediaSplit_artist {
  __typename: "Artist";
  id: string;
  /**
   * Name of an artist
   */
  name: string;
}

export interface MediaSplit_assets {
  __typename: "Asset";
  id: string;
  type: AssetType;
}

export interface MediaSplit_metadata {
  __typename: "Metadata";
  id: string;
  /**
   * Song name of the media this metadata is associated with
   */
  song_name: string;
}

export interface MediaSplit_music_video_remaining_payee_percentage {
  __typename: "MediaRemainingPayeePercentage";
  id: string;
  remaining_percentage: number | null;
  reason: RemainingPayeePercentageReason | null;
}

export interface MediaSplit_sound_recording_remaining_payee_percentage {
  __typename: "MediaRemainingPayeePercentage";
  id: string;
  remaining_percentage: number | null;
  reason: RemainingPayeePercentageReason | null;
}

export interface MediaSplit {
  __typename: "Media";
  id: string;
  artist: MediaSplit_artist | null;
  /**
   * The assets associated to this media
   */
  assets: MediaSplit_assets[];
  /**
   * Category of the media either Web | Music
   */
  category: VideoCategory;
  /**
   * Error that would be occur if assets were created for this media
   */
  create_media_assets_error_phrase: string | null;
  /**
   * Determines if this media can share royalties
   */
  can_share_royalties: boolean;
  metadata: MediaSplit_metadata | null;
  /**
   * The remaining percentage amount that is yet to be allocated to payees
   */
  music_video_remaining_payee_percentage: MediaSplit_music_video_remaining_payee_percentage;
  /**
   * Allows a media to be selectable in the asset split creation
   */
  payee_already_invited: boolean;
  /**
   * The remaining percentage amount that is yet to be allocated to payees
   */
  sound_recording_remaining_payee_percentage: MediaSplit_sound_recording_remaining_payee_percentage;
  thumbnail_url: string | null;
  type: MediaType;
}

/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Available actions (permissions) for a User
 */
export enum AccessPolicyAction {
  access_bypass_payout_requirements_features = "access_bypass_payout_requirements_features",
  access_cops_features = "access_cops_features",
  access_dev_features = "access_dev_features",
  access_lar_features = "access_lar_features",
  access_negative_balance_feature = "access_negative_balance_feature",
  access_rm_features = "access_rm_features",
  access_vip_feature = "access_vip_feature",
  allow_recoupment_sharing = "allow_recoupment_sharing",
  change_user_organization = "change_user_organization",
  create = "create",
  create_s3_bucket = "create_s3_bucket",
  deactivate_user = "deactivate_user",
  delete = "delete",
  deploy = "deploy",
  edit_has_label_revenue = "edit_has_label_revenue",
  gdpr_purge = "gdpr_purge",
  hubspot = "hubspot",
  ingest_segments = "ingest_segments",
  ingest_statements = "ingest_statements",
  lock_in_periods = "lock_in_periods",
  log_termination_notice = "log_termination_notice",
  manage_accounting_access = "manage_accounting_access",
  manage_client_success_access = "manage_client_success_access",
  manage_content_operations_access = "manage_content_operations_access",
  manage_executive_access = "manage_executive_access",
  manage_label_artist_relations_access = "manage_label_artist_relations_access",
  manage_label_services_access = "manage_label_services_access",
  manage_legal_access = "manage_legal_access",
  manage_marketing_access = "manage_marketing_access",
  manage_new_dev_access = "manage_new_dev_access",
  manage_organization_restrictions = "manage_organization_restrictions",
  manage_people_ops_access = "manage_people_ops_access",
  manage_rights_management_access = "manage_rights_management_access",
  manage_royalties_access = "manage_royalties_access",
  manage_super_access = "manage_super_access",
  manage_user_restrictions = "manage_user_restrictions",
  move = "move",
  observe = "observe",
  observe_mutate = "observe_mutate",
  partner_xml_override = "partner_xml_override",
  predict_revenue = "predict_revenue",
  read = "read",
  refund = "refund",
  reset = "reset",
  reset_mfa = "reset_mfa",
  resolve_split_requests = "resolve_split_requests",
  reupload_artwork_after_distribution = "reupload_artwork_after_distribution",
  review = "review",
  set_admin = "set_admin",
  set_mutually_terminated = "set_mutually_terminated",
  set_withdrawal_limit = "set_withdrawal_limit",
  show_in_csm_dropdown = "show_in_csm_dropdown",
  show_in_label_rep_dropdown = "show_in_label_rep_dropdown",
  show_in_poc_dropdown = "show_in_poc_dropdown",
  transfer_balances = "transfer_balances",
  transfer_content = "transfer_content",
  update = "update",
  update_csm = "update_csm",
  update_label_rep = "update_label_rep",
  update_organization_royalty_split = "update_organization_royalty_split",
  update_poc = "update_poc",
  update_royalty_split = "update_royalty_split",
  update_status = "update_status",
  view_royalties = "view_royalties",
  view_tipalti_info = "view_tipalti_info",
  wipe_revenue = "wipe_revenue",
}

/**
 * Available subjects to check permissions against for a User
 */
export enum AccessPolicyModel {
  album = "album",
  artist = "artist",
  asset = "asset",
  asset_digest = "asset_digest",
  asset_split_request = "asset_split_request",
  balance_adjustment = "balance_adjustment",
  broadcast = "broadcast",
  claim = "claim",
  deal = "deal",
  group = "group",
  media = "media",
  message = "message",
  organization = "organization",
  period = "period",
  recoupment = "recoupment",
  release = "release",
  release_extra = "release_extra",
  transaction = "transaction",
  user = "user",
  vydia = "vydia",
}

/**
 * Additional Service Category
 */
export enum AdditionalServiceCategory {
  CONTENT = "CONTENT",
  DESIGN = "DESIGN",
  SECURITY = "SECURITY",
}

/**
 * Additional Service Type
 */
export enum AdditionalServiceType {
  AUDIO = "AUDIO",
  PREMIUM_PARTNER = "PREMIUM_PARTNER",
  SOCIAL = "SOCIAL",
}

/**
 * The name of a variant or an alternative for the AdditionalServices Experiment split test.
 */
export enum AdditionalServicesVariant {
  Pages = "Pages",
  Tabs = "Tabs",
}

/**
 * This enum contains the types of agreements that can be accepted by the AcceptAgreementByType mutation.
 */
export enum AgreementByTypeAgreementType {
  fan_insights_privacy_agreement = "fan_insights_privacy_agreement",
  merch_agreement = "merch_agreement",
}

/**
 * The album type such as Single or EP.
 */
export enum AlbumPartnerDataLinkActionType {
  DOWNLOAD = "DOWNLOAD",
  PLAY = "PLAY",
}

export enum AlbumsSortBy {
  created_at = "created_at",
  id = "id",
  name = "name",
  revenue = "revenue",
}

/**
 * Analytics country code
 */
export enum AnalyticsCountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AN = "AN",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BQ = "BQ",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CW = "CW",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  SS = "SS",
  ST = "ST",
  SV = "SV",
  SX = "SX",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UK = "UK",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  XK = "XK",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZK = "ZK",
  ZM = "ZM",
  ZW = "ZW",
  ZZ = "ZZ",
}

/**
 * Artist Search By
 */
export enum ArtistSearchBy {
  artist_name = "artist_name",
  user_email = "user_email",
  user_name = "user_name",
}

/**
 * Artists Sort By
 */
export enum ArtistsCursorSortBy {
  fans_count = "fans_count",
  name = "name",
}

/**
 * Indicates the type of an earnings asset.
 */
export enum AssetType {
  composition = "composition",
  music_video = "music_video",
  sound_recording = "sound_recording",
  web = "web",
}

/**
 * Audio Analytics By Country Sort By
 */
export enum AudioAnalyticsByCountrySortBy {
  apple_music_listeners = "apple_music_listeners",
  apple_music_streams = "apple_music_streams",
  country_code = "country_code",
  spotify_streams = "spotify_streams",
  total_listeners = "total_listeners",
  total_streams = "total_streams",
}

/**
 * Audio Analytics Networks
 */
export enum AudioAnalyticsNetworks {
  amazon_music = "amazon_music",
  apple_music = "apple_music",
  spotify = "spotify",
}

/**
 * Audio Analytics Sort By
 */
export enum AudioAnalyticsSortBy {
  amazon_music_streams = "amazon_music_streams",
  apple_music_downloads = "apple_music_downloads",
  apple_music_streams = "apple_music_streams",
  date = "date",
  spotify_saves = "spotify_saves",
  spotify_skips = "spotify_skips",
  spotify_streams = "spotify_streams",
  total_downloads = "total_downloads",
  total_saves = "total_saves",
  total_skips = "total_skips",
  total_streams = "total_streams",
}

export enum BrandSignInTemplate {
  CENTERED = "CENTERED",
  DEFAULT = "DEFAULT",
  HORIZONTAL_SPLIT = "HORIZONTAL_SPLIT",
}

export enum BroadcastsSortBy {
  date = "date",
  network = "network",
}

/**
 * Organization Cash Advance Recoupments Cursor Sort By
 */
export enum CashAdvanceRecoupmentsCursorSortBy {
  amount_recouped = "amount_recouped",
  created_at = "created_at",
  id = "id",
  recouped_at = "recouped_at",
  total_amount = "total_amount",
  user = "user",
}

/**
 * General metadata content types. Used to filter which collaborating artist roles are available for the content's metadata
 */
export enum CollaboratingArtistContentTypeFilter {
  album = "album",
  audio = "audio",
  video = "video",
}

/**
 * Country code
 */
export enum CountryCode {
  AD = "AD",
  AE = "AE",
  AF = "AF",
  AG = "AG",
  AI = "AI",
  AL = "AL",
  AM = "AM",
  AO = "AO",
  AQ = "AQ",
  AR = "AR",
  AS = "AS",
  AT = "AT",
  AU = "AU",
  AW = "AW",
  AX = "AX",
  AZ = "AZ",
  BA = "BA",
  BB = "BB",
  BD = "BD",
  BE = "BE",
  BF = "BF",
  BG = "BG",
  BH = "BH",
  BI = "BI",
  BJ = "BJ",
  BL = "BL",
  BM = "BM",
  BN = "BN",
  BO = "BO",
  BR = "BR",
  BS = "BS",
  BT = "BT",
  BV = "BV",
  BW = "BW",
  BY = "BY",
  BZ = "BZ",
  CA = "CA",
  CC = "CC",
  CD = "CD",
  CF = "CF",
  CG = "CG",
  CH = "CH",
  CI = "CI",
  CK = "CK",
  CL = "CL",
  CM = "CM",
  CN = "CN",
  CO = "CO",
  CR = "CR",
  CU = "CU",
  CV = "CV",
  CX = "CX",
  CY = "CY",
  CZ = "CZ",
  DE = "DE",
  DJ = "DJ",
  DK = "DK",
  DM = "DM",
  DO = "DO",
  DZ = "DZ",
  EC = "EC",
  EE = "EE",
  EG = "EG",
  EH = "EH",
  ER = "ER",
  ES = "ES",
  ET = "ET",
  FI = "FI",
  FJ = "FJ",
  FK = "FK",
  FM = "FM",
  FO = "FO",
  FR = "FR",
  GA = "GA",
  GB = "GB",
  GD = "GD",
  GE = "GE",
  GF = "GF",
  GG = "GG",
  GH = "GH",
  GI = "GI",
  GL = "GL",
  GM = "GM",
  GN = "GN",
  GP = "GP",
  GQ = "GQ",
  GR = "GR",
  GS = "GS",
  GT = "GT",
  GU = "GU",
  GW = "GW",
  GY = "GY",
  HK = "HK",
  HM = "HM",
  HN = "HN",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  ID = "ID",
  IE = "IE",
  IL = "IL",
  IM = "IM",
  IN = "IN",
  IO = "IO",
  IQ = "IQ",
  IR = "IR",
  IS = "IS",
  IT = "IT",
  JE = "JE",
  JM = "JM",
  JO = "JO",
  JP = "JP",
  KE = "KE",
  KG = "KG",
  KH = "KH",
  KI = "KI",
  KM = "KM",
  KN = "KN",
  KP = "KP",
  KR = "KR",
  KW = "KW",
  KY = "KY",
  KZ = "KZ",
  LA = "LA",
  LB = "LB",
  LC = "LC",
  LI = "LI",
  LK = "LK",
  LR = "LR",
  LS = "LS",
  LT = "LT",
  LU = "LU",
  LV = "LV",
  LY = "LY",
  MA = "MA",
  MC = "MC",
  MD = "MD",
  ME = "ME",
  MF = "MF",
  MG = "MG",
  MH = "MH",
  MK = "MK",
  ML = "ML",
  MM = "MM",
  MN = "MN",
  MO = "MO",
  MP = "MP",
  MQ = "MQ",
  MR = "MR",
  MS = "MS",
  MT = "MT",
  MU = "MU",
  MV = "MV",
  MW = "MW",
  MX = "MX",
  MY = "MY",
  MZ = "MZ",
  NA = "NA",
  NC = "NC",
  NE = "NE",
  NF = "NF",
  NG = "NG",
  NI = "NI",
  NL = "NL",
  NO = "NO",
  NP = "NP",
  NR = "NR",
  NU = "NU",
  NZ = "NZ",
  OM = "OM",
  PA = "PA",
  PE = "PE",
  PF = "PF",
  PG = "PG",
  PH = "PH",
  PK = "PK",
  PL = "PL",
  PM = "PM",
  PN = "PN",
  PR = "PR",
  PS = "PS",
  PT = "PT",
  PW = "PW",
  PY = "PY",
  QA = "QA",
  RE = "RE",
  RO = "RO",
  RS = "RS",
  RU = "RU",
  RW = "RW",
  SA = "SA",
  SB = "SB",
  SC = "SC",
  SD = "SD",
  SE = "SE",
  SG = "SG",
  SH = "SH",
  SI = "SI",
  SJ = "SJ",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SN = "SN",
  SO = "SO",
  SR = "SR",
  ST = "ST",
  SV = "SV",
  SY = "SY",
  SZ = "SZ",
  TC = "TC",
  TD = "TD",
  TF = "TF",
  TG = "TG",
  TH = "TH",
  TJ = "TJ",
  TK = "TK",
  TL = "TL",
  TM = "TM",
  TN = "TN",
  TO = "TO",
  TR = "TR",
  TT = "TT",
  TV = "TV",
  TW = "TW",
  TZ = "TZ",
  UA = "UA",
  UG = "UG",
  UM = "UM",
  US = "US",
  UY = "UY",
  UZ = "UZ",
  VA = "VA",
  VC = "VC",
  VE = "VE",
  VG = "VG",
  VI = "VI",
  VN = "VN",
  VU = "VU",
  WF = "WF",
  WS = "WS",
  YE = "YE",
  YT = "YT",
  ZA = "ZA",
  ZK = "ZK",
  ZM = "ZM",
  ZW = "ZW",
  ZZ = "ZZ",
}

export enum DealReviewStatus {
  ACTION_REQUIRED = "ACTION_REQUIRED",
  APPROVED = "APPROVED",
  DENIED = "DENIED",
  PENDING = "PENDING",
}

/**
 * Possible event notification subscription service types.
 */
export enum EventNotificationSubscriptionServiceType {
  sms = "sms",
}

/**
 * A goal associated with a split test experiment.
 */
export enum ExperimentGoal {
  ApplyForWhitelabel = "ApplyForWhitelabel",
  PurchaseAdditionalServices = "PurchaseAdditionalServices",
  PurchaseYoutubeOac = "PurchaseYoutubeOac",
}

/**
 * The name of a split test experiment.
 */
export enum ExperimentName {
  AdditionalServices = "AdditionalServices",
  TestExample = "TestExample",
  WhitelabelBannerAd = "WhitelabelBannerAd",
  YoutubeOacPricing = "YoutubeOacPricing",
}

/**
 * Possible explicit types describing if media attached to metadata is explicit/clean
 */
export enum ExplicitType {
  clean = "clean",
  explicit = "explicit",
  none = "none",
}

/**
 * The extra links enum
 */
export enum ExtraLinkType {
  merch_store = "merch_store",
  nft = "nft",
  other = "other",
  social_links = "social_links",
  tour_links = "tour_links",
}

/**
 * Fans Sort By
 */
export enum FansSortBy {
  created_at = "created_at",
  email = "email",
  name = "name",
}

/**
 * Available features for a User
 */
export enum Features {
  add_record_labels_for_apple_music_audio = "add_record_labels_for_apple_music_audio",
  additional_services_v2 = "additional_services_v2",
  admin_label_earnings = "admin_label_earnings",
  allow_duplicate_isrcs = "allow_duplicate_isrcs",
  allow_negative_balance = "allow_negative_balance",
  beatport_delivery = "beatport_delivery",
  beatsource_delivery = "beatsource_delivery",
  d2_album_stat_tabs = "d2_album_stat_tabs",
  d2_alpha = "d2_alpha",
  d2_creators = "d2_creators",
  d2_dashboard = "d2_dashboard",
  d2_dev = "d2_dev",
  d2_revenue_albums = "d2_revenue_albums",
  d2_revenue_flash_message = "d2_revenue_flash_message",
  d2_smart_links = "d2_smart_links",
  d2_spotify_for_artists = "d2_spotify_for_artists",
  d2_vevo_analytics = "d2_vevo_analytics",
  d2_video_uploader = "d2_video_uploader",
  dev_only = "dev_only",
  distribute_service_access = "distribute_service_access",
  eligible_for_pub_admin = "eligible_for_pub_admin",
  event_notifications = "event_notifications",
  facebook_deployment = "facebook_deployment",
  hide_web_content = "hide_web_content",
  label_client_advances = "label_client_advances",
  lickd_delivery = "lickd_delivery",
  lime_blue_delivery = "lime_blue_delivery",
  marketing = "marketing",
  meta_pixel = "meta_pixel",
  mfa = "mfa",
  mfa_required = "mfa_required",
  organization_release_creation_notification = "organization_release_creation_notification",
  out_of_date_password = "out_of_date_password",
  overview_v2 = "overview_v2",
  paid_services = "paid_services",
  priority_scheduling = "priority_scheduling",
  require_tax_submission = "require_tax_submission",
  royalty_center_payor = "royalty_center_payor",
  sales_trends = "sales_trends",
  shopify_store = "shopify_store",
  skip_auth_for_withdrawal = "skip_auth_for_withdrawal",
  skip_cla = "skip_cla",
  skip_minimum_tipalti_revenue = "skip_minimum_tipalti_revenue",
  skip_socials = "skip_socials",
  smart_merch = "smart_merch",
  smartlinks_custom_subdomain = "smartlinks_custom_subdomain",
  smartlinks_tab = "smartlinks_tab",
  soundexchange = "soundexchange",
  storage_tier = "storage_tier",
  test_deployers = "test_deployers",
  traxsource_delivery = "traxsource_delivery",
  ucla = "ucla",
  unlimited_storage = "unlimited_storage",
  vip = "vip",
  whitelabel_invite_payee_outside_organization = "whitelabel_invite_payee_outside_organization",
}

/**
 * The name of a generator
 */
export enum Generator {
  a2v = "a2v",
  facebook = "facebook",
  instagram = "instagram",
  instagram_business = "instagram_business",
  royalty = "royalty",
  vydia = "vydia",
  youtube = "youtube",
}

/**
 * Metadata genre
 */
export enum Genre {
  AFRICAN = "AFRICAN",
  AFRICANDANCEHALL = "AFRICANDANCEHALL",
  AFRICANREGGAE = "AFRICANREGGAE",
  AFRIKAANS = "AFRIKAANS",
  AFROBEAT = "AFROBEAT",
  AFROBEATS = "AFROBEATS",
  AFROFOLK = "AFROFOLK",
  AFROFUSION = "AFROFUSION",
  AFROHOUSE = "AFROHOUSE",
  AFROPOP = "AFROPOP",
  AFROSOUL = "AFROSOUL",
  ALTE = "ALTE",
  ALTERNATIVE = "ALTERNATIVE",
  AMAPIANO = "AMAPIANO",
  AMBIENT = "AMBIENT",
  AMERICANA = "AMERICANA",
  BACHATA = "BACHATA",
  BASSHOUSE = "BASSHOUSE",
  BENGA = "BENGA",
  BLUEGRASS = "BLUEGRASS",
  BLUES = "BLUES",
  BOLLYWOOD = "BOLLYWOOD",
  BONGOFLAVA = "BONGOFLAVA",
  BREAKBEAT = "BREAKBEAT",
  CANTOPOP = "CANTOPOP",
  CHILDRENSMUSIC = "CHILDRENSMUSIC",
  CHRISTIANGOSPEL = "CHRISTIANGOSPEL",
  CLASSICAL = "CLASSICAL",
  COMEDYHUMOR = "COMEDYHUMOR",
  CONTEMPORARYJAZZ = "CONTEMPORARYJAZZ",
  COOLJAZZ = "COOLJAZZ",
  COUNTRY = "COUNTRY",
  COUPDCAL = "COUPDCAL",
  CROSSOVERJAZZ = "CROSSOVERJAZZ",
  CUMBIA = "CUMBIA",
  DANCE = "DANCE",
  DANCETRAP = "DANCETRAP",
  DEEPHOUSE = "DEEPHOUSE",
  DEEPTECH = "DEEPTECH",
  DEMBOW = "DEMBOW",
  DISCO = "DISCO",
  DOWNTEMPO = "DOWNTEMPO",
  DRUMBASS = "DRUMBASS",
  DUBSTEP = "DUBSTEP",
  ELECTRO = "ELECTRO",
  ELECTRONICA = "ELECTRONICA",
  ELECTRONICDANCE = "ELECTRONICDANCE",
  EXPERIMENTAL = "EXPERIMENTAL",
  FOLK = "FOLK",
  FUNK = "FUNK",
  FUNKYHOUSE = "FUNKYHOUSE",
  FUSION = "FUSION",
  GQOM = "GQOM",
  GRIME = "GRIME",
  HARDCORE = "HARDCORE",
  HARDCOREDANCE = "HARDCOREDANCE",
  HARDTECHNO = "HARDTECHNO",
  HIGHLIFE = "HIGHLIFE",
  HOLIDAY = "HOLIDAY",
  HOUSE = "HOUSE",
  INDIANPOP = "INDIANPOP",
  INDIE = "INDIE",
  INDIEPOP = "INDIEPOP",
  INDIEROCK = "INDIEROCK",
  INSTRUMENTAL = "INSTRUMENTAL",
  JAZZ = "JAZZ",
  KIZOMBA = "KIZOMBA",
  KPOP = "KPOP",
  KUDURO = "KUDURO",
  KWAITO = "KWAITO",
  LATINALTERNATIVE = "LATINALTERNATIVE",
  LATINJAZZ = "LATINJAZZ",
  LATINO = "LATINO",
  LATINPOP = "LATINPOP",
  MAINSTAGEEDM = "MAINSTAGEEDM",
  MAINSTREAMJAZZ = "MAINSTREAMJAZZ",
  MANDOPOP = "MANDOPOP",
  MASKANDI = "MASKANDI",
  MBALAX = "MBALAX",
  MELODICHOUSE = "MELODICHOUSE",
  MERENGUE = "MERENGUE",
  METAL = "METAL",
  MODERNDANCEHALL = "MODERNDANCEHALL",
  NATURESOUNDS = "NATURESOUNDS",
  NDOMBOLO = "NDOMBOLO",
  NEWAGE = "NEWAGE",
  POP = "POP",
  PROGRESSIVEHOUSE = "PROGRESSIVEHOUSE",
  PSYTRANCE = "PSYTRANCE",
  PUNK = "PUNK",
  RAPHIPHOP = "RAPHIPHOP",
  RBSOUL = "RBSOUL",
  REGGAE = "REGGAE",
  REGGAETON = "REGGAETON",
  REGIONALMEXICANO = "REGIONALMEXICANO",
  ROCK = "ROCK",
  SALSA = "SALSA",
  SHANGAANELECTRO = "SHANGAANELECTRO",
  SINGERSONGWRITER = "SINGERSONGWRITER",
  SKA = "SKA",
  SLEEPSOUNDS = "SLEEPSOUNDS",
  SOUKOUS = "SOUKOUS",
  SOUNDTRACK = "SOUNDTRACK",
  SPOKENWORD = "SPOKENWORD",
  TAARAB = "TAARAB",
  TAIPOP = "TAIPOP",
  TECHHOUSE = "TECHHOUSE",
  TECHNO = "TECHNO",
  TRANCE = "TRANCE",
  TROPICAL = "TROPICAL",
  UKGARAGEDANCE = "UKGARAGEDANCE",
  VOCALJAZZ = "VOCALJAZZ",
  WHITENOISE = "WHITENOISE",
  WORLD = "WORLD",
  ZOUGLOU = "ZOUGLOU",
}

/**
 * Grouped Fans Group By
 */
export enum GroupedFansGroupBy {
  day = "day",
  month = "month",
  territory = "territory",
}

/**
 * One of the supported languages.
 */
export enum I18nLocale {
  EN = "EN",
  ES = "ES",
}

/**
 * Possible reasons for a media to be invalid
 */
export enum InvalidMediaReason {
  COMPOSERS_MISSING = "COMPOSERS_MISSING",
  METADATA_MISSING = "METADATA_MISSING",
  POLICIES_NOT_SET = "POLICIES_NOT_SET",
  RIGHTS_NOT_SET = "RIGHTS_NOT_SET",
}

/**
 * Language codes
 */
export enum MediaLanguageCode {
  AF = "AF",
  AR = "AR",
  AS = "AS",
  AZ = "AZ",
  BE = "BE",
  BG = "BG",
  BN = "BN",
  BNT = "BNT",
  BS = "BS",
  CA = "CA",
  CE = "CE",
  CMN = "CMN",
  CS = "CS",
  DA = "DA",
  DE = "DE",
  EL = "EL",
  EN = "EN",
  ES = "ES",
  ES419 = "ES419",
  ET = "ET",
  FA = "FA",
  FI = "FI",
  FR = "FR",
  GA = "GA",
  GU = "GU",
  HA = "HA",
  HAK = "HAK",
  HAW = "HAW",
  HE = "HE",
  HI = "HI",
  HR = "HR",
  HT = "HT",
  HU = "HU",
  HY = "HY",
  ID = "ID",
  IG = "IG",
  IS = "IS",
  IT = "IT",
  JA = "JA",
  KA = "KA",
  KK = "KK",
  KM = "KM",
  KN = "KN",
  KO = "KO",
  KU = "KU",
  LA = "LA",
  LO = "LO",
  LT = "LT",
  LUG = "LUG",
  LV = "LV",
  MI = "MI",
  ML = "ML",
  MN = "MN",
  MR = "MR",
  MS = "MS",
  NL = "NL",
  NO = "NO",
  NPI = "NPI",
  NY = "NY",
  OR = "OR",
  PA = "PA",
  PL = "PL",
  PS = "PS",
  PT = "PT",
  RO = "RO",
  RU = "RU",
  SA = "SA",
  SI = "SI",
  SK = "SK",
  SL = "SL",
  SM = "SM",
  SO = "SO",
  SR = "SR",
  SV = "SV",
  SW = "SW",
  TA = "TA",
  TE = "TE",
  TH = "TH",
  TL = "TL",
  TO = "TO",
  TR = "TR",
  TW = "TW",
  TY = "TY",
  UK = "UK",
  UR = "UR",
  VI = "VI",
  WO = "WO",
  YI = "YI",
  YO = "YO",
  YUEHANT = "YUEHANT",
  ZH = "ZH",
  ZU = "ZU",
  ZXX = "ZXX",
}

/**
 * The deployment status of a media policy.
 */
export enum MediaPolicyStatus {
  deployed = "deployed",
  downgraded = "downgraded",
  failed = "failed",
  pending = "pending",
}

/**
 * Possible media policy types.
 */
export enum MediaPolicyType {
  block = "block",
  monetize = "monetize",
  none = "none",
  not_set = "not_set",
  permit = "permit",
}

/**
 * Media Shared With Me
 */
export enum MediaSharedWithMe {
  active = "active",
  archived = "archived",
}

/**
 * The type of media, either video, audio or comp.
 */
export enum MediaType {
  audio = "audio",
  comp = "comp",
  video = "video",
}

/**
 * Medias V2 Sort By
 */
export enum MediasV2SortBy {
  artist_name = "artist_name",
  date = "date",
  file_size = "file_size",
  id = "id",
  name = "name",
  revenue = "revenue",
}

/**
 * What date range individual data points are broken down into.
 */
export enum NetworkAnalyticsDateResolution {
  DAY = "DAY",
  MONTH = "MONTH",
}

/**
 * Possible media analytics network types
 */
export enum NetworkAnalyticsNetworkType {
  APPLE_MUSIC_TRACKS = "APPLE_MUSIC_TRACKS",
  FACEBOOK_VIDEOS = "FACEBOOK_VIDEOS",
  INSTAGRAM_STORIES = "INSTAGRAM_STORIES",
  INSTAGRAM_VIDEOS = "INSTAGRAM_VIDEOS",
  SPOTIFY_TRACKS = "SPOTIFY_TRACKS",
  VEVO_VIDEOS = "VEVO_VIDEOS",
  YOUTUBE_VIDEOS = "YOUTUBE_VIDEOS",
}

/**
 * The type of the Network Extra
 */
export enum NetworkExtraType {
  APPLE_MUSIC_VIDEO_ENCODING = "APPLE_MUSIC_VIDEO_ENCODING",
  BANNER_DESIGN = "BANNER_DESIGN",
  CONTENT_ID = "CONTENT_ID",
  COPYRIGHT_REGISTRATION = "COPYRIGHT_REGISTRATION",
  CUSTOM_TIKTOK_CLIP = "CUSTOM_TIKTOK_CLIP",
  CUSTOM_VEVO_CHANNEL = "CUSTOM_VEVO_CHANNEL",
  DOLBY_ATMOS_AUDIO_DELIVERY = "DOLBY_ATMOS_AUDIO_DELIVERY",
  EXPEDITED_DELIVERY = "EXPEDITED_DELIVERY",
  LYRIC_SYNC = "LYRIC_SYNC",
  NO_FOLLOW_UP = "NO_FOLLOW_UP",
  PRIORITY_DEPLOYMENT = "PRIORITY_DEPLOYMENT",
  QC_CHECK = "QC_CHECK",
  UPSELL = "UPSELL",
  WHITELABEL = "WHITELABEL",
  YOUTUBE_OAC_REQUEST = "YOUTUBE_OAC_REQUEST",
}

/**
 * Source network containing playlist data
 */
export enum NetworkPlaylistSource {
  APPLEMUSIC = "APPLEMUSIC",
  SPOTIFY = "SPOTIFY",
}

/**
 * Current or past
 */
export enum NetworkPlaylistStatus {
  CURRENT = "CURRENT",
  PAST = "PAST",
}

/**
 * The type of network
 */
export enum NetworkType {
  AUDIO = "AUDIO",
  DISTRIBUTE = "DISTRIBUTE",
  MONETIZE = "MONETIZE",
  PREMIUM_PARTNER = "PREMIUM_PARTNER",
  SOCIAL = "SOCIAL",
  VIDEO = "VIDEO",
}

/**
 * Cookie session Notice type
 */
export enum NoticeType {
  error = "error",
  success = "success",
  warning = "warning",
}

/**
 * The type of OTP authentication
 */
export enum OtpMethodType {
  authenticator = "authenticator",
  sms = "sms",
}

/**
 * Input values for table type on overview
 */
export enum OverviewTable {
  albums = "albums",
  countries = "countries",
  monthly = "monthly",
  networks = "networks",
  tracks = "tracks",
  videos = "videos",
}

/**
 * Sections in the Ownership and Policies flow for validation
 */
export enum OwnershipPoliciesSection {
  composition = "composition",
  music_video = "music_video",
  policies = "policies",
  sound_recording = "sound_recording",
  web = "web",
}

/**
 * The status of a payee asset splits.
 */
export enum PayeeAssetSplitStatus {
  accepted = "accepted",
  active = "active",
  expired = "expired",
  inactive = "inactive",
  pending = "pending",
  rejected = "rejected",
}

export enum PayeeInvitesSortBy {
  created_at = "created_at",
  email = "email",
  first_name = "first_name",
  last_name = "last_name",
}

export enum PlaylistAnalyticsSortBy {
  days_in_list = "days_in_list",
  followers = "followers",
  name = "name",
  peak_position = "peak_position",
  position = "position",
  removed_at = "removed_at",
  track_name = "track_name",
  track_streams = "track_streams",
}

/**
 * Ranked Medias Cursor Sort By
 */
export enum RankedMediasCursorSortBy {
  todays_rank = "todays_rank",
  todays_streams = "todays_streams",
  yesterdays_rank = "yesterdays_rank",
}

/**
 * The status of a recoupment.
 */
export enum RecoupmentStatus {
  ACTIVE = "ACTIVE",
  COMPLETED = "COMPLETED",
}

/**
 * The statuses of a release.
 */
export enum ReleaseCalendarStatus {
  accepted = "accepted",
  action_required = "action_required",
  complete = "complete",
  delivered = "delivered",
  denied = "denied",
  deployed = "deployed",
  distributed = "distributed",
  downgraded = "downgraded",
  error = "error",
  failed = "failed",
  pending = "pending",
  processing = "processing",
  ready = "ready",
  rejected_spam = "rejected_spam",
  retired = "retired",
  scheduled = "scheduled",
  submitted = "submitted",
  unauthorized = "unauthorized",
}

/**
 * The statuses of a release.
 */
export enum ReleaseCalendarStatusFilterOption {
  ACTION_REQUIRED = "ACTION_REQUIRED",
  DELIVERED = "DELIVERED",
  DENIED = "DENIED",
  DOWNGRADED = "DOWNGRADED",
  ERROR = "ERROR",
  PENDING = "PENDING",
}

/**
 * Indicates why an asset has the RemainingPayeePercentage value that it does.
 */
export enum RemainingPayeePercentageReason {
  FULLY_ALLOCATED = "FULLY_ALLOCATED",
  NO_ASSET = "NO_ASSET",
  NO_OWNERSHIP = "NO_OWNERSHIP",
}

/**
 * The types of rights
 */
export enum RightType {
  COMPOSITION = "COMPOSITION",
  MUSIC_VIDEO = "MUSIC_VIDEO",
  SOUND_RECORDING = "SOUND_RECORDING",
  WEB = "WEB",
}

/**
 * The asset types on a rights object
 */
export enum RightsAssetType {
  all = "all",
  except = "except",
  specific = "specific",
}

/**
 * The role of a royalty center user.
 */
export enum RoyaltyCenterRole {
  payee = "payee",
  payor = "payor",
}

/**
 * Royalty Revenues V2 Sort By
 */
export enum RoyaltyRevenuesSortBy {
  album_name = "album_name",
  album_sale_revenue = "album_sale_revenue",
  album_units_sold = "album_units_sold",
  country_name = "country_name",
  date = "date",
  gross_revenue = "gross_revenue",
  net_revenue = "net_revenue",
  network_name = "network_name",
  song_name = "song_name",
  streams = "streams",
  track_sale_revenue = "track_sale_revenue",
  track_units_sold = "track_units_sold",
  user_revenue = "user_revenue",
}

/**
 * Input values for grouping sales trends
 */
export enum SalesTrendGroupBy {
  country = "country",
  date = "date",
  network = "network",
}

/**
 * Sales Trends V2 Sort By
 */
export enum SalesTrendsSortBy {
  album_sales = "album_sales",
  country_code = "country_code",
  date = "date",
  gross_revenue = "gross_revenue",
  net_revenue = "net_revenue",
  network_name = "network_name",
  streams = "streams",
  track_sales = "track_sales",
}

/**
 * Search By Operators
 */
export enum SearchByOperator {
  exact = "exact",
  include = "include",
}

/**
 * The type of the SignedAgreement
 */
export enum SignedAgreementType {
  ADDRESS_CHANGE = "ADDRESS_CHANGE",
  AFFIDAVIT = "AFFIDAVIT",
  RCA = "RCA",
  RELEASE = "RELEASE",
  UNIVERSAL = "UNIVERSAL",
  UPGRADE_ARTIST = "UPGRADE_ARTIST",
  UPGRADE_ARTISTS = "UPGRADE_ARTISTS",
}

export enum SmartLinksAnalyticsSortBy {
  clickthroughs = "clickthroughs",
  created_at = "created_at",
  presaves = "presaves",
  visits = "visits",
}

/**
 * Input values for table type on smartlinks google analytics
 */
export enum SmartLinksAnalyticsTable {
  country = "country",
  network = "network",
  presave = "presave",
}

/**
 * Smartlinks Google Analytics Sort By
 */
export enum SmartlinksGoogleAnalyticsSortBy {
  clickthroughs_cumulative = "clickthroughs_cumulative",
  country = "country",
  downloads_cumulative = "downloads_cumulative",
  network = "network",
  percentage_total_clickthroughs = "percentage_total_clickthroughs",
  presaves_cumulative = "presaves_cumulative",
  streams_cumulative = "streams_cumulative",
  unique_clickthroughs_cumulative = "unique_clickthroughs_cumulative",
}

/**
 * Input values for table type on smartlinks google analytics
 */
export enum SmartlinksGoogleAnalyticsTable {
  country = "country",
  network = "network",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

/**
 * Possible SoundExchange policy types.
 */
export enum SoundExchangePolicyType {
  from_inception = "from_inception",
  from_specific_date = "from_specific_date",
  no_claim = "no_claim",
  not_set = "not_set",
}

/**
 * The statuses of a statement.
 */
export enum StatementStatus {
  complete = "complete",
  failed = "failed",
  pending = "pending",
}

/**
 * Input values for grouping statements
 */
export enum StatementsGroupBy {
  date = "date",
  network = "network",
}

/**
 * The supported types for music videos.
 */
export enum SupportedVideo {
  artwork_video = "artwork_video",
  behind_the_scenes = "behind_the_scenes",
  live_performance = "live_performance",
  lyric_video = "lyric_video",
  official_music_video = "official_music_video",
  teaser_trailer = "teaser_trailer",
  visualizer = "visualizer",
}

/**
 * Different types of tax forms that can be submitted
 */
export enum TaxFormType {
  W_8 = "W_8",
  W_8BEN = "W_8BEN",
  W_8BEN_E = "W_8BEN_E",
  W_8ECI = "W_8ECI",
  W_8EXP = "W_8EXP",
  W_8IMY = "W_8IMY",
  W_9 = "W_9",
}

/**
 * Different types of tax identification numbers
 */
export enum TaxIdType {
  EIN = "EIN",
  FOREIGN_TIN = "FOREIGN_TIN",
  SSN = "SSN",
  VAT_ID = "VAT_ID",
}

/**
 * The name of a variant or an alternative for the TestExample Experiment split test.
 */
export enum TestExampleVariant {
  Bar = "Bar",
  Foo = "Foo",
}

/**
 * Acceptable Sizes for Thumbnails
 */
export enum ThumbnailSize {
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
}

/**
 * A type of upload.
 */
export enum UploadType {
  a2v = "a2v",
  a2vArtwork = "a2vArtwork",
  a2vAudio = "a2vAudio",
  a2vBackgroundImage = "a2vBackgroundImage",
  album_artwork = "album_artwork",
  artist_image = "artist_image",
  audio = "audio",
  csv = "csv",
  d2Audio = "d2Audio",
  extra_link_image = "extra_link_image",
  merch_design_custom_image = "merch_design_custom_image",
  messageAttachment = "messageAttachment",
  period_statement = "period_statement",
  reuploadArtwork = "reuploadArtwork",
  reuploadAudio = "reuploadAudio",
  reuploadVideo = "reuploadVideo",
  uploadAdm = "uploadAdm",
  uploadAtmos = "uploadAtmos",
  video = "video",
}

/**
 * The status of a user invite.
 */
export enum UserInviteStatus {
  ACCEPTED = "ACCEPTED",
  PENDING = "PENDING",
}

/**
 * The role of a user within an Organization/Label
 */
export enum UserRole {
  ADMIN = "ADMIN",
  OWNER = "OWNER",
  USER = "USER",
}

/**
 * User Search By
 */
export enum UserSearchBy {
  artist_name = "artist_name",
  user_email = "user_email",
  user_name = "user_name",
  user_role = "user_role",
}

/**
 * Organization Users Cursor Sort By
 */
export enum UsersCursorSortBy {
  created_at = "created_at",
  estimated_revenue = "estimated_revenue",
  id = "id",
  invite_status = "invite_status",
  last_name = "last_name",
  organization_royalty_split = "organization_royalty_split",
  role = "role",
}

/**
 * The type of video, either web or music.
 */
export enum VideoCategory {
  music = "music",
  web = "web",
}

/**
 * Web video subcategories.
 */
export enum VideoSubcategory {
  autos = "autos",
  comedy = "comedy",
  education = "education",
  entertainment = "entertainment",
  film = "film",
  gaming = "gaming",
  howto = "howto",
  music = "music",
  news = "news",
  nonprofits = "nonprofits",
  people = "people",
  pets = "pets",
  science = "science",
  sports = "sports",
  travel = "travel",
}

/**
 * The name of a variant or an alternative for the WhitelabelBannerAd Experiment split test.
 */
export enum WhitelabelBannerAdVariant {
  Large = "Large",
  Medium = "Medium",
}

/**
 * Audio Analytics Sort By
 */
export enum YoutubeConflictsSortBy {
  artist_name = "artist_name",
  asset_type = "asset_type",
  conflicting_owner = "conflicting_owner",
  conflicting_territories = "conflicting_territories",
  created_at = "created_at",
  custom_id = "custom_id",
  daily_views = "daily_views",
  song_name = "song_name",
  user_name = "user_name",
}

/**
 * The name of a variant or an alternative for the YoutubeOacPricing Experiment split test.
 */
export enum YoutubeOacPricingVariant {
  ThirtyNine = "ThirtyNine",
  TwentyFour = "TwentyFour",
}

/**
 * Youtube UGC Search By
 */
export enum YoutubeUgcSearchBy {
  artist_name = "artist_name",
  channel_id = "channel_id",
  channel_name = "channel_name",
  claim_id = "claim_id",
  claim_type = "claim_type",
  song_name = "song_name",
  ugc_title = "ugc_title",
  ugc_video_id = "ugc_video_id",
  user_name = "user_name",
}

/**
 * Youtube UGC Sort By
 */
export enum YoutubeUgcSortBy {
  artist_name = "artist_name",
  channel_id = "channel_id",
  channel_name = "channel_name",
  claim_date = "claim_date",
  claim_id = "claim_id",
  claim_type = "claim_type",
  estimated_claimed_views = "estimated_claimed_views",
  media_id = "media_id",
  song_name = "song_name",
  ugc_title = "ugc_title",
  ugc_video_id = "ugc_video_id",
  user_name = "user_name",
}

/**
 * Autogenerated input type of AcceptAgreementByType
 */
export interface AcceptAgreementByTypeInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  type?: AgreementByTypeAgreementType | null;
  signature?: string | null;
  did_accept?: boolean | null;
  first_name?: string | null;
  last_name?: string | null;
  email_address?: string | null;
}

/**
 * Autogenerated input type of AcceptPayeeAssetSplit
 */
export interface AcceptPayeeAssetSplitInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  media_id: string;
}

/**
 * Autogenerated input type of AcceptPayeeInvitation
 */
export interface AcceptPayeeInvitationInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  code: string;
  first_name: string;
  last_name: string;
  email: string;
  password: string;
  confirm_password: string;
  receive_updates?: boolean | null;
  toc?: boolean | null;
}

/**
 * Autogenerated input type of AcceptRca
 */
export interface AcceptRcaInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  signature?: string | null;
  did_accept?: boolean | null;
  first_name?: string | null;
  last_name?: string | null;
  email_address?: string | null;
}

/**
 * Autogenerated input type of AcceptTermsAndConditions
 */
export interface AcceptTermsAndConditionsInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
}

/**
 * Autogenerated input type of AcceptUcla
 */
export interface AcceptUclaInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  client_type?: string | null;
  signature?: string | null;
  did_accept?: boolean | null;
  address?: string | null;
  address2?: string | null;
  city?: string | null;
  country?: string | null;
  dob?: string | null;
  entity_name?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  postal?: string | null;
  signatory_title?: string | null;
  state?: string | null;
}

/**
 * Autogenerated input type of AcknowledgeAddressChange
 */
export interface AcknowledgeAddressChangeInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
}

/**
 * Additional Artist or Collaborator
 */
export interface AdditionalArtistObject {
  amazon_asin?: string | null;
  apple_eid?: string | null;
  apple_new?: boolean | null;
  name?: string | null;
  role?: string | null;
  spotify_eid?: string | null;
  spotify_new?: boolean | null;
}

/**
 * Additional Service
 */
export interface AdditionalServiceObject {
  id: string;
  quantity: number;
}

/**
 * Track Artist Object
 */
export interface AdditionalTrackArtistObject {
  apple_eid?: string | null;
  name?: string | null;
  role?: string | null;
  spotify_eid?: string | null;
}

/**
 * AlbumPartnerData input Object
 */
export interface AlbumPartnerDataObject {
  id?: string | null;
  failed_at?: Scalar$DateTime | null;
  network_id: string;
  order?: number | null;
  partner_url?: string | null;
  type: string;
}

/**
 * Cursor input object for AlbumsCursor
 */
export interface AlbumsCursorInput {
  sort_by?: AlbumsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
  media_type?: string | null;
  include_admins?: boolean | null;
  include_organization_albums?: boolean | null;
}

/**
 * Cursor input object for AnalyticsTopAlbumsCursor
 */
export interface AnalyticsTopAlbumsCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  networks?: AudioAnalyticsNetworks[] | null;
  artist_id?: string | null;
  user_id?: string | null;
}

/**
 * Cursor input object for AnalyticsTopArtistsCursor
 */
export interface AnalyticsTopArtistsCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  networks?: AudioAnalyticsNetworks[] | null;
  user_id?: string | null;
}

/**
 * Cursor input object for AnalyticsTopContentCursor
 */
export interface AnalyticsTopContentCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  networks?: AudioAnalyticsNetworks[] | null;
  artist_id?: string | null;
  album_id?: string | null;
  user_id?: string | null;
}

/**
 * Cursor input object for AnalyticsTopGeocountryCursor
 */
export interface AnalyticsTopGeocountryCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  skip_validation?: boolean | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  network?: AudioAnalyticsNetworks | null;
  current_user_id?: string | null;
  networks?: AudioAnalyticsNetworks[] | null;
}

/**
 * Cursor input object for AnalyticsTopUsersCursor
 */
export interface AnalyticsTopUsersCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  networks?: AudioAnalyticsNetworks[] | null;
}

/**
 * Cursor input object for AppleMusicArtistAnalyticsCursor
 */
export interface AppleMusicArtistAnalyticsCursorInput {
  limit?: number | null;
  offset?: number | null;
  metric?: string | null;
}

/**
 * Autogenerated input type of ArchivePayeeAssetSplits
 */
export interface ArchivePayeeAssetSplitsInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  media_id: string;
  payee_invite_id: string;
  confirmed?: boolean | null;
}

/**
 * Autogenerated input type of ArchivePayeeInvite
 */
export interface ArchivePayeeInviteInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  payee_invite_id: string;
  confirmed?: boolean | null;
}

/**
 * Artist Object
 */
export interface ArtistObject {
  apple_eid?: string | null;
  name?: string | null;
  role?: string | null;
  spotify_eid?: string | null;
}

/**
 * Artist Search
 */
export interface ArtistSearch {
  column: ArtistSearchBy;
  operator?: SearchByOperator | null;
  term: string;
}

/**
 * Cursor input object for ArtistsCursor
 */
export interface ArtistsCursorInput {
  sort_by?: ArtistsCursorSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  user_id?: string | null;
  search_term?: string | null;
  has_chartmetric?: boolean | null;
  has_analytics?: boolean | null;
  has_connect_spotify?: boolean | null;
  include_admins?: boolean | null;
  exclude_owners_artists?: boolean | null;
  aggregate_fans?: boolean | null;
  where?: ArtistSearch[] | null;
}

/**
 * Cursor input object for AssetRevenuesCursor
 */
export interface AssetRevenuesCursorInput {
  limit?: number | null;
  offset?: number | null;
  album_id?: string | null;
  artist_id?: string | null;
  media_id?: string | null;
}

/**
 * Cursor input object for AudioAnalyticsByAgegroupCursor
 */
export interface AudioAnalyticsByAgegroupCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  skip_validation?: boolean | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  network?: AudioAnalyticsNetworks | null;
  current_user_id?: string | null;
}

/**
 * Cursor input object for AudioAnalyticsByCountryCursor
 */
export interface AudioAnalyticsByCountryCursorInput {
  sort_by?: AudioAnalyticsByCountrySortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  skip_validation?: boolean | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  network?: AudioAnalyticsNetworks | null;
  current_user_id?: string | null;
  networks?: AudioAnalyticsNetworks[] | null;
  media_type?: MediaType | null;
}

/**
 * Cursor input object for AudioAnalyticsByDeviceCursor
 */
export interface AudioAnalyticsByDeviceCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  skip_validation?: boolean | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  network?: AudioAnalyticsNetworks | null;
  current_user_id?: string | null;
}

/**
 * Cursor input object for AudioAnalyticsByGenderCursor
 */
export interface AudioAnalyticsByGenderCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  skip_validation?: boolean | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  network?: AudioAnalyticsNetworks | null;
  current_user_id?: string | null;
}

/**
 * Cursor input object for AudioAnalyticsBySourceCursor
 */
export interface AudioAnalyticsBySourceCursorInput {
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  skip_validation?: boolean | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  network?: AudioAnalyticsNetworks | null;
  current_user_id?: string | null;
}

/**
 * Cursor input object for AudioAnalyticsCursor
 */
export interface AudioAnalyticsCursorInput {
  sort_by?: AudioAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  skip_validation?: boolean | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  network?: AudioAnalyticsNetworks | null;
  current_user_id?: string | null;
  metric?: string | null;
  networks?: AudioAnalyticsNetworks[] | null;
}

/**
 * Cursor input object for BalanceHistoryDateSummariesCursor
 */
export interface BalanceHistoryDateSummariesCursorInput {
  limit?: number | null;
  offset?: number | null;
  networks?: string[] | null;
  types?: string[] | null;
  dates?: Scalar$DateTime[] | null;
}

/**
 * Cursor input object for BroadcastsCursor
 */
export interface BroadcastsCursorInput {
  sort_by?: BroadcastsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  media_id?: string | null;
  album_id?: string | null;
  network_type?: NetworkType | null;
  network_ids_to_ignore?: string[] | null;
}

/**
 * Cursor input object for CashAdvanceRecoupmentsCursor
 */
export interface CashAdvanceRecoupmentsCursorInput {
  sort_by?: CashAdvanceRecoupmentsCursorSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  status?: RecoupmentStatus | null;
}

/**
 * Composition Content Object
 */
export interface CompContentObject {
  artist_id?: string | null;
  composers?: NewComposerObject[] | null;
  composition_rights?: NewRightsObject | null;
  copyright_name?: string | null;
  copyright_year?: number | null;
  facebook?: MediaPolicyType | null;
  genre?: Genre | null;
  isrc?: string | null;
  iswc?: string | null;
  label?: string | null;
  lyrics?: string | null;
  media_type?: MediaType | null;
  secondary_genre?: Genre | null;
  title?: string | null;
  youtube?: MediaPolicyType | null;
}

/**
 * Autogenerated input type of ConfirmMerchItemDesignVariant
 */
export interface ConfirmMerchItemDesignVariantInput {
  client_mutation_id?: string | null;
  id: string;
  skip_save?: boolean | null;
}

/**
 * Autogenerated input type of ConfirmPassword
 */
export interface ConfirmPasswordInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  confirm_password?: string | null;
}

/**
 * Autogenerated input type of ConnectShopifyStore
 */
export interface ConnectShopifyStoreInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  shopify_store_domain: string;
  album_id: string;
}

/**
 * Autogenerated input type of CreateAlbum
 */
export interface CreateAlbumInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  name?: string | null;
  artist_id?: string | null;
  genre?: Genre | null;
  secondary_genre?: Genre | null;
  label?: string | null;
  copyright_name?: string | null;
  copyright_year?: number | null;
  upc?: string | null;
  territories?: string[] | null;
  has_previously_released?: boolean | null;
  original_release_date?: Scalar$Date | null;
  additional_artists?: AdditionalArtistObject[] | null;
  media_ids?: string[] | null;
  image_path?: string | null;
  validate_image_dimensions?: boolean | null;
}

/**
 * Autogenerated input type of CreateAlbumRelease
 */
export interface CreateAlbumReleaseInput {
  client_mutation_id?: string | null;
  onetime_total_confirmation?: string | null;
  accept_tos?: boolean | null;
  payment_method_id?: string | null;
  payment_method_nonce?: string | null;
  paypal?: boolean | null;
  country_code?: string | null;
  street_address?: string | null;
  extended_address?: string | null;
  locality?: string | null;
  state?: string | null;
  region?: string | null;
  existing_credit_card_cvv?: string | null;
  postal_code?: string | null;
  device_data?: string | null;
  skip_save?: boolean | null;
  album_id: string;
  deploy_at?: Scalar$DateTime | null;
  network_ids: string[];
  asap?: boolean | null;
  network_extra_types?: NetworkExtraType[] | null;
  additional_services?: AdditionalServiceObject[] | null;
  additional_service_bundle_ids?: string[] | null;
}

/**
 * Autogenerated input type of CreateArtist
 */
export interface CreateArtistInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  name?: string | null;
  bio?: string | null;
  site?: string | null;
  soundcloud?: string | null;
  itunes?: string | null;
  beatport?: string | null;
  facebook?: string | null;
  tumblr?: string | null;
  twitter?: string | null;
  instagram?: string | null;
  vevo_user?: string | null;
  user_id?: string | null;
}

/**
 * Autogenerated input type of CreateAssets
 */
export interface CreateAssetsInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  media_ids: string[];
}

/**
 * Autogenerated input type of CreateCashAdvanceRecoupment
 */
export interface CreateCashAdvanceRecoupmentInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  user_id?: string | null;
  before_split?: number | null;
  total_amount?: number | null;
  recoupment_items?: RecoupmentItemObject[] | null;
}

/**
 * Autogenerated input type of CreateCompContent
 */
export interface CreateCompContentInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  content: CompContentObject;
}

/**
 * Autogenerated input type of CreateComposer
 */
export interface CreateComposerInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  first_name: string;
  middle_name?: string | null;
  last_name: string;
  email?: string | null;
  invite?: boolean | null;
  perf_rights_org?: string | null;
  ipi?: string | null;
  publisher?: string | null;
  publisher_ipi?: string | null;
  user_id?: string | null;
}

/**
 * Autogenerated input type of CreateDeal
 */
export interface CreateDealInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  title?: string | null;
  borrower_recoupment_period_months?: number | null;
  client_after_recoupment_split_percent?: number | null;
  client_recoupment_split_percent?: number | null;
  irr?: number | null;
  lender_payback_period_months?: number | null;
  moic?: number | null;
  monthly_gross_cash_flow_dollars?: number | null;
  monthly_growth_rate_delay_months?: number | null;
  monthly_growth_rate_percent?: number | null;
  term_months?: number | null;
  advances?: DealAdvanceObject[] | null;
  organization_id?: string | null;
  user_id?: string | null;
  media_id?: string | null;
  latest_review_note?: string | null;
  latest_review_status?: DealReviewStatus | null;
  profit_thru_recoupment?: number | null;
  profit_thru_term?: number | null;
  hubspot_eid?: string | null;
  hubspot_dealname?: string | null;
  archived?: boolean | null;
}

/**
 * Autogenerated input type of CreateEarningsStatementIngestion
 */
export interface CreateEarningsStatementIngestionInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  s3_key?: string | null;
  group_id?: string | null;
  period_date?: string | null;
  period_segment?: boolean | null;
  csv_only?: boolean | null;
}

/**
 * Autogenerated input type of CreateEventNotificationSubscription
 */
export interface CreateEventNotificationSubscriptionInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  release_id: string;
  phone_number: string;
  remember?: boolean | null;
}

/**
 * Autogenerated input type of CreateMedias
 */
export interface CreateMediasInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  files: (FileObject | null)[];
  validate_video_type?: boolean | null;
  validate_writer_credits_presence?: boolean | null;
}

/**
 * Autogenerated input type of CreateMessage
 */
export interface CreateMessageInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  content: string;
  s3_path?: string | null;
  file_name?: string | null;
  file_type?: string | null;
  file_size?: number | null;
  media_id?: string | null;
  album_id?: string | null;
  release_id?: string | null;
  user_id?: string | null;
}

/**
 * Autogenerated input type of CreatePayeeAssetSplits
 */
export interface CreatePayeeAssetSplitsInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  splits: SplitObject[];
}

/**
 * Autogenerated input type of CreatePayeeInvitation
 */
export interface CreatePayeeInvitationInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  email?: string | null;
  confirmation_email?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  phone_number?: string | null;
  charity?: boolean | null;
}

/**
 * Autogenerated input type of CreateRelease
 */
export interface CreateReleaseInput {
  client_mutation_id?: string | null;
  onetime_total_confirmation?: string | null;
  accept_tos?: boolean | null;
  payment_method_id?: string | null;
  payment_method_nonce?: string | null;
  paypal?: boolean | null;
  country_code?: string | null;
  street_address?: string | null;
  extended_address?: string | null;
  locality?: string | null;
  state?: string | null;
  region?: string | null;
  existing_credit_card_cvv?: string | null;
  postal_code?: string | null;
  device_data?: string | null;
  skip_save?: boolean | null;
  subcategory?: string | null;
  type?: string | null;
  media_id?: string | null;
  description?: string | null;
  title?: string | null;
  deploy_at?: Scalar$DateTime | null;
  asap?: boolean | null;
  socials?: Socials[] | null;
  premium_network_ids?: string[] | null;
  network_extra_types?: NetworkExtraType[] | null;
  additional_services?: AdditionalServiceObject[] | null;
  additional_service_bundle_ids?: string[] | null;
  is_distribute_service?: boolean | null;
}

/**
 * Autogenerated input type of CreateWhitelabelUser
 */
export interface CreateWhitelabelUserInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  first_name: string;
  last_name: string;
  email: string;
  phone?: string | null;
  country_code?: string | null;
  password: string;
  confirm_password: string;
  custom_terms_of_use_agree?: boolean | null;
}

/**
 * Contains the month (zero-index) and amount (dollars) of a cash advance during a deal.
 */
export interface DealAdvanceObject {
  amount_dollars: number;
  month: number;
}

/**
 * Cursor input object for DealsCursor
 */
export interface DealsCursorInput {
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
}

/**
 * Autogenerated input type of DeleteAlbums
 */
export interface DeleteAlbumsInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  ids: string[];
}

/**
 * Autogenerated input type of DeleteArtist
 */
export interface DeleteArtistInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
}

/**
 * Autogenerated input type of DeleteData
 */
export interface DeleteDataInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  data: string[];
}

/**
 * Autogenerated input type of DeleteMedias
 */
export interface DeleteMediasInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  ids: string[];
}

/**
 * Autogenerated input type of DeleteUserRequest
 */
export interface DeleteUserRequestInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  reasons: string[];
}

/**
 * Autogenerated input type of DisconnectService
 */
export interface DisconnectServiceInput {
  client_mutation_id?: string | null;
  service?: string | null;
  skip_save?: boolean | null;
}

/**
 * Autogenerated input type of DisconnectSocialAccountFromArtist
 */
export interface DisconnectSocialAccountFromArtistInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
  deployer_name: string;
}

/**
 * Autogenerated input type of DowngradeStorageTier
 */
export interface DowngradeStorageTierInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
}

/**
 * Composer Object
 */
export interface EditComposerObject {
  id?: string | null;
  composer_id?: string | null;
  email?: string | null;
  invite?: boolean | null;
  name?: string | null;
  ownership_percentage?: number | null;
  perf_rights_org?: string | null;
  should_collect_revenue?: boolean | null;
}

/**
 * Autogenerated input type of EditOrganizationUser
 */
export interface EditOrganizationUserInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  user_id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  organization_royalty_split?: string | null;
  organization_royalty_split_v2?: number | null;
  role?: UserRole | null;
  country_code?: CountryCode | null;
  phone?: string | null;
  has_label_revenue?: boolean | null;
}

/**
 * Rights Object
 */
export interface EditRightsObject {
  id?: string | null;
  asset_type?: RightsAssetType | null;
  composer_id?: string | null;
  geo_restrictions?: CountryCode[] | null;
  ownership_percentage?: number | null;
  right_control?: boolean | null;
  should_collect_revenue?: boolean | null;
}

/**
 * EventNotificationSubscription Object
 */
export interface EventNotificationSubscriptionObject {
  id?: string | null;
  phone_number: string;
}

/**
 * Extra Link Object
 */
export interface ExtraLinkObject {
  id?: string | null;
  button_text?: string | null;
  description?: string | null;
  image_title?: string | null;
  image_url?: string | null;
  order: number;
  title?: string | null;
  type?: ExtraLinkType | null;
  url?: string | null;
}

/**
 * Cursor input object for FacebookArtistAnalyticsCursor
 */
export interface FacebookArtistAnalyticsCursorInput {
  limit?: number | null;
  offset?: number | null;
  metric?: string | null;
  group_by?: string | null;
}

/**
 * Cursor input object for FansCursor
 */
export interface FansCursorInput {
  sort_by?: FansSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  network_ids?: string[] | null;
  territories?: string[] | null;
}

/**
 * File Object
 */
export interface FileObject {
  additional_artists: ArtistObject[];
  additional_credits?: ArtistObject[] | null;
  album?: string | null;
  artist_id?: string | null;
  claim_from_date?: Scalar$Date | null;
  clip_start_time?: Scalar$TrackPosition | null;
  composers?: NewComposerObject[] | null;
  composition_rights?: NewRightsObject | null;
  copyright_name?: string | null;
  copyright_year?: number | null;
  country_of_recording?: CountryCode | null;
  director?: string | null;
  duration_in_ms?: number | null;
  explicit_type?: ExplicitType | null;
  external_url?: string | null;
  facebook?: MediaPolicyType | null;
  genre?: Genre | null;
  is_cover?: boolean | null;
  isrc?: string | null;
  iswc?: string | null;
  label?: string | null;
  language?: MediaLanguageCode | null;
  lyrics?: string | null;
  media_type?: MediaType | null;
  music_video_rights?: NewRightsObject | null;
  production_company?: string | null;
  s3_key?: string | null;
  secondary_genre?: Genre | null;
  size?: Scalar$BigInt | null;
  sound_exchange?: SoundExchangePolicyType | null;
  sound_recording_rights?: NewRightsObject | null;
  subcategory?: VideoSubcategory | null;
  title?: string | null;
  upload_error?: boolean | null;
  video_category?: VideoCategory | null;
  video_isrc?: string | null;
  video_producer?: string | null;
  video_type?: SupportedVideo | null;
  web_rights?: NewRightsObject | null;
  writer_credits?: ArtistObject[] | null;
  youtube?: MediaPolicyType | null;
}

/**
 * File Upload Object
 */
export interface FileUploadObject {
  image_height?: number | null;
  image_width?: number | null;
  name: string;
  order?: number | null;
}

/**
 * Autogenerated input type of FinishGoal
 */
export interface FinishGoalInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  goal: ExperimentGoal;
}

/**
 * Autogenerated input type of GenerateAWSV4Signature
 */
export interface GenerateAWSV4SignatureInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  to_sign: string;
}

/**
 * Autogenerated input type of GenerateAlbumSlug
 */
export interface GenerateAlbumSlugInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  album_id?: string | null;
  slug?: string | null;
  expedite?: boolean | null;
  skip_slug_update?: boolean | null;
}

/**
 * Autogenerated input type of GenerateAnalyticsCsv
 */
export interface GenerateAnalyticsCsvInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  album_id: string;
}

/**
 * Autogenerated input type of GenerateIsrc
 */
export interface GenerateIsrcInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
}

/**
 * Autogenerated input type of GenerateS3MultipartUpload
 */
export interface GenerateS3MultipartUploadInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  upload_key?: string | null;
  file_name: string;
  type: UploadType;
}

/**
 * Autogenerated input type of GenerateUpc
 */
export interface GenerateUpcInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
}

/**
 * Cursor input object for GroupedFansCursor
 */
export interface GroupedFansCursorInput {
  limit?: number | null;
  offset?: number | null;
  group_by?: GroupedFansGroupBy | null;
}

/**
 * Cursor input object for HubspotDealsCursor
 */
export interface HubspotDealsCursorInput {
  limit?: number | null;
  offset?: number | null;
  dealname: string;
}

/**
 * Autogenerated input type of IngestLabelUsersAndArtists
 */
export interface IngestLabelUsersAndArtistsInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  s3_key: string;
}

/**
 * Cursor input object for InstagramArtistAnalyticsCursor
 */
export interface InstagramArtistAnalyticsCursorInput {
  limit?: number | null;
  offset?: number | null;
  metric?: string | null;
}

/**
 * Cursor input object for InstagramArtistAudienceAnalyticsCursor
 */
export interface InstagramArtistAudienceAnalyticsCursorInput {
  limit?: number | null;
  offset?: number | null;
  metric?: string | null;
  group_by?: string | null;
}

/**
 * Autogenerated input type of InviteOrganizationUser
 */
export interface InviteOrganizationUserInput {
  client_mutation_id?: string | null;
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  organization_royalty_split?: string | null;
  organization_royalty_split_v2?: number | null;
  artist_name?: string | null;
  role?: UserRole | null;
  country_code?: string | null;
  phone?: string | null;
  has_label_revenue?: boolean | null;
  skip_save?: boolean | null;
}

/**
 * Autogenerated input type of MarkAsRead
 */
export interface MarkAsReadInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  media_id?: string | null;
  album_id?: string | null;
  release_id?: string | null;
  user_id?: string | null;
}

/**
 * Cursor input object for MediasV2Cursor
 */
export interface MediasV2CursorInput {
  sort_by?: MediasV2SortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  category?: string | null;
  generator?: Generator | null;
  ids?: string[] | null;
  include_admins?: boolean | null;
  supports_release?: boolean | null;
  unprotected?: boolean | null;
  with_file_upload?: boolean | null;
  with_sound_recording_rights?: boolean | null;
  with_thumbnail?: boolean | null;
  can_be_split?: boolean | null;
  shared_with_me?: boolean | null;
  shared_with_me_enum?: MediaSharedWithMe | null;
  audio_type?: boolean | null;
  comp_type?: boolean | null;
  all_medias?: boolean | null;
  artist_archived?: boolean | null;
  include_organization_medias?: boolean | null;
  include_comp_medias?: boolean | null;
  search_term?: string | null;
  artist_id?: string | null;
  user_id?: string | null;
  album_id?: string | null;
}

/**
 * Cursor input object for MessagesCursor
 */
export interface MessagesCursorInput {
  limit?: number | null;
  offset?: number | null;
  unread?: boolean | null;
  admin_messages?: boolean | null;
}

/**
 * Cursor input object for MonthlyNetworkRevenuesCursor
 */
export interface MonthlyNetworkRevenuesCursorInput {
  limit?: number | null;
  offset?: number | null;
  network_ids?: string[] | null;
  album_ids?: string[] | null;
  artist_ids?: string[] | null;
  user_ids?: string[] | null;
  media_ids?: string[] | null;
  date?: Scalar$DateTime | null;
}

/**
 * Cursor input object for NetworkRevenuesCursor
 */
export interface NetworkRevenuesCursorInput {
  limit?: number | null;
  offset?: number | null;
  album_id?: string | null;
  artist_id?: string | null;
  media_id?: string | null;
}

/**
 * Composer Object
 */
export interface NewComposerObject {
  composer_id?: string | null;
  email?: string | null;
  invite?: boolean | null;
  name?: string | null;
  ownership_percentage?: number | null;
  perf_rights_org?: string | null;
  should_collect_revenue?: boolean | null;
}

/**
 * Rights Object
 */
export interface NewRightsObject {
  asset_type?: RightsAssetType | null;
  geo_restrictions?: CountryCode[] | null;
  right_control?: boolean | null;
}

/**
 * Autogenerated input type of NotifyUploadComplete
 */
export interface NotifyUploadCompleteInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  upload_key: string;
  s3_key?: string | null;
  type: UploadType;
  file_size?: Scalar$BigInt | null;
  file_name?: string | null;
  external_url?: string | null;
  content_type?: string | null;
  duration?: Scalar$BigInt | null;
}

/**
 * Autogenerated input type of ObserveUser
 */
export interface ObserveUserInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  user_id?: string | null;
  artist_id?: string | null;
}

/**
 * Cursor input object for OrganizationsCursor
 */
export interface OrganizationsCursorInput {
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
}

export interface PayeeAssetObject {
  asset_id: string;
  asset_type: string;
  payee_invite_id: string;
  percentage?: number | null;
}

/**
 * Cursor input object for PayeeInvitesV2Cursor
 */
export interface PayeeInvitesV2CursorInput {
  sort_by?: PayeeInvitesSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  ids?: string[] | null;
  payee_ids?: string[] | null;
  role?: RoyaltyCenterRole | null;
  search_term?: string | null;
  status?: PayeeAssetSplitStatus | null;
  include_archived?: boolean | null;
  archived?: boolean | null;
  charity?: boolean | null;
  include_charity?: boolean | null;
}

/**
 * Autogenerated input type of PresignedUrl
 */
export interface PresignedUrlInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  files: FileUploadObject[];
  type: UploadType;
}

/**
 * Cursor input object for QuarterlyEarningsCursor
 */
export interface QuarterlyEarningsCursorInput {
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  year?: number | null;
  album_id?: string | null;
  artist_id?: string | null;
  media_id?: string | null;
}

/**
 * Cursor input object for RankedMediasCursor
 */
export interface RankedMediasCursorInput {
  sort_by?: RankedMediasCursorSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  network: AudioAnalyticsNetworks;
}

/**
 * Recoupment Item Object
 */
export interface RecoupmentItemObject {
  amount: number;
  category: string;
  description: string;
}

/**
 * Autogenerated input type of RegenerateMerchItemVariants
 */
export interface RegenerateMerchItemVariantsInput {
  client_mutation_id?: string | null;
  id: string;
  batch_size?: number | null;
  image_s3_path?: string | null;
  canvas_color?: string | null;
  skip_save?: boolean | null;
}

/**
 * Autogenerated input type of RejectPayeeAssetSplit
 */
export interface RejectPayeeAssetSplitInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  media_id: string;
}

/**
 * Cursor input object for ReleasesV2Cursor
 */
export interface ReleasesV2CursorInput {
  limit?: number | null;
  offset?: number | null;
  album_id?: string | null;
  artist_id?: string | null;
  organization_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  types?: MediaType[] | null;
  release_statuses?: ReleaseCalendarStatusFilterOption[] | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
}

/**
 * Autogenerated input type of RescanSmartlinks
 */
export interface RescanSmartlinksInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  album_id: string;
}

/**
 * Autogenerated input type of ResendConfirmationEmail
 */
export interface ResendConfirmationEmailInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
}

/**
 * Autogenerated input type of ResendPayeeInvite
 */
export interface ResendPayeeInviteInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
}

/**
 * Autogenerated input type of ResendWelcomeInvitation
 */
export interface ResendWelcomeInvitationInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  user_id: string;
}

/**
 * Autogenerated input type of ResetPassword
 */
export interface ResetPasswordInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  password?: string | null;
  confirm_password?: string | null;
  token?: string | null;
}

/**
 * Cursor input object for RevenueActualsCursor
 */
export interface RevenueActualsCursorInput {
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  organization_id?: string | null;
  user_id?: string | null;
  media_id?: string | null;
}

/**
 * Cursor input object for RevenuePredictionsCursor
 */
export interface RevenuePredictionsCursorInput {
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  organization_id?: string | null;
  user_id?: string | null;
  media_id?: string | null;
}

/**
 * Cursor input object for RootMediasCursor
 */
export interface RootMediasCursorInput {
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
}

/**
 * Cursor input object for RootUsersCursor
 */
export interface RootUsersCursorInput {
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
}

/**
 * Cursor input object for RoyaltyRevenues
 */
export interface RoyaltyRevenuesInput {
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  search_term?: string | null;
  network_ids?: string[] | null;
  country_codes?: AnalyticsCountryCode[] | null;
  user_id?: string | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  sort?: RoyaltyRevenuesSortBy | null;
  table_type?: OverviewTable | null;
}

/**
 * Cursor input object for SalesTrends
 */
export interface SalesTrendsInput {
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  group_by?: SalesTrendGroupBy | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  network_ids?: string[] | null;
  country_codes?: CountryCode[] | null;
  asset_type?: AssetType | null;
  media_type?: MediaType | null;
  user_id?: string | null;
  artist_id?: string | null;
  organization_id?: string | null;
  album_id?: string | null;
  media_id?: string | null;
  sort?: SalesTrendsSortBy | null;
  table_data?: boolean | null;
}

/**
 * Autogenerated input type of SendBalanceHistoryDetailsReport
 */
export interface SendBalanceHistoryDetailsReportInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  organization_id?: string | null;
  balance_adjustment_id?: string | null;
  month?: Scalar$Date | null;
  end_month?: Scalar$Date | null;
  emails?: string | null;
}

/**
 * Autogenerated input type of SendMediaAnalyticsReport
 */
export interface SendMediaAnalyticsReportInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
  artist_id?: string | null;
  album_id?: string | null;
  user_id?: string | null;
  media_id?: string | null;
  organization_id?: string | null;
  email_addresses: string[];
  report_type?: string | null;
}

/**
 * Autogenerated input type of SendOTPCode
 */
export interface SendOTPCodeInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  phone?: string | null;
  save_phone?: boolean | null;
}

/**
 * Autogenerated input type of SendResetPasswordEmail
 */
export interface SendResetPasswordEmailInput {
  client_mutation_id?: string | null;
  email?: string | null;
  skip_save?: boolean | null;
}

/**
 * Autogenerated input type of SendRoyaltyCenterPaymentHistory
 */
export interface SendRoyaltyCenterPaymentHistoryInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  payee_invite_id: string;
}

/**
 * Autogenerated input type of SendYoutubeConflictsReport
 */
export interface SendYoutubeConflictsReportInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  artist_id?: string | null;
  media_id?: string | null;
  user_id?: string | null;
  organization_id?: string | null;
  resolved?: boolean | null;
  generate_totals?: boolean | null;
  group_dataset_by_custom_id?: boolean | null;
  emails?: string | null;
}

/**
 * Shopify product to be shown on a smartlink. NOTE: Please keep fields in sync with types/objects/shopify_product
 */
export interface ShopifySmartlinksProduct {
  description?: string | null;
  image_url?: string | null;
  online_store_url?: string | null;
  order: number;
  price?: string | null;
  shop_name: string;
  shopify_id: string;
  title: string;
}

/**
 * Cursor input object for ShopifyStoreProductsCursor
 */
export interface ShopifyStoreProductsCursorInput {
  limit?: number | null;
  offset?: number | null;
  end_cursor?: string | null;
  search_term?: string | null;
}

/**
 * Autogenerated input type of SignAffidavit
 */
export interface SignAffidavitInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  tax_entity_name?: string | null;
  signature?: string | null;
  entity_name?: string | null;
}

/**
 * Autogenerated input type of SignInUser
 */
export interface SignInUserInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  email: string;
  password: string;
  new_user?: boolean | null;
}

/**
 * Cursor input object for SmartLinksAnalytics
 */
export interface SmartLinksAnalyticsInput {
  sort_by?: SmartLinksAnalyticsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  table_type?: SmartLinksAnalyticsTable | null;
  user_id?: string | null;
  album_id?: string | null;
  artist_id?: string | null;
}

/**
 * Cursor input object for SmartlinksGoogleAnalytics
 */
export interface SmartlinksGoogleAnalyticsInput {
  limit?: number | null;
  offset?: number | null;
  table_type?: SmartlinksGoogleAnalyticsTable | null;
  sort?: SmartlinksGoogleAnalyticsSortBy | null;
}

/**
 * Social Object
 */
export interface Socials {
  id?: string | null;
  deployer_name?: string | null;
  facebook_page_id?: number | null;
  network_id?: number | null;
  social_id?: number | null;
  thumbnail_url?: string | null;
  title?: string | null;
  trim_end_time?: string | null;
  trim_start_time?: string | null;
  twitter_user_id?: number | null;
  youtube_channel_id?: number | null;
}

export interface SplitObject {
  asset_splits: PayeeAssetObject[];
  media_id: string;
}

/**
 * Cursor input object for SpotifyArtistAnalyticsCursor
 */
export interface SpotifyArtistAnalyticsCursorInput {
  limit?: number | null;
  offset?: number | null;
  metric?: string | null;
}

/**
 * Cursor input object for StatementsCursor
 */
export interface StatementsCursorInput {
  limit?: number | null;
  offset?: number | null;
  group_by: StatementsGroupBy;
  network_ids?: string[] | null;
  start_date?: Scalar$Date | null;
  end_date?: Scalar$Date | null;
}

/**
 * Autogenerated input type of SubmitCaptcha
 */
export interface SubmitCaptchaInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  token: string;
}

/**
 * Autogenerated input type of SwitchUmbrellaUser
 */
export interface SwitchUmbrellaUserInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  linked_user_id?: string | null;
}

/**
 * Autogenerated input type of ToggleOtpMfa
 */
export interface ToggleOtpMfaInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  otp_mfa?: boolean | null;
  verify_method: OtpMethodType;
}

/**
 * Cursor input object for TopAlbumMediasCursor
 */
export interface TopAlbumMediasCursorInput {
  limit?: number | null;
  offset?: number | null;
  album_id?: string | null;
  include_admins?: boolean | null;
}

/**
 * Cursor input object for TopAlbumsCursor
 */
export interface TopAlbumsCursorInput {
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
  album_ids?: string[] | null;
  artist_ids?: string[] | null;
  user_id?: string | null;
  include_admins?: boolean | null;
}

/**
 * Cursor input object for TopArtistsCursor
 */
export interface TopArtistsCursorInput {
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
  artist_ids?: string[] | null;
  include_admins?: boolean | null;
}

/**
 * Cursor input object for TopGeocountryCursor
 */
export interface TopGeocountryCursorInput {
  limit?: number | null;
  offset?: number | null;
  album_id?: string | null;
  artist_id?: string | null;
  media_id?: string | null;
  include_admins?: boolean | null;
}

/**
 * Cursor input object for TopMediasCursor
 */
export interface TopMediasCursorInput {
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
  album_ids?: string[] | null;
  artist_ids?: string[] | null;
  media_ids?: string[] | null;
  include_admins?: boolean | null;
}

/**
 * Cursor input object for TopUsersCursor
 */
export interface TopUsersCursorInput {
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
  include_admins?: boolean | null;
  user_ids?: string[] | null;
}

/**
 * Autogenerated input type of UnarchivePayeeInvite
 */
export interface UnarchivePayeeInviteInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  payee_invite_id: string;
  confirmed?: boolean | null;
}

/**
 * Autogenerated input type of UpdateAlbumArtwork
 */
export interface UpdateAlbumArtworkInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
  image_path: string;
}

/**
 * Autogenerated input type of UpdateAlbumLinks
 */
export interface UpdateAlbumLinksInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  album_id: string;
  links?: ExtraLinkObject[] | null;
  extra_links_title?: string | null;
  extra_links_enabled: boolean;
}

/**
 * Autogenerated input type of UpdateAlbumMediaIds
 */
export interface UpdateAlbumMediaIdsInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  name?: string | null;
  artist_id?: string | null;
  genre?: Genre | null;
  secondary_genre?: Genre | null;
  label?: string | null;
  copyright_name?: string | null;
  copyright_year?: number | null;
  upc?: string | null;
  territories?: string[] | null;
  has_previously_released?: boolean | null;
  original_release_date?: Scalar$Date | null;
  additional_artists?: AdditionalArtistObject[] | null;
  id: string;
  media_ids?: string[] | null;
}

/**
 * Autogenerated input type of UpdateAlbumMetaPixel
 */
export interface UpdateAlbumMetaPixelInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
  pixel_eid?: string | null;
}

/**
 * Autogenerated input type of UpdateAlbumMetadata
 */
export interface UpdateAlbumMetadataInput {
  client_mutation_id?: string | null;
  id: string;
  skip_save?: boolean | null;
  name?: string | null;
  artist_id?: string | null;
  genre?: Genre | null;
  secondary_genre?: Genre | null;
  label?: string | null;
  copyright_name?: string | null;
  copyright_year?: number | null;
  upc?: string | null;
  territories?: string[] | null;
  has_previously_released?: boolean | null;
  original_release_date?: Scalar$Date | null;
  additional_artists?: AdditionalArtistObject[] | null;
}

/**
 * Autogenerated input type of UpdateAlbumPartnerData
 */
export interface UpdateAlbumPartnerDataInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
  album_partner_data: AlbumPartnerDataObject[];
  partner_url_required?: boolean | null;
  pre_release?: boolean | null;
}

/**
 * Autogenerated input type of UpdateArtistAppleEid
 */
export interface UpdateArtistAppleEidInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  artist_id: string;
  eid?: string | null;
}

/**
 * Autogenerated input type of UpdateArtist
 */
export interface UpdateArtistInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
  name?: string | null;
  bio?: string | null;
  site?: string | null;
  soundcloud?: string | null;
  itunes?: string | null;
  beatport?: string | null;
  facebook?: string | null;
  tumblr?: string | null;
  twitter?: string | null;
  instagram?: string | null;
  vevo_user?: string | null;
  youtube_channel_url?: string | null;
  spotify?: string | null;
  image_path?: string | null;
  default_label_name?: string | null;
  default_copyright_name?: string | null;
  default_copyright_year?: number | null;
  default_genre?: Genre | null;
  default_secondary_genre?: Genre | null;
  default_language_code?: MediaLanguageCode | null;
}

/**
 * Autogenerated input type of UpdateArtistSpotifyEid
 */
export interface UpdateArtistSpotifyEidInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  artist_id: string;
  eid?: string | null;
}

/**
 * Autogenerated input type of UpdateCategory
 */
export interface UpdateCategoryInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
  category: string;
  subcategory?: string | null;
}

/**
 * Autogenerated input type of UpdateComposer
 */
export interface UpdateComposerInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  composer_id: string;
  first_name: string;
  middle_name?: string | null;
  last_name: string;
  email?: string | null;
  invite?: boolean | null;
  perf_rights_org?: string | null;
  ipi?: string | null;
  publisher: string;
  publisher_ipi: string;
  user_id?: string | null;
}

/**
 * Autogenerated input type of UpdateDeal
 */
export interface UpdateDealInput {
  client_mutation_id?: string | null;
  id: string;
  skip_save?: boolean | null;
  title?: string | null;
  borrower_recoupment_period_months?: number | null;
  client_after_recoupment_split_percent?: number | null;
  client_recoupment_split_percent?: number | null;
  irr?: number | null;
  lender_payback_period_months?: number | null;
  moic?: number | null;
  monthly_gross_cash_flow_dollars?: number | null;
  monthly_growth_rate_delay_months?: number | null;
  monthly_growth_rate_percent?: number | null;
  term_months?: number | null;
  advances?: DealAdvanceObject[] | null;
  organization_id?: string | null;
  user_id?: string | null;
  media_id?: string | null;
  latest_review_note?: string | null;
  latest_review_status?: DealReviewStatus | null;
  profit_thru_recoupment?: number | null;
  profit_thru_term?: number | null;
  hubspot_eid?: string | null;
  hubspot_dealname?: string | null;
  archived?: boolean | null;
}

/**
 * Autogenerated input type of UpdateEventNotificationSubscription
 */
export interface UpdateEventNotificationSubscriptionInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  enable_notifications?: boolean | null;
  release_id?: string | null;
  event_notification_subscriptions?: EventNotificationSubscriptionObject[] | null;
}

/**
 * Autogenerated input type of UpdateMetadata
 */
export interface UpdateMetadataInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
  additional_artists?: AdditionalTrackArtistObject[] | null;
  additional_credits?: AdditionalTrackArtistObject[] | null;
  album?: string | null;
  audio_isrc?: string | null;
  collaborating_artists?: AdditionalTrackArtistObject[] | null;
  composer?: string | null;
  copyright_name?: string | null;
  copyright_year?: number | null;
  director?: string | null;
  genre?: Genre | null;
  secondary_genre?: Genre | null;
  iswc?: string | null;
  is_cover?: boolean | null;
  explicit_type?: ExplicitType | null;
  label?: string | null;
  language_code?: string | null;
  lyrics?: string | null;
  production_company?: string | null;
  song_name?: string | null;
  video_isrc?: string | null;
  video_producer?: string | null;
  country_of_recording?: string | null;
  video_type?: SupportedVideo | null;
  writer_credits?: AdditionalTrackArtistObject[] | null;
  validate_writer_credits_presence?: boolean | null;
  clip_start_time?: Scalar$TrackPosition | null;
}

/**
 * Autogenerated input type of UpdateOrganization
 */
export interface UpdateOrganizationInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  organization_id: string;
  name?: string | null;
}

/**
 * Autogenerated input type of UpdateOwnershipAndPolicies
 */
export interface UpdateOwnershipAndPoliciesInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  metadata_id: string;
  composers?: EditComposerObject[] | null;
  music_video_rights_object?: EditRightsObject | null;
  sound_recording_rights_object?: EditRightsObject | null;
  composition_rights_object?: EditRightsObject | null;
  web_rights_object?: EditRightsObject | null;
  youtube_policy?: MediaPolicyType | null;
  facebook_policy?: MediaPolicyType | null;
  validation_section?: OwnershipPoliciesSection | null;
}

/**
 * Autogenerated input type of UpdateSeenFeatures
 */
export interface UpdateSeenFeaturesInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  feature: Features;
}

/**
 * Autogenerated input type of UpdateShopifyStoreProducts
 */
export interface UpdateShopifyStoreProductsInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  album_id: string;
  shopify_products?: ShopifySmartlinksProduct[] | null;
  smartlinks_shopify_store_title?: string | null;
}

/**
 * Autogenerated input type of UpdateShowShopifyFeed
 */
export interface UpdateShowShopifyFeedInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  album_id: string;
  show_shopify_feed: boolean;
}

/**
 * Autogenerated input type of UpdateSocialSync
 */
export interface UpdateSocialSyncInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  id: string;
  artist_id: string;
  is_syncing?: boolean | null;
  default_subcategory?: string | null;
  deployer_name: string;
  web?: boolean | null;
}

/**
 * Autogenerated input type of UpdateUser
 */
export interface UpdateUserInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  user_id: string;
  first_name?: string | null;
  last_name?: string | null;
  phone?: string | null;
  email?: string | null;
}

/**
 * User Search
 */
export interface UserSearch {
  column: UserSearchBy;
  operator?: SearchByOperator | null;
  term: string;
}

/**
 * Cursor input object for UsersCursor
 */
export interface UsersCursorInput {
  sort_by?: UsersCursorSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  search_term?: string | null;
  where?: UserSearch[] | null;
  include_admins?: boolean | null;
  invite_status?: UserInviteStatus | null;
  role?: UserRole | null;
}

/**
 * Autogenerated input type of VerifyOTPCode
 */
export interface VerifyOTPCodeInput {
  client_mutation_id?: string | null;
  skip_save?: boolean | null;
  verify_method: OtpMethodType;
  code: string;
  remember_device?: boolean | null;
}

/**
 * Cursor input object for YoutubeArtistAnalyticsCursor
 */
export interface YoutubeArtistAnalyticsCursorInput {
  limit?: number | null;
  offset?: number | null;
  metric?: string | null;
}

/**
 * Cursor input object for YoutubeConflictsCursor
 */
export interface YoutubeConflictsCursorInput {
  sort_by?: YoutubeConflictsSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  artist_id?: string | null;
  media_id?: string | null;
  unknown_artist__user_id?: string | null;
  user_id?: string | null;
  organization_id?: string | null;
  resolved?: boolean | null;
  generate_totals?: boolean | null;
  group_dataset_by_custom_id?: boolean | null;
}

/**
 * Cursor input object for YoutubeUgcCursor
 */
export interface YoutubeUgcCursorInput {
  sort_by?: YoutubeUgcSortBy | null;
  sort_order?: SortOrder | null;
  limit?: number | null;
  offset?: number | null;
  where?: YoutubeUgcSearch[] | null;
  media_id?: string | null;
  artist_id?: string | null;
  unknown_artist__user_id?: string | null;
  claim_id?: string | null;
  user_id?: string | null;
  organization_id?: string | null;
  remove_claims_with_zero_views?: boolean | null;
  generate_totals?: boolean | null;
}

/**
 * Youtube UGC Search
 */
export interface YoutubeUgcSearch {
  column: YoutubeUgcSearchBy;
  operator?: SearchByOperator | null;
  term: string;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
